import injector from '@/scaffold/injector';
import { getModule } from '@/scaffold/app';
import { NAMESPACE_MAIN } from '@/scaffold/namespaces';

import observer from '@/libs/ni-observer';

const createUserModel = injector.resolve([null, 'Globals', 'Pubsub', 'ApiFactory', 'Cookies'], (instance = {}, Globals, Pubsub, ApiFactory, Cookies) => {
    const userData = {};
    let fetching = false;
    let isLoading = false;

    // ** Composition **

    observer(instance);

    // ** Private functions **

    const setAppData = () => {
        // Functionality is only available when user is logged in
        if (instance.isLoggedIn()) {
            Globals.cookbooks.fetch();
            Globals.bookmarks.fetch();
            Globals.shoppingLists.fetch();
        }
    };

    const fetchSuccess = (response) => {
        const data = typeof response === 'string' ? JSON.parse(response) : response;

        Object.entries(data).forEach(([key, value]) => {
            userData[key] = value;
        });

        setAppData();

        // Notify listeners
        instance.publish('fetched', [instance]);
        Pubsub.publish('user.fetched', [instance]);

        // Conditionally initiate agbCheck module
        if (!instance.isLoggedIn() && (instance.get('foobyAgb') === false || instance.get('supercardAgb') === false)) {
            // TODO; There should be a better solution to provide the namespace than by providing a hardcoded string
            getModule(NAMESPACE_MAIN, 'agb-check').init();
        }

        Cookies.set('userLoginStatus', instance.isLoggedIn() ? 'registered' : 'anonymous');
        Cookies.set('userProfileID', data['tracking_id'] || '');
    };

    const fetchError = (error) => {
        console.error('User data could not be parsed.', error);
    };

    const agbsAcceptedHandler = () => {
        userData.foobyAgb = true;
        userData.supercardAgb = true;

        setAppData();

        // TODO: get reinitialization to work
        // instance.publish('changed', [instance]);
        // Pubsub.publish('user.changed', [instance]);
        location.reload();
    };

    // ** Public functions **

    instance.fetch = (refetch = false) => {
        if (isLoading) {
            return fetching;
        }

        isLoading = true;

        if (!fetching || refetch) {
            fetching = new Promise((resolve, reject) => {
                const api = ApiFactory(store.apis.user, {
                    success: (response) => {
                        fetchSuccess(response);
                        resolve({ user: userData, response });
                    },
                    error: (error) => {
                        fetchError(error);
                        reject(error);
                    }
                });

                api.send();
            });
        }

        return fetching;
    };

    /**
     * Get property from user data
     * @param {String} property - Property name to return.
     * @return {Any} Returns value of given property. Returns empty string if property doesn't exist.
     */
    instance.get = function (property) {
        if (userData.hasOwnProperty(property)) {
            return userData[property];
        } else {
            return undefined;
        }
    };

    /**
     * Check login-status of user
     * @returns {Boolean} true if the user is logged in, false if not
     */
    instance.isLoggedIn = function () {
        return userData.hasOwnProperty('supercard_id')
            && userData['supercard_id'] !== ''
            && userData.hasOwnProperty('foobyAgb')
            && userData.foobyAgb === true
            && userData.hasOwnProperty('supercardAgb')
            && userData.supercardAgb === true;
    };

    /**
     * Add new user from AJAX resonse
     * @param {Object} response - The response from the AJAC call
     */
    instance.addUser = function (response) {
        fetchSuccess(response);
    };

    /**
     * Check if there is an SSO Session available for the user
     * Automatically logs in when session is available
     * @return {Promise} - Resolves with { error: true } if failed and with { user: userData } if successfull
     */
    instance.checkSSOSessionState = function () {

        // Only perform call, when user is not already logged in
        if (instance.isLoggedIn()) {
            return Promise.resolve({ user: userData });
        }

        return new Promise(resolve => {

            const http = new XMLHttpRequest();

            http.onload = function (event) {
                const response = JSON.parse(event.target.response);

                if (response.anonymous) {
                    // Resolve with error in authentication
                    resolve({ error: true });
                } else {
                    // User session found, add it to the global user state
                    instance.addUser(response);

                    // Resolve with the new userdata
                    resolve({ user: userData });
                }
            };

            // Set URL and add timestamp to bypass chaching this request (necessary for IE11)
            const url = store.apis.userSSOGateway;
            http.open('GET', url + ((/\?/).test(url) ? '&' : '?') + (new Date()).getTime());
            http.send();
        });
    };

    instance.init = () => {
        Pubsub.subscribe('agb-check.accepted', agbsAcceptedHandler);
    };

    instance.init();

    return instance;
});

// Use commonjs export
export default createUserModel;
