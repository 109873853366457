

// vars
var defaults = {
    searchfield: '.searchbar__searchfield',
    trigger: '.searchbar__searchtrigger',
    button: '.searchbar__btn',
    autocomplete: {
        init: false,
        threshhold: 2
    },
    onSubmit: {},
    onKeyup: {},
    onReset: {},
    onFocus: {},
    onBlur: {}
};

/**
 * Handles a searchbar and trigger
 *
 * @param {object} searchbar - overwrites default settings (optional)
 * @param {object} options - overwrites default settings (optional)
 * @return {object} instance of factory
 * */
export default function (searchbar, options) {
    var settings = Object.assign({}, defaults, options),
        isButtonBound = false,
        instance = {
            searchfield: searchbar.querySelector(settings.searchfield),
            trigger: searchbar.querySelector(settings.trigger),
            button: searchbar.querySelector(settings.button)
        },

        /**
         * Executes the onSubmit callback and checks buttonstatus
         */
        submit = function() {
            if (settings.onSubmit instanceof Function) {
                settings.onSubmit.call(instance, instance.searchfield.value);
            }

            if(instance.button instanceof Element) {
                checkButtonStatus(instance.searchfield.value);
            }
        },

        /**
         * If enterbutton is clicked, submit action gets fired
         *
         * @param {object} event of the click
         */
        bindEnter = function(event) {
            if(event.keyCode === 13) {
                submit();
            }
        },

        /**
         * Onclick function of the resetbutton
         */
        buttonClick = function() {
            instance.searchfield.value = '';
            submit();
        },

        /**
         * Binds the resetbutton
         */
        bindButton = function() {
            isButtonBound = true;
            // instance.button.classList.remove('btn--state-inactive');
            instance.button.removeAttribute('disabled');
            instance.button.addEventListener('click', buttonClick);
        },

        /**
         * Unbinds the resetbutton
         */
        unbindButton = function() {
            isButtonBound = false;
            // instance.button.classList.add('btn--state-inactive');
            instance.button.setAttribute('disabled', 'disabled');
            instance.button.removeEventListener('click', buttonClick);
        },

        /**
         * Enebled/disables the resetbutton and sets state-inactive accordingly
         *
         * @param {string} searchfieldValue value to check
         * @param {boolean} setAnyway binds/unbinds button allways (optional)
         */
        checkButtonStatus = function(searchfieldValue, setAnyway) {
            setAnyway = setAnyway || false;

            if (searchfieldValue === '' && (isButtonBound === true || setAnyway === true)) {
                unbindButton();
            } else if (searchfieldValue !== '' && (isButtonBound === false || setAnyway === true)) {
                bindButton();
            }
        },

        /**
         * Bind all events to the elements
         */
        bindEvents = function() {

            if(instance.searchfield instanceof Element) {
                instance.searchfield.addEventListener('focus', function() {
                    document.addEventListener('keyup', bindEnter);
                });

                if(settings.onFocus instanceof Function) {
                    instance.searchfield.addEventListener('focus', function() {
                        settings.onFocus.call(instance, this.value);
                    });
                }

                instance.searchfield.addEventListener('blur', function() {
                    document.removeEventListener('keyup', bindEnter);
                });

                if(settings.onBlur instanceof Function) {
                    instance.searchfield.addEventListener('blur', function() {
                        settings.onBlur.call(instance, this.value);
                    });
                }

                instance.searchfield.addEventListener('keydown', function(event) {
                    if (event.which === 38 || event.which === 40) {
                        event.preventDefault();
                        return;
                    }
                });

                instance.searchfield.addEventListener('keyup', function(event) {

                    if (event.which === 38 || event.which === 40 || event.which === 13) {
                        event.preventDefault();
                        return;
                    }

                    if (settings.onKeyup instanceof Function) {
                        settings.onKeyup.call(instance, this.value);
                    }
                });
            }

            if(instance.trigger instanceof Element) {
                instance.trigger.addEventListener('click', function() {
                    submit();
                });
            }
        };

    /**
     * Set or overwrite properties in the settings object
     *
     * @param {object} options - overwrites current settings (optional)
     * @return {object} returns instance of factory
     * */
    instance.setOptions = function(options) {
        settings = Object.assign({}, settings, options);

        // Optionally update internal logic

        return instance;
    };

    /**
     * Initializes the factory
     *
     * @return {object} instance
     */
    instance.init = function() {

        bindEvents();

        // checkButtonStatus(instance.searchfield.value, true);

        return instance;
    };

    /**
     * Should revert to initial state
     *
     * @return {object} instance
     */
    instance.destroy = function() {

        return instance;
    };

    /**
     * Overwrites the value of the input field
     *
     * @param {string} value to set the value to
     * @return {object} instance
     *
     */
    instance.setValue = function(value) {
        instance.searchfield.value = value;

        return instance;
    };

    /**
     * Submits the searchbar with optional new value for the input field
     *
     * @param {string} value to optionally overwrite what is currently in the input field
     * @return {object} instance
     */
    instance.submit = function(value) {

        value = value || undefined;

        if (value !== undefined) {
            instance.searchfield.value = value;
        }

        submit();

        return instance;
    };

    /**
     * Blurs the input field
     *
     * @return {object} instance
     */
    instance.blur = function() {

        instance.searchfield.blur();

        return instance;
    };

    /**
    * Disable input and trigger button
    * @return {object} instance
    */
    instance.disable = function() {
        instance.searchfield.setAttribute('disabled', '');
        instance.trigger.setAttribute('disabled', '');
        return instance;
    };

    /**
     * Enable input and trigger button
     * @return {object} instance
     */
    instance.enable = function() {
        instance.searchfield.removeAttribute('disabled');
        instance.trigger.removeAttribute('disabled');
        return instance;
    };

    instance.checkButtonStatus = checkButtonStatus;

    return instance.init();
};
