

// dependencies
var scopedPubsub = require('@/libs/ni-scoped-pub-sub').default,
    serialize = require('@/helpers/helper').default.serialize,
    render = require('@/libs/render').default;

// vars
var defaults = {
    wrappers: '.common-search-terms',
    tags: '.tag'
};

var loading = null;

/**
 * Loads common search terms inside given container
 *
 * @param {object} options to overwrite default options
 * @return {object} instance
 */
export default function(options) {
    var that = this || document,
        settings = Object.assign({}, defaults, options),
        instance = {},
        wrappers = [],
        tags,

        /**
         * Binds the events to the tags
         */
        bindEvents = function() {

            for (var i = 0; i < wrappers.length; i++) {
                tags = wrappers[i].querySelectorAll(settings.tags);

                for (var w = 0; w < tags.length; w++) {
                    tags[w].addEventListener('click', function(event) {
                        instance.publish('click', this, [event]);
                    });
                }
            }
        },

        request = async function() {
            if (loading) {
                return loading;
            }

            loading = new Promise((resolve) => {
                var http = new XMLHttpRequest();

                http.onload = function(event) {
                    var response = JSON.parse(event.target.response);
                    resolve(response);
                };

                http.open('GET', store.apis.commonSearchTerms + '?' + serialize({
                    lang: store.lang
                }));

                http.send();
            });

            return loading;
        },

        /**
         * Initializes the factory
         *
         * @return {object} instance
         */
        init = function() {

            wrappers = that.querySelectorAll(settings.wrappers);

            // Extends the instance with the functions subscribe(), unsubscribe() & publish()
            scopedPubsub(instance);

            return instance;
        };

    /**
     * Loads the common search tearms from solr
     * @returns {Promise} - Status of tags loading
     */
    instance.loadTags = async function() {
        return request().then((response) => {
            var rendered = render.tags(response.terms);

            for (var i = 0; i < wrappers.length; i++) {
                wrappers[i].innerHTML = rendered;
            }

            bindEvents();
        });
    };

    return init();
};
