

/*
 * Markup for link:
 * <a href="http://www.notch-interactive.com"
 *    data-alert-message-trigger data-alert-message-title="Bist du einverstanden?"
 *    data-alert-message-text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr."
 *    data-alert-message-confirm-label="Ich bin damit einverstanden."
 *    data-alert-message-doubleconfirm="false">triggers here</a>
 */

// dependencies
var forEach = require('@/helpers/helper').default.forEach,
    miniModal = require('@/libs/mini-modal').default,
    pubsub = require('@/scaffold/pubsub').default;

// vars
var defaults = {
        globAttribtues: {
            trigger: 'data-alert-message-trigger',
            nocloser: 'data-alert-message-nocloser',
            title: 'data-alert-message-title',
            text: 'data-alert-message-text',
            confirmLabel: 'data-alert-message-confirm-label',
            option: 'data-alert-message-doubleconfirm',
            cancleBtnLabel: 'data-alert-message-cancle-btn-text',
            confirmBtnLabel: 'data-alert-message-confirm-btn-text',
            confirmBtnStyle: 'data-alert-message-confirm-btn-style'
        },

        modalElements: {
            title: 'data-alert-message-title',
            text: 'data-alert-message-text',
            confirmWrapper: 'data-alert-message-confirm-wrapper',
            confirmField: 'data-alert-message-confirm-input',
            confirmLabel: 'data-alert-message-confirm-label',
            cancleTrigger: 'data-alert-message-cancle-btn',
            confirmTrigger: 'data-alert-message-confirm-btn'
        },

        miniModal: {
            modalOverlayClass: 'mini-modal__background',
            modalContentClass: 'mini-modal__content',
            modalCloseBtnClass: 'mini-modal__close',
            bodyOpenClass: 'mini-modal--active',
            modalOpenClass: 'mini-modal--open',
            backgroundClickClose: true,
            escClose: true,
            openImmediately: false,
            moveToBodyEnd: true,
            onInit: function () {},
            onBeforeOpen: function () {},
            onBeforeClose: function () {}
        }
    },
    modalId = 'alert-message';

/**
 * Factory for alert messages
 *
 * @param {object} options - overwrites default settings (optional)
 * @return {object} returns instance of factory
 */
export default function (options) {
    var that = this || document,
        instance = {},
        settings,
        triggers,
        href,
        nocloser,
        modal,
        modalElements = {},

        /**
         * Closes the overlay
         */
        cancle = function() {
            if (!href) {
                pubsub.publish('alert-modal-canceled');
            }
            modal.close();
        },

        /**
         * Confirms the link click
         */
        confirm = function() {
            if (href) {
                window.location = href;
            } else {
                pubsub.publish('alert-modal-confirmed');
            }
            modal.close();
        },

        /**
         * Binds the standardevents
         */
        bindEventHandlers = function() {
            modalElements.cancleTrigger.addEventListener('click', cancle);
            modalElements.confirmTrigger.addEventListener('click', confirm);
        },

        /**
         * Uninds the standardevents & the checkbox event
         */
        unbindEventHandlers = function() {
            modalElements.cancleTrigger.removeEventListener('click', cancle);
            modalElements.confirmTrigger.removeEventListener('click', confirm);
            modalElements.confirmField.removeEventListener('change', checkboxChange);
        },

        /**
         * disabled/enables the confirm button
         */
        checkboxChange = function() {
            if (this.checked) {
                modalElements.confirmTrigger.removeAttribute('disabled');
            } else {
                modalElements.confirmTrigger.setAttribute('disabled', '');
            }
        };

    /**
     * Initializes the factory
     *
     * @param {object} options to overwrite default settings
     * @return {object} instance
     */
    instance.init = function(options) {
        settings = Object.assign({}, defaults, options);

        triggers = that.querySelectorAll('[' + settings.globAttribtues.trigger + ']');

        if(triggers instanceof NodeList && triggers.length > 0) {

            forEach(triggers, function(trigger) {

                trigger.addEventListener('click', function(event) {
                    event.preventDefault();

                    href = trigger.getAttribute('href');
                    nocloser = trigger.hasAttribute(settings.globAttribtues.nocloser) ? true : false;

                    if (nocloser) {
                        modal = miniModal.create(modalId, {
                            onBeforeClose: unbindEventHandlers,
                            backgroundClickClose: false,
                            escClose: false
                        });

                        // Hide closing buttons
                        modal.modal.querySelectorAll('.mini-modal__close').forEach(function(el) {
                            el.style.display = 'none';
                        });
                    } else {
                        modal = miniModal.create(modalId, {
                            onBeforeClose: unbindEventHandlers
                        });

                        // Reset closing buttons
                        modal.modal.querySelectorAll('.mini-modal__close').forEach(function(el) {
                            el.style.display = '';
                        });
                    }


                    for (var key in settings.modalElements) {
                        modalElements[key] = modal.modal.querySelector('[' + settings.modalElements[key] + ']');
                    }

                    modalElements.title.innerHTML = this.getAttribute(settings.globAttribtues.title);
                    modalElements.text.innerHTML = this.getAttribute(settings.globAttribtues.text);

                    modalElements.cancleTrigger.querySelector('span').innerHTML = this.getAttribute(settings.globAttribtues.cancleBtnLabel);
                    modalElements.confirmTrigger.querySelector('span').innerHTML = this.getAttribute(settings.globAttribtues.confirmBtnLabel);
                    modalElements.confirmTrigger.setAttribute('data-btn-style', this.getAttribute(settings.globAttribtues.confirmBtnStyle));

                    if (this.getAttribute(settings.globAttribtues.option) === 'true') {
                        modalElements.confirmLabel.innerHTML = this.getAttribute(settings.globAttribtues.confirmLabel);
                        modalElements.confirmWrapper.style.display = 'block';
                        modalElements.confirmField.addEventListener('change', checkboxChange);
                        modalElements.confirmField.checked = false;
                        modalElements.confirmTrigger.setAttribute('disabled', '');
                    } else {
                        modalElements.confirmWrapper.style.display = 'none';
                        modalElements.confirmTrigger.removeAttribute('disabled');
                    }

                    bindEventHandlers();

                    modal.open();
                });
            });
        }

        return instance;
    };

    return instance.init(options);
};
