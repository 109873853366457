import injector from '@/scaffold/injector';

const createRecipeObserver = injector.resolve([null, 'Pubsub'], (instance = {}, pubsub) => {
    let teasersLoadedId = null;
    let observing = false;

    const recipeObserver = function(entries) {
        if (!observing) {
            return;
        }

        const seenEntries = [];

        entries.forEach((entry) => {
            const seen = entry.target.getAttribute('data-seen') !== null;

            if (!seen && entry.isIntersecting && entry.intersectionRatio > 0.5) {
                entry.target.setAttribute('data-seen', '');
                seenEntries.push(entry);
            }
        });

        if (seenEntries.length > 0) {
            pubsub.publish('recipeObserver.seen', [{ seenEntries }]);
        }
    };

    const io = new IntersectionObserver(recipeObserver, {
        threshold: 0.5
    });

    teasersLoadedId = pubsub.subscribe('teasers.added', () => {
        const recipes = document.querySelectorAll('.teaser[data-recipe-id]');

        recipes.forEach((recipe) => {
            io.observe(recipe);
        });
    });

    instance.enableObserving = function() {
        observing = true;
    };

    instance.disableObserving = function() {
        observing = false;
    };

    instance.destroy = function() {
        pubsub.unsubscribe(teasersLoadedId);
    };

    return instance;
});

export default createRecipeObserver;
