import videos from '@/apps/main/modules/videos';

const createRecipeOverlay = ({ el, state }, Hogan, Pubsub, Fractions, BodyScrollLock) => {

    const overlay = el;

    let repoStart;
    let repoStep;
    let repoEnd;
    let repoUtensils;
    let id;

    let calcFunc = function () {};

    const keyupHandler = function (event) {
        if (event.which === 39) {
            state.next();
        } else if (event.which === 37) {
            state.prev();
        } else if (event.which === 27) {
            state.closeOverlay();
        }
    };

    state.init = function() {
        // Prepare JSON
        state.prepareJSON();

        state.load(store.assetsPath + 'tmpl/startscreen.tmpl', state.prepareTemplate, 'repoStart');
        state.load(store.assetsPath + 'tmpl/stepscreen.tmpl', state.prepareTemplate, 'repoStep');
        state.load(store.assetsPath + 'tmpl/endscreen.tmpl', state.prepareTemplate, 'repoEnd');
        state.load(store.assetsPath + 'tmpl/utensilsscreen.tmpl', state.prepareTemplate, 'repoUtensils');

        window.recipeOverlay = state;
    };

    state.prepareJSON = function () {
        /**
         * Returns a function which will be called inside hogan to calculate the quantity of the ingredient
         *
         * @return {function} which will be called inside hogan
         */
        calcFunc = function () {
            return function () {
                // Calculating real quantity depending on what the initial portionamount and the set portionamount are (can be set by the User)
                var factor = document.querySelector('[data-portion-calculator-initial-portions]').innerHTML / document.querySelector('[data-portion-calculator-initial-portions]').getAttribute('data-portion-calculator-initial-portions');
                return Fractions(parseFloat(this.amount) * factor);
            };
        };

        var lastTitle = '';

        var i = recipeJSON.items.length;
        while (i--) {
            // unescape utensils svg markup
            if (recipeJSON.items[i].utensils) {
                if (recipeJSON.hasUtensilsStep) {
                    recipeJSON.items[i].utensils.forEach((utensil, u) => {
                        if (utensil.svg) {
                            recipeJSON.items[i].utensils[u].svg = unescape(utensil.svg);
                        }
                    });
                } else {
                    recipeJSON.items.splice(i, 1);
                }
            } else {
                recipeJSON.items[i]['calcIngredients'] = calcFunc;
                if (recipeJSON.items[i].title === lastTitle) {
                    recipeJSON.items[i].title = '';
                } else {
                    lastTitle = recipeJSON.items[i].title;
                }

                if (recipeJSON.items[i].hasOwnProperty('ingredients') && recipeJSON.items[i].ingredients.length > 0) {
                    // Do nothing, ingredients were found, everything is good
                } else {
                    if (recipeJSON.items[i + 1] && recipeJSON.items[i + 1].title === '' && recipeJSON.items[i + 1].hasOwnProperty('ingredients') && recipeJSON.items[i + 1].ingredients.length > 0) {
                        // Do nothing, since the next title is the same as the current title
                    } else {
                        recipeJSON.items[i].title = ''; // Title should not be output, due to a lack of ingredients. If the Title
                    }
                }
            }
        }

        recipeJSON['calcIngredients'] = calcFunc;
        recipeJSON.url = window.location.href;
    };

    state.prepareTemplate = function (payload, id) {
        switch (id) {
        case 'repoStart':
            repoStart = payload;
            break;
        case 'repoStep':
            repoStep = payload;
            break;
        case 'repoEnd':
            repoEnd = payload;
            break;
        case 'repoUtensils':
            repoUtensils = payload;
            break;
        }
    };

    state.load = function (url, callback, id) {
        var xmlhttp;
        xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
                callback(xmlhttp.responseText, id);
            }
        };
        xmlhttp.open('GET', url, true);
        xmlhttp.send();
    };

    state.start = function () {
        window.addEventListener('keyup', keyupHandler);

        // calc values
        var steps = recipeJSON.items.length + 1;
        var stepValue = parseInt(100 / steps);
        // reset id counter
        id = 1;
        // create startscreen
        state.createPage(repoStart, 0, 0, recipeJSON, true, false);

        // create stepscreens
        for (var i = 1; i <= recipeJSON.items.length; i++) {
            var stepPage = recipeJSON.items[i - 1];
            stepPage.isLittleFooby = recipeJSON.isLittleFooby;
            stepPage.stepNr = recipeJSON.hasUtensilsStep ? i - 1 : i;
            stepPage.stepTotal = recipeJSON.hasUtensilsStep ? recipeJSON.items.length - 1 : recipeJSON.items.length;
            stepPage.step = unescape(stepPage.step);
            stepPage.stepLabel = unescape(stepPage.stepLabel);
            state.createPage(stepPage.utensils ? repoUtensils : repoStep, (i - 1) * stepValue, i * stepValue, stepPage, false, true);
        }
        // create endscreen
        state.createPage(repoEnd, (steps - 1) * stepValue, 100, recipeJSON, false, false);
        // attach step controls
        state.attachControls();

        if (parseInt(document.querySelector('[data-portion-calculator-initial-portions]').innerHTML) === 1) {
            document.querySelector('.recipe-ingredientlist-header--label').classList.add('is-single');
        } else {
            document.querySelector('.recipe-ingredientlist-header--label').classList.remove('is-single');
        }

        overlay.classList.add('is-show');
        document.body.classList.add('recipeoverlay-active');

        // trigger initiation of things
        Pubsub.publish('tooltip.init');
        Pubsub.publish('likes.bindTrigger');
        Pubsub.publish('likes.loadLikes');
        Pubsub.publish('likes.setActives');

        Pubsub.publish('cookoverlay.opened');

        BodyScrollLock.disableBodyScroll(overlay.querySelector('.overlay-page.p1'));

        Array.from(overlay.querySelectorAll('.controller-interface.top')).forEach((overlayPageController) => {
            BodyScrollLock.disableBodyScroll(overlayPageController);
        });
    };

    state.closeOverlay = function () {

        window.removeEventListener('keyup', keyupHandler);

        overlay.classList.remove('is-show');
        state.detachControls();

        // clean overlay markup from every child which has no 'data-recipe-overlay-keep' attribute
        Array.from(overlay.children).forEach((child) => {
            if (child.getAttribute('data-recipe-overlay-keep') === null) {
                overlay.removeChild(child);
            }
        });

        document.body.classList.remove('recipeoverlay-active');

        BodyScrollLock.clearAllBodyScrollLocks();
    };

    state.createPage = function (repo, startProgress, endProgress, data, startFlag, stepFlag) {
        var screenEl = document.createElement('div');
        if (stepFlag) {
            var preRender = state.precompileTemplate(repo).render(recipeJSON);
            screenEl.innerHTML = state.compileTemplate(preRender).render(data);
        } else {
            screenEl.innerHTML = state.compileTemplate(repo).render(data);
        }
        var screenElChild = screenEl.children[0];
        screenElChild.setAttribute('data-progress-start', startProgress.toString());
        screenElChild.setAttribute('data-progress', endProgress.toString());
        state.setProgress(screenElChild, startProgress);
        state.setId(screenElChild);

        if (startFlag) {
            screenElChild.classList.add('is-active');
        }

        state.appendPage(screenElChild);
    };

    state.attachControls = function () {
        var pages = overlay.querySelectorAll('.overlay-page'),
            closeBtn = overlay.querySelector('.recipe-overlay--close');

        for (var i = 0; i < pages.length; i++) {
            var btns = pages[i].querySelectorAll('.btn.step');
            for (var j = 0; j < btns.length; j++) {
                btns[j].addEventListener('click', state.controls, false);
            }
        }
        closeBtn.data = {
            page: '1'
        };
        closeBtn.addEventListener('click', state.controls, false);
        videos.init();
    };

    state.detachControls = function () {
        var pages = overlay.querySelectorAll('.overlay-page');
        var closeBtn = overlay.querySelector('.recipe-overlay--close');
        for (var i = 0; i < pages.length; i++) {
            var btns = pages[i].querySelectorAll('.btn.step');
            for (var j = 0; j < btns.length; j++) {
                btns[j].removeEventListener('click', state.controls, false);
            }
        }
        closeBtn.removeEventListener('click', state.controls, false);
    };

    state.controls = function (e) {
        var target;
        target = e.target;
        if (target.tagName !== 'A') target = e.target.closest('a');
        var direction = parseInt(target.getAttribute('data-trigger'));

        if (direction === 1) {
            state.next();
        } else if (direction === 0) {
            state.prev();
        } else {
            state.closeOverlay();
        }
    };

    state.getActiveId = function () {
        var actId = state.getActivePage().getAttribute('data-id');
        return actId;
    };

    state.getActivePage = function () {
        var actPage = overlay.querySelector('.overlay-page.is-active');
        return actPage;
    };

    state.next = function () {
        var actPage = state.getActivePage(),
            actPageId = state.getActiveId(),
            nextPage = overlay.querySelector('.overlay-page[data-id="' + (parseInt(actPageId) + 1) + '"]');
        if (nextPage !== null) {
            var endProgress = nextPage.getAttribute('data-progress'),
                startProgress = nextPage.getAttribute('data-progress-start');

            nextPage.classList.add('is-active');
            actPage.classList.remove('is-active');
            Pubsub.publish('cookoverlay.next-step', [endProgress, startProgress]);

            requestAnimationFrame(function () {
                requestAnimationFrame(function () {
                    state.setProgress(actPage, endProgress);
                    state.setProgress(nextPage, endProgress);
                });
            });
        }
    };

    state.prev = function () {
        var actPage = state.getActivePage(),
            actPageId = state.getActiveId(),
            prevPage = overlay.querySelector('.overlay-page[data-id="' + (parseInt(actPageId) - 1) + '"]');
        if (prevPage !== null) {
            var endProgress = prevPage.getAttribute('data-progress'),
                startProgress = prevPage.getAttribute('data-progress-start');

            prevPage.classList.add('is-active');
            actPage.classList.remove('is-active');
            Pubsub.publish('cookoverlay.prev-step', [endProgress, startProgress]);

            requestAnimationFrame(function () {
                requestAnimationFrame(function () {
                    state.setProgress(actPage, endProgress);
                    state.setProgress(prevPage, endProgress);
                });
            });
        }

        Pubsub.publish('cookoverlay.previous-step');
    };

    state.setId = function (page) {
        page.setAttribute('data-id', id++);
    };

    state.setProgress = function (page, value) {
        page.setAttribute('data-progress-current', value.toString());
        var progressBar = page.querySelector('.controller-interface__progressbar .progress');
        if (progressBar !== null) {
            progressBar.style.width = value + '%';
        }
    };

    state.appendPage = function (page) {
        overlay.appendChild(page);
    };

    state.compileTemplate = function (repo) {
        return Hogan.compile(repo, {
            delimiters: '[[ ]]'
        });
    };

    state.precompileTemplate = function (repo) {
        return Hogan.compile(repo, {
            delimiters: '%% %%'
        });
    };

    state.init();

    return state;
};

export const config = {
    name: 'recipe-overlay',
    constructor: createRecipeOverlay,
    selector: '#recipe-overlay',
    dependencies: ['Hogan', 'Pubsub', 'Fractions', 'BodyScrollLock'],
    options: {}
};
