import validate from 'validate.js';
import FormValidate from '@/libs/form-validate';
import serialize from 'form-serialize';
import api from '@/libs/api-factory';
import pubsub from '@/scaffold/pubsub';
import MiniModal from '@/libs/mini-modal';

let moment;
let initiated = false;

// Add date validation
validate.extend(validate.validators.datetime, {
    parse: function(value) {
        if(moment(value, 'DD.MM.YYYY', true).isValid()) {
            return +moment.utc(value, 'DD.MM.YYYY');
        } else {
            return NaN; // Not valid
        }
    },
    format: function(value) {
        return moment.utc(value).format('DD.MM.YYYY');
    }
});

// vars
var instance = {},
    form,
    formValidate,
    apiNewsletter = api(store.apis.newsletter, {
        method: 'POST',
        params: {} // will be set dynamically
    });

instance.init = async function () {
    if (initiated) {
        return;
    }
    initiated = true;

    moment = await import(/* webpackChunkName: "moment" */ 'moment/src/moment').then(module => module.default);
    form = document.getElementById('newsletter-form');

    if (form === undefined || form === null)
        return;

    const messages = {
        presence: form.getAttribute('data-msg-presence'),
        email: form.getAttribute('data-msg-email'),
        format: form.getAttribute('data-msg-format'),
        maxlength: form.getAttribute('data-msg-emailmaxlength'),
        date: form.getAttribute('data-msg-date')
    };

    const validHandler = () => {
        var now = new Date();
        var time = now.getTime();
        var expireTime = time + 90 * 84000 * 1000; // 90 Days
        now.setTime(expireTime);

        // A cookie with no expire date will last until the session closes
        document.cookie = 'newsletterPopped=true;expires=' + now.toGMTString() + ';path=/';

        instance.showSuccess();
    };

    const invalidHandler = (errors) => {
        pubsub.publish('errors.show', ['Newsletter Overlay', errors]);
    };

    formValidate = FormValidate(form, { messages });
    formValidate.subscribe('valid', validHandler);
    formValidate.subscribe('invalid', invalidHandler);

    form.querySelector('[data-mini-modal-close]').addEventListener('click', function (ev) {
        ev.preventDefault();
        MiniModal.close();
    });
};

instance.clearSignupForms = function () {
    var signupForms = document.querySelectorAll('.newsletter-form-signup');
    signupForms.forEach(function(signupForm) {
        signupForm.reset();
    });
};

instance.handleResponse = function (response) {

    // store mini-modal instance for later use
    var activeModal = MiniModal.getActiveModal();

    // open success message as soon form is closed.
    activeModal.setOptions({
        onAfterClose: function () {
            MiniModal.open('modal-newsletter-success');
        }
    });

    response = JSON.parse(response);
    if (!response.success && !response.hasOwnProperty('redirect')) {
        for (var key in response.fields) {
            var value = response.fields[key],
                msg = form.getAttribute(value);
            if (msg === null) msg = value;
            var input = form.querySelector('input[name="' + key + '"]');
            formValidate.showErrorsForInput(input, [msg]);
        }
    } else if (response.hasOwnProperty('redirect')) {
        window.location.href = response.redirect;
    } else {

        pubsub.publish('newsletter.signup');

        instance.clearSignupForms();
        form.reset();
        MiniModal.close();
    }
};

instance.handleError = function (status) {
    console.error('api call was not successful. returned status code ' + status + '.');
};

instance.showSuccess = function () {
    var params = serialize(form, {hash: true});

    // add language
    params.lang = store.lang;

    apiNewsletter.send({
        params: params,
        success: instance.handleResponse,
        error: instance.handleError
    });
};

export default instance;
