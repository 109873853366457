

var api = require('@/libs/api-factory').default,
    observer = require('@/libs/ni-observer').default,
    globals = require('@/scaffold/globals').default,
    pubsub = require('@/scaffold/pubsub').default;

/**
 * Creates a Bookmarks model.
 *
 * This model depends on User and Cookbooks.
 * This means data will be set in User model, which is of course unusal.
 *
 * @return {object} Bookmarks model
 */
export default function () {
    var model = {},
        bookmarks = [],
        apiBookmarksCreate,
        apiBookmarksUpdate,
        apiBookmarksDelete,
        bookmarkMapping = function (cookbookId) {
            var obj = {
                'cookbook_id': Number(cookbookId)
            };

            if (this.url) {
                obj['target_url'] = this.url;
            }

            if (this.recipeId) {
                obj['recipe_id'] = this.recipeId;
            }

            if (this.ownRecipeId || this.ownRecipeId === 0) {
                obj['own_recipe_id'] = this.ownRecipeId;
            }

            if (this.treffertypSub) {
                obj['treffertyp_sub'] = this.treffertypSub;
            }

            if (this.menuartId) {
                obj['menuart_id'] = this.menuartId;
            }

            return obj;
        },

        createSuccessHandler = function (response, context) {
            var createdBookmarks = context.cookbooks.toString().split(',').map(bookmarkMapping, context);

            bookmarks = bookmarks.concat(createdBookmarks);

            // pass created bookmarks to subscribers.
            model.publish('created', [createdBookmarks]);

            pubsub.publish('bookmarks.created', [{ bookmarks: createdBookmarks }]);
        },

        createErrorHandler = function(status) {
            model.publish('created.error', [status]);
            pubsub.publish('bookmarks.created.error', [{ status }]);
        },

        updateSuccessHandler = function (response, context) {
            var updatedBookmarks = context.cookbooks.toString().split(',').map(bookmarkMapping, context);

            // filter out bookmarks, which have been updated
            bookmarks = bookmarks.filter(function (bookmark) {
                if (context.treffertypSub === 'own') {
                    if ((context.ownRecipeId || context.ownRecipeId === 0) && bookmark.hasOwnProperty('own_recipe_id')) {
                        return bookmark['own_recipe_id'] !== context.ownRecipeId;
                    }
                } else if (context.treffertypSub === 'extern') {
                    if (context.url) {
                        return bookmark['target_url'] !== context.url;
                    }
                } else {
                    if (context.recipeId) {
                        return bookmark['recipe_id'] !== context.recipeId;
                    }
                }

                return true;
            });

            // add updated bookmarks
            bookmarks = bookmarks.concat(updatedBookmarks);

            // pass updated bookmarks to subscribers.
            model.publish('updated', [updatedBookmarks]);
            pubsub.publish('bookmarks.updated', [{ bookmarks: updatedBookmarks }]);
        },

        updateErrorHandler = function(status) {
            model.publish('updated.error', [status]);
            pubsub.publish('bookmarks.updated.error', [{ status }]);
        },

        deleteSuccessHandler = function (response, context) {
            var deletedBookmarks = bookmarks.filter(function (bookmark) {
                if (context.treffertypSub === 'own') {
                    if ((context.ownRecipeId || context.ownRecipeId === 0) && bookmark.hasOwnProperty('own_recipe_id')) {
                        return bookmark['own_recipe_id'] === context.ownRecipeId;
                    }
                } else if (context.treffertypSub === 'extern') {
                    if (context.url) {
                        return bookmark['target_url'] === context.url;
                    }
                } else {
                    if (context.recipeId) {
                        return bookmark['recipe_id'] === context.recipeId;
                    }
                }

                return false;
            });

            bookmarks = bookmarks.filter(function (bookmark) {
                if (context.treffertypSub === 'own') {
                    if ((context.ownRecipeId || context.ownRecipeId === 0) && bookmark.hasOwnProperty('own_recipe_id')) {
                        return bookmark['own_recipe_id'] !== context.ownRecipeId;
                    }
                } else if (context.treffertypSub === 'extern') {
                    if (context.url) {
                        return bookmark['target_url'] !== context.url;
                    }
                } else {
                    if (context.recipeId) {
                        return bookmark['recipe_id'] !== context.recipeId;
                    }
                }

                return true;
            });

            // pass deleted bookmarks to subscribers.
            model.publish('deleted', [deletedBookmarks]);
            pubsub.publish('bookmarks.deleted', [{ bookmarks: deletedBookmarks }]);
        },

        deleteErrorHandler = function(status) {
            model.publish('deleted.error', [status]);
            pubsub.publish('bookmarks.deleted.error', [{ status }]);
        };

    // define APIs
    apiBookmarksCreate = api(store.apis.bookmark, {
        method: 'POST',
        params: {
            'target_url': '',        // will be set dynamically
            'cookbook_ids': '',   // will be set dynamically,
            'treffertyp_sub': '',
            'recipe_id': '',
            'own_recipe_id': '',
            'menuart_id': ''
        },
        success: createSuccessHandler,
        error: createErrorHandler
    });

    apiBookmarksUpdate = api(store.apis.bookmark, {
        method: 'PUT',
        params: {
            'target_url': '',        // will be set dynamically
            'cookbook_ids': '',   // will be set dynamically
            'treffertyp_sub': '',
            'recipe_id': '',
            'own_recipe_id': '',
            'menuart_id': ''
        },
        success: updateSuccessHandler,
        error: updateErrorHandler
    });

    apiBookmarksDelete = api(store.apis.bookmark, {
        method: 'DELETE',
        params: {
            'target_url': '',         // will be set dynamically
            'treffertyp_sub': '',
            'recipe_id': '',
            'own_recipe_id': '',
            'menuart_id': ''
        },
        success: deleteSuccessHandler,
        error: deleteErrorHandler
    });

    // extend model
    observer(model);

    // public methods

    /**
     * Get bookmarks list
     *
     * @return {Array} Returns array
     */
    model.getAll = function () {
        return bookmarks;
    };

    /**
     * Get like bookmarks
     *
     * @return {Array} Returns array
     */
    model.getAllLikeBookmarks = function() {
        const likeCookbookIds = globals.cookbooks.getLikeCookbook().map(cookbook => cookbook.cookbook_id);

        return bookmarks.filter(function (bookmark) {
            return likeCookbookIds.indexOf(bookmark.cookbook_id) > -1;
        });
    };

    /**
     * Get list of unique Bookmarks-Teasers
     *
     * @return {Array} Returns array
     */
    model.getAllUnique = function() {
        var uniques = [];

        return bookmarks.filter(function(bookmark) {
            var key;

            if (bookmark.hasOwnProperty('own_recipe_id')) {
                key = 'own_recipe_id';
            } else if (bookmark.hasOwnProperty('target_url')) {
                key = 'target_url';
            } else if (bookmark.hasOwnProperty('recipe_id')) {
                key = 'recipe_id';
            }

            if (uniques.indexOf(bookmark[key]) > -1) {
                return false;
            } else {
                uniques.push(bookmark[key]);
                return true;
            }
        });
    };

    /**
     * Get list of bookmarks which have given url.
     *
     * @param {string} url - Bookmark url
     * @param {boolean} excludeMenus=true - Whether bookmarks from menu cookbooks should be excluded and not returned
     * @param {boolean} excludeSwipe=true - Whether bookmarks from like cookbook should be excluded and not returned
     *
     * @return {Array} Returns array with found bookmarks
     */
    model.getByTargetUrl = function (url, excludeMenus = true, excludeSwipe = true) {
        const menuCookbookIds = globals.cookbooks.getAllFromTypeMenu().map(cookbook => Number(cookbook.cookbook_id));
        const swipeCookbookIds = globals.cookbooks.getSwipeCookbook().map(cookbook => Number(cookbook.cookbook_id));

        return bookmarks.filter(function(bookmark) {
            const bookmarkIsNotInMenuCookbook = menuCookbookIds.indexOf(Number(bookmark.cookbook_id)) === -1;
            const bookmarkIsNotInSwipeCookbook = swipeCookbookIds.indexOf(Number(bookmark.cookbook_id)) === -1;

            return bookmark['target_url'] === url
                && (!excludeMenus || (excludeMenus && bookmarkIsNotInMenuCookbook))
                && (!excludeSwipe || (excludeSwipe && bookmarkIsNotInSwipeCookbook));
        });
    };

    /**
     * Get list of bookmarks which have given recipe id.
     *
     * @param {number} recipeId - Recipe ID
     * @param {boolean} excludeMenus=true - Whether bookmarks from menu cookbooks should be excluded and not returned
     * @param {boolean} excludeSwipe=true - Whether bookmarks from swipe cookbook should be excluded and not returned
     *
     * @return {Array} - bookmarks with matching recipe ids
     */
    model.getByRecipeId = function (recipeId, excludeMenus = true, excludeSwipe = true) {
        const menuCookbookIds = globals.cookbooks.getAllFromTypeMenu().map(cookbook => Number(cookbook.cookbook_id));
        const swipeCookbookIds = globals.cookbooks.getSwipeCookbook().map(cookbook => Number(cookbook.cookbook_id));

        return bookmarks.filter(function (bookmark) {
            const bookmarkIsNotInMenuCookbook = menuCookbookIds.indexOf(Number(bookmark.cookbook_id)) === -1;
            const bookmarkIsNotInSwipeCookbook = swipeCookbookIds.indexOf(Number(bookmark.cookbook_id)) === -1;

            return Number(bookmark['recipe_id']) === Number(recipeId)
                && (!excludeMenus || (excludeMenus && bookmarkIsNotInMenuCookbook))
                && (!excludeSwipe || (excludeSwipe && bookmarkIsNotInSwipeCookbook));
        });
    };

    /**
     * Get list of bookmarks which have given own recipe id.
     *
     * @param {number} ownRecipeId - Own recipe ID
     * @return {Array} - bookmarks with matching own recipe ids
     */
    model.getByOwnRecipeId = function (ownRecipeId) {
        return bookmarks.filter(function (bookmark) {
            return bookmark['own_recipe_id'] === ownRecipeId;
        });
    };

    /**
     * Get list of urls which have given cookbook_id.
     *
     * @param {number} id of the cookbook
     * @return {Array} Returns array with found bookmarks
     */
    model.getByCookbookId = function (id) {
        return bookmarks.filter(function(bookmark) {
            return bookmark['cookbook_id'] === id;
        });
    };

    /**
     * Fetches bookmarks via User model. Therefore User must be fetched first.
     *
     * Event 'fetched' will be fired.
     *
     * @return {object} Model instance
     */
    model.fetch = function () {
        bookmarks = globals.user.get('bookmarks');

        console.info('bookmarks-model.js fetched bookmarks');

        // pass bookmarks array to subscribers.
        model.publish('fetched', [bookmarks]);
        pubsub.publish('bookmarks.fetched', [{ bookmarks }]);

        return model;
    };

    /**
     * Create a new bookmark to the users cookbook.
     *
     * Response is ignored in success handler.
     * Event 'created' will be fired.
     *
     * @param {string} url - Url of detail page to be bookmarked
     * @param {number} recipeId - only given if treffertyp_sub = rezept
     * @param {number} ownRecipeId - only given if treffertyp_sub = own
     * @param {string} cookbooks - Comma-separated string with ids of cookbooks !Important: define with backend
     * @param {string} treffertypSub - treffertyp_sub of the teaser
     * @param {string} menuartId - only given if treffertyp_sub = rezept
     * @param {boolean|null} like=null - only given if like bookmark
     *
     * @returns {Promise} - Promise resolving when request has finished.
     */
    model.createBookmark = function (url, recipeId, ownRecipeId, cookbooks, treffertypSub, menuartId, like = null) {

        var params = {
            'cookbook_ids': cookbooks
        };

        if (url) {
            params['target_url'] = url;
        }

        if (recipeId) {
            params['recipe_id'] = recipeId;
        }

        if (ownRecipeId || ownRecipeId === 0) {
            params['own_recipe_id'] = ownRecipeId;
        }

        if (menuartId) {
            params['menuart_id'] = menuartId;
        }

        if (treffertypSub) {
            params['treffertyp_sub'] = treffertypSub;
        }

        if (like !== null) {
            params['like'] = like;
        }

        return apiBookmarksCreate.send({
            params: params,
            context: {
                url: url,
                recipeId : recipeId,
                ownRecipeId: ownRecipeId,
                cookbooks: cookbooks,
                treffertypSub: treffertypSub,
                like: like
            }
        });
    };

    /**
     * Adds a new bookmark to the users cookbooks.
     *
     * Main usage is in combination with external recipes
     * Event 'added' will be fired.
     *
     * @param {string} url - Url of detail page to be bookmarked
     * @param {number} recipeId - only given if treffertyp_sub = rezept
     * @param {number} ownRecipeId - only given if treffertyp_sub = own
     * @param {string} cookbooks - Comma-separated string with ids of cookbooks !Important: define with backend
     * @param {string} treffertypSub - treffertyp_sub of the teaser
     * @param {string} menuartId - only given if treffertyp_sub = rezept
     */
    model.addBookmark = function (url, recipeId, ownRecipeId, cookbooks, treffertypSub, menuartId) {
        var addedBookmarks = cookbooks.toString().split(',').map(bookmarkMapping, {
            url: url,
            recipeId : recipeId,
            ownRecipeId: ownRecipeId,
            treffertypSub: treffertypSub,
            menuartId: menuartId
        });

        bookmarks = bookmarks.concat(addedBookmarks);

        // pass created bookmarks to subscribers.
        model.publish('added', [addedBookmarks]);
    };

    /**
     * Updates a bookmark
     *
     * Response is ignored in success handler.
     * Event 'updated' will be fired.
     *
     * @param {string} url - Url of detail page to be bookmarked
     * @param {number} recipeId - only given if treffertyp_sub = rezept
     * @param {number} ownRecipeId - only given if treffertyp_sub = own
     * @param {string} cookbooks - Comma-separated string with ids of cookbooks !Important: define with backend
     * @param {string} treffertypSub - treffertyp_sub of the teaser
     * @param {string} menuartId - only given if treffertyp_sub = rezept
     *
     * @returns {Promise} - Promise resolving when request has finished.
     */
    model.updateBookmark = function (url, recipeId, ownRecipeId, cookbooks, treffertypSub, menuartId) {

        var params = {
            'cookbook_ids': cookbooks
        };

        if (url) {
            params['target_url'] = url;
        }

        if (recipeId) {
            params['recipe_id'] = recipeId;
        }

        if (ownRecipeId || ownRecipeId === 0) {
            params['own_recipe_id'] = ownRecipeId;
        }

        if (menuartId) {
            params['menuart_id'] = menuartId;
        }

        if (treffertypSub) {
            params['treffertyp_sub'] = treffertypSub;
        }

        return apiBookmarksUpdate.send({
            params: params,
            context: {
                url: url,
                recipeId : recipeId,
                ownRecipeId: ownRecipeId,
                cookbooks: cookbooks,
                treffertypSub: treffertypSub
            }
        });
    };

    /**
     * Change a bookmark
     *
     * Main usage is in combination with external recipes
     * Event 'changed' will be fired.
     *
     * @param {string} url - Url of detail page to be bookmarked
     * @param {number} recipeId - only given if treffertyp_sub = rezept
     * @param {number} ownRecipeId - only given if treffertyp_sub = own
     * @param {string} cookbooks - Comma-separated string with ids of cookbooks !Important: define with backend
     * @param {string} treffertypSub - treffertyp_sub of the teaser
     * @param {string} menuartId - only given if treffertyp_sub = rezept
     */
    model.changeBookmark = function (url, recipeId, ownRecipeId, cookbooks, treffertypSub, menuartId) {
        var changedBookmarks = cookbooks.toString().split(',').map(bookmarkMapping, {
            url: url,
            recipeId : recipeId,
            ownRecipeId: ownRecipeId,
            treffertypSub: treffertypSub,
            menuartId: menuartId
        });

        // filter out bookmarks, which have been changed
        bookmarks = bookmarks.filter(function (bookmark) {
            if (treffertypSub === 'own') {
                if ((ownRecipeId || ownRecipeId === 0) && bookmark.hasOwnProperty('own_recipe_id')) {
                    return bookmark['own_recipe_id'] !== ownRecipeId;
                }
            } else if (treffertypSub === 'extern') {
                if (url) {
                    return bookmark['target_url'] !== url;
                }
            } else {
                if (recipeId) {
                    return bookmark['recipe_id'] !== recipeId;
                }
            }

            return true;
        });

        // add changed bookmarks
        bookmarks = bookmarks.concat(changedBookmarks);

        // pass changed bookmarks to subscribers.
        model.publish('changed', [changedBookmarks]);
    };

    /**
     * Deletes a bookmark. At least 'url' or 'recipeId' must be passed.
     *
     * Response is ignored in success handler.
     * Event 'deleted' will be fired.
     *
     * @param {string} url - Url of which bookmarks should be removed.
     * @param {number} recipeId - only given if treffertyp_sub = rezept
     * @param {number} ownRecipeId - only given if treffertyp_sub = own
     * @param {string} treffertypSub - treffertyp_sub of the teaser
     * @param {string} menuartId - only given if treffertyp_sub = rezept
     *
     * @returns {Promise} - Promise resolving when request has finished.
     */
    model.deleteBookmark = function (url, recipeId, ownRecipeId, treffertypSub, menuartId) {

        var params = {};

        if (url) {
            params['target_url'] = url;
        }

        if (recipeId) {
            params['recipe_id'] = recipeId;
        }

        if (ownRecipeId || ownRecipeId === 0) {
            params['own_recipe_id'] = ownRecipeId;
        }

        if (menuartId) {
            params['menuart_id'] = menuartId;
        }

        if (treffertypSub) {
            params['treffertyp_sub'] = treffertypSub;
        }

        return apiBookmarksDelete.send({
            params: params,
            context: {
                url: url,
                recipeId : recipeId,
                ownRecipeId: ownRecipeId,
                treffertypSub: treffertypSub
            }
        });
    };

    /**
     * Remove a bookmark
     *
     * Main usage is in combination with external recipes
     * Event 'removed' will be fired.
     *
     * @param {string} url - Url of detail page to be bookmarked
     * @param {number} recipeId - only given if treffertyp_sub = rezept
     * @param {number} ownRecipeId - only given if treffertyp_sub = own
     */
    model.removeBookmark = function (url, recipeId, ownRecipeId) {
        var removedBookmarks = bookmarks.filter(function (bookmark) {
            if (ownRecipeId || ownRecipeId === 0) {
                return bookmark['own_recipe_id'] === ownRecipeId;
            }

            if (url) {
                return bookmark['target_url'] === url;
            }

            if (recipeId) {
                return bookmark['recipe_id'] === recipeId;
            }
        });

        bookmarks = bookmarks.filter(function (bookmark) {
            if (ownRecipeId || ownRecipeId === 0) {
                return bookmark['own_recipe_id'] !== ownRecipeId;
            }

            if (url) {
                return bookmark['target_url'] !== url;
            }

            if (recipeId) {
                return bookmark['recipe_id'] !== recipeId;
            }
        });

        // pass removed bookmarks to subscribers.
        model.publish('removed', [removedBookmarks]);
    };

    /**
     * Removes all bookmarks from the bookmarks array by given cookbookId
     *
     * !Important! This does not make an ajax call and should only be called after a cookbook got deleted (deleting a cookbook will already remove the bookmarks of said cookbook on the server)
     *
     * @param {Integer} cookbookId of the Bookmarks to remove
     */
    model.removeBookmarksByCookbookId = function(cookbookId) {
        bookmarks = bookmarks.filter(function(bookmark) {
            return Number(bookmark.cookbook_id) !== Number(cookbookId);
        });
    };

    return model;
};
