import injector from '@/scaffold/injector';

export default injector.resolve(['Store', 'ApiFactory', 'Observer'], (Store, ApiFactory, Observer) => {

    let fetching = null;
    let isLoading = false;

    let ingredients = [];

    const instance = {};
    Observer(instance);

    // public methods

    /**
     * Get all shopping list ingredients
     *
     * @return {Array}  Returns array
     */
    instance.getAll = () => {
        return ingredients;
    };

    /**
     * Get shopping list ingredient by id.
     *
     * @param {string} id - Cookbook id
     * @return {object} Cookbook object
     */
    instance.getById = (id) => {
        id = parseInt(id, 10);

        for (let i = ingredients.length; i--;) {

            if (ingredients[i]['id'] === id) {
                return ingredients[i];
            }
        }
    };

    /**
     * Fetches all shopping list ingredients.
     *
     * Event 'fetched' will be fired.
     *
     * @param {object} data - Data
     * @param {number|null} data.shoppinglistId - Shoppinglist id
     *
     * @return {object} Model instance
     */
    instance.fetch = async ({ shoppinglistId }) => {
        if (isLoading) {
            return fetching;
        }

        isLoading = true;

        fetching = new Promise((resolve, reject) => {
            const api = ApiFactory(Store.apis.userShoppinglistIngredient, {
                params: { shoppinglistId },
                success: (response) => {
                    isLoading = false;
                    ingredients = JSON.parse(response).items;
                    instance.publish('fetched', [ingredients]);
                    resolve({ response, ingredients });
                },
                error: (error) => {
                    isLoading = false;
                    console.error('Ingredients data could not be parsed.', error);
                    reject(error);
                }
            });

            api.send();
        });

        return fetching;
    };

    /**
     * Creates a new shopping list ingredient
     *
     * Response is ignored in success handler.
     * Event 'created' will be fired.
     *
     * @param {object} data - Data
     * @param {string|null} data.title - Name of shopping list ingredient
     * @param {number|null} data.shoppinglistId - Shoppinglist id
     * @param {number|null} data.amount - Amount
     * @param {number|null} data.unitId - Unit id
     * @param {number|null} data.rayonId - Rayon id
     * @param {number|null} [data.state] - State of ingredient
     *
     * @returns {Promise} Promise
     */
    instance.createShoppingListIngredient = async (data) => {
        return new Promise((resolve, reject) => {
            const api = ApiFactory(Store.apis.userShoppinglistIngredient, {
                method: 'POST',
                success: (response) => {
                    const ingredient = { ...data, ...JSON.parse(response) };
                    ingredients.push(ingredient);
                    instance.publish('created', [ingredient]);
                    resolve({ response, ingredient });
                },
                error: (error) => {
                    console.error('Failed creating shopping list ingredient', error);
                    reject(error);
                },
                params: {
                    ...data,
                    '_charset_': 'UTF-8'
                }
            });

            api.send();
        });
    };

    /**
     * Updates a shopping list ingredient
     *
     * Response is ignored in success handler.
     * Event 'updated' will be fired.
     *
     * @param {object} data - Data
     * @param {number|null} data.id - Id of ingredient
     * @param {string|null} data.title - Name of shopping list ingredient
     * @param {number|null} data.shoppinglistId - Shoppinglist id
     * @param {number|null} data.amount - Amount
     * @param {number|null} data.unitId - Unit id
     * @param {number|null} data.rayonId - Rayon id
     * @param {number|null} [data.state] - State of ingredient
     *
     * @returns {Promise} Promise
     */
    instance.updateShoppingListIngredient = (data) => {
        return new Promise((resolve, reject) => {
            const api = ApiFactory(Store.apis.userShoppinglistIngredient, {
                method: 'PUT',
                success: (response) => {
                    let updatedShoppinglistIngredient = {};

                    for (let i = ingredients.length; i--;) {
                        if (ingredients[i]['id'] === data['id']) {
                            ingredients[i] = data;
                            updatedShoppinglistIngredient = ingredients[i];
                            break;
                        }
                    }

                    // pass updated shopping list ingredient to subscribers.
                    instance.publish('updated', [updatedShoppinglistIngredient]);
                    resolve({ response, updatedShoppinglistIngredient });
                },
                error: (error) => {
                    console.error('Failed updating shopping list ingredient', error);
                    reject(error);
                },
                params: {
                    ...data,
                    state: data.state || 0,
                    '_charset_': 'UTF-8'
                }
            });

            api.send();
        });
    };

    /**
     * Deletes an external recipe
     *
     * Response is ignored in success handler.
     * Event 'deleted' will be fired.
     *
     * @param {object} data - Data
     * @param {number|null} data.id - Id of ingredient
     *
     * @returns {Promise} Promise
     */
    instance.deleteShoppingListIngredient = (data) => {
        return new Promise((resolve, reject) => {
            const api = ApiFactory(Store.apis.userShoppinglistIngredient, {
                method: 'DELETE',
                success: (response) => {
                    let deletedShoppinglistIngredient = {};

                    // filter out removed ingredient from array
                    ingredients = ingredients.filter(function(ingredient) {
                        if (ingredient['id'] === data['id']) {
                            deletedShoppinglistIngredient = ingredient;
                            return false;
                        } else {
                            return true;
                        }
                    });

                    // pass deleted shopping list ingredient to subscribers.
                    instance.publish('deleted', [deletedShoppinglistIngredient]);
                    resolve({ response, deletedShoppinglistIngredient });
                },
                error: (error) => {
                    console.error('Failed removing shopping list ingredient', error);
                    reject(error);
                },
                params: {
                    'id': data.id,
                    '_charset_': 'UTF-8'
                }
            });

            api.send();
        });

    };

    return instance;
});
