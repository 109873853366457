

var globals = require('@/scaffold/globals').default,
    modules = {
        saveDeleteBar: require('@/apps/myfooby/modules/save-delete-bar').default,
        simpleConfirm: require('@/apps/myfooby/modules/simple-confirm').default,
        view: require('@/apps/main/plugins/view-controller').default
    };

var defaults = {
        cookbookIdAttr: 'data-cookbook-id',
        cookbookInputId: 'cookook-title-change',
        successViewTimeout: 1000,
        trigger: null,
        tooltipInstance: null
    },

    viewToDarkThemeMap = {
        'cookbooks': false,
        'warning': true,
        'ajax': false,
        'success': true,
        'error': true
    };

export default function(container, options) {
    var instance = {},
        settings = Object.assign({}, defaults, options),
        components = {},
        initialView = (options.trigger && options.trigger.getAttribute('data-cookbook-tooltip-initial-view')) || 'change',
        cookbookId,
        cookbook,
        input,

        changeView = function(name) {
            components.view.updateView(name);

            if (viewToDarkThemeMap[name] === true) {
                container.classList.add('tooltip--theme-dark');
            } else {
                container.classList.remove('tooltip--theme-dark');
            }

            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    settings.tooltipInstance.update();
                });
            });
        },

        checkChange = function () {
            if (cookbook.title === input.value || input.value === '') {
                components.saveDeleteBar.disableSave();
            } else {
                components.saveDeleteBar.enableSave();
            }
        },

        saveCookbook = function () {
            var title = input.value,
                subscriptionId,
                errorSubscriptionId,

                cleanup = function () {
                    // unsubscribe from ajax calls
                    globals.cookbooks.unsubscribe(subscriptionId);
                    globals.cookbooks.unsubscribe(errorSubscriptionId);
                },

                updatedHandler = function (newCookbook) {

                    // set the new cookbook to be the active cookbook
                    cookbook = newCookbook;

                    // This will disable the save button, sind the new title is now the default title
                    checkChange();

                    changeView('success');

                    components.saveDeleteBar.enableDelete();
                    components.saveDeleteBar.disableSave();

                    setTimeout(function() {

                        // // switches to the cookbooks again
                        // changeView(initialView);

                        // Closes the tooltip
                        settings.tooltipInstance.close();
                    }, settings.successViewTimeout);

                    cleanup();
                },

                errorUpdatedHandler = function () {
                    changeView('error');
                    cleanup();
                };

            // switching to ajax view so the user can see an ajax call is triggered
            changeView('ajax');

            // subscribe to ajax calls
            subscriptionId = globals.cookbooks.subscribe('updated', updatedHandler);
            errorSubscriptionId = globals.cookbooks.subscribe('updated.error', errorUpdatedHandler);

            // make the call
            globals.cookbooks.updateCookbook(cookbook.cookbook_id, title);
        },

        deleteCookbook = function () {
            var subscriptionId,
                deletedHandler = function () {

                    changeView('success');

                    setTimeout(function () {

                        // Closes the tooltip
                        settings.tooltipInstance.close();

                        globals.cookbooks.unsubscribe(subscriptionId);
                    }, 1500);
                };

            changeView('ajax');

            // subscribe to ajax calls
            subscriptionId = globals.cookbooks.subscribe('deleted', deletedHandler);

            // make the calls
            globals.cookbooks.deleteCookbook(cookbook.cookbook_id);
        };

    if (container instanceof HTMLElement === false) {
        console.error('Passed container element is not a DOM element.');
        return;
    }

    cookbookId = settings.trigger.getAttribute(settings.cookbookIdAttr);
    cookbook = globals.cookbooks.getById(cookbookId);

    // set the input field and set default value
    input = document.getElementById(settings.cookbookInputId);
    input.value = cookbook.title;


    components.view = modules.view.call(container, {
        containers: [
            {
                type: 'change',
                wrapper: '[data-view-type="change"]',
                content: '[data-view-type="change"]'
            },
            {
                type: 'warning',
                wrapper: '[data-view-type="warning"]',
                content: '[data-view-type="warning"]'
            },
            {
                type: 'ajax',
                wrapper: '[data-view-type="ajax"]',
                content: '[data-view-type="ajax"]'
            },
            {
                type: 'success',
                wrapper: '[data-view-type="success"]',
                content: '[data-view-type="success"]'
            },
            {
                type: 'error',
                wrapper: '[data-view-type="error"]',
                content: '[data-view-type="error"]'
            }
        ]
    });

    // Set initial view to change
    changeView(initialView);

    // init save delete actions
    components.saveDeleteBar = modules.saveDeleteBar(container);

    // inti confirm module
    components.simpleConfirm = modules.simpleConfirm(container);

    // initial check will disable save button
    checkChange();

    // *************
    // Subscriptions
    // *************

    // cookbookAddView.subscribe('added', addCookbook);
    input.addEventListener('keyup', checkChange);

    components.saveDeleteBar.subscribe('delete', function() {
        changeView('warning');
    });

    components.saveDeleteBar.subscribe('save', function () {
        saveCookbook();
    });

    components.simpleConfirm.subscribe('denied', function() {
        changeView(initialView);
    });

    components.simpleConfirm.subscribe('confirmed', function() {
        deleteCookbook();
    });

    /**
     * Destroys this instance.
     */
    instance.destroy = function () {
        input.removeEventListener('keyup', checkChange);
        components.saveDeleteBar.destroy();
        components.simpleConfirm.destroy();
    };

    return instance;
};
