import Pubsub from '@/scaffold/pubsub';
import fastdom from 'fastdom';

/**
 * Configurable equalizer which can equalize the height deficit of divverent elements
 */

// dependencies
var forEach = require('@/helpers/helper').default.forEach,
    breakpoint = require('@/libs/breakpoint').default;

// vars
var instance = {},
    defaults = {
        selector: 'data-ni-equalizer',
        imageSelector: '.image__image',
        grouping: 'data-ni-equalizer-group',
        type: 'data-ni-equalizer-type' // ENUM: min / max
    },
    settings,
    groups = [],
    subscriptions = {},

    /**
     * Unsets the height of a given groupId or all the groups if no groupId is given
     *
     * @param {integer} groupId of the group to reset (optional)
     */
    unsetHeight = function(groupId) {

        groupId = groupId || null;

        var nodes = [];

        if (groupId !== null) {
            nodes.push(getGroupNode(groupId));
        } else {
            groups.forEach(function(groupId) {
                nodes.push(getGroupNode(groupId));
            });
        }


        nodes.forEach(function(groupNode) {
            try {
                var nodes = groupNode.querySelectorAll('[' + settings.grouping + ']');
                forEach(nodes, function(element) {
                    element.style.height = '';
                });
            } catch (e) {
                console.warn('groupNode is not an Element!');
            }
        });
    },


    /**
     * Set the height of a given groupId or all the groups if no groupId is given
     *
     * @param {integer} groupId of the group to equalize (optional)
     */
    setHeight = async function(groupId) {
        await breakpoint.initialized;

        if (breakpoint.curBreakpoint === 'small') {
            return;
        }

        groupId = groupId || null;

        var nodes = [];

        if (groupId !== null) {
            nodes.push(getGroupNode(groupId));
        } else {
            groups.forEach(function(groupId) {
                nodes.push(getGroupNode(groupId));
            });
        }

        nodes.forEach(function(groupNode) {

            if (groupNode instanceof Element) {
                var groupings = {
                    min: {},
                    max: {}
                };

                var nodes = groupNode.querySelectorAll('[' + settings.grouping + ']');
                var groupingName,
                    type,
                    nodesArray = [];

                forEach(nodes, function(node) {
                    nodesArray.push(node);
                });

                nodesArray = nodesArray.filter(function(node) {
                    if (node.hasAttribute('data-skip-on-' + breakpoint.curBreakpoint)) {
                        return false;
                    }
                    return true;
                });

                nodesArray.forEach(function(node) {
                    groupingName = node.getAttribute(settings.grouping);
                    type = node.getAttribute(settings.type);

                    if (groupingName in groupings[type] === false) {
                        groupings[type][groupingName] = [];
                    }
                    groupings[type][groupingName].push(node);
                });


                const groupingKeysGroups = {
                    'min': (innerHeight, targetHeight) => {
                        return innerHeight < targetHeight || targetHeight === 0;
                    },
                    'max': (innerHeight, targetHeight) => {
                        return innerHeight > targetHeight || targetHeight === 0;
                    }
                };

                Object.keys(groupingKeysGroups).forEach((groupingKey) => {
                    fastdom.measure(() => {
                        let targetHeight = 0;

                        Object.keys(groupings[groupingKey]).forEach((key) => {
                            const group = groupings[groupingKey][key];

                            if (!group) {
                                return;
                            }

                            group.forEach(function (element) {
                                if ((breakpoint.curBreakpoint === 'small' && element.hasAttribute('data-ni-equalizer-skip-on-small')) ||
                                    (breakpoint.curBreakpoint === 'medium' && element.hasAttribute('data-ni-equalizer-skip-on-medium'))) {
                                    return;
                                }

                                const innerHeight = element.offsetHeight;

                                if (groupingKeysGroups[groupingKey](innerHeight, targetHeight)) {
                                    targetHeight = innerHeight;
                                }
                            });

                            group.forEach(function (element) {
                                if ((breakpoint.curBreakpoint === 'small' && element.hasAttribute('data-ni-equalizer-skip-on-small')) ||
                                    (breakpoint.curBreakpoint === 'medium' && element.hasAttribute('data-ni-equalizer-skip-on-medium'))) {
                                    return;
                                }

                                fastdom.mutate(() => {
                                    element.style.height = targetHeight + 'px';
                                });
                            });
                        });
                    });
                });
            }
        });
    },

    /**
     * Unsets and sets the groups for new adaption
     *
     * @param {integer} groupId of the group to resize
     */
    resize = function(groupId = null) {
        unsetHeight(groupId);
        setHeight(groupId);
    },

    /**
     * Resize event handler
     */
    resizeHandler = function() {
        resize();
    },

    /**
     * Get a groupNode ID by a child node
     *
     * @param {element} element child element of the group to get the group id from
     * @return {string} ID
     */
    getGroupIdByChildNode = function(element) {
        const groupNode = element.closest('[' + settings.selector + ']');
        return groupNode ? groupNode.getAttribute(settings.selector) : '';
    },

    /**
     * Get a groupNode by the groupId
     *
     * @param {integer} groupId of the group to get the Element from
     * @return {element} node
     */
    getGroupNode = function(groupId) {
        return document.querySelector('[' + settings.selector + '="' + groupId + '"]');
    };

/**
 * Initializes the equalizer
 *
 * @param {object} options to overwrite default settings
 * @return {object} instance
 */
instance.init = function(options) {
    settings = Object.assign({}, defaults, options);

    var groupNodes = document.querySelectorAll('[' + settings.selector + ']');

    forEach(groupNodes, function(groupNode) {
        instance.add(groupNode.getAttribute(settings.selector));
    });

    // add general listeners so resizeing can be triggered
    window.addEventListener('optimizedResize', resizeHandler);
    window.addEventListener('ni-equalizer.update', resizeHandler);

    return instance;
};

/**
 * Should revert to initial state
 *
 * @return {object} instance
 */
instance.destroy = function() {
    groups.forEach(function(groupId) {
        instance.remove(groupId);
    });

    // remove general listeners
    window.removeEventListener('optimizedResize', resizeHandler);
    window.removeEventListener('ni-equalizer.update', resizeHandler);

    return instance;
};

/**
 * Add a group to the equalizer
 *
 * @param {integer} groupId if the group to add
 * @return {object} instance
 */
instance.add = function(groupId) {
    groups.push(groupId);
    setHeight(groupId);
    var groupNode = getGroupNode(groupId);
    groupNode.addEventListener('ni-equalizer.update', instance.update);

    // resize if image has been loaded inside group
    subscriptions[groupId] = Pubsub.subscribe('image.loaded', function(element) {
        if (groupId === getGroupIdByChildNode(element)) {
            resize(groupId);
        }
    });

    return instance;
};

/**
 * Remove a group to the equalizer
 *
 * @param {integer} groupId if the group to remove
 * @return {object} instance
 */
instance.remove = function(groupId) {
    unsetHeight(groupId);
    delete groups[groupId];
    var groupNode = getGroupNode(groupId);
    groupNode.removeEventListener('ni-equalizer.update', instance.update);
    Pubsub.unsubscribe(subscriptions[groupId]);

    return instance;
};

/**
 * Updates a surtain
 *
 * @param {integer} groupId of the group to update
 * @return {object} instance;
 */
instance.update = function(groupId) {
    if (groupId instanceof Object) {
        groupId = this.getAttribute(settings.selector);
    } else if (groupId instanceof Element) {
        groupId = groupId.getAttribute(settings.selector);
    }

    unsetHeight(groupId);
    setHeight(groupId);

    return instance;
};

export default instance;
