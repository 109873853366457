
const createImageGallery = ({ el, state, options }, Swiper) => {

    const initSwiper = () => {
        return new Swiper(state.refs.swiperContainer, {
            spaceBetween: 30,
            slidesPerView: 'auto',
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            // Responsive breakpoints
            breakpoints: {
                // when window width is <= 767px
                767: {
                    spaceBetween: 12
                }
            }
        }).on('slideChangeStart', function(swiper) {
            if(swiper.activeIndex > 0) {
                state.refs.swiperContainer.classList.add('swiper-container--centered');
            } else {
                state.refs.swiperContainer.classList.remove('swiper-container--centered');
            }
        });
    };


    state.init = () => {
        state.refs = {
            swiperContainer: el.querySelector(options.refs.swiperContainer)
        };

        state.swiper = initSwiper();
    };

    state.destroy = () => {
        state.swiper.destroy();
    };

    return state.init();
};

export const config = {
    name: 'swiper-gallery',
    selector: '[data-swiper-gallery]',
    constructor: createImageGallery,
    dependencies: ['Swiper'],
    options: {
        refs: {
            swiperContainer: '.swiper-container'
        }
    }
};
