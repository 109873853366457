const createCategoryEntrance = ({ el, state, options }, Store) => {
    const isOpen = () => {
        return el.classList.contains(options.classStateOpen);
    };

    const toggleOpen = () => {
        el.classList.toggle(options.classStateOpen);

        state.refs.moreButtonText.textContent = isOpen()
            ? Store.translations['trans:hideMore']
            : Store.translations['trans:showMore'];
    };

    const clickButtonHandler = (e) => {
        e.preventDefault();
        toggleOpen();
    };

    const addEventListeners = () => {
        state.refs.moreButton.addEventListener('click', clickButtonHandler);
    };

    const removeEventListeners = () => {
        state.refs.moreButton.removeEventListener('click', clickButtonHandler);
    };

    state.init = async function() {
        state = {
            refs: {
                moreButton: el.querySelector(options.refs.moreButton),
                moreButtonText: el.querySelector(options.refs.moreButtonText)
            }
        };

        addEventListeners();
    };

    state.destroy = function() {
        removeEventListeners();
    };

    state.init();

    return state;
};

export const config = {
    name: 'category-entrance',
    constructor: createCategoryEntrance,
    dependencies: ['Store'],
    options: {
        classStateOpen: 'is-open',
        refs: {
            moreButton: '[data-category-entrance-more]',
            moreButtonText: '[data-category-entrance-more] .btn__text'
        }
    }
};
