import Popper from 'popper.js';

let activeTooltip = null;

const highlightElement = document.createElement('div');
highlightElement.classList.add('tooltip-highlight');
document.body.appendChild(highlightElement);

const resizeHighlightElement = (tooltip) => {
    const trigger = tooltip.getTriggerElement();
    const triggerBounding = trigger.getBoundingClientRect();
    tooltip.getOptions().highlightElementPosition(highlightElement, triggerBounding, trigger);
};

const Tooltip = function (trigger, userOptions) {
    const options = {
        ...Tooltip.defaultOptions,
        ...userOptions
    };

    let instance = {};
    let opening = null;
    let closing = null;
    let popper = null;
    let initialOpenClosed = false;

    const tooltipTarget = trigger.getAttribute('data-tooltip-target') || options.target;
    const tooltipElement = document.getElementById(tooltipTarget);
    const tooltipInner = tooltipElement.firstElementChild;
    const closeButton = tooltipElement.querySelectorAll(options.selectorCloseButton);

    const localStorageKeyClosedIntialOpen = `tooltip-${tooltipTarget}-closed-initial-open`;

    const bodyClickListener = (e) => {
        if (options.manually) {
            return;
        }

        if (!e.target.closest('.tooltip')) {
            instance.close();
        }
    };

    const bodyClickCaptureListener = (e) => {
        // allow just clicks inside of tooltip
        if (!e.target.closest('.tooltip')) {
            e.preventDefault();
            e.stopImmediatePropagation();
        }
    };

    const triggerClickListener = (e) => {
        if (options.preventTriggerAction) {
            e.preventDefault();
        }

        if (options.manually || options.noClick) {
            return;
        }

        instance.toggle();
    };

    const closeButtonClickListener = (e) => {
        e.preventDefault();
        instance.close();
    };

    const unbindEvents = () => {
        trigger.removeEventListener('click', triggerClickListener);
        document.removeEventListener('click', bodyClickListener);
        document.removeEventListener('click', bodyClickCaptureListener, true);

        if (closeButton) {
            closeButton.forEach((button) => {
                button.removeEventListener('click', closeButtonClickListener);
            });
        }
    };

    instance.init = () => {
        trigger.addEventListener('click', triggerClickListener);

        if (closeButton) {
            closeButton.forEach((button) => {
                button.addEventListener('click', closeButtonClickListener);
            });
        }

        if (!options.stay) {
            // move tooltip element to end of body
            document.body.appendChild(tooltipElement);
        }

        // update intialOpenClosed from localStorage
        instance.getInitialOpenClosed();

        if (options.initialOpen
            && (!options.initialOpenUntilClosed
                || (options.initialOpenUntilClosed && !initialOpenClosed))
        ) {
            instance.open();
        }
    };

    instance.open = async () => {
        if (popper || opening) {
            return true;
        }

        // if (initialOpenClosed && options.initialOpenUntilClosed) {
        //     return false;
        // }

        if (!options.manually) {
            if (activeTooltip) {
                await activeTooltip.close({ previousTooltip: instance });
            }

            activeTooltip = instance;
        }

        requestAnimationFrame(() => {
            document.addEventListener('click', bodyClickListener);
        });

        if (options.onBeforeOpen) {
            await options.onBeforeOpen.call(instance);
        }

        if (options.highlight) {
            instance.resizeHighlightElementForTooltip();
            window.addEventListener('optimizedResize', instance.resizeHighlightElementForTooltip);

            highlightElement.classList.add(`tooltip-highlight--${tooltipTarget}`);

            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    highlightElement.classList.add(options.classHighlightElementVisible);
                });
            });
        }

        opening = new Promise((resolve) => {
            tooltipElement.style.display = 'block';
            trigger.classList.add(options.classTriggerOpen);

            popper = new Popper(trigger, tooltipElement, {
                placement: options.placement,
                onCreate: () => {
                    requestAnimationFrame(() => {
                        requestAnimationFrame(() => {
                            tooltipElement.classList.add(options.classOpen);

                            requestAnimationFrame(() => {
                                requestAnimationFrame(() => {
                                    opening = null;
                                    popper.update();
                                    resolve();

                                    if (options.onAfterOpen) {
                                        options.onAfterOpen.call(instance);
                                    }
                                });
                            });
                        });
                    });
                }
            });
        });

        return opening;
    };

    instance.close = async ({ previousTooltip = null, setInitialOpenClosed = true } = {}) => {
        if (!popper || closing) {
            return true;
        }

        if (options.onBeforeClose) {
            await options.onBeforeClose.call(instance);
        }

        if (setInitialOpenClosed) {
            localStorage.setItem(localStorageKeyClosedIntialOpen, true);
            initialOpenClosed = true;
        }

        document.removeEventListener('click', bodyClickListener);

        if (highlightElement) {
            highlightElement.classList.remove(`tooltip-highlight--${tooltipTarget}`);
            window.removeEventListener('optimizedResize', instance.resizeHighlightElementForTooltip);

            if (!previousTooltip) {
                highlightElement.classList.remove(options.classHighlightElementVisible);
            }
        }

        closing = new Promise((resolve) => {
            let transitionEndListener;

            tooltipInner.addEventListener('transitionend', transitionEndListener = (e) => {
                if (e.target === e.currentTarget) {
                    tooltipInner.removeEventListener('transitionend', transitionEndListener);
                    closing = null;

                    if (!popper) {
                        return true;
                    }

                    popper.destroy();
                    popper = null;
                    tooltipElement.style.display = 'none';
                    trigger.classList.remove(options.classTriggerOpen);

                    if (!options.manually) {
                        activeTooltip = null;
                    }

                    resolve();

                    if (options.onAfterClose) {
                        options.onAfterClose.call(instance);
                    }
                }
            });

            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    tooltipElement.classList.remove(options.classOpen);
                });
            });
        });

        return closing;
    };

    instance.resizeHighlightElementForTooltip = () => {
        resizeHighlightElement(instance);
    };

    instance.getInitialOpenClosed = () => {
        initialOpenClosed = localStorage.getItem(localStorageKeyClosedIntialOpen) !== null;
        return initialOpenClosed;
    };

    instance.getOptions = () => {
        return options;
    };

    instance.getTriggerElement = () => {
        return trigger;
    };

    instance.getTooltipElement = () => {
        return tooltipElement;
    };

    instance.getIsClosing = () => {
        return closing;
    };

    instance.getPopperInstance = () => {
        return popper;
    };

    instance.toggle = () => {
        if (popper) {
            instance.close();
        } else {
            instance.open();
        }
    };

    instance.update = () => {
        if (!popper) {
            return;
        }

        popper.update();
    };

    instance.lock = () => {
        document.addEventListener('click', bodyClickCaptureListener, true);
    };

    instance.unlock = () => {
        document.removeEventListener('click', bodyClickCaptureListener, true);
    };

    instance.destroy = () => {
        unbindEvents();

        if (popper) {
            popper.destroy();
            popper = null;
        }
    };

    instance.init();

    return instance;
};

Tooltip.defaultOptions = {
    initialOpen: false,
    initialOpenUntilClosed: false,
    manually: false,
    noClick: false,
    stay: false,
    placement: 'bottom',
    selectorCloseButton: '[data-tooltip-close]',
    classOpen: 'tooltip--open',
    classTriggerOpen: 'tooltip-trigger--active',
    classHighlightElementVisible: 'tooltip-highlight--visible',
    highlight: false,
    preventTriggerAction: false,
    highlightElementPosition: function(highlightElement, triggerBounding) {
        highlightElement.style.position = 'absolute';
        highlightElement.style.transform = `translate3d(${window.scrollX + triggerBounding.left - 14}px, ${window.scrollY + triggerBounding.top - 8}px, 0)`;
        highlightElement.style.width = `${triggerBounding.width + 28}px`;
        highlightElement.style.height = `${triggerBounding.height + 16}px`;
    }
};

export default Tooltip;
