const templates = {
    recipes: `
        <ul class="page-header-search__popular-list">
            {{#recipes}}
                <li class="page-header-search__popular-list-item">
                    <a class="popular-recipe" href="{{url}}">
                        <div class="popular-recipe__image-wrapper">
                            <img class="popular-recipe__image"
                                src="{{image}}"
                                srcset="{{image}} 1x, {{image:retina}} 2x">
                        </div>
                        <div class="popular-recipe__text">
                            <div class="popular-recipe__title">
                                {{title}}
                            </div>
                            <div class="popular-recipe__views">
                                <div class="popular-recipe__views-icon">
                                    <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                        <path d="M12 5.5933c-5.8305 0-10 5.9322-10 5.9322s4.1695 5.9322 10 5.9322 10-5.9322 10-5.9322-4.1695-5.9322-10-5.9322z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.9999 14.7457c1.8722 0 3.3899-1.5177 3.3899-3.3898 0-1.8722-1.5177-3.3898-3.3899-3.3898-1.8721 0-3.3898 1.5176-3.3898 3.3898 0 1.8721 1.5177 3.3898 3.3898 3.3898z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10.8135 10.6779a1.3564 1.3564 0 00.003 1.3612A1.3562 1.3562 0 0012 12.7118" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div class="popular-recipe__views-text">{{views}}</div>
                            </div>
                        </div>
                    </a>
                </li>
            {{/recipes}}
        </ul>
    `
};

const popularRecipes = ({ el, state }, Store, Hogan, Pubsub) => {
    let recipes = [];

    state.fetchRecipes = async () => {
        return new Promise((resolve, reject) => {
            const http = new XMLHttpRequest();

            http.onload = function(event) {
                var response = JSON.parse(event.target.response);
                recipes = response.results;
                Pubsub.publish('popularRecipes.loaded', [recipes]);
                resolve(response);
            };

            http.onerror = reject;

            http.open('GET', Store.apis.popularRecipes + '?lang=' + Store.lang);
            http.send();
        });
    };

    state.renderRecipes = () => {
        // prepare images
        recipes.forEach((recipe, i) => {
            recipes[i]['image'] = Store.imagePaths['recipe-popular'].replace('{{keyvisual}}', recipe.keyvisual);
            recipes[i]['image:retina'] = Store.imagePaths['recipe-popular-@2x'].replace('{{keyvisual}}', recipe.keyvisual);
        });

        const content = Hogan.compile(templates.recipes).render({
            recipes
        });

        el.innerHTML = content;

        requestAnimationFrame(() => {
            Pubsub.publish('popularRecipes.rendered');
        });
    };

    state.init = async () => {
        await state.fetchRecipes();
        state.renderRecipes();
    };

    state.destroy = () => {
        el.innerHTML = '';
    };

    state.init();

    return state;
};

export const config = {
    name: 'popular-recipes',
    constructor: popularRecipes,
    dependencies: ['Store', 'Hogan', 'Pubsub'],
    options: {}
};
