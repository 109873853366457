

// dependencies
var g = require('@/scaffold/globals').default,
    customEvent = require('@/helpers/helper').default.customEvent,
    forEach = require('@/helpers/helper').default.forEach,
    serialize = require('@/helpers/helper').default.solrSerialize,
    get = require('@/helpers/helper').default.get,
    render = require('@/libs/render').default,
    pubsub = require('@/scaffold/pubsub').default,

    // controllers
    // ingredientSearchController = require('@/apps/search/modules/searchbar-controller').default,
    searchbarController = require('@/apps/search/modules/searchbar-controller').default,
    tokenController = require('@/apps/search/modules/token-controller').default,
    filterController = require('@/apps/search/modules/filter-controller').default,
    modules = {
        view: require('@/apps/main/plugins/view-controller').default,
        button: require('@/apps/search/modules/button').default
    };

// vars
var container = '[data-teaser-group-feed]',
    resultsSelector = '[data-teaser-group-feed-container]',
    ingredientSelector = '[data-teaser-group-feed-ingredient]',
    ingedientTextSelector = '[data-teaser-group-feed-ingredient-text]',
    ingedientRemoveSelector = '[data-teaser-group-feed-ingredient-remove]',
    ingedientSearchbarSelector = '[data-teaser-group-feed-placeholder-ingredient]',
    baseFilter = 'data-teaser-group-feed-basefilter',
    filterTypeAttr = 'data-teaser-group-feed-filtertype',
    ingredientSearchAttr = 'data-teaser-group-feed-ingredient-search',
    ingredientPlaceholderAttr = 'data-teaser-group-feed-placeholder-ingredient',
    ingredientPlaceholderFullAttr = 'data-teaser-group-feed-placeholder-full',
    defaultNum = 9,
    counter = 0,
    searchDefaults = {
        'query': '',
        'lang': store.lang,
        'treffertyp': '',
        'start': 0,
        'num': defaultNum
    };

// private functions
var init = function(container) {

    var instance = {},
        settings = {},
        id = ++counter,
        nextStart = 0,
        http,
        httpSearch,
        query,
        sort = '',
        ingredientInitialPlaceholder = '',
        ingredientQueries = [],
        loaders,
        currentSearchParams,
        paramsChangedByPopState,
        sortElement,
        dropdownLabels,
        dropdownValues,

        hideLoaders = function() {
            forEach(loaders, function(loader) {
                loader.style.display = 'none';
            });
        },

        showLoaders = function() {
            forEach(loaders, function(loader) {
                loader.style.display = 'block';
            });
        },

        shouldReplaceHistory = function(newParams) {
            var currentParams = g.history.getParams();

            // changed query
            if (newParams.query !== currentParams.query) {
                return false;
            }

            // navigated inside rezept category
            if (currentParams[`filtersFeed${id}`].treffertyp === 'rezepte' &&
                newParams[`filtersFeed${id}`].treffertyp === 'rezepte'
            ) {
                return true;
            }

            // navigated inside same treffertyp and inhaltsart
            if (currentParams[`filtersFeed${id}`].treffertyp === newParams[`filtersFeed${id}`].treffertyp &&
                currentParams[`filtersFeed${id}`].inhaltsart === newParams[`filtersFeed${id}`].inhaltsart
            ) {
                return true;
            }

            return false;
        },

        initFiltersByParams = function(newParams = {}) {
            resetTokens();

            // apply tokens
            for (let filterKey in newParams[`filtersFeed${id}`]) {
                if (newParams[`filtersFeed${id}`].hasOwnProperty(filterKey)) {
                    let filterValue = newParams[`filtersFeed${id}`][filterKey];

                    if (Array.isArray(filterValue)) {
                        forEach(filterValue, function (filterId) {
                            // filterId = filterId.replace(/"/g, '');
                            let filter = document.querySelector(`[data-filter-id="${filterId}"]`);

                            if (filter) {
                                var token = {
                                    id: filter.getAttribute('data-filter-id'),
                                    title: filter.querySelector('[data-filter-title-element]').innerHTML,
                                    value: true
                                };

                                instance.tokenController.addToken(token, true);
                                instance.filterController.addSearchParam(token, true);
                            }
                        });
                    }
                }
            }

        },

        resetTokens = function () {
            var tokens = instance.tokenController.removeAllTokens(true);

            if (tokens instanceof Array && tokens.length > 0) {

                // unchecks every checkbox in the filter without dispatching the event at the end
                tokens.forEach(function (token) {
                    instance.filterController.removeSearchParam(token, true);
                });
            }
        },

        /**
         * Evaluating the response given by the ajax call
         *
         * @param {object} response of the call
         * @param {object} params of the call
         * @param {boolean} [initial=false] whether it's initial call
         */
        handleResponse = function(response, params, initial = false) {
            var tmpQuery;

            // no teaser and no alternative teaser found
            if (response.hasOwnProperty('resultgroups') || (response.hasOwnProperty('resultcounts') && response.resultcounts.all === 0)) {

                if (instance.view.getType() === 'noresults') {
                    container.querySelector('[data-noresults-query]').innerHTML = settings.baseFilter.query || params.query || '';
                } else {
                    instance.view.updateView('noresults', '', true, false, function() {
                        container.querySelector('[data-noresults-query]').innerHTML = settings.baseFilter.query || params.query || '';
                    });
                }

            } else if (response.hasOwnProperty('resultcounts')) { // teasers found

                var cleanOld = false,
                    cleanNew = false,
                    content = '';

                if (settings.filterType === 'filter') {
                    instance.filterController.updateFilterCounts(response.filters);
                }

                if (response.resultcounts.start === 0 || 'results' in response === false) {
                    cleanNew = true;

                    tmpQuery = (response.query.hasOwnProperty('dym') && response.query.dym.hasOwnProperty('autocorrect') && response.query.dym.autocorrect === true) ? response.query.dym.query : params[`queryFeed${id}`] || '';

                    if (tmpQuery !== '') {
                        // Cleaning the View is only necessary when the query changed
                        let query = (response.query.hasOwnProperty('dym') && response.query.dym.hasOwnProperty('autocorrect') && response.query.dym.autocorrect === true) ? response.query.dym.query : params[`queryFeed${id}`];

                        pubsub.publish('searchResponse.mainFilterChanged', ['teaserGroupFeed', {
                            treffertypChanged: false,
                            treffertyp: 'rezepte',
                            queryChanged: (settings.filterType === 'filter') ? false : true,
                            query: (settings.filterType === 'filter') ? JSON.stringify(query) : query,
                            results: String(response.hasOwnProperty('resultcounts') ? response.resultcounts.all : 0),
                            filter: `Kategorie : ${document.location.pathname.split('/').pop().replace('.html', '')}`,
                            initial
                        }]);
                    }
                } else {
                    cleanNew = false;
                }

                if (response.hasOwnProperty('results')) {
                    content = render.teaser(response.results, {
                        initialwith: 4,
                        xs: 12,
                        sm: 4,
                        md: 4
                    }, {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        hgutter: true
                    });
                }

                instance.view.updateView('teaser', content, cleanOld, cleanNew, function() {
                    if (settings.filterType === 'filter') {
                        instance.filterController.updateTotalCount(response.resultcounts.all);
                    } else {
                        container.querySelector('[data-resultcount-total]').innerHTML = response.resultcounts.all;
                    }
                });

                if (response.resultcounts.hasOwnProperty('next_start')) {
                    nextStart = response.resultcounts.next_start;

                    // Shows load more button (if not already visible)
                    instance.button.showButton();
                } else {

                    // Hides load more button (if not already hidden)
                    instance.button.hideButton();
                }
            }
        };

    instance.search = function(start, initial = false, executeRequest = true) {
        if (http) {
            http.abort();
        }

        if (httpSearch) {
            httpSearch.abort();
        }

        start = start || 0;
        http = new XMLHttpRequest();

        const params = {
            sort: sort
        };

        params[`queryFeed${id}`] = query;
        params[`startFeed${id}`] = start;

        if (settings.filterType === 'filter') {
            params[`filtersFeed${id}`] = instance.filterController.getFilterQuery(true);
        }

        if (start !== 0 && g.isRecipe === false) {
            g.history.replaceParams(params);
        }

        // Adding filter params to the params
        let searchParams;
        if (settings.filterType === 'filter') {
            searchParams = Object.assign({}, searchDefaults, params, {
                query,
                start,
                interface: store.interface || 'hawaii'
            }, settings.baseFilter, instance.filterController.getFilterQuery());
        } else {
            searchParams = Object.assign({}, searchDefaults, params, {
                query,
                start,
                interface: store.interface || 'hawaii'
            }, settings.baseFilter);
        }

        if (initial) {
            searchParams.start = 0;
            searchParams.num = start + defaultNum;
        }

        if (settings.filterType === 'filter') {
            if (!paramsChangedByPopState) {
                if (initial || shouldReplaceHistory(params)) {
                    g.history.replaceParams(params, null, true);
                } else {
                    g.history.pushParams(params, null, true);
                }
            }
        }

        // reset flag
        paramsChangedByPopState = false;

        if (executeRequest) {
            currentSearchParams = searchParams;

            // Activate loading animations
            instance.button.hideButton();
            showLoaders();

            http.onload = function(event) {
                hideLoaders();
                handleResponse(JSON.parse(event.target.response), params, initial);
            };

            http.open('GET', store.apis.search + '?' + serialize(searchParams));
            http.send();
        }
    };

    instance.getCurrentSearchParams = function() {
        return currentSearchParams;
    };

    instance.findEmptyIngredientIndex = function() {
        return instance.ingredients
            .findIndex(ingredient => !ingredient.classList.contains('active'));
    };

    instance.ingredientSearch = function(start, initial = false) {
        // write new query in state
        query = ingredientQueries.join(' ').trim();

        let newIngredientPlaceholder = '';
        const searchbar = container.querySelector(ingedientSearchbarSelector);

        const ingredientFieldsLength = instance.ingredients.length;
        const ingredientQueriesLength = ingredientQueries.filter(ingredientQuery => ingredientQuery !== null).length;

        // disable searchfield if all ingredient fields are used
        if (ingredientFieldsLength === ingredientQueriesLength) {
            instance.searchbarController.searchbar.disable();
            newIngredientPlaceholder = searchbar.getAttribute(ingredientPlaceholderFullAttr);
            instance.resultsSection.classList.remove('hidden');
        } else if (ingredientQueriesLength === 0) {
            instance.searchbarController.searchbar.enable();
            newIngredientPlaceholder = ingredientInitialPlaceholder;
            instance.resultsSection.classList.add('hidden');
            // clean markup from results element so loader gets shown when term gets added again
            container.querySelector('.teaser-group-feed__results').innerHTML = '';
        } else {
            const emptyIngredientIndex = instance.findEmptyIngredientIndex();
            instance.searchbarController.searchbar.enable();
            newIngredientPlaceholder = searchbar.getAttribute(ingredientPlaceholderAttr).replace('{ingredientNr}', emptyIngredientIndex + 1);
            instance.resultsSection.classList.remove('hidden');
        }

        instance.searchbarController.searchbar.searchfield.setAttribute('placeholder', newIngredientPlaceholder);

        instance.search(start, initial, query !== '');
    };

    instance.addIngredientQuery = function(ingredientQuery, triggerSearch = true) {
        const newQuery = ingredientQuery;
        const emptyIngredientIndex = instance.findEmptyIngredientIndex();
        const queryAlreadyExists = ingredientQueries.find(query => query && query.toLowerCase() === newQuery.toLowerCase());

        if (newQuery === '' || emptyIngredientIndex < 0 || queryAlreadyExists) {
            return;
        }

        const defaultLabel = instance.ingredients[emptyIngredientIndex].querySelector(ingedientTextSelector).textContent;

        instance.ingredients[emptyIngredientIndex].setAttribute('data-default-label', defaultLabel);
        instance.ingredients[emptyIngredientIndex].classList.add('active');
        instance.ingredients[emptyIngredientIndex].querySelector(ingedientTextSelector).textContent = newQuery;

        ingredientQueries[emptyIngredientIndex] = newQuery;

        // reset input value
        instance.searchbarController.searchbar.searchfield.value = '';

        if (triggerSearch) {
            instance.ingredientSearch();
        }
    };

    instance.removeIngredientQuery = function(index, triggerSearch = true) {
        const defaultLabel = instance.ingredients[index].getAttribute('data-default-label');

        instance.ingredients[index].classList.remove('active');
        instance.ingredients[index].querySelector(ingedientTextSelector).innerHTML = defaultLabel;
        instance.ingredients[index].removeAttribute('data-default-label');

        ingredientQueries[index] = null;

        if (triggerSearch) {
            instance.ingredientSearch();
        }
    };

    instance.init = function() {

        settings.baseFilter = JSON.parse(container.getAttribute(baseFilter));
        settings.filterType = container.getAttribute(filterTypeAttr);
        settings.ingredientSearch = container.getAttribute(ingredientSearchAttr) !== null;

        instance.resultsSection = container.querySelector(resultsSelector);

        loaders = container.querySelectorAll('.loader-pot');

        hideLoaders();

        if (settings.filterType === 'search') {
            instance.searchbarController = searchbarController(container, { autocomplete: { init: false } });
        } else if (settings.filterType === 'filter' && settings.ingredientSearch) {
            instance.ingredients = Array.from(container.querySelectorAll(ingredientSelector));

            instance.searchbarController = searchbarController(container, {
                autocomplete: {
                    init: true,
                    openthreshhold: 2,
                    limit: 5,
                    api: store.apis.autocomplete,
                    method: 'GET'
                }
            });

            ingredientInitialPlaceholder = instance.searchbarController.searchbar.searchfield.getAttribute('placeholder') || '';
        }

        instance.button = modules.button(container);
        instance.button.subscribe('click', function() {
            // instance.button.disableButton();
            instance.search(nextStart);
        });

        if (settings.filterType === 'filter') {
            instance.tokenController = tokenController(container);
            instance.filterController = filterController(container);

            pubsub.subscribe('filterController.filter.changed', function() {
                instance.search();
            });

            // token controller handling

            container.addEventListener('filter.add', function(event) {
                instance.tokenController.addToken({
                    id: event.detail.id,
                    title: event.detail.title,
                    value: true
                }, true);
            });

            container.addEventListener('filter.remove', function(event) {
                instance.tokenController.removeToken({
                    id: event.detail.id
                }, true);
            });

            container.addEventListener('token.addToken', function(event) {
                // checks the checkbox in the filter without dispatching the event at the end
                instance.filterController.addSearchParam(event.detail.token, true);
                instance.search();
            });

            container.addEventListener('token.removeToken', function(event) {
                // unchecks the checkbox in the filter without dispatching the event at the end
                instance.filterController.removeSearchParam(event.detail.token, true);
                instance.search();
            });

            container.addEventListener('token.removeAllToken', function(event) {
                // unchecks every checkbox in the filter without dispatching the event at the end
                event.detail.tokens.forEach(function (token) {
                    instance.filterController.removeSearchParam(token, true);
                });

                instance.search();
            });
        }

        instance.view = modules.view.call(container, {
            activeType: 'teaser',
            containers: [
                {
                    type: 'teaser',
                    wrapper: '.teaser-group-feed__teaser-result-wrapper',
                    content: '.teaser-group-feed__results',
                    onAfterAdd: function() {
                        this.content.dispatchEvent(customEvent('ni-equalizer.update', {}));
                        pubsub.publish('tooltip.init');
                        pubsub.publish('likes.bindTrigger');
                        pubsub.publish('likes.loadLikes');
                        pubsub.publish('likes.setActives');

                        pubsub.publish('teasers.added');
                        pubsub.publish('teaserGroupFeed.teasers.added');

                        // add delay, because dom can take a little time to append the teasers
                        setTimeout(function () {
                            pubsub.publish('teasers.updateStates', [true]);
                        }, 200);
                    }
                },
                {
                    type: 'noresults',
                    wrapper: '.teaser-group-feed__noresults-wrapper',
                    content: '.teaser-group-feed__noresults-wrapper'
                }
            ]
        });

        if (settings.filterType === 'search') {
            container.addEventListener('searchbar.submit', function () {
                // Don't execute a search, if the query didn't change
                if (query !== instance.searchbarController.searchbar.searchfield.value) {

                    // write new query in state
                    query = instance.searchbarController.searchbar.searchfield.value;

                    instance.searchbarController.blurInput();
                    instance.search();
                }
            });
        } else if (settings.filterType === 'filter' && settings.ingredientSearch) {
            container.addEventListener('searchbar.submit', () => {
                instance.addIngredientQuery(instance.searchbarController.searchbar.searchfield.value);
            });

            container.querySelectorAll(ingedientRemoveSelector).forEach((ingredientRemove, index) => {
                ingredientRemove.addEventListener('click', () => {
                    instance.removeIngredientQuery(index);
                });
            });
        }

        container.addEventListener('click', function(e) {
            const wrapper = e.target.closest('.teaser-group-feed__result-container');
            const teaser = e.target.closest('.teaser');

            if (wrapper && teaser) {
                const teasers = Array.from(wrapper.querySelectorAll('.teaser'));
                const teaserLink = teaser.querySelector('.teaser__click-area');
                const teaserLinkUrl = teaserLink.getAttribute('href').split('#')[0].split('?')[0];
                pubsub.publish('teaserGroup.clicked', [instance, teasers.indexOf(teaser), teaserLinkUrl]);
            }
        });

        // looks if the query is given in the url
        query = get(`queryFeed${id}`) || '';
        if (typeof query === 'string') {
            query = query.replace(/\+/g, ' ');
        }

        sortElement = document.querySelector('#teaser-group-sort');

        dropdownLabels = container.querySelectorAll('[data-sort-dropdown-label]');
        dropdownValues = sortElement.querySelectorAll('[data-sort-dropdown-value]');

        dropdownValues.forEach(element => {
            const value = element.getAttribute('data-sort-dropdown-value');
            const text = element.innerText;

            element.addEventListener('click', () => {
                sort = value;

                dropdownLabels.forEach(label => {
                    label.innerText = text;
                });

                dropdownValues.forEach(valueElement => {
                    if (valueElement === element) {
                        valueElement.setAttribute('data-sort-dropdown-current', '');
                    } else {
                        valueElement.removeAttribute('data-sort-dropdown-current');
                    }
                });

                instance.search(start);
            });
        });

        if (settings.filterType === 'search') {
            instance.searchbarController.searchbar.searchfield.value = query;
            instance.searchbarController.searchbar.checkButtonStatus(query);
        } else {
            if (settings.filterType === 'filter' && settings.ingredientSearch && query) {
                query.split(' ').forEach((query) => {
                    instance.addIngredientQuery(query, false);
                });
            } else {
                query = '';
            }

            g.history.subscribe('params.changed', function (params, previousParams, options) {
                paramsChangedByPopState = false;

                if (options.popstate) {
                    let start = 0;

                    if (params[`startFeed${id}`]) {
                        start = parseInt(params[`startFeed${id}`]);
                    }

                    paramsChangedByPopState = true;
                    initFiltersByParams(params, previousParams);
                    instance.search(start, true);
                }
            });
        }

        let start = 0;
        let currentParams = g.history.getParams();

        sort = currentParams['sort'] || '';

        let currentSortDropdownValueElement;

        if (sort) {
            currentSortDropdownValueElement = sortElement.querySelector(`[data-sort-dropdown-value="${sort}"]`);
        } else {
            currentSortDropdownValueElement = sortElement.querySelector('[data-sort-dropdown-default]')
                || sortElement.querySelector('[data-sort-dropdown-value]');
        }

        if (currentSortDropdownValueElement) {
            const text = currentSortDropdownValueElement.innerText;
            dropdownLabels.forEach(label => {
                label.innerText = text;
            });

            dropdownValues.forEach(valueElement => {
                if (valueElement === currentSortDropdownValueElement) {
                    valueElement.setAttribute('data-sort-dropdown-current', '');
                } else {
                    valueElement.removeAttribute('data-sort-dropdown-current');
                }
            });
        }

        if (currentParams[`startFeed${id}`]) {
            start = parseInt(currentParams[`startFeed${id}`]);
        }

        if (settings.filterType === 'filter') {
            initFiltersByParams(currentParams);
        }

        if (settings.filterType === 'filter' && settings.ingredientSearch) {
            instance.ingredientSearch(start, true);
        } else {
            instance.search(start, true);
        }

        return instance;
    };

    return instance.init();
};

// public functions
export default function () {
    var searches = document.querySelectorAll(container);

    if (searches instanceof NodeList && searches.length > 0) {
        forEach(searches, init);

        pubsub.publish('teasers.initialized', [searches]);
        pubsub.publish('teaserGroupFeed.teasers.initialized', [searches]);
    }
};
