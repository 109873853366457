

// dependencies
var g = require('@/scaffold/globals').default,
    customEvent = require('@/helpers/helper').default.customEvent,
    pubsub = require('@/scaffold/pubsub').default,
    modules = {
        api: require('@/libs/api-factory').default,
        cookbookSelect: require('@/apps/myfooby/modules/cookbook-select-view').default,
        cookbookAdd: require('@/apps/myfooby/modules/cookbook-add-view').default,
        saveDeleteBar: require('@/apps/myfooby/modules/save-delete-bar').default,
        simpleConfirm: require('@/apps/myfooby/modules/simple-confirm').default,
        view: require('@/apps/main/plugins/view-controller').default
    };

// vars
var defaults = {
    urlContainer: '[data-url-container]',
    urlInput: '[data-external-recipe-url]',
    urlInputTimeout: 1000,
    groupToShow: '[data-external-recipe-show-group]',
    titleContainer: '[data-title-container]',
    missingImageContainer: '.external-recipe__missing-image',
    titleInput: '[data-external-recipe-title]',
    imgSlider: '.external-recipe__img-slider-wrapper',
    cookbooksContainer: '[data-cookbooks-container]',
    loader: '[data-loader]',
    paginationText: 'data-pagination-text',
    errorClass: 'error',
    mode: 'create',  // 'create' or 'edit'
    data: false,     // used to prefill data,
    btnDelete: '[data-delete-recipe]'
};

export default function (container, options) {
    var instance = {},
        settings,
        cookbooks = g.cookbooks,
        externalRecipe = g.externalRecipe,
        urlContainer,
        urlInput,
        startedTyping = false,
        titleContainer,
        titleInput,
        missingImageContainer,
        imgSlider,
        swiperInstance,
        loader,
        apiRecipeGet,
        paginationText,
        cookbooksContainer,
        btnDelete,
        groupToShow,

        testUrl = function (url) {
            return /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(url);
        },

        /**
         * Checks if url is valid.
         *
         * @return {boolean} True if valid
         */
        validateUrl = function () {
            return testUrl(urlInput.value);
        },

        /**
         * Checks if title is not empty.
         *
         * @returns {boolean} True if valid
         */
        validateTitle = function () {
            return titleInput.value.length > 0;
        },

        /**
         * Checks if at least one cookbook is selected.
         *
         * @return {boolean} True if valid
         */
        validateCookbooks = function () {
            var checkboxes = cookbooksContainer.querySelectorAll('input[type="checkbox"]:checked');

            return checkboxes.length > 0;
        },

        showResponseResult = async function (response) {
            // fill recipe title. when in edit mode leave title as is.
            if (settings.mode === 'create') {
                titleInput.value = response['title'];
            }

            if (response['images'].length > 0) {
                const { default: Swiper } = await import(/* webpackChunkName: "swiper" */'swiper');

                var slide = '<div class="swiper-slide"><img src="<%imgurl%>" alt=""></div>',
                    tmp = '';

                // fill slides
                response['images'].forEach(function (item) {
                    tmp += slide.replace('<%imgurl%>', item);
                });

                imgSlider.querySelector('.swiper-wrapper').innerHTML = tmp;

                // init swiper
                swiperInstance = new Swiper(imgSlider.querySelector('.swiper-container'), {
                    pagination: '.swiper-pagination',
                    paginationClickable: true,
                    nextButton: '.swiper-button-next',
                    prevButton: '.swiper-button-prev',
                    spaceBetween: 30,
                    slidesPerView: 1,
                    initialSlide: 0,
                    paginationType: 'custom',
                    paginationCustomRender: function (swiper, current, total) {
                        return paginationText
                            .replace('<%index%>', current)
                            .replace('<%total%>', total);
                    }
                });

                missingImageContainer.classList.remove('show');
                imgSlider.classList.add('show');

                swiperInstance.update(); // Update to rerender after setting display: block
                swiperInstance.slideReset(); // Reset counter to first slide
            } else {
                if (swiperInstance && swiperInstance.destroy instanceof Function) {
                    swiperInstance.destroy();
                    swiperInstance = null;
                }

                missingImageContainer.classList.add('show');
                imgSlider.classList.remove('show');
            }

            // make elements visible
            titleContainer.classList.add('show');

            // trigger resize to show scrollbar if necessary
            window.dispatchEvent(customEvent('optimizedResize', {}));

            // validate
            titleContainer.classList.toggle(settings.errorClass, !validateTitle());
        },

        showLoader = function () {
            loader.classList.add('show');
            titleContainer.classList.add('loading');
            imgSlider.classList.add('loading');
        },

        hideLoader = function () {
            loader.classList.remove('show');
            titleContainer.classList.remove('loading');
            imgSlider.classList.remove('loading');
        },

        handleCrawlSiteError = function (statusCode) {
            groupToShow.classList.remove('show');
            hideLoader();
            urlContainer.classList.add('error');
            instance.saveDeleteBar.disableSave();

            const errorMessage = urlContainer.querySelector('small');
            const errors = {
                url: errorMessage.textContent.trim()
            };

            pubsub.publish('errors.show', ['External Recipe', errors]);
            console.error('Ajax call was unsuccessful. Returned status code ' + statusCode + '.');
        },

        handleCrawlSiteSuccess = function (response) {
            var tmpResponse;

            try {
                tmpResponse = JSON.parse(response);
            } catch (e) {
                console.error('There was an error while parsing ajax response.', e);
                return;
            }

            hideLoader();
            showResponseResult(tmpResponse);
            instance.saveDeleteBar.enableSave();
        },

        getExternalRecipeContent = function (url) {
            groupToShow.classList.add('show');
            showLoader();

            titleContainer.classList.remove('show');
            imgSlider.classList.remove('show');

            apiRecipeGet.send({
                params: {
                    url: url
                }
            });
        },

        handleUrlInput = function () {
            var makeCall = function () {

                // validate url
                if (validateUrl()) {
                    urlContainer.classList.remove(settings.errorClass);

                    // make ajax call
                    getExternalRecipeContent(urlInput.value);
                } else {

                    // show error
                    urlContainer.classList.add(settings.errorClass);
                    instance.saveDeleteBar.disableSave();

                    const errorMessage = urlContainer.querySelector('small');
                    const errors = {
                        url: errorMessage.textContent.trim()
                    };

                    pubsub.publish('errors.show', ['External Recipe', errors]);

                    // clear title and swiper
                    clear();
                }

                startedTyping = false;
            };

            if (startedTyping === false) {
                startedTyping = true;

                setTimeout(makeCall, settings.urlInputTimeout);
            }
        },

        bindUrlInput = function () {
            if (urlInput) {
                // 'input' also works when pasting text from clipboard.
                urlInput.addEventListener('input', handleUrlInput);
            }
        },

        unbindUrlInput = function () {
            if (urlInput) {
                urlInput.removeEventListener('input', handleUrlInput);
            }
        },

        clear = function () {
            groupToShow.classList.remove('show');

            titleContainer.classList.remove(settings.errorClass);
            titleInput.value = '';

            titleContainer.classList.remove('show');
            imgSlider.classList.remove('show');

            hideLoader();

            if (swiperInstance) {
                swiperInstance.destroy(true, true);
                swiperInstance = null;

                // remove all slides
                imgSlider.querySelector('.swiper-wrapper').innerHTML = '';
            }
        },

        clearAll = function () {
            urlContainer.classList.remove(settings.errorClass);
            urlInput.value = '';

            cookbooksContainer.classList.remove(settings.errorClass);

            clear();
        },

        addCookbook = function (cookbook) {
            instance.cookbookSelect.updateCookbooks(
                instance.cookbookSelect.getSelected(),
                cookbook
            );
        },

        cancelHandler = function () {
            if (settings.miniModalInstance) {
                settings.miniModalInstance.close();
            }
        },

        validateFields = function () {
            var urlValid = validateUrl(),
                titleValid = validateTitle(),
                cookbooksValid = validateCookbooks();

            urlContainer.classList.toggle(settings.errorClass, !urlValid);
            titleContainer.classList.toggle(settings.errorClass, !titleValid);
            cookbooksContainer.classList.toggle(settings.errorClass, !cookbooksValid);

            const errors = {};

            if (!urlValid) {
                const errorMessage = urlContainer.querySelector('small');

                if (errorMessage) {
                    errors.url = errorMessage.textContent.trim();
                }
            }

            if (!titleValid) {
                const errorMessage = titleContainer.querySelector('small');
                if (errorMessage) {
                    errors.title = errorMessage.textContent.trim();
                }
            }

            if (!cookbooksValid) {
                const errorMessage = cookbooksContainer.parentElement.querySelector('.cookbook-select-error-message');

                if (errorMessage) {
                    errors.cookbook = errorMessage.textContent.trim();
                }
            }

            if (Object.keys(errors).length) {
                pubsub.publish('errors.show', ['External Recipe', errors]);
            }

            return urlValid && titleValid && cookbooksValid;
        },

        getActiveImageUrl = function () {
            if (!swiperInstance) {
                return '';
            }

            var slide = swiperInstance.slides[swiperInstance.activeIndex];
            return slide.querySelector('img').src;
        },

        saveHandler = function () {
            var createdId,
                updatedId;

            if (validateFields()) {

                // save external recipe
                switch (settings.mode) {
                case 'create':
                    createdId = externalRecipe.subscribe('created', function () {
                        externalRecipe.unsubscribe(createdId);
                        // close modal
                        settings.miniModalInstance.close();
                    });

                    externalRecipe.createExternalRecipe(
                        urlInput.value,
                        getActiveImageUrl(),
                        titleInput.value,
                        instance.cookbookSelect.getSelected().join(','));
                    break;

                case 'edit':
                    updatedId = externalRecipe.subscribe('updated', function () {
                        externalRecipe.unsubscribe(updatedId);
                        // close modal
                        settings.miniModalInstance.close();
                    });

                    externalRecipe.updateExternalRecipe(
                        settings.data['target_url'],
                        titleInput.value,
                        instance.cookbookSelect.getSelected().join(','));
                    break;
                }
            }
        },

        deleteHandler = function () {
            var deletedId;

            // delete is only available when in edit mode. data is always available.
            deletedId = externalRecipe.subscribe('deleted', function () {
                externalRecipe.unsubscribe(deletedId);
                // close modal
                settings.miniModalInstance.close();
            });

            externalRecipe.deleteExternalRecipe(settings.data['target_url']);
        },

        applyMode = function () {
            container.classList.toggle('external-recipe--edit-mode', settings.mode === 'edit');
            container.classList.toggle('external-recipe--create-mode', settings.mode === 'create');

            if (settings.mode === 'edit') {
                groupToShow.classList.add('show');
                titleContainer.classList.add('show');
                instance.saveDeleteBar.enableSave();
            }
        },

        prefillData = function () {
            if (settings.data) {
                urlInput.value = settings.data['target_url'];
                titleInput.value = settings.data['title'];
                instance.cookbookSelect.setActives(settings.data['cookbook_ids']);

                // start loading images
                if (settings.mode === 'create') {
                    handleUrlInput();
                }
            }
        },

        bindDeleteRecipe = function () {
            if (settings.mode === 'edit' && btnDelete) {
                btnDelete.addEventListener('click', deleteHandler);
            }
        },

        unbindDeleteRecipe = function () {
            if (settings.mode === 'edit' && btnDelete) {
                btnDelete.removeEventListener('click', deleteHandler);
            }
        };

    instance.init = function () {
        settings = Object.assign({}, defaults, options);

        instance.cookbookSelect = modules.cookbookSelect(cookbooks, container);
        instance.cookbookAdd = modules.cookbookAdd(cookbooks, container);
        instance.saveDeleteBar = modules.saveDeleteBar(container);

        instance.saveDeleteBar.disableSave();

        // get dom elements
        urlContainer = container.querySelector(settings.urlContainer);
        urlInput = container.querySelector(settings.urlInput);
        titleContainer = container.querySelector(settings.titleContainer);
        missingImageContainer = container.querySelector(settings.missingImageContainer);
        titleInput = container.querySelector(settings.titleInput);
        imgSlider = container.querySelector(settings.imgSlider);
        loader = container.querySelector(settings.loader);
        cookbooksContainer = container.querySelector(settings.cookbooksContainer);
        btnDelete = container.querySelector(settings.btnDelete);
        groupToShow = container.querySelector(settings.groupToShow);

        applyMode();

        paginationText = container.querySelector('[' + settings.paginationText + ']').getAttribute(settings.paginationText);

        apiRecipeGet = modules.api(store.apis.crawlSite, {
            success: handleCrawlSiteSuccess,
            error: handleCrawlSiteError
        });

        // bind events
        bindUrlInput();
        bindDeleteRecipe();

        // subscriptions
        instance.saveDeleteBar.subscribe('cancel', cancelHandler);
        instance.saveDeleteBar.subscribe('save', saveHandler);
        instance.cookbookAdd.subscribe('added', addCookbook);

        prefillData();

        return instance;
    };

    instance.destroy = function () {
        clearAll();
        instance.cookbookSelect.destroy();
        instance.cookbookAdd.destroy();
        instance.saveDeleteBar.destroy();
        unbindUrlInput();
        unbindDeleteRecipe();
    };

    return instance.init();
};
