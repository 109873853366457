

/**
 * Simple script that adds the pub/sub system directly onto a given instance
 *
 * @author Christian Sany
 *
 * @param {object} instance to add the pub/sub to
 */
export default function(instance) {
    var hooks = {};

    /**
     * Adds a hook
     *
     * @param {string} hook identifyer
     * @param {function} callback to add
     * @return {object} hookindex
     */
    instance.subscribe = function(hook, callback) {
        if (hook in hooks === false) {
            hooks[hook] = [];
        }

        if(callback instanceof Function) {

            // pushes callback into hook array and return the index of the added hook so it can be removed if needed
            return hooks[hook].push(callback) - 1;
        } else {
            console.error('The hook you tried to add, either doesn\' exist, or the callback you gave is not a function');
        }
    };

    /**
     * Removes a hook
     *
     * @param {string} hook identifyer
     * @param {integer} hookindex of the hook to be removed
     * @return {object} instance
     */
    instance.unsubscribe = function(hook, hookindex) {

        // 'Removes' the subscription while preserving indexes for the other subscriptions
        hooks[hook][hookindex] = null;

        return instance;
    };

    /**
     * Fires all callbacks from given hook
     *
     * @param {string} hook identifyer
     * @param {any} scope for the callback
     * @param {array} args to be given the callback function
     */
    instance.publish = function(hook, scope, args) {
        if (hook in hooks && hooks[hook].length > 0) {
            for (var i = 0; i < hooks[hook].length; i++) {
                if (hooks[hook][i] instanceof Function) {
                    hooks[hook][i].apply(scope, args);
                }
            }
        }
    };
};
