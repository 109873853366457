const DIRECTION = 'horizontal';
const IGNORE_DRAG_OFFSET = 5;

const scrollboostFactory = ({ el, state, options }, Scrollbooster, Pubsub) => {
    let hookPopularRecipesLoaded = null;

    const updateCollisionClasses = () => {
        if (state.borderCollision.left) {
            el.classList.remove(options.classLeftHasScroll);
        } else {
            el.classList.add(options.classLeftHasScroll);
        }

        if (state.borderCollision.right) {
            el.classList.remove(options.classRightHasScroll);
        } else {
            el.classList.add(options.classRightHasScroll);
        }

        if (state.borderCollision.top) {
            el.classList.remove(options.classTopHasScroll);
        } else {
            el.classList.add(options.classTopHasScroll);
        }

        if (state.borderCollision.bottom) {
            el.classList.remove(options.classBottomHasScroll);
        } else {
            el.classList.add(options.classBottomHasScroll);
        }
    };


    const update = () => {
        // updateMetrics method from scrollbooster doesn't seem to work so we reinit
        state.destroy();
        state.init();
    };

    /* Initiation/Destruction */

    const scrollListener = () => {
        state.borderCollision.left = state.refs.viewport.scrollLeft === 0;
        state.borderCollision.right = state.refs.viewport.scrollLeft + state.refs.viewport.clientWidth === state.refs.viewport.scrollWidth;
        state.borderCollision.top = state.refs.viewport.scrollTop === 0;
        state.borderCollision.bottom = state.refs.viewport.scrollTop + state.refs.viewport.clientHeight === state.refs.viewport.scrollHeight;
        updateCollisionClasses();
    };

    state.init = () => {
        state.refs = {
            viewport: el.querySelector(options.refs.viewport)
        };

        state.borderCollision = {
            left: true,
            right: true,
            top: true,
            bottom: true
        };

        state.scrollbooster = new Scrollbooster({
            onClick: (data, event) => {
                const dragOffsetX = DIRECTION !== 'vertical' ? data.dragOffset.x : 0;
                const dragOffsetY = DIRECTION !== 'horizontal' ? data.dragOffset.y : 0;

                if (Math.max(Math.abs(dragOffsetX), Math.abs(dragOffsetY)) > IGNORE_DRAG_OFFSET) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            },
            viewport: state.refs.viewport,
            scrollMode: 'native',
            direction: DIRECTION,
            bounce: false,
            onUpdate: (boosterState) => {
                state.borderCollision = boosterState.borderCollision;
                updateCollisionClasses();
            }
        });

        state.refs.viewport.addEventListener('scroll', scrollListener);
        hookPopularRecipesLoaded = Pubsub.subscribe('popularRecipes.rendered', update);
    };

    state.destroy = () => {
        if (hookPopularRecipesLoaded) {
            Pubsub.unsubscribe(hookPopularRecipesLoaded);
        }

        state.refs.viewport.removeEventListener('scroll', scrollListener);
        state.scrollbooster.destroy();
    };

    state.init();

    return state;
};

export const config = {
    name: 'scrollboost',
    dependencies: ['Scrollbooster', 'Pubsub'],
    constructor: scrollboostFactory,
    options: {
        classLeftHasScroll: 'has-left-scroll',
        classRightHasScroll: 'has-right-scroll',
        classTopHasScroll: 'has-top-scroll',
        classBottomHasScroll: 'has-bottom-scroll',
        refs: {
            viewport: '[data-scrollboost-viewport]'
        }
    }
};
