

var api = require('@/libs/api-factory').default,
    observer = require('@/libs/ni-observer').default,
    g = require('@/scaffold/globals').default,
    pubsub = require('@/scaffold/pubsub').default;

/**
 * Creates a Cookbooks model.
 * This model depends on User, because cookbooks data are in User.
 * This means data will be set in User model, which is of course unusal.
 *
 * @return {object} Cookbooks model
 */
export default function () {
    var model = {},
        cookbooks = [],
        apiCookbookCreate,
        apiCookbookUpdate,
        apiCookbookDelete,
        tmpId,
        tmpTitle,

        orderCookbooks = function () {
            if (cookbooks.length === 0) {
                return;
            }

            // split into four types
            const userCookbooks = cookbooks.filter(cookbook =>
                cookbook.title !== store.cookbookLikes
                && cookbook.title !== store.cookbookSwipe
                && !cookbook.menu
            );

            const likeCookbook = cookbooks.filter(cookbook => cookbook.title === store.cookbookLikes);
            const swipeCookbook = cookbooks.filter(cookbook => cookbook.title === store.cookbookSwipe);
            const menuCookbooks = cookbooks.filter(cookbook => !!cookbook.menu);

            userCookbooks.sort(function(a, b) {
                return a.title.localeCompare(b.title);
            });

            menuCookbooks.sort(function (a, b) {
                return a.title.localeCompare(b.title);
            });

            cookbooks = [
                ...userCookbooks,
                ...likeCookbook,
                ...swipeCookbook,
                ...menuCookbooks
            ];
        },

        createSuccessHandler = function (response) {
            var cookbook = JSON.parse(response);
            cookbooks.push(cookbook);
            orderCookbooks();

            // pass new cookbook to subscribers.
            model.publish('created', [cookbook]);

            pubsub.publish('cookbooks.created', [{ cookbook }]);
        },

        createErrorHandler = function (errorCode, response) {
            model.publish('created.error', [errorCode, JSON.parse(response)]);
        },

        updateSuccessHandler = function () {
            var updatedCookbook = {};

            for (var i = cookbooks.length; i--;) {
                if (cookbooks[i]['cookbook_id'] === tmpId) {
                    cookbooks[i]['title'] = tmpTitle;

                    updatedCookbook = cookbooks[i];

                    break;
                }
            }

            tmpId = null;
            tmpTitle = null;

            orderCookbooks();

            // pass updated cookbook to subscribers.
            model.publish('updated', [updatedCookbook]);
        },

        updateErrorHandler = function (errorCode, response) {
            model.publish('updated.error', [errorCode, JSON.parse(response)]);
        },

        deleteSuccessHandler = function () {
            var deletedCookbook = {};

            cookbooks = cookbooks.filter(function (cookbook) {
                if (cookbook['cookbook_id'] === tmpId) {
                    deletedCookbook = cookbook;
                    return false;
                } else {
                    return true;
                }
            });

            tmpId = null;

            orderCookbooks();

            // pass deleted cookbook to subscribers.
            model.publish('deleted', [deletedCookbook]);
        },

        deleteErrorHandler = function (errorCode, response) {
            model.publish('deleted.error', [errorCode, JSON.parse(response)]);
        };

    // define APIs
    apiCookbookCreate = api(store.apis.cookbook, {
        method: 'POST',
        params: {
            'title': '',       // will be set dynamically
            '_charset_': 'UTF-8' // default
        },
        success: createSuccessHandler,
        error: createErrorHandler
    });

    apiCookbookUpdate = api(store.apis.cookbook, {
        method: 'PUT',
        params: {
            'cookbook_id': 0,  // will be set dynamically
            'title': '',       // will be set dynamically
            '_charset_': 'UTF-8' // default
        },
        success: updateSuccessHandler,
        error: updateErrorHandler
    });

    apiCookbookDelete = api(store.apis.cookbook, {
        method: 'DELETE',
        params: {
            'cookbook_id': 0,  // will be set dynamically
            '_charset_': 'UTF-8' // default
        },
        success: deleteSuccessHandler,
        error: deleteErrorHandler
    });

    // extend model
    observer(model);

    // public methods

    /**
     * Get cookbooks list
     *
     * @return {Array} All cookbooks from the user
     */
    model.getAll = function () {
        return cookbooks;
    };

    /**
     * Get all cookbooks that are menu coobooks
     *
     * @return {Array} All cookbooks from the user that are menu cookbooks
     */
    model.getAllFromTypeMenu = function () {
        return cookbooks.filter(cookbook => !!cookbook.menu);
    };

    /**
     * Get like cookbook
     *
     * @return {Array} Like cookbook
     */
    model.getLikeCookbook = function () {
        return cookbooks.filter(cookbook => cookbook.title === store.cookbookLikes);
    };

    /**
     * Get swipe cookbook
     *
     * @return {Array} Swipe cookbook
     */
    model.getSwipeCookbook = function () {
        return cookbooks.filter(cookbook => cookbook.title === store.cookbookSwipe);
    };

    /**
     * Get cookbook by id.
     *
     * @param {string} id - Cookbook id
     * @return {object} Cookbook object
     */
    model.getById = function (id) {
        for (var i = cookbooks.length; i--;) {

            // has to be only ==, it compares strings with integers
            /* eslint-disable */
            if (cookbooks[i]['cookbook_id'] == id) {
                return cookbooks[i];
            }
            /* eslint-enable */
        }
    };

    /**
     * Get target_urls of a cookbook by id.
     *
     * @param {number} id - Cookbook id
     * @return {array} Cookbook object
     */
    model.getBookmarksById = function(id) {
        return g.bookmarks.getByCookbookId(Number(id));
    };

    /**
     * Fetches cookbooks via User model. Therefore User must be fetched first.
     *
     * Event 'fetched' will be fired.
     *
     * @return {object} Model instance
     */
    model.fetch = function () {
        cookbooks = g.user.get('cookbooks');

        orderCookbooks();

        // pass cookbooks array to subscribers.
        model.publish('fetched', [cookbooks]);

        return model;
    };

    /**
     * Create a new cookbook
     *
     * Reponse contains new cookbook.
     * Event 'created' will be fired.
     *
     * @param {string} title - Title of new cookbook.
     */
    model.createCookbook = function (title) {
        apiCookbookCreate.send({
            params: {
                'title': title,
                '_charset_': 'UTF-8' // default
            }
        });
    };

    /**
     * Updates a cookbook title
     *
     * Response is ignored in success handler.
     * Event 'updated' will be fired.
     *
     * @param {string} cookbookid - IMPORTANT: define it this should be an integer or string, and update mockserver data.
     * @param {string} title - New title for cookbook
     */
    model.updateCookbook = function (cookbookid, title) {

        // cache title and id because we need to update it afterwards in the array.
        tmpId = cookbookid;
        tmpTitle = title;

        apiCookbookUpdate.send({
            params: {
                'cookbook_id': cookbookid,
                'title': title,
                '_charset_': 'UTF-8' // default
            }
        });
    };

    /**
     * Deletes a cookbook
     *
     * Response is ignored in success handler.
     * Event 'deleted' will be fired.
     *
     * @param {number} cookbookid - IMPORTANT: define it this should be an integer or string, and update mockserver data.
     */
    model.deleteCookbook = function (cookbookid) {

        // cache id because we need to remove it afterwards from the array.
        tmpId = cookbookid;

        apiCookbookDelete.send({
            params: {
                'cookbook_id': cookbookid,
                '_charset_': 'UTF-8' // default
            }
        });
    };

    return model;
};
