const pushLog = (object) => {
    const copy = Object.assign({}, object);
    console.info('pushing utag:\n', copy);
};

const createTealium = () => {
    const instance = {};

    let queueLink = [];
    let queueView = [];

    let utagLoaded = false;

    /**
     * Links all queued tags
     *
     * Main usage is when the utag script gets loaded, but an asynchronous utag should be set,
     * in that case the tags get queued and executed as soon as the script is loaded
     */
    const linkAllQueued = function() {
        for (var i = 0; i < queueLink.length; i++) {
            pushLog(queueLink[i]);
            utag.link(queueLink[i]);
        }

        // empty queueueueue :D
        queueLink = [];
    };

    /**
     * Views all queued tags
     *
     * Main usage is when the utag script gets loaded, but an asynchronous utag should be set,
     * in that case the tags get queued and executed as soon as the script is loaded
     */
    const viewAllQueued = function() {
        for (var i = 0; i < queueView.length; i++) {
            pushLog(queueView[i]);
            utag.view(queueView[i]);
        }

        // empty queueueueue :D
        queueView = [];
    };

    instance.loaded = null;

    /**
     * Sets utags
     *
     * If the utag object doesn't exist by the time this function gets called,
     * the object is beeing queued and linked when the script is loaded
     *
     * @param {object} object to set
     */
    instance.link = function(object) {
        if (utagLoaded) {
            pushLog(object);
            utag.link(object);
        } else {
            queueLink.push(object);
        }
    };

    /**
     * Sets utags (view)
     *
     * If the utag object doesn't exist by the time this function gets called,
     * the object is beeing queued and viewed when the script is loaded
     *
     * @param {object} object to set
     */
    instance.view = function(object) {
        if (utagLoaded) {
            pushLog(object);
            utag.view(object);
        } else {
            queueView.push(object);
        }
    };

    /**
     * Initiates the tealium module
     *
     * Checks if the utag object exists, if not, adds an eventlistener to the script that sets the object
     * @return {Promise} - Init status
     */
    instance.init = async function() {
        if (typeof utag === 'object') {
            utagLoaded = true;
            return instance.loaded = Promise.resolve(utag);
        }

        const tealiumScript = document.getElementById('tealium');

        if (!tealiumScript) {
            return instance.loaded = Promise.reject('The tealium script is missing on this page.');
        }

        return instance.loaded = new Promise((resolve) => {
            tealiumScript.addEventListener('load', function() {
                utagLoaded = true;
                viewAllQueued();
                linkAllQueued();
                resolve(utag);
            });
        });
    };

    return instance;
};

export default createTealium();
