import fastdom from 'fastdom';

import g from '@/scaffold/globals';
import Helper from '@/helpers/helper';

const initialX = typeof(Helper.get('x')) !== 'undefined';
const initialY = typeof(Helper.get('y')) !== 'undefined';
const scrollRestorationExists = 'scrollRestoration' in history;

if (scrollRestorationExists && (initialX || initialY)) {
    history.scrollRestoration = 'manual';
}


const instance = {};

var scrollRestored = false,
    isScrollPositionTracked = false,
    isScrollPositionTrackedEnhanced = false,
    previousScrollPos = {
        x: null,
        y: null
    };

const setPreviousScrollPositionFromUrl = function(cleanup = false) {
    const params = Helper.paramsFromUrl();

    previousScrollPos = {
        x: null,
        y: null
    };

    if (params.x) {
        previousScrollPos.x = parseInt(params.x);
    }

    if (params.y) {
        previousScrollPos.y = parseInt(params.y);
    }

    if (cleanup) {
        delete params.x;
        delete params.y;
        g.history.replaceParams(params);
    }
};

const scroll = function() {
    if (isScrollPositionTrackedEnhanced) {
        saveScrollPosition();
    }
};

const beforeUnload = function() {
    if (isScrollPositionTracked) {
        saveScrollPosition();
    }
};

const popState = function() {
    scrollRestored = false;
    setPreviousScrollPositionFromUrl();
};

const paramsChanged = function(params, previousParams, options) {
    if (isScrollPositionTrackedEnhanced && !options.popstate) {
        saveScrollPosition();
    }
};

const saveScrollPosition = function() {
    fastdom.measure(() => {
        // replace params silently
        g.history.replaceParams({
            y: window.pageYOffset,
            x: window.pageXOffset
        }, null, false, true);
    });
};

instance.init = function() {
    g.history.subscribe('popstate', popState);
    g.history.subscribe('params.changed', paramsChanged);

    window.addEventListener('beforeunload', beforeUnload);
    window.addEventListener('scroll', Helper.debounce(scroll, 50));
    window.addEventListener('optimizedResize', scroll);

    setPreviousScrollPositionFromUrl(true);
};

instance.isScrollRestored = function() {
    return scrollRestored;
};

instance.trackScrollPosition = function() {
    isScrollPositionTracked = true;
};

instance.trackScrollPositionEnhanced = function() {
    if (isScrollPositionTrackedEnhanced) {
        return true;
    }

    instance.trackScrollPosition();
    isScrollPositionTrackedEnhanced = true;

    if (scrollRestorationExists) {
        history.scrollRestoration = 'manual';
    }

    saveScrollPosition();
};

instance.restoreScroll = function() {
    scrollRestored = true;

    requestAnimationFrame(function() {
        requestAnimationFrame(function() {
            if (previousScrollPos.x !== null || previousScrollPos.y !== null) {
                window.scrollTo(previousScrollPos.x || 0, previousScrollPos.y || 0);
            }
        });
    });
};

export default instance;
