const createReadmore = ({ el, state, options }, Breakpoint) => {
    const getCurrentMaxHeight = () => {
        let maxHeight = options.maxHeight;

        if (typeof options.maxHeight === 'object') {
            if (Breakpoint.curBreakpoint === 'xlarge') {
                maxHeight = options.maxHeight['xlarge'] || options.maxHeight['large'] || options.maxHeight['medium'] || options.maxHeight['small'];
            } else if (Breakpoint.curBreakpoint === 'large') {
                maxHeight = options.maxHeight['large'] || options.maxHeight['medium'] || options.maxHeight['small'];
            } else if (Breakpoint.curBreakpoint === 'medium') {
                maxHeight = options.maxHeight['medium'] || options.maxHeight['small'];
            } else if (Breakpoint.curBreakpoint === 'small') {
                maxHeight = options.maxHeight['small'];
            }
        }

        return maxHeight;
    };

    const setMaxHeight = () => {
        if (!isOpen() && isExpandable()) {
            state.refs.copy.style.maxHeight = `${getCurrentMaxHeight()}px`;
        } else {
            state.refs.copy.style.maxHeight = 'none';
        }
    };

    const setExpandable = () => {
        if (isExpandable()) {
            el.classList.add(options.classStateExpandable);
        } else {
            el.classList.remove(options.classStateExpandable);
        }
    };

    const isExpandable = () => {
        return getCurrentMaxHeight() !== true
            && getCurrentMaxHeight() < state.refs.copyInner.offsetHeight;
    };

    const isOpen = () => {
        return el.classList.contains(options.classStateOpen);
    };

    const toggleOpen = () => {
        el.classList.toggle(options.classStateOpen);
        setMaxHeight();
    };

    const resizeHandler = () => {
        setMaxHeight();
        setExpandable();
    };

    const clickButtonHandler = (e) => {
        e.preventDefault();
        toggleOpen();
    };

    const clickCopyHandler = () => {
        if (!isOpen()) {
            toggleOpen();
        }
    };

    const addEventListeners = () => {
        window.addEventListener('optimizedResize', resizeHandler);
        state.refs.copy.addEventListener('click', clickCopyHandler);
        state.refs.button.addEventListener('click', clickButtonHandler);
    };

    const removeEventListeners = () => {
        window.removeEventListener('optimizedResize', resizeHandler);
        state.refs.copy.removeEventListener('click', clickCopyHandler);
        state.refs.button.removeEventListener('click', clickButtonHandler);
    };

    state.init = async function() {
        state = {
            refs: {
                copy: el.querySelector(options.refs.copy),
                copyInner: el.querySelector(options.refs.copyInner),
                button: el.querySelector(options.refs.button)
            }
        };

        await Breakpoint.initialized;

        resizeHandler();
        addEventListeners();
    };

    state.destroy = function() {
        removeEventListeners();
    };

    state.init();

    return state;
};

export const config = {
    name: 'readmore',
    constructor: createReadmore,
    dependencies: ['Breakpoint'],
    options: {
        // can be number or object with breakpoints:
        // { small: 90, medium: 180, large: 180, xlarge: 270 }
        // disabling expanding from medium breakpoint:
        // { small: 90, medium: true }
        maxHeight: 90,
        classStateOpen: 'is-open',
        classStateExpandable: 'is-expandable',
        refs: {
            copy: '[data-readmore-copy]',
            copyInner: '[data-readmore-copy-inner]',
            button: '[data-readmore-btn]'
        }
    }
};
