const swapArrayElements = function(arr, indexA, indexB) {
    var temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
};

const defaultLineOptions = {
    wordsCount: null,
    breakWords: true,
    reduceFontSize: true,
    // reduce font size below minimum if line still exceeds max width
    reduceFontSizeBelowMinimum: false
};

const TitleLines = function(element, options) {
    const text = element.textContent.trim();

    if (text === '') {
        return;
    }

    element.classList.add('title-lines');

    const words = text.match(options.wordMatch);
    const wordsCount = words.length;

    const wordsToLinesCount = [];
    const wordsToLines = [];

    const primaryLineIndex = options.lines.findIndex(line => line.primary) || 0;
    // const optionalLinesCount = options.lines.filter(line => line.optional).length;
    const requiredLinesWithoutSetWordsCount = options.lines.filter(line => !line.optional && line.wordsCount === null).length;
    const wordsOptionSum = options.lines.reduce((total, line) => {
        if (line.optional || !line.wordsCount) {
            return total;
        }

        return total + line.wordsCount;
    }, 0);

    // determine which line has how many words
    options.lines.forEach((line) => {
        if (line.optional) {
            wordsToLinesCount.push(0);
            return;
        }

        if (line.wordsCount) {
            wordsToLinesCount.push(line.wordsCount);
        } else {
            const wordsOnLine = Math.ceil((wordsCount - wordsOptionSum) / requiredLinesWithoutSetWordsCount);
            wordsToLinesCount.push(wordsOnLine);
        }
    });

    // determine which words are on which line
    let wordsBeforeCount = 0;
    wordsToLinesCount.forEach((count) => {
        if (count === 0) {
            wordsToLines.push([]);
        } else {
            wordsToLines.push(words.slice(wordsBeforeCount, wordsBeforeCount + count));
            wordsBeforeCount = wordsBeforeCount + count;
        }
    });

    // if just one word exists, put it in the primary line
    if (wordsCount === 1 && primaryLineIndex !== 0) {
        swapArrayElements(wordsToLines, 0, primaryLineIndex);
    }

    const renderLines = (finishCallback) => {
        const wordsToLinesWithMaxWidth = [];
        const maxWidth = options.maxWidth();

        // push copies of words into new array to determine correct words per line with max width
        wordsToLines.forEach((lineWords) => {
            wordsToLinesWithMaxWidth.push([...lineWords]);
        });

        // clean container element
        element.innerHTML = '';

        // remove lines count
        element.removeAttribute('data-title-lines-count');

        // hide visibility as long as its rendered
        element.style.visibility = 'hidden';

        const renderLine = (lineIndex) => {
            const renderNextLine = () => {
                // render next line if one exists
                if (wordsToLinesWithMaxWidth[lineIndex + 1]) {
                    // render next line
                    renderLine(lineIndex + 1);
                } else {
                    // make element visible again
                    element.style.visibility = 'visible';
                    element.setAttribute('data-title-lines-count', element.children.length);

                    if (finishCallback) {
                        finishCallback(element.children.length);
                    }
                }
            };

            // check if there are words are on line
            if (wordsToLinesWithMaxWidth[lineIndex].length === 0) {
                renderNextLine();
                return;
            }

            const lineElement = document.createElement('div');
            lineElement.classList.add('title-lines__line');
            lineElement.classList.add(`title-lines__line--${lineIndex + 1}`);

            const fillLineText = () => {
                lineElement.textContent = wordsToLinesWithMaxWidth[lineIndex].join('').split();
            };

            fillLineText();
            element.appendChild(lineElement);

            let minFontSizeReached = false;

            const reduceLine = (finishCallback) => {
                const lineBounds = lineElement.getBoundingClientRect();
                const lineOptions = { ...defaultLineOptions, ...options.lines[lineIndex] };
                const maxWidthExceeded = lineBounds.width > maxWidth;
                const hasWordToBreakDown = wordsToLinesWithMaxWidth[lineIndex].length > 1
                    && wordsToLinesWithMaxWidth[lineIndex + 1];

                if (maxWidthExceeded && !minFontSizeReached
                    && ((lineOptions.breakWords && hasWordToBreakDown)
                        || lineOptions.reduceFontSize)
                ) {
                    // check if line has more than one word and next line is avaible
                    if (lineOptions.breakWords && hasWordToBreakDown) {
                        // add last word from line to start of next line
                        const lastWordFromLine = wordsToLinesWithMaxWidth[lineIndex].pop();
                        wordsToLinesWithMaxWidth[lineIndex + 1].unshift(lastWordFromLine);
                        fillLineText();
                    } else if (lineOptions.reduceFontSize) {
                        // decrease font size
                        const currentFontSize = parseInt(getComputedStyle(lineElement).getPropertyValue('font-size'), 10);

                        if (lineOptions.reduceFontSizeBelowMinimum
                            || !options.lines[lineIndex].minFontSize
                            || (options.lines[lineIndex].minFontSize
                                && options.lines[lineIndex].minFontSize < currentFontSize)
                        ) {
                            lineElement.style.fontSize = `${currentFontSize - 1}px`;
                        } else {
                            minFontSizeReached = true;
                        }
                    }

                    // reduce line even more
                    reduceLine(finishCallback);
                } else {
                    finishCallback(element.children.length);
                }
            };

            reduceLine(renderNextLine);
        };

        // start rendering first line
        renderLine(0);
    };

    return {
        renderLines
    };
};

export default TitleLines;
