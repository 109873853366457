const instance = {};
const userFromCoopApp = window.location.hash === '#coopapp';

const fromCoopApp = (container) => {
    const display = container.getAttribute('data-coopapp-display').split(',');

    if (userFromCoopApp && display[0]) {
        container.style.display = display[0].trim();
    } else if (display[1]) {
        container.style.display = display[1].trim();
    }
};

// Public functions
instance.init = () => {
    document.querySelectorAll('[data-coopapp-display]').forEach(fromCoopApp);
};

export default instance;
