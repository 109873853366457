// for communcation with native app
const bridge = {
    // invoke native function via app
    // possible functions in constants.js
    invokeNative(data) {
        try {
            console.log(data);

            // this function will be included by the webview
            if (window.invokeCSharpAction) {
                window.invokeCSharpAction(JSON.stringify(data));
            }
        } catch (error) {
            console.log(error);
        }
    },
    // invoked by app
    invokedByNative(data) {
        console.log(data);
        // context.store.commit('addAppMessage', JSON.parse(data));
    }
};

// needs to be globally accessible for the native app
window.bridge = bridge;

export default bridge;

