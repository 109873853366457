

// dependencies
var customEvent = require('@/helpers/helper').default.customEvent,
    searchbar = require('@/apps/search/modules/searchbar').default,
    pubsub = require('@/scaffold/pubsub').default,
    suggestedSearchController = require('@/apps/search/modules/suggested-search-controller').default;

// vars
var defaults = {
    searchbarContainer: '.searchbar',
    autocomplete: {
        init: false,
        openthreshhold: 2,
        limit: 4,
        api: '',
        method: ''
    }
};

/**
 * Controls the searchbar
 *
 * @param {Element} container which to search inside for the searchbar-container
 * @param {object} options to configure
 * @return {object} instance
 */
export default function(container, options) {
    var settings = Object.assign({}, defaults, options),
        instance = {},
        fieldValue = '',
        autocomplete = false,
        submittedByAutocomplete = false,
        searchbarContainer,

        /**
         * Dispatches a custom event with the current value of the inputfield
         *
         * @param {string} value if the inputfield
         */
        onSubmit = function (value) {
            container.dispatchEvent(customEvent('searchbar.submit', { value: value }));
            pubsub.publish('searchbar.search', [value, submittedByAutocomplete, container]);

            if (submittedByAutocomplete) {
                pubsub.publish('autocomplete.select', [value]);
                // reset flag
                submittedByAutocomplete = false;
            }

            if (autocomplete === true) {
                instance.suggestedSearchController.forceClose();
            }
        },

        /**
         * Dispatches a custom event with the current value of the inputfield
         *
         * @param {string} value if the inputfield
         */
        onFocus = function(value) {
            container.dispatchEvent(customEvent('searchbar.focus', { value: value }));

            // window.scrollTo(0, 100);

            if (autocomplete === true) {
                instance.suggestedSearchController.searchbarFocus();
                instance.suggestedSearchController.queryChange(value);
            }
        },

        /**
         * Dispatches a custom event with the current value of the inputfield
         *
         * @param {string} value if the inputfield
         */
        onBlur = function(value) {
            container.dispatchEvent(customEvent('searchbar.blur', { value: value }));

            if (autocomplete === true) {
                instance.suggestedSearchController.searchbarBlur();
            }
        },

        /**
         * Dispatches a custom event with the current value of the inputfield
         *
         * @param {string} value if the inputfield
         */
        onKeyup = function(value) {
            if(fieldValue !== value) {
                fieldValue = value;
                submittedByAutocomplete = false;
                container.dispatchEvent(customEvent('searchbar.keyup', { value: value }));

                if (autocomplete === true) {
                    instance.suggestedSearchController.queryChange(value);
                }
            }
        };

    instance.setValue = function(value) {
        instance.searchbar.setValue(value);
    };

    instance.blurInput = function() {
        instance.searchbar.blur();
    };

    /**
     * Destroys the searchbar functionality to its original state
     *
     * @return {object} instance
     */
    instance.destroy = function() {
        instance.searchbar.destroy();

        return instance;
    };

    /**
     * Initiates the searchbar functionality
     *
     * @return {object} instance
     */
    instance.init = function() {

        container = container || document;

        searchbarContainer = container.querySelector(settings.searchbarContainer);

        instance.searchbar = searchbar(searchbarContainer, {
            onSubmit: onSubmit,
            onFocus: onFocus,
            onKeyup: onKeyup,
            onBlur: onBlur
        });

        if (settings.autocomplete.init === true) {
            autocomplete = true;

            instance.suggestedSearchController = suggestedSearchController(container, {
                openthreshhold: settings.autocomplete.openthreshhold,
                limit: settings.autocomplete.limit,
                api: settings.autocomplete.api,
                method: settings.autocomplete.method,
                onValueChange: function (value) {
                    instance.searchbar.setValue(value);
                    submittedByAutocomplete = true;
                },
                onSelect: function(value) {
                    submittedByAutocomplete = true;
                    instance.searchbar.submit(value);
                    submittedByAutocomplete = false;
                }
            });
        }

        return instance;
    };

    return instance.init();
};
