const createPageHeader = ({ el, state, options }, TimelineMax, ScrollShizzle) => {
    /* --- Private methods --- */

    const initParallax = () => {
        state.scene = ScrollShizzle.create({ preventUpdateOnBreakpoint: ['small'] });

        const updateParallax = function() {
            var pageHeaderHeight = el.offsetHeight; // minus the grey bar at the bottom
            var startPosition = 0;

            var tl = new TimelineMax({ useFrames: true, paused: true });
            tl.fromTo(state.refs.heroImageWrapper, 1,
                { 'y': startPosition + 'px' },
                { 'y': (state.refs.heroImageWrapper.offsetHeight + 50) * -1, ease: Linear.easeNone }
            );

            // overwriting settings & timeline
            state.scene.set({ duration: pageHeaderHeight + 20 }).setTimeline(tl);
        };

        // first call to setup parallax
        updateParallax();

        // when the viewport gets resized, the parallax must be adjusted
        window.addEventListener('optimizedResize', updateParallax);
    };


    /* --- Public methods --- */

    state.init = () => {
        state.refs = {
            heroImageWrapper: el.querySelector(options.refs.heroImageWrapper)
        };

        initParallax();
    };

    state.destroy = () => {
        state.scene.destroy();
        window.removeEventListener('optimizedResize', updateParallax);
    };

    state.init();

    return state;
};

export const config = {
    name: 'page-header',
    constructor: createPageHeader,
    dependencies: ['TimelineMax', 'ScrollShizzle'],
    options: {
        refs: {
            heroImageWrapper: '[data-page-header-hero-image-wrapper]'
        }
    }
};
