

// dependencies
var forEach = require('@/helpers/helper').default.forEach,
    animate = require('@/helpers/helper').default.animate,
    getCoords = require('@/helpers/helper').default.getCoords;

// vars
var instance = {},
    smoothScrollTriggers;

// public functions
// code partly from https://github.com/adrianklimek/smoothscroll
// (TODO: refactor in to more general, horizontal scrolling etc.)
instance.scrollTo = function(targetScrollPos, offset, duration, easing, callback) {

    const curScrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

    const updateScrollPosition = (value) => {
        window.scrollTo(0, value);
    };

    animate({
        start: curScrollPos,
        end: targetScrollPos - offset,
        onUpdate: updateScrollPosition,
        onComplete: callback,
        duration: duration || 1000,
        easing: easing || 'easeInOutQuad'
    });
};

// private functions
instance.init = function() {
    smoothScrollTriggers = document.querySelectorAll('[data-smooth-scroll]');
    if (smoothScrollTriggers.length > 0) {
        forEach(smoothScrollTriggers, function(trigger) {

            var target = document.querySelector(trigger.getAttribute('data-scroll-target'));

            if(target instanceof HTMLElement === false) {
                console.error(trigger.getAttribute('data-scroll-target') + ' was not found in the document!');
            } else {
                trigger.addEventListener('click', function(event) {

                    event.preventDefault();

                    var offset = trigger.getAttribute('data-scroll-target-offset');

                    if(offset === '') {
                        offset = 0;
                    }

                    instance.scrollTo(getCoords(target).top, offset, 1000);
                });
            }
        });
    }
};

export default instance;
