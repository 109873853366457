import injector from '@/scaffold/injector';
import { NAMESPACE_MAIN } from '@/scaffold/namespaces';

export const startup = injector.resolve(['Globals', 'Pubsub', 'ScrollRestore'], async (Globals, Pubsub, ScrollRestore) => {
    // enable scroll position tracking when ajax teasers are added (save scroll position before user leaves page)
    Pubsub.subscribe('teaserGroupRecipelist.teasers.added', ScrollRestore.trackScrollPosition);
    Pubsub.subscribe('teaserGroupFeed.teasers.added', ScrollRestore.trackScrollPosition);
    Pubsub.subscribe('teaserGroupAutomated.teasers.added', ScrollRestore.trackScrollPosition);

    // enable enhanced scroll position tracking on search or myfooby page (save scroll position on scroll event to restore position when popstate is used)
    Pubsub.subscribe('searchFactory.teasers.added', ScrollRestore.trackScrollPositionEnhanced);
    Pubsub.subscribe('myFooby.teasers.added', ScrollRestore.trackScrollPositionEnhanced);

    // wait for all initial ajax teasers to be loaded
    await window[NAMESPACE_MAIN].allTeasersAdded;

    // restore scroll position by parameters if any given
    ScrollRestore.restoreScroll();

    if (Globals.isMyFooby) {
        // restore scroll position everytime new teasers are added (user goes back in history with popstate)
        Pubsub.subscribe('teasers.added', ScrollRestore.restoreScroll);
    }
});
