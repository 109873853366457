/**
 * createPortionCalculator
 * @param {Object} module - Module
 * @param {Element} module.el - Element
 * @param {Object} module.state - State
 * @param {Object} module.options - Options
 * @param {Object} Globals - Globals
 * @param {Object} Pubsub - Global observer
 * @param {Object} Helper - Global helpers
 * @param {Object} Easings - Global easings
 * @param {Object} Fractions - Fraction helper
 * @return {Object} state
 */
const createPortionCalculator = ({ el, state, options }, Globals, Pubsub, Helper, Easings, Fractions) => {
    let pubsubHookIdUpdate, pubsubHookIdHideHint;

    /* --- Private methods --- */

    /**
     * Hides the hint overlay
     */
    const hideHint = () => {
        if (!state.hintVisible) {
            return;
        }

        state.hintVisible = false;
        state.refs.hint.notchAnimateStyle('opacity', 0, 300, Easings.easeInOutCubic, () => {
            state.refs.hint.style.display = 'none';
        });

        Pubsub.publish('portionCalculator.hideHint');
    };

    /**
     * Shows the hint overlay
     */
    const showHint = () => {
        if (state.hintVisible) {
            return;
        }

        state.hintVisible = true;
        state.hintShown = true;
        state.refs.hint.style.display = 'block';
        setTimeout(() => {
            state.refs.hint.notchAnimateStyle('opacity', 1, 300, Easings.easeInOutCubic);
        }, 10);

        Pubsub.publish('portionCalculator.showHint');
    };

    /**
     * Updates all recipe ingredient quantities by given factor of initial value
     *
     * @param {floor} factor to multiply the initial value from
     * @param {boolean} hideHint to be force hidden
     */
    const updateValues = (factor, hideHint = false) => {
        if (state.hintShown === false && hideHint !== true) {
            showHint();
        }

        state.refs.quantities.forEach((element) => {
            let initialValue = Number(element.getAttribute(options.attrInitialQuantity));

            // using == would throw a linting error, so we convert it back to a string
            if (Number.isNaN(initialValue) !== true) {
                let tmp = parseFloat(element.getAttribute(options.attrInitialQuantity));
                element.innerHTML = Fractions(tmp * factor);
            }
        });
    };

    /**
     * Clickhandler for pressing the plus icon
     */
    const plus = () => {
        let nextPortionNumber = state.currentPortionNumber + 1;

        if (updatePortionNumber(nextPortionNumber)) {
            Pubsub.publish('portionCalculator.plus', [nextPortionNumber]);
        }
    };

    /**
     * Clickhandler for pressing the minus icon
     */
    const minus = () => {
        let previousPortionNumber = state.currentPortionNumber - 1;

        if (updatePortionNumber(previousPortionNumber)) {
            Pubsub.publish('portionCalculator.minus', [previousPortionNumber]);
        }
    };

    /**
     * Update portion number
     * @param {number} portionNumber - Portion number to set
     * @return {boolean} Whether setting the portion number was successful
     */
    const updatePortionNumber = (portionNumber) => {
        if (portionNumber <= 0) {
            return false;
        }

        Pubsub.publish('portionCalculator.update', [portionNumber, state.currentPortionNumber]);
        return true;
    };

    /**
     * Update handler for pubsub event to update state
     * @param {number} newPortionNumber - New portion number
     */
    const updateHandler = (newPortionNumber) => {
        state.currentPortionNumber = newPortionNumber;
        recipeJSON.amount = state.currentPortionNumber;
        state.refs.number.innerHTML = state.currentPortionNumber;
        updateValues(state.currentPortionNumber / state.initialPortionNumber);
        updateUrl();
        updateRecipeAmount();
    };

    const hideHintHandler = () => {
        hideHint();
    };

    /**
     * Update url with current recipe settings
     */
    const updateUrl = () => {
        Globals.history.replaceParams({
            menge: state.currentPortionNumber
        });
    };

    /**
     * Update the link to buy the ingredients online with the current recipe amount to provide the to match the result on `www.coop.ch`
     * 
     * @return {void}
     */
    const updateRecipeAmount = () => {
        const recipeAmountParam = new URLSearchParams(window.location.search);
        const recipeAmount = recipeAmountParam.get('menge');
    
        if (recipeAmount) {
            const buyIngredientsBtn = document.querySelector('[data-buy-ingredients-online]');
            if (buyIngredientsBtn) {
                const href = new URL(buyIngredientsBtn.getAttribute('href'));
    
                href.searchParams.set('recipeAmount', recipeAmount);
                buyIngredientsBtn.setAttribute('href', href.toString());
            }
        }
    };    

    const addEventListeners = () => {
        state.refs.minus.addEventListener('click', minus);
        state.refs.plus.addEventListener('click', plus);
        state.refs.closeHint.addEventListener('click', hideHint);
    };

    const removeEventListeners = () => {
        state.refs.minus.removeEventListener('click', minus);
        state.refs.plus.removeEventListener('click', plus);
        state.refs.closeHint.removeEventListener('click', hideHint);
    };

    /* --- Public methods --- */

    state.init = () => {
        state.hintVisible = false;
        state.hintShown = false;
        state.refs = {
            number: el.querySelector(options.refs.number),
            minus: el.querySelector(options.refs.minus),
            plus: el.querySelector(options.refs.plus),
            hint: el.querySelector(options.refs.hint),
            closeHint: el.querySelector(options.refs.hint),
            quantities: el.querySelectorAll(options.refs.quantities)
        };

        state.currentPortionNumber = parseInt(state.refs.number.innerHTML);
        state.initialPortionNumber = parseInt(state.refs.number.getAttribute(options.attrInitialPortions));

        addEventListeners();
        // listen to own pubsub event to update state of every module
        pubsubHookIdUpdate = Pubsub.subscribe('portionCalculator.update', updateHandler);
        pubsubHookIdHideHint = Pubsub.subscribe('portionCalculator.hideHint', hideHintHandler);

        let portionNumber = parseInt(Helper.get('menge'));

        if (portionNumber && portionNumber !== state.initialPortionNumber) {
            // use timeout to trigger initial update event when modules are initialized
            // and are able to listen to the event (initial call stack finished)
            window.setTimeout(() => {
                // initially update portion number
                updatePortionNumber(portionNumber);
         
            }, 0);
        } else {
            // just update all values for displaying the fractions
            updateValues(1, true);
        }
    };

    state.destroy = () => {
        removeEventListeners();
        Pubsub.unsubscribe(pubsubHookIdUpdate);
        Pubsub.unsubscribe(pubsubHookIdHideHint);
    };

    state.init();

    return state;
};

export const config = {
    name: 'portion-calculator',
    selector: '.portion-calculator',
    constructor: createPortionCalculator,
    dependencies: ['Globals', 'Pubsub', 'Helper', 'Easings', 'Fractions'],
    options: {
        refs: {
            minus: '.portion-calculator__operation-minus',
            plus: '.portion-calculator__operation-plus',
            number: '.portion-calculator__display-portions',
            hint: '.portion-calculator__calculator-hint',
            closeHint: '.portion-calculator__calculator-hint-closer',
            quantities: '[data-portion-calculator-initial-quantity]'
        },
        attrInitialQuantity: 'data-portion-calculator-initial-quantity',
        attrInitialPortions: 'data-portion-calculator-initial-portions'
    }
};
