const modalInput = document.getElementById('newsletter-form__email');

const createNewsletterSignup = ({ el, state, options }, Pubsub, HelperModal) => {

    const handleFormSubmit = function() {
        if (state.refs.input.value === '') {
            return;
        }

        modalInput.value = state.refs.input.value;
        Pubsub.publish('input-manipulated');
        HelperModal.openModal('modal-newsletter');
    };

    const submitHandler = (event) => {
        event.preventDefault();
        handleFormSubmit();
    };

    /* Eventhandling */

    const addEventListeners = () => {
        el.addEventListener('submit', submitHandler);
    };

    const removeEventListeners = () => {
        el.removeEventListener('submit', submitHandler);
    };

    /* Initiation/Destruction */

    state.init = () => {
        state.refs = {
            input: el.querySelector(options.refs.input)
        };

        addEventListeners();
    };

    state.destroy = () => {
        removeEventListeners();
    };

    state.init();

    return state;
};

export const config = {
    name: 'newsletter-signup',
    selector: '.newsletter-form-signup',
    constructor: createNewsletterSignup,
    dependencies: ['Pubsub', 'HelperModal'],
    options: {
        refs: {
            input: 'input[type="text"]'
        }
    }
};
