

var globals = require('@/scaffold/globals').default,
    compareArrays = require('@/helpers/helper').default.compareArrays,
    // pubsub = require('@/scaffold/pubsub').default,
    storage = require('@/libs/localstorage').default,
    modules = {
        saveDeleteBar: require('@/apps/myfooby/modules/save-delete-bar').default,
        simpleConfirm: require('@/apps/myfooby/modules/simple-confirm').default,
        view: require('@/apps/main/plugins/view-controller').default
    },
    views = {
        cookbookSelectView: require('@/apps/myfooby/modules/cookbook-select-view').default,
        cookbookAddView: require('@/apps/myfooby/modules/cookbook-add-view').default
    };

var defaults = {
        modalInstance: null,
        successViewTimeout: 1500,
        errorViewTimeout: 1500,
        url: null,
        ownRecipeId: null,
        menuartId: null,
        treffertypSub: null,
        recipeId: null
    },

    viewToDarkThemeMap = {
        'not-logged-in': false,
        'not-logged-in-article': false,
        'cookbooks': false,
        // 'warning-cookbook': true,
        'warning': true,
        'warning-article': true,
        'ajax': false,
        'success': true,
        'error': true
    };

export default function (container, options) {
    var instance = {},
        user = globals.user,
        cookbooks = globals.cookbooks,
        bookmarks = globals.bookmarks,
        settings = Object.assign({}, defaults, options),
        initialView = options.initialView || 'cookbooks',
        view,
        cookbookSelectView,
        cookbookAddView,
        saveDeleteBar,
        // simpleConfirmCookbook,
        simpleConfirm,
        bookmarkUrl,
        bookmarkTreffertypSub,
        bookmarkRecipeId,
        bookmarkOwnRecipeId,
        bookmarkMenuartId,
        selectedCookbooks,

        // eslint-disable-next-line
        addToStorage = function (bookmarkUrl, bookmarkRecipeId, bookmarkOwnRecipeId, bookmarkMenuartId, bookmarkTreffertypSub) {
            var storedBookmarks = storage.get('stored-bookmarks'),
                bookmark = {};

            // create key if it doesn't exist yet
            if (!storedBookmarks) {
                storedBookmarks = [];
            }

            // check if bookmark is already in storage
            for (var i = storedBookmarks.length; i--;) {
                if (storedBookmarks[i]['url'] === bookmarkUrl || storedBookmarks[i]['recipe_id'] === bookmarkRecipeId) {

                    // bookmark is already saved. abort.
                    return;
                }
            }

            // fill values
            if (bookmarkUrl) bookmark['url'] = bookmarkUrl;
            if (bookmarkRecipeId) bookmark['recipe_id'] = bookmarkRecipeId;
            if (bookmarkOwnRecipeId) bookmark['own_recipe_id'] = bookmarkOwnRecipeId;
            if (bookmarkMenuartId) bookmark['menuart_id'] = bookmarkMenuartId;
            if (bookmarkTreffertypSub) bookmark['treffertyp_sub'] = bookmarkTreffertypSub;

            storedBookmarks.push(bookmark);

            // save
            storage.set('stored-bookmarks', storedBookmarks);
        },

        getActiveCookbooks = function () {
            if (bookmarkRecipeId) {
                return bookmarks.getByRecipeId(bookmarkRecipeId).map(function (bookmark) {
                    return bookmark['cookbook_id'];
                });
            } else if (bookmarkTreffertypSub === 'own') {
                return bookmarks.getByOwnRecipeId(bookmarkOwnRecipeId).map(function (bookmark) {
                    return bookmark['cookbook_id'];
                });
            } else {
                return bookmarks.getByTargetUrl(bookmarkUrl).map(function (bookmark) {
                    return bookmark['cookbook_id'];
                });
            }
        },

        changeView = function(name) {
            view.updateView(name);

            if (viewToDarkThemeMap[name] === true) {
                container.classList.add('is-theme-dark');
            } else {
                container.classList.remove('is-theme-dark');
            }
        },

        checkLoggedInState = function () {
            if (user.isLoggedIn()) {
                changeView(initialView);

                // just to be sure
                // settings.tooltipInstance.unlock();
            } else {
                changeView(bookmarkTreffertypSub === 'rezept' ? 'not-logged-in' : 'not-logged-in-article');
                // TODO: we don't add to storage anymore because we don't have a default cookbook anymore
                // addToStorage(bookmarkUrl, bookmarkRecipeId, bookmarkOwnRecipeId, bookmarkMenuartId, bookmarkTreffertypSub);
            }
        },

        checkChange = function () {
            if (compareArrays(selectedCookbooks, cookbookSelectView.getSelected())) {
                saveDeleteBar.disableSave();
            } else {
                saveDeleteBar.enableSave();
            }
        },

        saveBookmarks = function (closeOnSuccess = false) {
            var activeBooks = cookbookSelectView.getSelected(),
                createdId,
                updatedId,
                createdErrorId,
                updatedErrorId,

                unsubscribeEvents = function () {
                    bookmarks.unsubscribe(createdId);
                    bookmarks.unsubscribe(updatedId);
                    bookmarks.unsubscribe(createdErrorId);
                    bookmarks.unsubscribe(updatedErrorId);
                },

                createdUpdatedErrorHandler = function () {
                    unsubscribeEvents();
                    changeView('error');

                    setTimeout(() => {
                        // switches to the cookbooks again
                        changeView(initialView);
                        // settings.tooltipInstance.unlock();
                    }, settings.errorViewTimeout);
                },

                createdUpdatedHandler = function () {
                    changeView('success');

                    saveDeleteBar.enableDelete();
                    saveDeleteBar.disableSave();

                    selectedCookbooks = activeBooks;

                    setTimeout(() => {
                        if (closeOnSuccess) {
                            // closes the modal
                            settings.modalInstance.close();
                        } else {
                            // switches to the initial view again
                            changeView(initialView);
                        }

                        // settings.tooltipInstance.unlock();
                        unsubscribeEvents();
                    }, settings.successViewTimeout);

                    // update states
                    // pubsub.publish('teasers.updateStates');
                    // pubsub.publish('pageActions.updateStates');
                    // pubsub.publish('bookmark.saved');
                };

            // switching to ajax view so the user can see an ajax call is triggered
            changeView('ajax');

            // settings.tooltipInstance.lock();

            // subscribe to ajax calls
            createdId = bookmarks.subscribe('created', createdUpdatedHandler);
            updatedId = bookmarks.subscribe('updated', createdUpdatedHandler);
            createdErrorId = bookmarks.subscribe('created.error', createdUpdatedErrorHandler);
            updatedErrorId = bookmarks.subscribe('updated.error', createdUpdatedErrorHandler);

            // make the calls
            if (selectedCookbooks.length < 1) {
                bookmarks.createBookmark(bookmarkUrl, bookmarkRecipeId, bookmarkOwnRecipeId, activeBooks.join(','), bookmarkTreffertypSub, bookmarkMenuartId);
            } else {
                bookmarks.updateBookmark(bookmarkUrl, bookmarkRecipeId, bookmarkOwnRecipeId, activeBooks.join(','), bookmarkTreffertypSub, bookmarkMenuartId);
            }
        },

        // deleteBookmarkFromCurrentCookbook = function() {
        //     const currentCookbook = document.querySelector('[data-myfooby-cookbook-container] [data-call-state="2"]');

        //     if (!currentCookbook) {
        //         return;
        //     }

        //     const currentCookbookId = Number(currentCookbook.getAttribute('data-cookbook-id'));
        //     const selectedCookbooks = cookbookSelectView.getSelected().filter(cookbookId => cookbookId !== currentCookbookId);

        //     cookbookSelectView.setActives(selectedCookbooks);

        //     if (selectedCookbooks.length === 0) {
        //         deleteBookmarks();
        //     } else {
        //         saveBookmarks(true);
        //     }
        // },

        deleteBookmarks = function() {
            var deletedId,
                deletedErrorId,
                unsubscribeEvents = function() {
                    bookmarks.unsubscribe(deletedId);
                    bookmarks.unsubscribe(deletedErrorId);
                },
                deletedErrorHandler = function() {
                    unsubscribeEvents();
                    changeView('error');

                    setTimeout(() => {
                        // switches to the cookbooks again
                        changeView(initialView);
                        // settings.tooltipInstance.unlock();
                    }, settings.errorViewTimeout);
                },
                deletedHandler = function() {
                    changeView('success');

                    setTimeout(() => {
                        // closes the modal
                        settings.modalInstance.close();
                        // settings.tooltipInstance.unlock();
                        unsubscribeEvents();
                    }, settings.successViewTimeout);

                    // update states
                    // pubsub.publish('teasers.updateStates');
                    // pubsub.publish('pageActions.updateStates');
                    // pubsub.publish('bookmark.deleted');
                };

            changeView('ajax');

            // settings.tooltipInstance.lock();

            // subscribe to ajax calls
            deletedId = bookmarks.subscribe('deleted', deletedHandler);
            deletedErrorId = bookmarks.subscribe('deleted.error', deletedErrorHandler);

            // make the calls
            bookmarks.deleteBookmark(bookmarkUrl, bookmarkRecipeId, bookmarkOwnRecipeId, bookmarkTreffertypSub, bookmarkMenuartId);
        },

        deleteConfirm = function () {
            changeView(bookmarkTreffertypSub === 'rezept' ? 'warning' : 'warning-article');
        },

        addCookbook = function (cookbook) {
            cookbookSelectView.updateCookbooks(getActiveCookbooks(), cookbook);
        },

        closeModal = function(ev) {
            ev.preventDefault();
            settings.modalInstance.close();
        };

    if (container instanceof HTMLElement === false) {
        console.error('Passed container element is not a DOM element.');
        return;
    }

    view = modules.view.call(container, {
        containers: [
            {
                type: 'not-logged-in',
                wrapper: '[data-view-type="not-logged-in"]',
                content: '[data-view-type="not-logged-in"]'
            },
            {
                type: 'not-logged-in-article',
                wrapper: '[data-view-type="not-logged-in-article"]',
                content: '[data-view-type="not-logged-in-article"]'
            },
            {
                type: 'cookbooks',
                wrapper: '[data-view-type="cookbooks"]',
                content: '[data-view-type="cookbooks"]'
            },
            // {
            //     type: 'warning-cookbook',
            //     wrapper: '[data-view-type="warning-cookbook"]',
            //     content: '[data-view-type="warning-cookbook"]'
            // },
            {
                type: 'warning',
                wrapper: '[data-view-type="warning"]',
                content: '[data-view-type="warning"]'
            },
            {
                type: 'warning-article',
                wrapper: '[data-view-type="warning-article"]',
                content: '[data-view-type="warning-article"]'
            },
            {
                type: 'ajax',
                wrapper: '[data-view-type="ajax"]',
                content: '[data-view-type="ajax"]'
            },
            {
                type: 'success',
                wrapper: '[data-view-type="success"]',
                content: '[data-view-type="success"]'
            },
            {
                type: 'error',
                wrapper: '[data-view-type="error"]',
                content: '[data-view-type="error"]'
            }
        ]
    });

    // get bookmark infos for this teaser/detail-page
    bookmarkUrl = settings.url;
    bookmarkTreffertypSub = settings.treffertypSub;
    bookmarkRecipeId = settings.recipeId;
    bookmarkOwnRecipeId = settings.ownRecipeId;
    bookmarkMenuartId = settings.menuartId;

    // create views
    cookbookSelectView = views.cookbookSelectView(cookbooks, container);
    cookbookAddView = views.cookbookAddView(cookbooks, container);

    // init save delete actions
    saveDeleteBar = modules.saveDeleteBar(container);

    // init confirm module
    // simpleConfirmCookbook = modules.simpleConfirm(container.querySelector('[data-view-type="warning-cookbook"]'));
    simpleConfirm = modules.simpleConfirm(container.querySelector(`[data-view-type="${bookmarkTreffertypSub === 'rezept' ? 'warning' : 'warning-article'}"]`));

    // initially save button is always disabled
    saveDeleteBar.disableSave();

    checkLoggedInState();

    // set initial cookbook state
    cookbookSelectView.setActives(getActiveCookbooks());

    // store currently selected cookbooks
    selectedCookbooks = cookbookSelectView.getSelected();

    if (selectedCookbooks.length > 0) {
        saveDeleteBar.enableDelete();
    } else {
        saveDeleteBar.disableDelete();
    }

    // *************
    // Subscriptions
    // *************

    // TODO: sollte nicht auf den komponenten subscriben, sondern auf das cookbook Model selbst
    cookbookAddView.subscribe('added', addCookbook);

    saveDeleteBar.subscribe('delete', deleteConfirm);

    saveDeleteBar.subscribe('save', function () {
        if(cookbookSelectView.getSelected().length < 1) {
            deleteConfirm();
        } else {
            saveBookmarks();
        }
    });

    cookbookSelectView.subscribe('changed', checkChange);

    simpleConfirm.subscribe('denied', checkLoggedInState);
    simpleConfirm.subscribe('confirmed', deleteBookmarks);

    // simpleConfirmCookbook.subscribe('denied', checkLoggedInState);
    // simpleConfirmCookbook.subscribe('confirmed', deleteBookmarkFromCurrentCookbook);

    container.querySelector('[data-mini-modal-flex-wrapper] [data-mini-modal-close]').addEventListener('click', closeModal);

    // public methods

    /**
     * Destroys this instance.
     */
    instance.destroy = function () {
        container.querySelector('[data-mini-modal-flex-wrapper] [data-mini-modal-close]').removeEventListener('click', closeModal);
        cookbookAddView.destroy();
        cookbookSelectView.destroy();
        saveDeleteBar.destroy();
        simpleConfirm.destroy();
        // simpleConfirmCookbook.destroy();
    };

    return instance;
};
