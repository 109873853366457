// Important note: use this variables only if feature detection fails

// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';
// Internet Explorer 6-11
const isIE = navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode;
// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;
// Chrome
const isChrome = !!window.chrome;
// isMobile
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);

export default {
    isFirefox, isIE, isEdge, isChrome, isMobile
};
