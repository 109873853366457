import injector from '@/scaffold/injector';

const createUtagEventsListener = injector.resolve([null, 'Store', 'Pubsub', 'Helper', 'Tealium', 'Globals'], (instance = {}, store, pubsub, helper, tealium, globals) => {
    let listening = false;

    const eventListeners = {};
    const pubsubListenerIds = [];

    const fireMultipleEvents = true;
    const matchMultipleSelectorsInSameGroup = false;

    const primaryCategoryContentInteraction = 'Content Interaktion';
    const primaryCategoryNavigation = 'Navigation';
    const primaryCategoryConversionAndLeads = 'Konversionen & Leads';

    const get = helper.get;
    // const isObject = helper.isObject;

    const utagDomEvents = [
        {
            trigger: 'click',
            events: [
                {
                    attributes: {
                        'event_attributes_eventName' : 'product_click'
                    },
                    selectors: [
                        ['.teaser[data-recipe-id]', (target) => {
                            const isListEvent = utag.data['event_attributes_eventName'] === 'list';
                            const isCategoryEvent = utag.data['event_attributes_eventName'] === 'category';

                            const data = {
                                'product_category_01': ['Rezepte'],
                                'product_productInfo_sku': [clean(target.getAttribute('data-recipe-id'))],
                                'product_productInfo_productName': [clean(target.querySelector('.teaser__body-title').textContent)],
                                'product_attributes_position': [clean(target.getAttribute('data-position') || '')]
                            };

                            data['product_list_name'] = target.closest('.t12-searchresults') ?
                                `Suche${get('query') ? ` : ${get('query')}` : ''}` :
                                `${isListEvent || isCategoryEvent ? 'Kategorie' : 'Content'} : ${document.location.pathname.split('/').pop().replace('.html', '')}`;

                            return data;
                        }, (target, e) => {
                            const notFooter = !e.target.closest('.teaser__footer');
                            return notFooter;
                        }]
                    ]
                },
                {
                    attributes: {
                        'event_category': primaryCategoryContentInteraction
                    },
                    selectors: [
                        ['a.tag', (target) => {
                            let href = clean(target.getAttribute('href'));

                            if (href === '' || href === '#') {
                                const parentElement = target.closest('.main-search');

                                if (parentElement) {
                                    const form = parentElement.querySelector('form');

                                    if (form) {
                                        href = `${form.action}?query=${target.innerText}`;
                                    }
                                } else if (globals.isSearch) {
                                    href = `${document.location.origin}${document.location.pathname}?query=${target.innerText}`;
                                }
                            }

                            return {
                                'event_action': `Tagsuche : ${clean(target.textContent)}`,
                                'event_label': href
                            };
                        }],
                        ['.btn', (target) => {
                            const matchRecipeId = document.location.pathname.match(
                                /(?:rezepte|recettes|ricette|recipes)\/([0-9]*)\//
                            );

                            return {
                                'event_action': `Button : ${clean(target.textContent)}`,
                                'event_label': target.getAttribute('href')
                                    || (globals.isRecipe
                                        ? (matchRecipeId ? matchRecipeId[1] : null)
                                        : null
                                    ) || 'none'
                            };
                        }]
                    ],
                    group: [
                        // {
                        //     attributes: {
                        //         'event_action': 'Button Click'
                        //     },
                        //     selectors: [
                        //         ['.btn', (target) => ({
                        //             'event_label': `${clean(target.textContent)} : ${clean(target.getAttribute('href') || '')}`
                        //         })]
                        //     ]
                        // },
                        // {
                        //     attributes: {
                        //         'event_action': 'Hauptsuche'
                        //     },
                        //     selectors: [
                        //         ['.main-search a.tag', (target) => ({
                        //             'event_label': `Häufig gesucht : ${clean(target.textContent)}`
                        //         })]
                        //     ]
                        // },
                        // {
                        //     attributes: {
                        //         'event_action': 'Kategoriesuche'
                        //     },
                        //     selectors: [
                        //         ['[data-teaser-group-feed] .searchbar__btn', (target) => ({
                        //             'event_label': `Filter : ${clean(target.textContent)}`
                        //         })]
                        //     ]
                        // },
                        // {
                        //     attributes: {
                        //         'event_action': 'Filter'
                        //     },
                        //     selectors: [
                        //         ['.t12-searchresults__filter-l1-list-item', (target) => ({
                        //             'event_label': `Ergebnisse aus : ${clean(target.querySelector('[data-filter-title-element]').textContent)}`
                        //         }), (target) => target.getAttribute('data-filter-state') === '1'],
                        //         ['.filter-bar__filter-l2-group-item[data-filter-type="radio"]', (target) => ({
                        //             'event_label': `Radio : ${clean(target.querySelector('[data-filter-title-element]').textContent)}`
                        //         }), (target) => target.getAttribute('data-filter-state') === '1'],
                        //         ['.filter-bar__filter-l2-group-item[data-filter-type="dropdown"] .filter-bar__filter-l2-group-item-option', (target) => ({
                        //             'event_label': `Dropdown : ${clean(target.querySelector('[data-filter-title-element]').textContent)} : ${target.getAttribute('data-filter-state') === '2' ? 'Deaktiviert' : 'Aktiviert'}`
                        //         })]
                        //     ]
                        // },
                        {
                            attributes: {
                                'event_action': 'Facettensuche'
                            },
                            selectors: [
                                ['.season-calendar .filter-bar__item', (target) => ({
                                    'event_label': `Balkenfilter : ${clean(target.textContent)}`
                                })],
                                ['.season-calendar .switch__input', (target) => ({
                                    'event_label': `Jetzt Saison : ${target.checked}`
                                })]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Facettensuche',
                                'event_attributes_eventName': 'filter_search'
                            },
                            selectors: [
                                ['.t12-searchresults__filter-l1-list-item', (target) => ({
                                    'event_label': `Ergebnisse aus : ${clean(target.querySelector('[data-filter-title-element]').textContent)}`
                                }), (target) => target.getAttribute('data-filter-state') === '1'],
                                ['.filter-bar__filter-l2-group-item[data-filter-type="radio"]', (target) => ({
                                    'event_label': 'Aktiviert',
                                    'facetSearch_facetCount': 1,
                                    'facetSearch_label': `${clean(target.querySelector('[data-filter-title-element]').textContent)}`,
                                    'facetSearch_sectionName': `${clean(target.querySelector('[data-filter-title-element]').textContent)}`,
                                    'facetSearch_category': target.closest('.t12-searchresults') ? 'search' : 'list',
                                    'product_list_name': `Suche${get('query') ? ` : ${get('query')}` : ''}`
                                }), (target) => target.getAttribute('data-filter-state') === '1']
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Teaser : Image Special'
                            },
                            selectors: [
                                ['.teaser-image-special__link', (target) => ({
                                    'event_label': `Klick : ${clean(target.querySelector('.teaser-image-special__type').textContent)} : ${clean(target.querySelector('.teaser-image-special__body-title').textContent)}`
                                })]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Teaser : Video'
                            },
                            selectors: [
                                // ['.teaser-video a.btn', (target) => ({
                                //     'event_label': `Klick : ${clean(target.textContent)}`
                                // })],
                                ['.teaser-video .video', (target) => ({
                                    'event_label': `Video : ${clean(target.closest('.teaser-video').querySelector('.heading--title').textContent)}`
                                })]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Teaser : Service'
                            },
                            selectors: [
                                ['.teaser-service a.social-media-list__item', (target) => ({
                                    'event_label': `Share : ${clean(target.getAttribute('title'))}`
                                })],
                                ['.teaser-service a.functions-link', (target) => ({
                                    'event_label': `Funktion : ${clean(target.textContent)}`
                                })],
                                ['.teaser-service a.app-buttons__btn', (target) => ({
                                    'event_label': `App Store : ${clean(target.querySelector('img').getAttribute('title'))}`
                                })],
                                // ['.teaser-service__items a', (target) => ({
                                //     'event_label': `Klick : ${clean(target.textContent)}`
                                // })],
                                // ['.teaser-service a', (target) => ({
                                //     'event_label': `Klick : ${clean(target.textContent)}`
                                // })]
                                ['.teaser-service .service-card__btn', (target) => ({
                                    'event_label': `Klick : ${clean(target.textContent)}`
                                })]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Teaser : Rezept'
                            },
                            selectors: [
                                ['.category-entrance__item', (target) => ({
                                    'event_label': clean(target.textContent)
                                })]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Teaser'
                            },
                            selectors: [
                                ['.teaser [data-like-save]', (target) => ({
                                    'event_label': `Like : ${clean(target.closest('.teaser').querySelector('.teaser__type').textContent)} : ${clean(target.closest('.teaser').querySelector('.teaser__body-title').textContent)}`
                                })],
                                // ['.teaser [data-tooltip-target="bookmark-save"]', (target) => ({
                                //     'event_label': `Zu Kochbuch hinzufügen : ${clean(target.closest('.teaser').querySelector('.teaser__type').textContent)} : ${clean(target.closest('.teaser').querySelector('.teaser__body-title').textContent)}`
                                // })],
                                ['.teaser', (target) => ({
                                    'event_label': `Klick : ${clean(target.querySelector('.teaser__type').textContent)} : ${clean(target.querySelector('.teaser__body-title').textContent)}`
                                }), (target, e) => !e.target.closest('.teaser__footer')]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'My Fooby Profil löschen'
                            },
                            selectors: [
                                ['.t13-myfooby [data-delete-profile]', (target) => ({
                                    'event_label': clean(target.textContent)
                                })]
                            ]
                        },
                        // {
                        //     attributes: {
                        //         'event_action': 'Notiz hinzufügen'
                        //     },
                        //     selectors: [
                        //         ['.t5-recipe > .container [data-notes-open=""]:not([data-notes-hasnote])', () => ({
                        //             'event_label': 'Hinzufügen'
                        //         })]
                        //     ]
                        // },
                        {
                            attributes: {
                                'event_action': 'Notiz hinzufügen - Kochmodus'
                            },
                            selectors: [
                                ['.recipe-overlay [data-notes-open=""]:not([data-notes-hasnote])', () => ({
                                    'event_label': 'Hinzufügen'
                                })]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Notiz bearbeiten'
                            },
                            selectors: [
                                // ['.t5-recipe > .container [data-notes-open=""][data-notes-hasnote]', () => ({
                                ['.t5-recipe > .container .notes [data-notes-open=""][data-notes-hasnote]', () => ({
                                    'event_label': 'Bearbeiten'
                                })]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Notiz bearbeiten - Kochmodus'
                            },
                            selectors: [
                                ['.recipe-overlay [data-notes-open=""][data-notes-hasnote]', () => ({
                                    'event_label': 'Bearbeiten'
                                })]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Notiz löschen'
                            },
                            selectors: [
                                // ['.t5-recipe > .container [data-notes-open="warning"]', () => ({
                                ['.t5-recipe > .container .notes [data-notes-open="warning"]', () => ({
                                    'event_label': 'Löschen'
                                })]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Notiz löschen - Kochmodus'
                            },
                            selectors: [
                                ['.recipe-overlay [data-notes-open="warning"]', () => ({
                                    'event_label': 'Löschen'
                                })]
                            ]
                        },
                        // {
                        //     attributes: {
                        //         'event_action': 'In Einkaufsliste speichern'
                        //     },
                        //     selectors: [
                        //         ['.t5-recipe [data-module="save-recipe-ingredients"]:not([data-saved])', () => ({
                        //             'event_label': 'Speichern'
                        //         })]
                        //     ]
                        // },
                        // {
                        //     attributes: {
                        //         'event_action': 'Aus Einkaufsliste löschen'
                        //     },
                        //     selectors: [
                        //         ['.t5-recipe [data-module="save-recipe-ingredients"][data-saved]', () => ({
                        //             'event_label': 'Löschen'
                        //         })]
                        //     ]
                        // },
                        {
                            attributes: {
                                'event_action': 'Einkaufsliste verschicken'
                            },
                            selectors: [
                                // ['.t5-recipe [data-mini-modal-target="modal-shoppinglist"]', () => ({
                                //     'event_label': 'Erstellen'
                                // })],
                                ['.mini-modal--shoppinglist [data-add-to-ingredients]', (target) => ({
                                    'event_label': `Zutat vom Vorrat : ${clean(target.closest('.shopping-itemrow__item').querySelector('[data-ingredient-item-desc]').textContent)}`
                                })],
                                ['.mini-modal--shoppinglist [data-add-to-stock]', (target) => ({
                                    'event_label': `Zutat zum Vorrat : ${clean(target.closest('.shopping-itemrow__item').querySelector('[data-ingredient-item-desc]').textContent)}`
                                })],
                                ['.mini-modal--shoppinglist [data-addmore-trigger]', (target) => ({
                                    'event_label': `Zutat hinzufügen : ${clean(target.closest('.shoppinglist__add').querySelector('[data-addmore-input]').value)}`
                                }), (target) => target.closest('.shoppinglist__add').querySelector('[data-addmore-input]').value]
                            ]
                        },
                        // {
                        //     attributes: {
                        //         'event_action': 'Zutaten online kaufen'
                        //     },
                        //     selectors: [
                        //         ['.t5-recipe [data-buy-ingredients-online]', (target) => ({
                        //             'event_label': clean(target.getAttribute('data-buy-ingredients-online'))
                        //         })]
                        //     ]
                        // },
                        // {
                        //     attributes: {
                        //         'event_action': 'Kochmodus starten'
                        //     },
                        //     selectors: [
                        //         ['.t5-recipe [data-start-cooking-view]', (target) => ({
                        //             'event_label': clean(target.getAttribute('data-start-cooking-view'))
                        //         })]
                        //     ]
                        // },
                        {
                            attributes: {
                                'event_action': 'Rezept drucken'
                            },
                            selectors: [
                                ['.t5-recipe [data-print-recipe]', (target) => ({
                                    'event_label': clean(target.getAttribute('data-print-recipe'))
                                })]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Kochvideo anschauen'
                            },
                            selectors: [
                                ['.t5-recipe [data-watch-cooking-video]', (target) => ({
                                    'event_label': clean(target.getAttribute('data-watch-cooking-video'))
                                })]
                            ]
                        },
                        // {
                        //     attributes: {
                        //         'event_action': 'Mehr anzeigen'
                        //     },
                        //     selectors: [
                        //         ['.teaser-group-feed__result-container .btn[data-button]', () => ({
                        //             'event_label': `${document.location.pathname + document.location.search}`
                        //         })],
                        //         ['.t13-myfooby__teaser-wrapper .btn[data-button]', () => ({
                        //             'event_label': `${document.location.pathname + document.location.search}`
                        //         })],
                        //         ['.t12-searchresults__result-container .btn[data-button]', () => ({
                        //             'event_label': `${document.location.pathname + document.location.search}`
                        //         })],
                        //         ['.teaser-group-rezeptliste .btn[data-button]', () => ({
                        //             'event_label': `${document.location.pathname + document.location.search}`
                        //         })],
                        //         ['.teaser-group-automated .btn[data-button]', () => ({
                        //             'event_label': `${document.location.pathname + document.location.search}`
                        //         })]
                        //     ]
                        // },
                        {
                            attributes: {
                                'event_action': 'Clickout',
                                'event_attributes_eventName': 'clickout'
                            },
                            selectors: [
                                [`a[href*="//"]:not([href^="${location.protocol}//${location.hostname}"]):not([href^="https://fooby.ch"]):not([href^="https://fooby.templates.coop.ch"])`, (target) => ({
                                    'event_label': `${clean(target.getAttribute('href'))}`
                                })]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Footer'
                            },
                            selectors: [
                                ['.footer .social-media-list__item', (target) => ({
                                    'event_label': `Follow us : ${clean(target.getAttribute('title'))}`
                                })]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Rezept teilen'
                            },
                            selectors: [
                                ['.t5-recipe a.tooltip-trigger.share-trigger-bound:not(.tooltip-trigger--active)', (target) => ({
                                    'event_label': clean(target.closest('.t5-recipe').querySelector('#page-header-recipe__panel-detail h1').textContent)
                                })]
                            ]
                        }
                        // {
                        //     attributes: {
                        //         'event_action': 'Page Header : Recipe'
                        //     },
                        //     selectors: [
                        //         ['.page-header-recipe [data-like-save]', () => ({
                        //             'event_label': 'Like'
                        //         })],
                        //         ['.page-header-recipe [data-bookmark-trigger]', () => ({
                        //             'event_label': 'Bookmark'
                        //         })],
                        //         ['.page-header-recipe [data-share-trigger]', () => ({
                        //             'event_label': 'Share'
                        //         })],
                        //         ['.page-header-recipe a.tag', (target) => ({
                        //             'event_label': `Kategorie : ${clean(target.textContent)}`
                        //         })]
                        //     ]
                        // },
                        // {
                        //     attributes: {
                        //         'event_action': 'Page Header : Foobys'
                        //     },
                        //     selectors: [
                        //         ['.page-header-foobys a.page-header-foobys__weblink', (target) => ({
                        //             'event_label': `Klick : ${clean(target.textContent)}`
                        //         })],
                        //         ['.page-header-foobys a.social-media-list__item', (target) => ({
                        //             'event_label': `Share : ${clean(target.getAttribute('title'))}`
                        //         })]
                        //     ]
                        // },
                        // {
                        //     attributes: {
                        //         'event_action': 'Page Header : Event'
                        //     },
                        //     selectors: [
                        //         ['.page-header-standard--event a.functions-link', (target) => ({
                        //             'event_label': `Funktion : ${clean(target.textContent)}`
                        //         })]
                        //     ]
                        // },
                        // {
                        //     attributes: {
                        //         'event_action': 'Page Header : Standard'
                        //     },
                        //     selectors: [
                        //         ['.page-header-standard [data-like-save]', () => ({
                        //             'event_label': 'Like'
                        //         })],
                        //         ['.page-header-standard [data-bookmark-trigger]', () => ({
                        //             'event_label': 'Bookmark'
                        //         })],
                        //         ['.page-header-standard [data-share-trigger]', () => ({
                        //             'event_label': 'Share'
                        //         })]
                        //     ]
                        // },
                        // {
                        //     attributes: {
                        //         'event_action': 'Tag Cloud'
                        //     },
                        //     selectors: [
                        //         ['.tag', (target) => ({
                        //             'event_label': clean(target.textContent)
                        //         })]
                        //     ]
                        // }
                    ]
                },
                {
                    attributes: {
                        'event_category': primaryCategoryNavigation
                    },
                    group: [
                        {
                            attributes: {
                                'event_action': 'Main Header'
                            },
                            selectors: [
                                ['.navigation-desktop__l1-link', (target) => ({
                                    'event_label': clean(target.textContent)
                                })],
                                ['.navigation-meta__l1-link', (target) => ({
                                    'event_label': clean(target.textContent)
                                })],
                                ['.navigation-desktop__l2-link', (target) => ({
                                    'event_label': `${clean(target.closest('.navigation-desktop__l1-list-item').querySelector('.navigation-desktop__l1-link').textContent)} : ${clean(target.textContent)}`
                                })]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Service Sidebar'
                            },
                            selectors: [
                                ['.t13-myfooby__sidebar-section-title', (target) => ({
                                    'event_label': clean(target.textContent)
                                }), (target) => !target.closest('.t13-myfooby__sidebar-section').classList.contains('t13-myfooby__sidebar-section--open')],
                                ['.t13-myfooby__sidebar-section-content-list-item', (target) => ({
                                    'event_label': `${clean(target.closest('.t13-myfooby__sidebar-section').querySelector('.t13-myfooby__sidebar-section-title').textContent)} : ${clean(target.querySelector('[data-call-title]').textContent)}`
                                }), (target) => target.getAttribute('data-call-state') === '1']
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Prev-Next Navigation'
                            },
                            selectors: [
                                ['.page-header-recipe__panel-detail [data-overview-link]', () => ({
                                    'event_label': 'Kategorie'
                                })],
                                ['.page-header-recipe__panel-detail [data-prev-link]', () => ({
                                    'event_label': 'Vorheriges'
                                })],
                                ['.page-header-recipe__panel-detail [data-next-link]', () => ({
                                    'event_label': 'Nächstes'
                                })]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Footer'
                            },
                            selectors: [
                                ['.footer .footer-nav__link', (target) => ({
                                    'event_label': clean(target.textContent)
                                })],
                                ['.footer .footer-nav__list-item-link', (target) => ({
                                    'event_label': `${clean(target.closest('.footer-nav__section').querySelector('.footer-nav__link').textContent)} : ${clean(target.textContent)}`
                                })],
                                ['.footer .footer-meta-nav__link', (target) => ({
                                    'event_label': clean(target.textContent)
                                })],
                                ['.footer .language-nav__l1-link', (target) => ({
                                    'event_label': clean(target.textContent)
                                })]
                            ]
                        }
                    ]
                },
                {
                    attributes: {
                        'event_category': primaryCategoryConversionAndLeads
                    },
                    group: [
                        // {
                        //     attributes: {
                        //         'event_action': 'Bookmark',
                        //         'event_attributes_eventName': 'bookmark'
                        //     },
                        //     selectors: [
                        //         ['#page-header-recipe__panel-detail [data-tooltip-target="bookmark-save"]', (target) => ({
                        //             'event_label': clean(target.closest('#page-header-recipe__panel-detail').querySelector('h1').textContent),
                        //             'product_productInfo_productName': [clean(target.closest('#page-header-recipe__panel-detail').querySelector('h1').textContent)],
                        //             'product_productInfo_sku': [clean(target.closest('#page-header-recipe__panel-detail').getAttribute('data-recipe-id'))]
                        //         })],
                        //         ['.page-header-standard [data-bookmark-trigger]', () => ({
                        //             'event_label': 'Bookmark'
                        //         })]
                        //     ]
                        // },
                        {
                            attributes: {
                                'event_action': 'Share',
                                'event_attributes_eventName': 'share'
                            },
                            selectors: [
                                ['.share-icon-list__item', (target) => ({
                                    'event_label': `${clean(target.getAttribute('data-share') || '-')}`
                                })],
                                ['.page-header-foobys a.social-media-list__item', (target) => ({
                                    'event_label': `${clean(target.getAttribute('title') || '-' )}`
                                })]
                                // ['.page-header-standard [data-share-trigger]', () => ({
                                //     'event_label': 'Share'
                                // })],
                                // ['.page-header-recipe [data-share-trigger]', () => ({
                                //     'event_label': 'Share'
                                // })]
                            ]
                        },
                        {
                            attributes: {
                                'event_action': 'Like',
                                'event_attributes_eventName': 'like'
                            },
                            selectors: [
                                ['.page-header-recipe [data-like-save]', () => ({
                                    'event_label': 'Like'
                                })],
                                ['.page-header-standard [data-like-save]', () => ({
                                    'event_label': 'Like'
                                })]
                            ]
                        }
                    ]
                }
            ]
        },
        {
            trigger: 'click',
            capture: false,
            events: [
                {
                    attributes: {
                        'event_category': primaryCategoryContentInteraction
                    },
                    group: [
                        {
                            attributes: {
                                'event_action': 'Produktbewertung',
                                'event_attributes_eventName': 'product_rating'
                            },
                            selectors: [
                                ['#page-header-recipe__panel-detail [data-like-save]', (target) => ({
                                    'event_label': clean(target.querySelector('[data-like-count]').textContent),
                                    'product_productInfo_productName': [clean(target.closest('#page-header-recipe__panel-detail').querySelector('h1').textContent)],
                                    'product_productInfo_sku': [clean(target.closest('#page-header-recipe__panel-detail').getAttribute('data-recipe-id'))]
                                })],
                                ['.teaser[data-recipe-id] [data-like-save]', (target) => ({
                                    'event_label': clean(target.querySelector('[data-like-count]').textContent),
                                    'product_productInfo_productName': [clean(target.closest('.teaser').querySelector('.teaser__body-title').textContent)],
                                    'product_productInfo_sku': [clean(target.closest('.teaser').getAttribute('data-recipe-id'))]
                                })]
                            ]
                        }
                    ]
                },
                {
                    attributes: {
                        'event_category': primaryCategoryConversionAndLeads
                    },
                    group: [
                        {
                            attributes: {
                                'event_action': 'Download',
                                'event_attributes_eventName': 'download'
                            },
                            selectors: [
                                // example pdf link: /content/dam/culinaria/images/littlefooby/content/infographic/pdf/wo-waechst-was_en.pdf?.html
                                ['a[href*=".pdf"]', (target) => ({
                                    'event_label': `${clean(target.getAttribute('href'))}`
                                }), (target, e) => !e.defaultPrevented]
                            ]
                        }
                    ]
                }
            ]
        },
        {
            trigger: 'submit',
            events: [
                // {
                //     attributes: {
                //         'event_category': primaryCategoryContentInteraction
                //     },
                //     group: [
                //         {
                //             attributes: {
                //                 'event_action': 'Hauptsuche'
                //             },
                //             selectors: [
                //                 ['.main-search form', (target) => ({
                //                     'event_label': `Freitextsuche : ${clean(target.closest('.main-search').querySelector('.searchbar__searchfield').value)}`
                //                 })]
                //             ]
                //         }
                //     ]
                // }
                // {
                //     attributes: {
                //         'event_category': primaryCategoryConversionAndLeads
                //     },
                //     group: [
                //         {
                //             attributes: {
                //                 'event_action': 'Newsletterregistrierung'
                //             },
                //             selectors: [
                //                 ['.newsletter-form-signup', () => ({
                //                     'event_label': 'Teaser'
                //                 }), (target) => target.querySelector('.newsletter-form-signup__input').value]
                //             ]
                //         }
                //     ]
                // }
            ]
        }
    ];

    const pubsubEvents = {
        // 'recipeObserver.seen': ({ seenEntries }) => {
        //     seenEntries.filter((entry) => {
        //         const insideSearch = entry.target.closest('.t12-searchresults');
        //         const insideTeaserGroupFeed = entry.target.closest('[data-teaser-group-feed]');
        //         return insideSearch || insideTeaserGroupFeed;
        //     });

        //     if (seenEntries.length === 0) {
        //         return;
        //     }

        //     view({
        //         'transaction_attributes_eventType' : 'product_imp',
        //         'imp_product_list_name' : seenEntries[0].target.closest('.t12-searchresults')
        //             ? `Suche${get('query') ? ` : ${get('query')}` : ''}`
        //             : `Kategorie : ${clean(document.location.pathname.split('/').pop().replace('.html', ''))}`,
        //         'imp_product_productInfo_sku' : seenEntries.map((entry) => clean(entry.target.getAttribute('data-recipe-id'))),
        //         'imp_product_productInfo_productName' : seenEntries.map((entry) => clean(entry.target.querySelector('.teaser__body-title').textContent)),
        //         'imp_product_category_primaryCategory' : seenEntries.map(() => 'Rezepte'),
        //         'imp_product_attributes_position' : seenEntries.map((entry) => clean(entry.target.getAttribute('data-position')))
        //     });
        // },
        // 'errors.show': (module, errors) => {
        //     if (isObject(errors)) {
        //         Object.keys(errors).forEach((field) => {
        //             const fieldErrors = errors[field];
        //             link({
        //                 'error_errorInfo_message': `${module} : ${field} : ${Array.isArray(fieldErrors) ? fieldErrors.join(', ') : fieldErrors}`
        //             });
        //         });
        //     } else {
        //         link({
        //             'error_errorInfo_message': `${module} : ${errors}`
        //         });
        //     }
        // },
        // 'recipe.save': ({ url }) => {
        //     link({
        //         'event_category': primaryCategoryConversionAndLeads,
        //         'event_action': 'Download',
        //         'event_attributes_eventName': 'download',
        //         'event_label': url
        //     });
        // },
        'filterController.filter.add': ({ filter, filterObject }) => {
            if (!filter || !filterObject) {
                return;
            }

            const filterCount = document.querySelectorAll('.filter-bar__filter-l2-group-item-option[data-filter-state="2"]').length;

            link({
                'event_category': primaryCategoryContentInteraction,
                'event_action': 'Facettensuche',
                'event_attributes_eventName': 'filter_search',
                'event_label': `${filter.getAttribute('data-filter-state') === '2' ? 'Aktiviert' : 'Deaktiviert'}`,
                'facetSearch_facetCount': filterCount,
                'facetSearch_label': `${clean(filter.querySelector('[data-filter-title-element]').textContent)}`,
                'facetSearch_sectionName': `${clean(filter.closest('[data-filter-type="dropdown"]').querySelector('[data-filter-title-element]').textContent)}`,
                'facetSearch_category': filter.closest('.t12-searchresults') ? 'search' : 'list',
                'product_list_name': `Suche${get('query') ? ` : ${get('query')}` : ''}`
            });
        },
        'filterController.filter.remove': ({ filter, filterObject }) => {
            if (!filter || !filterObject) {
                return;
            }

            const filterCount = document.querySelectorAll('.filter-bar__filter-l2-group-item-option[data-filter-state="2"]').length;

            link({
                'event_category': primaryCategoryContentInteraction,
                'event_action': 'Facettensuche',
                'event_attributes_eventName': 'filter_search',
                'event_label': `${filter.getAttribute('data-filter-state') === '2' ? 'Aktiviert' : 'Deaktiviert'}`,
                'facetSearch_facetCount': filterCount,
                'facetSearch_label': `${clean(filter.querySelector('[data-filter-title-element]').textContent)}`,
                'facetSearch_sectionName': `${clean(filter.closest('[data-filter-type="dropdown"]').querySelector('[data-filter-title-element]').textContent)}`,
                'facetSearch_category': filter.closest('.t12-searchresults') ? 'search' : 'list',
                'product_list_name': `Suche${get('query') ? ` : ${get('query')}` : ''}`
            });
        },
        'searchResponse.mainFilterChanged': (module, { query, initial, queryChanged }) => {
            // replaced within 'searchbar.search' event to distinguish
            // whether search was executed by autocomplete or manual search
            // if (module === 'search' && queryChanged && !initial) {
            //     link({
            //         'event_category': primaryCategoryContentInteraction,
            //         'event_action': 'Hauptsuche',
            //         'event_label': `Freitextsuche : ${clean(query)}`
            //     });
            // }

            if (module === 'teaserGroupFeed' && queryChanged && !initial) {
                link({
                    'event_category': primaryCategoryContentInteraction,
                    'event_action': 'Kategoriesuche',
                    'event_label': `Freitextsuche : ${clean(query)}`
                });
            }
        },
        'autocomplete.select': (value) => {
            link({
                'event_category': primaryCategoryContentInteraction,
                'event_action': 'Hauptsuche',
                'event_label': `Wortvorschläge : ${value}`
            });
        },
        'searchbar.search': (value, submittedByAutocomplete, container) => {
            const isTeaserGroupFeed = container.hasAttribute('data-teaser-group-feed');
            // don't trigger event if autocomplete.select was triggered before
            if (!submittedByAutocomplete && !isTeaserGroupFeed) {
                link({
                    'event_category': primaryCategoryContentInteraction,
                    'event_action': 'Hauptsuche',
                    'event_label': `Freitextsuche : ${clean(value)}`
                });
            }
        },
        // 'filterController.filter.add': ({ parent, filterObject }) => {
        //     link({
        //         'filter_element_elementName': 'Suche',
        //         'filter_element_elementValue': parent.querySelector('[data-filter-title-element]').innerHTML,
        //         'filter_element_sectionName': filterObject.title
        //     });
        // },
        // 'filterController.filter.radioChange': ({ radio }) => {
        //     link({
        //         'filter_element_elementName': 'Suche',
        //         'filter_element_elementValue': radio.querySelector('[data-filter-title-element]').innerHTML
        //     });
        // },
        'newsletter.signup': () => {
            link({
                'event_category': primaryCategoryConversionAndLeads,
                'event_attributes_eventName': 'newsletter_signup',
                'event_action': 'Newsletter Registrierung',
                'event_label': 'Sign-Up Module'
            });
        },
        'newsletter.signout': () => {
            link({
                'event_category': primaryCategoryConversionAndLeads,
                'event_attributes_eventName': 'newsletter_signout',
                'event_action': 'Newsletter Abmeldung',
                'event_label': 'Sign-Out Module'
            });
        },
        'portionCalculator.plus': () => {
            link({
                'event_category': primaryCategoryContentInteraction,
                'event_action': 'Portionenrechner',
                'event_label': 'Plus'
            });
        },
        'portionCalculator.minus': () => {
            link({
                'event_category': primaryCategoryContentInteraction,
                'event_action': 'Portionenrechner',
                'event_label': 'Minus'
            });
        },
        'cookbooks.created': ({ cookbook }) => {
            link({
                'event_category': primaryCategoryContentInteraction,
                'event_action': 'Neues Kochbuch erstellen',
                'event_label': `Speichern : ${clean(cookbook.title)}`
            });
        },
        'externalRecipe.created': ({ recipe }) => {
            link({
                'event_category': primaryCategoryConversionAndLeads,
                'event_attributes_eventName': 'create_recipe',
                'event_action': 'Rezept erstellen',
                'event_label': `${clean(recipe.title)}`
            });
        },
        'shoppinglist.sent': () => {
            link({
                'event_category': primaryCategoryContentInteraction,
                'event_action': 'Einkaufsliste verschicken',
                'event_label': 'Senden'
            });
        }
    };

    const clean = function(str) {
        return str.trim();
    };

    const getDomEventGroupMatches = function(e, group, attributes = {}) {
        attributes = Object.assign({}, attributes, group.attributes);

        let matches = [];

        if (group.selectors) {
            let matchedSelector = false;

            group.selectors.forEach((selector) => {
                if (matchedSelector && !matchMultipleSelectorsInSameGroup) {
                    return true;
                }

                let el = e.target.closest(selector[0]);

                if (el) {
                    matchedSelector = true;
                    let check = selector[2];

                    if (!check || check(el, e, group, attributes)) {
                        let selectorAttributes = selector[1](el, e, group, attributes);
                        matches.push(Object.assign({}, attributes, selectorAttributes));
                    }
                }
            });
        }

        if (group.group) {
            group.group.forEach((subgroup) => {
                matches.push(...getDomEventGroupMatches(e, subgroup, attributes));
            });
        }

        return matches;
    };

    const link = function(obj) {
        if (utag.data['platform']) {
            obj['platform'] = utag.data['platform'];
        }

        if (utag.data['page_attributes_URL']) {
            obj['page_attributes_URL'] = utag.data['page_attributes_URL'];
        }

        tealium.link(obj);
    };

    const view = function(obj) {
        tealium.view(obj);
    };

    const handleDomEvent = function(e, groups) {
        if (!listening) {
            return;
        }

        let groupMatches = [];

        groups.forEach((rootGroup) => {
            groupMatches.push(...getDomEventGroupMatches(e, rootGroup));
        });

        if (!fireMultipleEvents && groupMatches.length > 1) {
            groupMatches = groupMatches.slice(0, 1);
        }

        groupMatches.forEach((match) => {
            link(match);
        });
    };

    Object.keys(pubsubEvents).forEach((event) => {
        const id = pubsub.subscribe(event, function() {
            if (!listening) {
                return;
            }

            pubsubEvents[event].apply(this, arguments);
        });

        pubsubListenerIds.push(id);
    });

    utagDomEvents.forEach((config) => {
        const useCapture = typeof config.capture !== 'undefined' ? config.capture : true;

        eventListeners[`${config.trigger}-${useCapture ? 'true' : 'false'}`] = (e) => {
            handleDomEvent(e, config.events);
        };

        // use capture to execute events before bubbling other events
        // importent for e.g. search filter (effect "Filter") where the state switches on click
        document.addEventListener(
            config.trigger,
            eventListeners[`${config.trigger}-${useCapture ? 'true' : 'false'}`],
            useCapture
        );
    });

    instance.trackPageView = function(data = utag.data) {
        view(data);
    };

    instance.enableListening = function() {
        listening = true;
    };

    instance.disableListening = function() {
        listening = false;
    };

    instance.destroy = function() {
        pubsubListenerIds.forEach((id) => {
            pubsub.unsubscribe(id);
        });

        Object.keys(eventListeners).forEach((event) => {
            document.removeEventListener(event, eventListeners[event]);
        });
    };

    return instance;
});

export default createUtagEventsListener;
