const DATA_CACHED_NOTES = {};

const CLASS_HIDDEN = 'hidden';
const CLASS_HAS_NOTE = 'has-note';
const CLASS_MODAL_DARK_BG = 'mini-modal--theme-dark';
const CLASS_MODAL_HUGE_PADDINGS = 'mini-modal--huge-paddings';

const ATTR_NOTE = 'data-note';
const ATTR_ACTION_NOTES = 'data-notes-action';
const ATTR_HASNOTE = 'data-notes-hasnote';

const TIMEOUT_VIEW_ERROR = 1000;
const TIMEOUT_VIEW_SUCCESS = 1000;

const VIEW_TO_DARK_THEME_MAP = {
    'not-logged-in': false,
    'create': false,
    'edit': false,
    'loading': false,
    'warning': true,
    'success': true,
    'error': true
};

const VIEW_TO_HUGE_PADDING_MAP = {
    'not-logged-in': false,
    'create': false,
    'edit': false,
    'loading': true,
    'warning': true,
    'success': true,
    'error': true
};

const createNotes = ({ el, state, options }, Globals, Pubsub, HelperModal, ViewController, NotesService) => {
    let lastView;
    let hookIdCookoverlayOpened;
    let hookIdUserChanged;
    let hookIdUserFetched;

    const updateView = (view) => {
        if (VIEW_TO_DARK_THEME_MAP[view] || false) {
            state.refs.modal.classList.add(CLASS_MODAL_DARK_BG);
        } else {
            state.refs.modal.classList.remove(CLASS_MODAL_DARK_BG);
        }

        if (VIEW_TO_HUGE_PADDING_MAP[view] || false) {
            state.refs.modal.classList.add(CLASS_MODAL_HUGE_PADDINGS);
        } else {
            state.refs.modal.classList.remove(CLASS_MODAL_HUGE_PADDINGS);
        }

        state.view.updateView(view);
    };

    const updateInitView = (initialView) => {
        if (!Globals.user.isLoggedIn()) {
            updateView('not-logged-in');
        } else if (initialView) {
            updateView(initialView);
        } else if (state.note.text) {
            updateView('edit');
        } else {
            updateView('create');
        }
    };

    const updateTextarea = () => {
        if (!Globals.user.isLoggedIn()) {
            state.refs.textareaUpdate.value = '';
            state.refs.textareaCreate.value = '';
        } else if (state.note.text) {
            state.refs.textareaUpdate.value = state.note.text;
        } else {
            state.refs.textareaCreate.value = '';
        }
    };

    const newlineToBreaks = (text) => {
        return text.replace(/<[^>]+>/g, '').replace(/(?:\r\n|\r|\n)/g, '<br>');
    };

    const updateNotes = () => {
        const loggedIn = Globals.user.isLoggedIn();

        state.globalRefs.notes.forEach((note) => {
            note.querySelector(options.noteRefs.text).innerHTML = loggedIn ? newlineToBreaks(state.note.text) : '';
            note.classList.toggle(CLASS_HIDDEN, !loggedIn || !state.note.text);
        });
    };

    const updateOpenLinks = () => {
        const loggedIn = Globals.user.isLoggedIn();
        const hasNote = loggedIn && !!state.note.text;

        state.globalRefs.openLinks.forEach((link) => {
            link.classList.toggle(CLASS_HAS_NOTE, hasNote);

            if (hasNote) {
                link.setAttribute(ATTR_HASNOTE, '');
            } else {
                link.removeAttribute(ATTR_HASNOTE);
            }
        });
    };

    const updateGlobalRefs = () => {
        state.globalRefs = {
            notes: document.querySelectorAll(`[${ATTR_NOTE}="${options.recipeId}"]`),
            openLinks: document.querySelectorAll(options.globalRefs.openLinks)
        };
    };

    const update = (updateView = true, initialView = '') => {
        updateGlobalRefs();
        addEventListeners();
        updateTextarea();
        updateOpenLinks();
        updateNotes();

        if (updateView) {
            updateInitView(initialView);
        }
    };

    const errorHandler = () => {
        updateView('error');

        window.setTimeout(() => {
            updateView(lastView);
        }, TIMEOUT_VIEW_ERROR);
    };

    const showSuccessViewAndClose = () => {
        updateView('success');
        update(false);

        window.setTimeout(() => {
            HelperModal.closeModal();
        }, TIMEOUT_VIEW_SUCCESS);
    };

    const successDeleteHandler = () => {
        state.note = { recipeId: options.recipeId, text: '' };
        showSuccessViewAndClose();
    };

    const successUpdateHandler = () => {
        state.note.text = state.refs.textareaUpdate.value;
        showSuccessViewAndClose();
    };

    const successCreateHandler = (note) => {
        state.note = note;
        showSuccessViewAndClose();
    };

    const clickActionHandler = (() => {
        const actions = {
            'cancel': () => {
                HelperModal.closeModal();
            },
            'cancel-delete': () => {
                updateView('edit');
            },
            'delete': async () => {
                if (state.view.getType() !== 'warning') {
                    updateView('warning');
                    return;
                }

                updateView('loading');
                await NotesService.delete({
                    ...state.note
                }).then(successDeleteHandler).catch(errorHandler);
            },
            'update': async () => {
                // return warning/confirmation to delete note when textarea content was removed
                if (!state.refs.textareaUpdate.value) {
                    updateView('warning');
                    return;
                }

                updateView('loading');
                await NotesService.update({
                    ...state.note,
                    text: state.refs.textareaUpdate.value
                }).then(successUpdateHandler).catch(errorHandler);
            },
            'create': async () => {
                // make sure no empty text gets submitted
                if (!state.refs.textareaCreate.value) {
                    return;
                }

                updateView('loading');
                await NotesService.create({
                    ...state.note,
                    text: state.refs.textareaCreate.value
                }).then(successCreateHandler).catch(errorHandler);
            }
        };

        return async (e) => {
            e.preventDefault();
            const action = e.currentTarget.getAttribute(ATTR_ACTION_NOTES);
            lastView = state.view.getType();
            await actions[action]();
        };
    })();

    const getRecipeNote = async () => {
        if (!DATA_CACHED_NOTES[options.recipeId]) {
            const response = await NotesService.getByRecipeId(options.recipeId);
            Pubsub.publish('notes.fetched', [{ response }]);

            if (response.text) {
                // cache note so no need to refetch it on the same site
                DATA_CACHED_NOTES[options.recipeId] = response;
            }
        }

        state.note = DATA_CACHED_NOTES[options.recipeId] || {
            recipeId: options.recipeId,
            text: ''
        };
    };

    const userChangedHandler = async () => {
        if (Globals.user.isLoggedIn()) {
            await getRecipeNote();
        }

        update();
    };

    const clickOpenLinkHandler = async (e) => {
        e.preventDefault();
        const initialView = e.currentTarget.getAttribute('data-notes-open') || '';

        await Globals.user.checkSSOSessionState();
        HelperModal.openModal(state.refs.modal.getAttribute('id'), {
            onBeforeOpen: () => {
                update(true, initialView);
            }
        });
    };

    const addEventListeners = () => {
        state.refs.textareaCreate.addEventListener('keyup', () => {
            if (state.refs.textareaCreate.value) {
                state.refs.buttonCreate.removeAttribute('disabled');
            } else {
                state.refs.buttonCreate.setAttribute('disabled', '');
            }
        });

        state.refs.actions.forEach((action) => {
            action.addEventListener('click', clickActionHandler);
        });

        state.globalRefs.openLinks.forEach((link) => {
            link.addEventListener('click', clickOpenLinkHandler);
        });
    };

    const removeEventListeners = () => {
        state.refs.actions.forEach((action) => {
            action.removeEventListener('click', clickActionHandler);
        });

        state.globalRefs.openLinks.forEach((link) => {
            link.removeEventListener('click', clickOpenLinkHandler);
        });
    };

    state.init = async () => {
        if (!options.recipeId) {
            return;
        }

        state.refs = {
            modal: el.closest(options.refs.modal),
            textareaCreate: el.querySelector(options.refs.textareaCreate),
            textareaUpdate: el.querySelector(options.refs.textareaUpdate),
            actions: el.querySelectorAll(options.refs.actions),
            buttonCreate: el.querySelector(options.refs.buttonCreate)
        };

        state.view = ViewController.call(el, {
            containers: [
                {
                    type: 'not-logged-in',
                    wrapper: '[data-view-type="not-logged-in"]',
                    content: '[data-view-type="not-logged-in"]'
                },
                {
                    type: 'create',
                    wrapper: '[data-view-type="create"]',
                    content: '[data-view-type="create"]'
                },
                {
                    type: 'edit',
                    wrapper: '[data-view-type="edit"]',
                    content: '[data-view-type="edit"]'
                },
                {
                    type: 'warning',
                    wrapper: '[data-view-type="warning"]',
                    content: '[data-view-type="warning"]'
                },
                {
                    type: 'loading',
                    wrapper: '[data-view-type="loading"]',
                    content: '[data-view-type="loading"]'
                },
                {
                    type: 'success',
                    wrapper: '[data-view-type="success"]',
                    content: '[data-view-type="success"]'
                },
                {
                    type: 'error',
                    wrapper: '[data-view-type="error"]',
                    content: '[data-view-type="error"]'
                }
            ]
        });

        await Globals.user.fetch();

        if (!Globals.user.isLoggedIn()) {
            hookIdUserChanged = Globals.user.subscribe('changed', userChangedHandler);
            hookIdUserFetched = Globals.user.subscribe('fetched', userChangedHandler);
        } else {
            await getRecipeNote();
        }

        // initial update
        update();

        // update when cooking overlay has been opened (to display hidden note)
        hookIdCookoverlayOpened = Pubsub.subscribe('cookoverlay.opened', update);

        return state;
    };

    state.destroy = () => {
        removeEventListeners();

        Pubsub.unsubscribe(hookIdCookoverlayOpened);
        if (hookIdUserChanged) Globals.user.unsubscribe(hookIdUserChanged);
        if (hookIdUserFetched) Globals.user.unsubscribe(hookIdUserFetched);

        // remove cached data
        DATA_CACHED_NOTES[options.recipeId] = null;
        delete DATA_CACHED_NOTES[options.recipeId];

        // ensure hidden class on all notes
        state.globalRefs.notes.forEach((note) => {
            note.classList.add(CLASS_HIDDEN);
        });
    };

    return state.init();
};

export const config = {
    name: 'notes',
    constructor: createNotes,
    dependencies: ['Globals', 'Pubsub', 'HelperModal', 'ViewController', 'NotesService'],
    options: {
        refs: {
            modal: '.mini-modal',
            textareaCreate: '[data-notes-textarea-create]',
            textareaUpdate: '[data-notes-textarea-update]',
            actions: `[${ATTR_ACTION_NOTES}]`,
            buttonCreate: `[${ATTR_ACTION_NOTES}="create"]`
        },
        noteRefs: {
            text: '[data-notes-text]'
        },
        globalRefs: {
            openLinks: '[data-notes-open]'
        }
    }
};
