import injector from '@/scaffold/injector';

export default injector.resolve(['Store', 'ApiFactory', 'Observer'], (Store, ApiFactory, Observer) => {

    const api = ApiFactory(Store.apis.notes);
    const instance = {};
    Observer(instance);

    /**
     * Get note by recipe id.
     *
     * @param {string} recipeId - Recipe id
     * @return {Promise} Promise
     */
    instance.getByRecipeId = async (recipeId) => {
        return new Promise((resolve, reject) => {
            api.send({
                params: {
                    recipeId
                },
                success: (response) => {
                    const note = JSON.parse(response);
                    instance.publish('fetched', [note]);
                    resolve(note);
                },
                error: (error) => {
                    console.error('Notes data could not be parsed.', error);
                    reject(error);
                }
            });
        });
    };

    /**
     * Creates a new note.
     *
     * @param {object} data - Data
     * @param {string|null} data.recipeId - Name of shopping list ingredient
     * @param {number|null} data.text - Note text
     * @return {Promise} Promise
     */
    instance.create = async ({ recipeId, text }) => {
        return new Promise((resolve, reject) => {
            api.send({
                method: 'POST',
                params: {
                    recipeId,
                    text,
                    '_charset_': 'UTF-8'
                },
                success: (response) => {
                    const result = JSON.parse(response);

                    if (result.status !== 'success') {
                        reject(result.message || result.status);
                    } else {
                        instance.publish('created', [result]);
                        resolve({
                            recipeId,
                            text
                        });
                    }
                },
                error: (error) => {
                    console.error('Notes data could not be parsed.', error);
                    reject(error);
                }
            });
        });
    };

    /**
     * Updates a note.
     *
     * @param {object} data - Data
     * @param {number|null} data.id - Id of recipe the note belongs to
     * @param {string|null} data.text - New text of the note
     * @return {Promise} Promise
     */
    instance.update = async ({ recipeId, text }) => {
        return new Promise((resolve, reject) => {
            api.send({
                method: 'PUT',
                params: {
                    recipeId,
                    text,
                    '_charset_': 'UTF-8'
                },
                success: (response) => {
                    const result = JSON.parse(response);

                    if (result.status !== 'success') {
                        reject(result.message || result.status);
                    } else {
                        instance.publish('updated', [result]);
                        resolve(result);
                    }
                },
                error: (error) => {
                    console.error('Notes data could not be parsed.', error);
                    reject(error);
                }
            });
        });
    };

    /**
     * Deletes a note.
     *
     * @param {object} data - Data
     * @param {number|null} data.recipeID - Recipe id of the recipe the note belongs to
     * @return {Promise} Promise
     */
    instance.delete = async ({ recipeId }) => {
        return new Promise((resolve, reject) => {
            api.send({
                method: 'DELETE',
                params: { recipeId },
                success: (response) => {
                    const result = JSON.parse(response);

                    if (result.status !== 'success') {
                        reject(result.message || result.status);
                    } else {
                        instance.publish('deleted', [result]);
                        resolve(result);
                    }
                },
                error: (error) => {
                    console.error('Notes data could not be parsed.', error);
                    reject(error);
                }
            });
        });
    };

    return instance;
});
