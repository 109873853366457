import injector from '@/scaffold/injector';

import userModel from '@/apps/myfooby/user-model';
import cookbooksModel from '@/apps/myfooby/cookbooks-model';
import bookmarksModel from '@/apps/myfooby/bookmarks-model';
import externalRecipeService from '@/apps/myfooby/external-recipe-service';
import userShoppinglistService from '@/apps/shared/services/user-shoppinglist';
import userShoppinglistIngredientService from '@/apps/shared/services/user-shoppinglist-ingredient';

export const startup = injector.resolve(['Globals', 'History'], (Globals, History) => {
    // create global env variables
    Globals.isRecipe = document.querySelector('.t5-recipe') !== null;
    Globals.isMyFooby = document.querySelector('.t13-myfooby') !== null;
    Globals.isSearch = document.querySelector('.t12-searchresults') !== null;

    // create and store models and services
    Globals.user = userModel();
    Globals.cookbooks = cookbooksModel();
    Globals.bookmarks = bookmarksModel();
    Globals.externalRecipe = externalRecipeService();
    Globals.shoppingLists = userShoppinglistService();
    Globals.shoppingListIngredients = userShoppinglistIngredientService();
    Globals.history = History;

    // start fetching user
    Globals.user.fetch();
});
