import injector from '@/scaffold/injector';

export const startup = injector.resolve(['Globals', 'Pubsub', 'Cookies'], async (Globals, Pubsub, Cookies) => {
    if (Globals.isRecipeSite) {
        return;
    }

    const clickTeaserGroupHandler = (instance, index, url) => {
        if (instance.getCurrentSearchParams && url && index > -1) {
            const params = instance.getCurrentSearchParams();

            Cookies.set('prevNextStartUrl', location.href);
            Cookies.set('prevNextParams', JSON.stringify({
                ...params,
                lang: params.lang || 'de'
            }));

            Cookies.set('prevNextCurrentIndex', index);
            Cookies.set('prevNextCurrentUrl', url);
        }
    };

    Pubsub.subscribe('teaserGroup.clicked', clickTeaserGroupHandler);
});
