

var getClosestNumber = function(num, arr) {
    var curr = arr[0];
    var diff = Math.abs (num - curr);
    for (var val = 0; val < arr.length; val++) {
        var newdiff = Math.abs (num - arr[val]);
        if (newdiff < diff) {
            diff = newdiff;
            curr = arr[val];
        }
    }
    return curr;
};

export default function(value) {

    var floored = Math.floor(value);
    var remainder = value % 1;
    var valueString = '';
    var fraction = '';
    var closest = 0;

    if (floored > 0) {
        valueString = floored + '';
    }

    // When 100 or more, don't show fractions
    if (floored > 99) {

        // Find the fraction closest to the given remainder
        closest = getClosestNumber(remainder, [0, 1]);
    } else if (floored > 9) { // When 10 or more, only show 1/2 fractions

        // Find the fraction closest to the given remainder
        closest = getClosestNumber(remainder, [0, 0.5, 1]);
    } else {

        // Find the fraction closest to the given remainder
        closest = getClosestNumber(remainder, [
            0,
            0.20,
            0.25,
            0.33,
            0.5,
            0.66,
            0.75,
            1
        ]);
    }

    // &frac12; => ½

    if (closest === 0) {
        valueString = floored;
    } else if (closest === 0.20) {
        fraction = '⅕'; // &frac15;
    } else if (closest === 0.25) {
        fraction = '¼'; // &frac14;
    } else if (closest === 0.33) {
        fraction = '⅓'; // &frac13;
    } else if (closest === 0.5) {
        fraction = '½'; // &frac12;
    } else if (closest === 0.66) {
        fraction = '⅔'; // &frac23;
    } else if (closest === 0.75) {
        fraction = '¾'; // &frac34;
    } else if (closest === 1) {
        valueString = floored + 1;
    }

    if (floored > 0 && fraction !== '') {
        valueString = floored + ' ' + fraction;
    } else if (fraction !== '') {
        valueString = fraction;
    }

    return valueString;
};
