import Globals from '@/scaffold/globals';
import MiniModal from '@/libs/mini-modal';
import ShoppingList from '@/apps/main/modules/shoppinglist';
import NewsletterOverlay from '@/apps/main/modules/newsletter-overlay';
import BookmarkModal from '@/apps/myfooby/bookmark-modal';

var instance = {};
let bookmarkModal = null;

instance.openBookmarkModal = (bookmarkOptions) => {
    MiniModal.open('modal-bookmark', {
        onBeforeOpen: async function (modalInstance) {
            await Globals.user.checkSSOSessionState();

            bookmarkModal = BookmarkModal(modalInstance.modal, {
                ...bookmarkOptions,
                modalInstance
            });
        },
        onAfterClose: function () {
            bookmarkModal.destroy();
        }
    });
};

instance.openModal = (modalId, options = {}, clickEvent = null) => {
    /*
        Currently only the "modal-login" is login dependent. It is possible, that other modals yould be dependend on a
        logged in user. One solution would be to have an option for that, the other one would be to use a naming convention
        to differentiate both types.
    */

    if (modalId === 'modal-login' && Globals.user.isLoggedIn()) {
        return true;
    }

    if (clickEvent) {
        clickEvent.preventDefault();
    }

    if (modalId === 'modal-login') {
        Globals.user.checkSSOSessionState().then(({
            error
        }) => {
            if (error) {
                MiniModal.open(modalId, options);
            } else {
                clickEvent.currentTarget.click();
            }
        });
    } else if (modalId === 'modal-newsletter') {
        // newsletter modal relies on the async newsletter overlay instance beeing initialized
        NewsletterOverlay.init().then(() => {
            MiniModal.open('modal-newsletter', options);
        });
    } else {
        if (modalId === 'modal-shoppinglist') {
            // can't be the onBeforeOpen-Callback because it renders Elements into the modal, which will be needed in the modal initiation
            ShoppingList.onBeforeOpen(MiniModal);
        }

        MiniModal.open(modalId, options);
    }
};

instance.closeModal = () => {
    MiniModal.close();
};

export default instance;
