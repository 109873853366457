

// dependencies
var hogan = require('hogan.js'),
    render = require('@/libs/render').default,
    globals = require('@/scaffold/globals').default,
    breakpoint = require('@/libs/breakpoint').default,
    storage = require('@/libs/localstorage').default,
    forEach = require('@/helpers/helper').default.forEach,
    serialize = require('@/helpers/helper').default.solrSerialize,
    customEvent = require('@/helpers/helper').default.customEvent,
    appendHtml = require('@/helpers/helper').default.appendHtml,
    pubsub = require('@/scaffold/pubsub').default,
    closest = require('@/helpers/helper').default.closest,
    stickyfill = require('stickyfilljs'),

    modules = {
        miniModal: require('@/libs/mini-modal').default,
        accordion: require('@/apps/main/modules/accordion').default,
        sno: require('@/apps/myfooby/modules/small-navigation-overlay').default,
        button: require('@/apps/search/modules/button').default,
        view: require('@/apps/main/plugins/view-controller').default,
        searchbar: require('@/apps/search/modules/searchbar-controller').default,
        externalRecipe: require('@/apps/myfooby/external-recipe').default,
        sortBar: require('@/apps/main/plugins/sortbar').default
    },

    views = {
        cookbookAddView: require('@/apps/myfooby/modules/cookbook-add-view').default
    };

// vars
var instance = {},
    defaults = {
        container: '.t13-myfooby',
        sno: '.t13-myfooby__sidebar',
        snoCol: '.t13-myfooby__sidebar-col',
        externalRecipe: '.external-recipe',
        sortBar: '[data-sort-bar]',
        sortOrder: '[data-sort-order]',
        cookbookMenuOpen: '[data-myfooby-cookbook-menu-open]',

        triggers: {
            call: 'data-call',
            openProfile: 'data-open-profile',
            filterGroceryList: 'data-ingredient-filter',
            openExternalRecipe: 'data-external-recipe-open'
        },

        accordion: {
            sectionClass: '',
            triggerClass: '',
            contentClass: '',
            triggerActiveClass: 't13-myfooby__sidebar-section-title--active',
            contentOpenClass: 't13-myfooby__sidebar-section--open'
        }
    },
    settings,
    container,
    sno,
    http,
    sortBar,
    paramsChangedByPopState = false,
    defaultNum = 12,
    sort = 'latest',
    accordion,
    externalRecipe,
    newTeaserResultIsCookbook,
    newTeaserResultIsCookbookMenu,
    newTeaserResultIsCookbookLikes,
    newTeaserResultIsCookbookSwipes,
    elCookbookMenuOpen,
    elSortbar,
    activeCookbookId,
    state = '',
    storedBookmarks,
    nextStart = 0,
    lastOptions = {},

    shouldReplaceHistory = function(newParams) {
        const currentParams = globals.history.getParams();

        if (!newParams.filters || !currentParams.filters) {
            return false;
        }

        if (newParams.sort !== currentParams.sort) {
            return false;
        }

        if (Object.keys(newParams.filters).length === Object.keys(currentParams.filters).length) {
            // check if navigated inside same filters
            for (let key in newParams.filters) {
                if (newParams.filters.hasOwnProperty(key)) {
                    if (newParams.filters[key] !== currentParams.filters[key]) {
                        return false;
                    }
                }
            }

            return true;
        }

        return false;
    },

    setActiveFilterByParams = function(newParams = {}) {
        let filter = container.querySelector('[data-call-default]');

        if (newParams.page === 'profile') {
            filter = null;
        } else if (newParams.page === 'grocerylist') {
            filter = container.querySelector(`[data-ingredient-filter="${newParams.recipeId ? newParams.recipeId : ''}"]`);
        } else if (newParams.filters) {
            const dataCallAttr = JSON.stringify(newParams.filters)
                // add trailing space
                .replace(/({|":|",)/g, '$1 ')
                // add leading space
                .replace(/(})/g, ' $1')
                // remove quotation marks from numbers and booleans
                .replace(/"(\d+|true|false)"/g, '$1')
                // replace quotation marks with single quotation marks
                .replace(/"/g, '\'');

            const paramFilter = container.querySelector(`[data-call="${dataCallAttr}"]`);

            if (paramFilter) {
                filter = paramFilter;
            }
        }

        changeActiveState(filter);
    },

    changeActiveState = function(element) {
        container.querySelectorAll('[data-call-state="2"]').forEach(function(element) {
            element.setAttribute('data-call-state', '1');
        });

        if (element !== undefined && element instanceof Element) {
            element.setAttribute('data-call-state', '2');

            const accordionSection = element.closest('[data-ni-accordion-section]');

            // open accordion section if not already open
            if (accordionSection && !accordionSection.classList.contains(defaults.accordion.contentOpenClass)) {
                const trigger = accordionSection.querySelector('[data-ni-accordion-trigger]');

                if (trigger) {
                    trigger.dispatchEvent(customEvent('click'));
                }
            }

            newTeaserResultIsCookbook = element.hasAttribute('data-cookbook-id');
            newTeaserResultIsCookbookMenu = element.hasAttribute('data-cookbook-menu');
            newTeaserResultIsCookbookLikes = element.hasAttribute('data-cookbook-likes');
            newTeaserResultIsCookbookSwipes = element.hasAttribute('data-cookbook-swipes');

            if (newTeaserResultIsCookbook) {
                activeCookbookId = element.getAttribute('data-cookbook-id');
            } else {
                activeCookbookId = '';
            }

            container.querySelector('[data-myfooby-teaser-title]').innerHTML = element.querySelector('[data-call-title]').innerHTML;
        }
    },

    initMediumUp = function() {
        accordion.init();
    },

    destroyMediumUp = function() {
        accordion.destroy();
    },

    initSmall = function() {
        sno.init();
    },

    destroySmall = function() {
        sno.destroy();
    },

    resizeHandler = function() {

        if(state === '' && breakpoint.curBreakpoint === 'small') {
            initSmall();
        } else if(state === '' && breakpoint.curBreakpoint !== 'small') {
            initMediumUp();
        } else if (state === 'small' && breakpoint.curBreakpoint !== 'small') {
            destroySmall();
            initMediumUp();
        } else if (state !== 'small' && breakpoint.curBreakpoint === 'small') {
            destroyMediumUp();
            initSmall();
        }

        state = breakpoint.curBreakpoint;

        if (state === 'small') {
            sno.updateLevels();
        }
    },

    showProfileHandler = function(e, initial = false) {
        if (e) {
            e.preventDefault();
        }

        if (instance.view.getType() === 'teaser') {
            instance.view.updateView('profile', '', true);
        } else if (instance.view.getType() !== 'profile') {
            instance.view.updateView('profile');
        }

        if (!paramsChangedByPopState && !initial) {
            globals.history.pushParams({ page: 'profile' }, null, true);
        }

        // reset flag
        paramsChangedByPopState = false;

        changeActiveState(this);
    },

    showGroceryListHandler = function (e, initial = false) {
        let recipeId = '';

        if (e) {
            e.preventDefault();
            const element = e.delegateTarget;

            // Do nothing if the trigger already is active
            if (element.getAttribute('data-call-state') === '2') {
                return false;
            }

            recipeId = element.getAttribute('data-ingredient-filter');

            // change new active state
            changeActiveState(element);
        }

        if (instance.view.getType() === 'teaser') {
            instance.view.updateView('grocerylist', '', true);
        } else if (instance.view.getType() !== 'grocerylist') {
            instance.view.updateView('grocerylist');
        }

        if (!paramsChangedByPopState && !initial) {
            globals.history.pushParams({ page: 'grocerylist', recipeId: recipeId ? recipeId : '' }, null, true);
        }

        // reset flag
        paramsChangedByPopState = false;
    },

    syncronizeSearchvarInputFields = function(event) {
        instance.searchbarMediumUp.setValue(event.detail.value);
        instance.searchbarSmall.setValue(event.detail.value);
    },

    searchSubmitHandler = function(event) {

        instance.searchbarMediumUp.blurInput();
        instance.searchbarSmall.blurInput();

        // change new active state
        changeActiveState();

        if (state === 'small') {
            sno.closeOverlay();
        }

        container.querySelector('[data-myfooby-teaser-title]').innerHTML = container.querySelector('[data-myfooby-teaser-title-default]').getAttribute('data-myfooby-teaser-title-default');
        newTeaserResultIsCookbook = false;
        newTeaserResultIsCookbookMenu = false;
        newTeaserResultIsCookbookLikes = false;
        newTeaserResultIsCookbookSwipes = false;
        activeCookbookId = '';

        search(0, { query: event.detail.value });
    },

    /**
     * Handle response of the search
     *
     * @param {object} response of the ajax call
     */
    responseHandler = function(response) {

        var clean;

        if (response.resultcounts.start === 0) {
            clean = true;
        } else {
            clean = false;
        }

        instance.view.updateView('teaser', render.teaser(response.results, { initialwith: 4, xs: 12, sm: 4, md: 3 }, { xs: 12, sm: 6, md: 4, hgutter: true }, false, newTeaserResultIsCookbookMenu), false, clean, function() {

            if (clean) {
                const cookbookTooltipTriggers = container.querySelectorAll('[data-cookbook-tooltip-trigger]');
                const cookbookTooltipEdit = container.querySelector('[data-myfooby-cookbook-edit]');
                const cookbookTeaserCountHeadline = container.querySelector('[data-myfooby-teaser-count-total-headline]');
                const cookbookCourses = container.querySelector('[data-myfooby-courses]');
                const cookbookTeaserWrapper = container.querySelector('[data-myfooby-teaser-wrapper]');

                if (newTeaserResultIsCookbook && !newTeaserResultIsCookbookLikes && !newTeaserResultIsCookbookSwipes) {
                    cookbookTooltipTriggers.forEach((cookbookTooltipTrigger) => {
                        cookbookTooltipTrigger.style.display = 'inline-flex';
                        cookbookTooltipTrigger.setAttribute('data-cookbook-id', activeCookbookId);
                    });
                } else {
                    cookbookTooltipTriggers.forEach((cookbookTooltipTrigger) => {
                        cookbookTooltipTrigger.style.display = 'none';
                        cookbookTooltipTrigger.setAttribute('data-cookbook-id', '');
                    });
                }

                if (newTeaserResultIsCookbookMenu) {
                    const cookbookMenu = activeCookbookId && globals.cookbooks.getById(activeCookbookId);

                    if (cookbookTeaserWrapper) {
                        cookbookTeaserWrapper.classList.add('t13-myfooby__teaser-wrapper--cookbook-menu');
                    }

                    if (cookbookTeaserCountHeadline) {
                        cookbookTeaserCountHeadline.style.display = 'none';
                    }

                    if (elSortbar) {
                        elSortbar.style.display = 'none';
                    }

                    if (elCookbookMenuOpen) {
                        elCookbookMenuOpen.style.display = 'block';
                        const baseUrl = elCookbookMenuOpen.getAttribute('data-myfooby-cookbook-menu-open');

                        if (cookbookMenu) {
                            elCookbookMenuOpen.setAttribute('href',
                                `${baseUrl}?cookbook=${cookbookMenu.cookbook_id}`
                            );
                        }
                    }

                    if (cookbookTooltipEdit) {
                        cookbookTooltipEdit.style.display = 'none';
                    }

                    if (cookbookCourses) {
                        cookbookCourses.style.display = 'inline-block';
                    }
                } else {
                    if (cookbookTeaserWrapper) {
                        cookbookTeaserWrapper.classList.remove('t13-myfooby__teaser-wrapper--cookbook-menu');
                    }

                    if (cookbookTeaserCountHeadline) {
                        cookbookTeaserCountHeadline.style.display = 'inline-block';
                    }

                    if (elSortbar) {
                        elSortbar.style.display = 'block';
                    }

                    if (elCookbookMenuOpen) {
                        elCookbookMenuOpen.style.display = 'none';
                    }

                    if (cookbookCourses) {
                        cookbookCourses.style.display = 'none';
                    }
                }

            }

            // Render the new Totalcount (Not inside of cleaning, because this doesn't have to my in sync, e.g. multiple tabs when removing a bookmark in one tab and s forth)
            container.querySelectorAll('[data-myfooby-teaser-count-total]').forEach(count => count.innerHTML = response.resultcounts.all);

            if ('next_start' in response.resultcounts) {

                nextStart = response.resultcounts.next_start;

                // Shows load more button (if not already visible)
                instance.button.showButton();
            } else {

                // Hides load more button (if not already hidden)
                instance.button.hideButton();
            }
        });
    },

    /**
     * Execute a search
     * Use start for paging
     *
     * @param {integer} start for paging - optional
     * @param {object} options of the search - optional
     * @param {boolean} initial whether intial search
     */
    search = function(start, options, initial = false) {
        if (http) {
            http.abort();
        }

        http = new XMLHttpRequest();
        start = start || 0;
        lastOptions = Object.assign({
            treffertyp: 'rezepte'
        }, options || lastOptions);

        var params = {
            start: start,
            filters: lastOptions,
            sort: sort
        };

        var searchParams = Object.assign({}, lastOptions, {
            lang: store.lang,
            start: start,
            num: defaultNum,
            sort: sort
        });

        if (initial) {
            searchParams.start = 0;
            searchParams.num = start + defaultNum;
        }

        if (!paramsChangedByPopState) {
            if (initial || shouldReplaceHistory(params)) {
                globals.history.replaceParams(params, null, true);
            } else {
                globals.history.pushParams(params, null, true);
            }
        }

        // reset flag
        paramsChangedByPopState = false;

        http.onload = function(event) {
            responseHandler(JSON.parse(event.target.response));
        };

        http.open('GET', store.apis.userTeaser + '?' + serialize(searchParams));
        http.send();
    },

    /**
     * Calculate the counters for the navigation
     */
    calculateCounts = function() {

        var calls = document.querySelectorAll('[data-call]'),
            uniqueBookmarks = globals.bookmarks.getAllUnique(),
            call,
            count;

        forEach(calls, function(el) {
            const isCookbookMenu = el.getAttribute('data-cookbook-menu') !== null;
            call = JSON.parse(el.getAttribute('data-call').replace(/'/g, '"'));

            count = 0;

            // do not update when cookbook is a menu
            if (isCookbookMenu) {
                return;
            }

            switch (call.treffertyp) {
            case 'kochbuch':

                // TODO: This code is redundant
                count = globals.cookbooks.getBookmarksById(call.kochbuchid).length;
                break;
            case 'rezepte':
                if(call.hasOwnProperty('treffertyp_sub') && Array.isArray(call.treffertyp_sub)) {
                    count = uniqueBookmarks.filter(function(bookmark) {
                        return call.treffertyp_sub.indexOf(bookmark.treffertyp_sub) > -1;
                    }).length;
                } else if(call.hasOwnProperty('treffertyp_sub')) {
                    count = uniqueBookmarks.filter(function(bookmark) {
                        return bookmark.treffertyp_sub === call.treffertyp_sub;
                    }).length;
                } else if(call.hasOwnProperty('menuart')) {
                    count = uniqueBookmarks.filter(function(bookmark) {
                        return bookmark.hasOwnProperty('menuart') && bookmark.menuart === call.menuart;
                    }).length;
                    if (count === 0) {
                        el.style.display = 'none';
                    } else {
                        el.style.display = '';
                    }
                } else {
                    count = uniqueBookmarks.filter(function(bookmark) {
                        return ['rezept', 'rezeptliste', 'extern', 'own', 'littlefooby'].indexOf(bookmark.treffertyp_sub) > -1;
                    }).length;
                }
                break;
            case 'kochschule':
                if(call.hasOwnProperty('inhaltsart')) {
                    count = uniqueBookmarks.filter(function(bookmark) {
                        return bookmark.treffertyp_sub === call.inhaltsart;
                    }).length;
                } else {
                    count = uniqueBookmarks.filter(function(bookmark) {
                        return ['foodlexikon', 'howto', 'wissen'].indexOf(bookmark.treffertyp_sub) > -1;
                    }).length;
                }
                break;
            case 'storys':
                if(call.hasOwnProperty('inhaltsart')) {
                    count = uniqueBookmarks.filter(function(bookmark) {
                        return bookmark.treffertyp_sub === call.inhaltsart;
                    }).length;
                } else {
                    count = uniqueBookmarks.filter(function(bookmark) {
                        return ['story', 'thema'].indexOf(bookmark.treffertyp_sub) > -1;
                    }).length;
                }
            case 'foobyversum':
                if(call.hasOwnProperty('inhaltsart')) {
                    count = uniqueBookmarks.filter(function(bookmark) {
                        return bookmark.treffertyp_sub === call.inhaltsart;
                    }).length;
                } else {
                    count = uniqueBookmarks.filter(function(bookmark) {
                        return ['story', 'event', 'trend', 'cityguide', 'bookreview', 'foodscout'].indexOf(bookmark.treffertyp_sub) > -1;
                    }).length;
                }
            case 'kleinekochwelt':
                if(call.hasOwnProperty('inhaltsart')) {
                    count = uniqueBookmarks.filter(function(bookmark) {
                        return bookmark.treffertyp_sub === call.inhaltsart;
                    }).length;
                } else {
                    count = uniqueBookmarks.filter(function(bookmark) {
                        return ['lf-tricks', 'lf-wissen', 'lf-geschichten'].indexOf(bookmark.treffertyp_sub) > -1;
                    }).length;
                }
            default:

            }

            el.querySelector('[data-count]').innerHTML = count;
        });
    },

    /**
     * Prepare given cookbooks to later be rendered into the DOM
     *
     * @param {array} cookbooks to prepare
     * @return {promise} prepared cookbooks
     */
    prepCookbooks = function(cookbooks) {

        return new Promise(function(resolve) {

            cookbooks.forEach(function(cookbook) {

                if (cookbook.menu && cookbook.menu.recipeIds) {
                    cookbook.urlCount = Object.keys(cookbook.menu.recipeIds).reduce((previousValue, currentValue) => {
                        if (cookbook.menu.recipeIds[currentValue]) {
                            return previousValue + cookbook.menu.recipeIds[currentValue].length;
                        } else {
                            return previousValue;
                        }
                    }, 0);
                } else {
                    cookbook.urlCount = globals.cookbooks.getBookmarksById(cookbook['cookbook_id']).length || 0;
                }
            });

            // Resolve promise and return cookbooks
            resolve(cookbooks);
        });
    },

    /**
     * Render cookbooks into the DOM
     * Cookbooks are from the globals.cookbooks instance
     */
    renderCookbooks = function() {
        const menuTitle = document.querySelector('[data-myfooby-cookbook-menu-title]')
            .getAttribute('data-myfooby-cookbook-menu-title');

        var container = document.querySelector('[data-myfooby-cookbook-container]'),
            template = `
                <li
                    class="t13-myfooby__sidebar-section-content-list-item t13-myfooby__sidebar-section-content-list-item--cookbook {{#menu}}t13-myfooby__sidebar-section-content-list-item--cookbook-menu{{/menu}}"
                    data-cookbook-id="{{ cookbook_id }}"
                    {{#menu}}data-cookbook-menu{{/menu}}
                    {{#isLikeCookbook}}data-cookbook-likes{{/isLikeCookbook}}
                    {{#isSwipeCookbook}}data-cookbook-swipes{{/isSwipeCookbook}}
                    data-count="{{urlCount}}"
                    data-sno-close
                    data-call-state="1"
                    data-call="{ \'treffertyp\': \'kochbuch\', \'kochbuchid\': {{ cookbook_id }} }"
                >
                    <span class="t13-myfooby__sidebar-section-content-list-item-cookbooktext">
                        <span data-call-title>
                            {{^isLikeCookbook}}
                                {{^isSwipeCookbook}}
                                    {{ title }}
                                {{/isSwipeCookbook}}
                            {{/isLikeCookbook}}

                            {{#isLikeCookbook}}
                                {{ likeTitle }}
                            {{/isLikeCookbook}}

                            {{#isSwipeCookbook}}
                                {{ swipeTitle }}
                            {{/isSwipeCookbook}}
                        </span>
                        <span class="t13-myfooby__sidebar-section-content-list-item-count" data-count>
                            {{#menu}}
                                <span class="t13-myfooby__sidebar-section-content-list-item-label">
                                    {{ menuTitle }}
                                </span>
                            {{/menu}}
                            {{^menu}}
                                {{ urlCount }}
                            {{/menu}}
                        </span>
                    </span>
                    <span class="t13-myfooby__sidebar-section-content-list-item-cookbookicon">
                        <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                            {{#isNormalCookbook}}
                                <path d="M17.5 3H5v18h12.5c.4 0 .75-.15 1.05-.45.3-.3.45-.65.45-1.05v-15c0-.4-.15-.75-.45-1.05-.3-.3-.65-.45-1.05-.45Zm-5.55 1h3.1v4.15L13.5 7.1l-1.55 1.05V4ZM6 4h1v16H6V4Zm12 15.5c0 .1333-.05.25-.15.35-.1.1-.2167.15-.35.15H8V4h3v6l2.5-1.75L16 10V4h1.5c.1333 0 .25.05.35.15.1.1.15.2167.15.35v15Z" fill="currentColor"/>
                            {{/isNormalCookbook}}

                            {{#isLikeCookbook}}
                                <path d="M7.85 5c.6667 0 1.2833.1667 1.85.5.6.3333 1.0833.8 1.45 1.4L12 8.3l.85-1.4c.4333-.7667 1.05-1.3 1.85-1.6.8333-.3333 1.6667-.3833 2.5-.15.8333.2333 1.5167.7 2.05 1.4.5333.7.7833 1.5.75 2.4-.0333 1.7667-.6167 3.3167-1.75 4.65-1.7667 2.1333-3.85 3.8833-6.25 5.25-2.3667-1.4333-4.4333-3.2-6.2-5.3C4.6667 12.2167 4.0667 10.6667 4 8.9c0-1.0667.3667-1.9833 1.1-2.75C5.8667 5.3833 6.7833 5 7.85 5Zm8.3-1c-.8333 0-1.6333.2167-2.4.65-.7333.4333-1.3167 1.0167-1.75 1.75-.4333-.7333-1.0333-1.3167-1.8-1.75C9.4667 4.2167 8.6833 4 7.85 4c-1.3333 0-2.4833.4833-3.45 1.45C3.4667 6.4167 3 7.5667 3 8.9c.0667 2 .7333 3.75 2 5.25 2 2.3667 4.3333 4.3167 7 5.85l1.3-.75c1.0333-.6333 1.9833-1.3167 2.85-2.05 1.2333-1 2.2-2 2.9-3 .6333-.7333 1.1167-1.55 1.45-2.45.3333-.9333.5-1.8833.5-2.85 0-1.3333-.4833-2.4667-1.45-3.4-.9333-.9667-2.0667-1.4667-3.4-1.5Z" fill="currentColor"/>
                            {{/isLikeCookbook}}

                            {{#isSwipeCookbook}}
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M18.519 10.077c.204-.158.477-.248.772-.248.703 0 1.27.563 1.27 1.262v.541c0 .947-.044 1.894-.135 2.84a29.11 29.11 0 0 1-.273 2.029c-.272 1.51-1.452 4.057-4.38 4.057-2.929 0-4.608-1.87-4.608-1.87L8.6 16.275 6.83 14.36a1.544 1.544 0 0 1 .09-2.164 1.57 1.57 0 0 1 2.18.09l1.747 1.893V3.63c0-.653.522-1.172 1.18-1.172.659 0 1.18.519 1.18 1.172v6.875-1.15c0-.743.613-1.352 1.362-1.352.75 0 1.362.609 1.362 1.353v1.15-.812c0-.722.59-1.285 1.294-1.285.727 0 1.294.586 1.294 1.285v1.487M22.91 4.48a30.294 30.294 0 0 0-7.503-1.718"/>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M19.905 6.272 22.91 4.48l-1.792-3.004M1.09 4.48a30.294 30.294 0 0 1 7.503-1.718"/>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M4.095 6.272 1.09 4.48l1.792-3.004"/>
                            {{/isSwipeCookbook}}

                            {{#menu}}
                                <path d="M19.25 21.5H4.75V3h14.5v18.5Z" stroke="currentColor"/>
                                <path d="m6.8501 12.236 1.8647 1.8647a1.581 1.581 0 1 0 2.2352-2.236L9.0853 10M7.9672 11.1188l7.8382 7.8382M13.678 14.0999 8.7771 19" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                <path clip-rule="evenodd" d="M14.4231 11.1188a1.5807 1.5807 0 0 1 2.6443.7085 1.5806 1.5806 0 0 1-.4091 1.5267l-.7451.7459c-.6213.5932-1.6027.5817-2.21-.026-.6072-.6077-.618-1.5891-.0243-2.21l.7442-.7451Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                <path stroke="currentColor" d="M10 5.5h4M8 8h8"/>
                            {{/menu}}
                        </svg>
                    </span>
                </li>
            `;

        // Clean the container containing the cookbooks
        container.innerHTML = '';

        prepCookbooks(globals.cookbooks.getAll())
            .then(function(cookbooks) {

                cookbooks.forEach(function(cookbook) {
                    const isLikeCookbook = cookbook.title === store.cookbookLikes;
                    const isSwipeCookbook = cookbook.title === store.cookbookSwipe;

                    // Render cookbook into template
                    appendHtml(container, hogan.compile(template).render({
                        ...cookbook,
                        isNormalCookbook: !isLikeCookbook && !isSwipeCookbook && !cookbook.menu,
                        isLikeCookbook,
                        isSwipeCookbook,
                        likeTitle: store.translations['trans:likecookbook'],
                        swipeTitle: store.translations['trans:swipecookbook'],
                        menuTitle: menuTitle.replace('{coursesCount}', cookbook.urlCount)
                    }));
                });

                pubsub.publish('cookbooks.rendered');
            });
    },

    /**
     * Render fetched userdata into the DOM
     */
    renderUserdata = function() {
        forEach(container.querySelectorAll('[data-user-suppercard-id]'), function(element) {
            element.innerHTML = globals.user.get('supercard_id');
        });

        forEach(container.querySelectorAll('[data-user-suppercard-number]'), function(element) {
            element.innerHTML = globals.user.get('supercard_nr');
        });

        forEach(container.querySelectorAll('[data-user-firstname]'), function(element) {
            element.innerHTML = globals.user.get('firstname');
        });

        forEach(container.querySelectorAll('[data-user-lastname]'), function(element) {
            element.innerHTML = globals.user.get('lastname');
        });
    },

    /**
     * Handle click events on search triggers
     * Calls a new Serach if the clicked delegateTarget is not already active
     *
     * @param {event} e clickevent
     * @return {boolean} false if the delegateTarget is already active
     */
    callHandler = function(e) {
        var element = e.delegateTarget;

        // Do nothing if the trigger already is active
        if(element.getAttribute('data-call-state') === '2') {
            return false;
        }

        // change new active state
        changeActiveState(element);

        var params = JSON.parse(element.getAttribute('data-call').replace(/'/g, '"'));

        search(0, params);
    },

    /**
     * Open extern recipe modal
     *
     * @param {event} e clickevent
     */
    openExternRecipeHandler = function(e) {
        e.preventDefault();

        var target = e.delegateTarget,
            mode = target.getAttribute(settings.triggers.openExternalRecipe),
            prefillData = target.hasAttribute('data-external-recipe-data') ?
                JSON.parse(target.getAttribute('data-external-recipe-data')) : false;

        // Fetch the CookbookIds for the bookmark
        if (prefillData) {
            prefillData['cookbook_ids'] = globals.bookmarks.getAll().filter(function(bookmark) {
                return bookmark.hasOwnProperty('target_url') && bookmark.target_url === prefillData.target_url;
            }).map(function(bookmark) {
                return bookmark.cookbook_id;
            });
        }

        // open external recipe overlay manually, because we need to add callbacks
        modules.miniModal.open('modal-external-recipe', {
            onBeforeOpen: function (miniModalInstance) {

                // Instantiate overlay for external recipe
                externalRecipe = modules.externalRecipe(document.querySelector(settings.externalRecipe), {
                    miniModalInstance: miniModalInstance,
                    mode: mode,
                    data: prefillData
                });
            },
            onAfterClose: function () {
                externalRecipe.destroy();
            }
        });
    },

    bindEventHandler = function() {

        breakpoint.initialized.then(() => {
            window.addEventListener('optimizedResize', resizeHandler, false);
        });

        // Add listener for event handler delegation
        container.addEventListener('click', function(e) {
            var trigger;

            // Check if trigger is found
            if ((trigger = closest(e.target, '[' + settings.triggers.call + ']')) !== undefined) {

                // Set the delegate Target
                e.delegateTarget = trigger;

                callHandler(e);
            } else if ((trigger = closest(e.target, '[' + settings.triggers.openProfile + ']')) !== undefined) {

                // Set the delegate Target
                e.delegateTarget = trigger;

                showProfileHandler(e);
            } else if ((trigger = closest(e.target, '[' + settings.triggers.filterGroceryList + ']')) !== undefined) {
                // Set the delegate Target
                e.delegateTarget = trigger;

                showGroceryListHandler(e);
            } else if ((trigger = closest(e.target, '[' + settings.triggers.openExternalRecipe + ']')) !== undefined) {

                // Set the delegate Target
                e.delegateTarget = trigger;

                openExternRecipeHandler(e);
            }
        });

        container.addEventListener('searchbar.submit', searchSubmitHandler);
        container.addEventListener('searchbar.blur', syncronizeSearchvarInputFields);
    },

    handleSortChange = function (newSort) {
        sort = newSort;
        search();
    },

    loadPage = function () {
        // setting the main container
        container = document.querySelector(settings.container);

        if (container instanceof Element) {

            // Factory of the accordion
            accordion = modules.accordion.create(container, settings.accordion);

            // Factory of the small navigation overlay
            sno = modules.sno.call(container.querySelector(settings.sno));

            // init stickyfill for sidebar column
            stickyfill.add(container.querySelector(settings.snoCol));

            // Due to having two different searchbars, they need to be instantiate both
            if (container.querySelectorAll('.searchbar--myfooby-medium-up').length > 0) {
                instance.searchbarMediumUp = modules.searchbar(container, { searchbarContainer: '.searchbar--myfooby-medium-up' });
            }
            if (container.querySelectorAll('.searchbar--myfooby-small-only').length > 0) {
                instance.searchbarSmall = modules.searchbar(container, { searchbarContainer: '.searchbar--myfooby-small-only' });
            }

            // SortBar factory
            elSortbar = document.querySelector(settings.sortBar);
            sortBar = modules.sortBar(container, {active: 'latest', onChange: handleSortChange});

            elCookbookMenuOpen = document.querySelector(settings.cookbookMenuOpen);

            container.addEventListener('click', function(e) {
                const wrapper = e.target.closest('[data-myfooby-teaser-wrapper]');

                if (wrapper) {
                    const teasers = Array.from(wrapper.querySelectorAll('.teaser'));
                    const teaser = e.target.closest('.teaser');
                    pubsub.publish('teaserGroup.clicked', [instance, teasers.indexOf(teaser)]);
                }
            });

            // Instantiation of the view
            instance.view = modules.view.call(container, {
                activeType: 'teaser',
                containers: [
                    {
                        type: 'teaser',
                        wrapper: '.t13-myfooby__teaser-wrapper',
                        content: '.t13-myfooby__teaser-container',
                        onAfterAdd: function() {
                            this.content.dispatchEvent(customEvent('ni-equalizer.update', {}));
                            pubsub.publish('tooltip.init');
                            pubsub.publish('likes.bindTrigger');
                            pubsub.publish('likes.loadLikes');
                            pubsub.publish('likes.setActives');

                            pubsub.publish('teasers.added');
                            pubsub.publish('myFooby.teasers.added');

                            // add delay, because dom can take a little time to append the teasers
                            setTimeout(function () {
                                pubsub.publish('teasers.updateStates', [true]);
                            }, 200);
                        }
                    },
                    {
                        type: 'profile',
                        wrapper: '.t13-myfooby__profile-wrapper',
                        content: '.t13-myfooby__profile-content'
                    },
                    {
                        type: 'grocerylist',
                        wrapper: '.t13-myfooby__grocerylist-wrapper',
                        content: '.t13-myfooby__grocerylist-content'
                    }
                ]
            });

            instance.button = modules.button(container);
            instance.button.subscribe('click', function() {
                search(nextStart);
            });

            // Instantiation of the cookbookAddView
            views.cookbookAddView(globals.cookbooks, container);


            // Subscribers

            // A cookbook got added
            globals.cookbooks.subscribe('created', function() {
                renderCookbooks();
            });

            // A cokbook got update
            globals.cookbooks.subscribe('updated', function(cookbook) {
                renderCookbooks();

                // Update title, since the active cookbook was updated
                container.querySelector('[data-myfooby-teaser-title]').innerHTML = cookbook.title;
            });

            // A cokbook got deleted
            globals.cookbooks.subscribe('deleted', function(cookbook) {
                renderCookbooks();

                globals.bookmarks.removeBookmarksByCookbookId(cookbook.cookbook_id);
                calculateCounts();

                // Navigate away, since only the activ cookbook can be removed (and was)
                callHandler({
                    delegateTarget: container.querySelector('[data-call-default]')
                });
            });

            // Some bookmark got created and the counts have to be adjusted
            globals.bookmarks.subscribe('created', function() {
                calculateCounts();
            });

            // Some bookmark got added and the counts have to be adjusted
            globals.bookmarks.subscribe('added', function() {
                calculateCounts();
            });

            // Some bookmark got updated and the counts have to be adjusted
            globals.bookmarks.subscribe('updated', function() {
                calculateCounts();
            });

            // Some bookmark got changed and the counts have to be adjusted
            globals.bookmarks.subscribe('changed', function() {
                calculateCounts();
            });

            // Some bookmark got deleted
            globals.bookmarks.subscribe('deleted', function() {
                // Trigger a new search, to clean the removed teaser
                search();
                calculateCounts();
            });

            // Some bookmark got removed
            globals.bookmarks.subscribe('removed', function() {
                // Trigger a new search, to clean the removed teaser
                search();
                calculateCounts();
            });

            globals.externalRecipe.subscribe('created', function () {
                // Trigger a new search, to show the created teaser
                search();
                calculateCounts();
            });

            globals.externalRecipe.subscribe('updated', function () {
                // Trigger a new search, to show the edited teaser correctly
                search();
                calculateCounts();
            });

            globals.externalRecipe.subscribe('deleted', function () {
                // Trigger a new search, to show the edited teaser correctly
                search();
                calculateCounts();
            });

            globals.history.subscribe('params.changed', function(params, previousParams, options) {
                paramsChangedByPopState = false;

                if (options.popstate) {
                    paramsChangedByPopState = true;
                    setActiveFilterByParams(params);

                    if (params['page'] === 'profile') {
                        showProfileHandler();
                    } else if (params['page'] === 'grocerylist') {
                        showGroceryListHandler();
                    } else {
                        let start = 0;

                        if (params['start']) {
                            start = parseInt(params['start']);
                        }

                        if (params['sort']) {
                            sort = params['sort'];
                            sortBar.setActiveElement(sort);
                        }

                        search(start, params.filters, true);
                    }
                } else if (options.push) {
                    window.scrollTo(0, 0);
                }
            });

            // Rendering stuff into the template
            renderCookbooks();
            renderUserdata();
            calculateCounts();

            breakpoint.initialized.then(() => {
                // initial call after page load
                resizeHandler();
            });

            // Eventhandler gets added last, due to low priority
            bindEventHandler();

            let start = 0;
            let currentParams = globals.history.getParams();

            if (currentParams['page'] === 'profile') {
                showProfileHandler(null, true);
            } else if (currentParams['page'] === 'grocerylist') {
                showGroceryListHandler(null, true);

                // delay setting the active filter until shopping lists are rendered
                const hook = pubsub.subscribe('ingredientFilters.updated', function () {
                    pubsub.unsubscribe(hook);

                    requestAnimationFrame(function () {
                        requestAnimationFrame(function () {
                            setActiveFilterByParams(currentParams);
                        });
                    });
                });
            } else {
                if (currentParams['start']) {
                    start = parseInt(currentParams['start']);
                }

                if (currentParams['sort']) {
                    sort = currentParams['sort'];
                    sortBar.setActiveElement(sort);
                }

                if (currentParams.filters && currentParams.filters.treffertyp === 'kochbuch') {
                    // delay setting the active filter and searching until cookbooks are rendered
                    const hook = pubsub.subscribe('cookbooks.rendered', function () {
                        pubsub.unsubscribe(hook);

                        requestAnimationFrame(function() {
                            requestAnimationFrame(function() {
                                setActiveFilterByParams(currentParams);
                                search(start, currentParams.filters, true);
                            });
                        });
                    });
                } else {
                    setActiveFilterByParams(currentParams);
                    search(start, currentParams.filters, true);
                }
            }
        }
    };

let userFechedHookid;
let userChangedHookid;

const userChanged = () => {

    // Check if the user is logged in
    if (globals.user.isLoggedIn()) {

        // Unsubscribe from changing
        globals.user.unsubscribe(userFechedHookid);
        globals.user.unsubscribe(userChangedHookid);

        // Check if there are stored bookmarks in localstorage
        storedBookmarks = storage.get('stored-bookmarks');

        if (storedBookmarks) {

            var counter = 0,
                amount,
                createdId,
                createdErrorId,
                unsubscribeEvents = function() {
                    globals.bookmarks.unsubscribe(createdId);
                    globals.bookmarks.unsubscribe(createdErrorId);
                },
                createdErrorCallback = function() {
                    unsubscribeEvents();
                    // show the page
                    loadPage();
                },
                createdCallback = function() {
                    counter++;

                    if (counter === amount) {
                        unsubscribeEvents();
                        storage.remove('stored-bookmarks');

                        // fetch user data again
                        globals.user.fetch(true);

                        // show the page
                        loadPage();
                    }
                };

            createdId = globals.bookmarks.subscribe('created', createdCallback);
            createdErrorId = globals.bookmarks.subscribe('created.error', createdErrorCallback);

            var storedBookmarksFiltered = storedBookmarks.filter(function(b) {

                var temp = globals.bookmarks.getByTargetUrl(b['url'] || false);
                temp = temp.concat(globals.bookmarks.getByRecipeId(Number(b['recipe_id'])));

                if (Array.isArray(temp) && temp.length > 0) {
                    return false;
                } else {
                    return true;
                }
            });

            if (storedBookmarksFiltered.length > 0) {
                amount = storedBookmarksFiltered.length;
                storedBookmarksFiltered.forEach(function (b) {
                    globals.bookmarks.createBookmark(
                        b['url'] || false,
                        Number(b['recipe_id']),
                        (b['own_recipe_id'] || b['own_recipe_id'] === 0) ? b['own_recipe_id'] : false,
                        '0',
                        b['treffertyp_sub'] || false,
                        b['menuart_id'] || false
                    );
                });
            } else {
                loadPage();
            }
        } else {
            loadPage();
        }
    } else {
        loadPage();
    }
};

/**
 * Initializes MyFooby
 *
 * @param {object} options to overwrite default settings
 * @return {object} instance
 */
instance.init = function(options) {
    settings = Object.assign({}, defaults, options);

    const container = document.querySelector(settings.container);

    if (container) {
        pubsub.publish('teasers.initialized', [[container]]);
        pubsub.publish('myFooby.teasers.initialized', [[container]]);
    }

    userFechedHookid = globals.user.subscribe('fetched', userChanged);
    userChangedHookid = globals.user.subscribe('changed', userChanged);

    return instance;
};

export default instance;
