import iziToast from 'izitoast';

export const notify = (message, options) => {
    iziToast.show({
        message,
        position: 'bottomCenter',
        theme: 'default', // default or default-image
        transitionIn: 'fadeInUp',
        transitionOut: 'fadeOutDown',
        animateInside: false,
        timeout: 4000,
        targetFirst: false,
        close: true,
        ...options
    });
};
