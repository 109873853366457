import injector from '@/scaffold/injector';

// TODO: refactor
import smoothscroll from '@/apps/main/modules/ni-smoothscroll';
import commonSearchTerms from '@/apps/main/plugins/common-search-terms';

import tokenController from '@/apps/search/modules/token-controller';
import searchbarController from '@/apps/search/modules/searchbar-controller';
import filterController from '@/apps/search/modules/filter-controller';

import backToTop from '@/apps/search/modules/back-to-top';
import button from '@/apps/search/modules/button';

export default injector.resolve(['Globals', 'Pubsub', 'Helper', 'Render', 'ViewController'], (Globals, Pubsub, Helper, Render, ViewController) => {

    var defaultNum = 12,
        http = null,
        httpSearch = null,
        currentSearchParams,
        paramsChangedByPopState = false,
        searchDefaults = {
            'query': '',
            'lang': store.lang,
            'treffertyp': '',
            'start': 0,
            'num': defaultNum
        };

    return function(container) {
        var instance = {},
            query = '',
            sort = '',
            queryChanged = false,
            treffertypChanged = false,
            nextStart = 0,
            // categoryEntrance,
            loaders,
            sortElement,
            dropdownLabels,
            dropdownValues,

            hideLoaders = function() {
                loaders.forEach((loader) => {
                    loader.style.display = 'none';
                });
            },

            showLoaders = function() {
                loaders.forEach((loader) => {
                    loader.style.display = 'block';
                });
            },

            shouldReplaceHistory = function(newParams) {
                var currentParams = Globals.history.getParams();

                // changed query
                if (newParams.query !==  currentParams.query) {
                    return false;
                }

                // changed sort
                if (newParams.sort !==  currentParams.sort) {
                    return true;
                }

                // navigated inside rezept category
                if (currentParams.filters.treffertyp === 'rezepte'
                    && newParams.filters.treffertyp === 'rezepte'
                ) {
                    return true;
                }

                // navigated inside same treffertyp and inhaltsart
                if (currentParams.filters.treffertyp === newParams.filters.treffertyp
                    && currentParams.filters.inhaltsart === newParams.filters.inhaltsart
                ) {
                    return true;
                }

                return false;
            },

            initFiltersByParams = function(newParams = {}, previousParams = {}) {
                resetTokens();

                query = newParams['query'] || '';
                sort = newParams['sort'] || '';

                query.replace(/\+/g, ' ');
                instance.searchbarController.searchbar.searchfield.value = query;
                queryChanged = previousParams.query !== query;

                let currentSortDropdownValueElement;

                if (sort) {
                    currentSortDropdownValueElement = sortElement.querySelector(`[data-sort-dropdown-value="${sort}"]`);
                } else {
                    currentSortDropdownValueElement = sortElement.querySelector('[data-sort-dropdown-default]')
                        || sortElement.querySelector('[data-sort-dropdown-value]');
                }

                if (currentSortDropdownValueElement) {
                    const text = currentSortDropdownValueElement.innerText;
                    dropdownLabels.forEach(label => {
                        label.innerText = text;
                    });

                    dropdownValues.forEach(valueElement => {
                        if (valueElement === currentSortDropdownValueElement) {
                            valueElement.setAttribute('data-sort-dropdown-current', '');
                        } else {
                            valueElement.removeAttribute('data-sort-dropdown-current');
                        }
                    });
                }

                if (!newParams.filters) {
                    instance.filterController.changeLevelOne('rezepte', true);
                    return true;
                }

                if (newParams.filters.treffertyp) {
                    if (previousParams.filters) {
                        treffertypChanged = previousParams.filters.treffertyp !== newParams.filters.treffertyp;
                    } else {
                        treffertypChanged = false;
                    }
                }

                if (newParams.filters.treffertyp) {
                    let treffertyp = newParams.filters.treffertyp;

                    if (treffertyp !== 'rezepte') {
                        let inhaltsart = newParams.filters.inhaltsart || 'alle';
                        let filter = document.querySelector(`[data-filter-id="${treffertyp}-inhaltsart-${inhaltsart}"]`);

                        if (filter) {
                            instance.filterController.filterLevelTwoRadio(filter, true);
                        }
                    }

                    instance.filterController.changeLevelOne(treffertyp, true);
                }

                // apply tokens
                for (let filterKey in newParams.filters) {
                    if (newParams.filters.hasOwnProperty(filterKey)) {
                        let filterValue = newParams.filters[filterKey];

                        if (Array.isArray(filterValue)) {
                            filterValue.forEach((filterId) => {
                                // filterId = filterId.replace(/"/g, '');
                                let filter = document.querySelector(`[data-filter-id="${filterId}"]`);

                                if (filter) {
                                    var token = {
                                        id: filter.getAttribute('data-filter-id'),
                                        title: filter.querySelector('[data-filter-title-element]').innerHTML,
                                        value: true
                                    };

                                    instance.tokenController.addToken(token, true);
                                    instance.filterController.addSearchParam(token, true);
                                }
                            });
                        }
                    }
                }

            },

            resetTokens = function() {
                var tokens = instance.tokenController.removeAllTokens(true);

                if (tokens instanceof Array && tokens.length > 0) {

                    // unchecks every checkbox in the filter without dispatching the event at the end
                    tokens.forEach(function(token) {
                        instance.filterController.removeSearchParam(token, true);
                    });
                }
            },

            /**
             * Evaluating the response given by the ajax call
             *
             * @param {object} response of the call
             * @param {object} params of the call
             * @param {boolean} [initial=false] whether it's initial call
             */
            handleResponse = function(response, params, initial = false) {
                var cleanView;

                // search as you type results (gets shown if in current 'treffertyp' nothing was found, but the search found something in other 'treffertyp')
                if ('resultgroups' in response) {

                    if (instance.contentView.getType() !== 'search-as-you-type') {
                        cleanView = true;
                    } else {
                        cleanView = false;
                    }

                    var resultgroups = Render.searchAsYouType(response.resultgroups);

                    instance.contentView.updateView('search-as-you-type', resultgroups, cleanView, true, function() {

                        instance.filterController.updateFilterCounts(response.resultgroups, true);

                        container.querySelector('[data-sayt-user]').innerHTML = params.query;

                        // Reading translated treffertyp
                        container.querySelector('[data-treffertyp]').innerHTML = store.translations['trans:' + params.treffertyp];
                    }, function() {
                        var showallLinks = this.content.querySelectorAll('.suggested-results__show-all');

                        for (var i = 0; i < showallLinks.length; i++) {
                            showallLinks[i].addEventListener('click', function() {
                                instance.filterController.triggerFilter(this.getAttribute('data-treffertyp'));
                            });
                        }
                    });

                } else if('query' in response) {

                    if ('resultcounts' in response) {
                        // no teaser and no alternative teaser found
                        if (response.resultcounts.all === 0) {

                            if (instance.contentView.getType() !== 'noresults') {
                                cleanView = true;
                            } else {
                                cleanView = false;
                            }

                            // Update filtercounts asap
                            instance.filterController.updateFilterCounts(response.filters);

                            // noresults
                            instance.contentView.updateView('noresults', '', cleanView, false, function() {
                                container.querySelector('[data-noresults-query]').innerHTML = response.query.user;
                            });

                        } else { // teasers found

                            var clean;

                            if (response.resultcounts.start === 0) {
                                clean = true;
                            } else {
                                clean = false;
                            }

                            // Update filtercounts asap
                            instance.filterController.updateFilterCounts(response.filters);

                            instance.contentView.updateView('teaser', Render.teaser(response.results, { initialwith: 3, xs: 12, sm: 4, md: 3 }, { xs: 12, sm: 4, md: 3, hgutter: true }), clean, clean, function() {
                                // instance.button.showButton();
                                instance.filterController.updateTotalCount(response.resultcounts.all);

                                if (response.query.hasOwnProperty('dym') && response.query.dym.hasOwnProperty('autocorrect') && response.query.dym.autocorrect === true) {
                                    container.querySelector('[data-altquery-user]').innerHTML = response.query.user;
                                    container.querySelector('[data-altquery-dynamic]').innerHTML = response.query.dym.query;
                                    container.querySelector('[data-altquery-wrapper]').style.display = 'block';
                                } else {
                                    container.querySelector('[data-altquery-wrapper]').style.display = 'none';
                                    container.querySelector('[data-altquery-user]').innerHTML = '';
                                    container.querySelector('[data-altquery-dynamic]').innerHTML = '';
                                }
                            });

                            if ('next_start' in response.resultcounts) {

                                nextStart = response.resultcounts.next_start;

                                // Shows load more button (if not already visible)
                                instance.button.showButton();
                            } else {

                                // Hides load more button (if not already hidden)
                                instance.button.hideButton();
                            }
                        }
                    }
                }

                if (queryChanged || treffertypChanged) {
                    Pubsub.publish('searchResponse.mainFilterChanged', ['search', {
                        treffertypChanged: treffertypChanged,
                        treffertyp: params.treffertyp ? params.treffertyp : 'rezepte',
                        queryChanged: queryChanged,
                        query: (response.hasOwnProperty('query') && response.query.hasOwnProperty('dym') && response.query.dym.hasOwnProperty('autocorrect') && response.query.dym.autocorrect === true) ? response.query.dym.query : params.query,
                        results: String(response.hasOwnProperty('resultcounts') ? response.resultcounts.all : 0),
                        filter: `Search : ${params.treffertyp ? params.treffertyp : 'rezepte'}`,
                        initial
                    }]);
                }

                queryChanged = false;
                treffertypChanged = false;
            };


        instance.search = function(start = 0, initial = false) {
            if (http) {
                http.abort();
            }

            if (httpSearch) {
                httpSearch.abort();
            }

            http = new XMLHttpRequest();

            var params = {
                query: query,
                start: start,
                sort: sort,
                filters: instance.filterController.getFilterQuery(true)
            };

            var searchParams = Object.assign({}, searchDefaults, {
                query: query,
                start: start,
                sort: sort,
                interface: store.interface || 'hawaii',
                userquery: true
            }, instance.filterController.getFilterQuery());

            if (initial) {
                searchParams.start = 0;
                searchParams.num = start + defaultNum;
            }

            if (!paramsChangedByPopState) {
                if (initial || shouldReplaceHistory(params)) {
                    Globals.history.replaceParams(params);
                } else {
                    Globals.history.pushParams(params);
                }
            }

            // reset flag
            paramsChangedByPopState = false;
            currentSearchParams = searchParams;

            // Activate loading animations
            instance.button.hideButton();
            showLoaders();

            http.onload = function(event) {
                http = null;
                hideLoaders();
                handleResponse(JSON.parse(event.target.response), searchParams, initial);
            };

            http.open('GET', store.apis.search + '?' + Helper.solrSerialize(searchParams));
            http.send();
        };

        instance.getCurrentSearchParams = function() {
            return currentSearchParams;
        };

        instance.init = function() {

            // state = JSON.parse(container.getAttribute('data-search-initial-state'));
            // categoryEntrance = container.querySelectorAll('.t12-searchresults__category-entrance-wrapper')[0];
            loaders = container.querySelectorAll('.loader-pot');

            hideLoaders();

            backToTop.init();

            instance.filterController = filterController(container);
            instance.tokenController = tokenController(container);
            instance.searchbarController = searchbarController(container, {
                autocomplete: {
                    init: true,
                    openthreshhold: 2,
                    limit: 4,
                    api: store.apis.autocomplete,
                    method: 'GET'
                }
            });

            instance.button = button(container);
            instance.button.subscribe('click', function() {
                instance.search(nextStart);
            });


            instance.commonSearchTerms = commonSearchTerms.call(container);
            instance.commonSearchTerms.loadTags();

            instance.commonSearchTerms.subscribe('click', function() {

                // Reads Term out of button clicked
                query = this.getElementsByTagName('span')[0].innerHTML;

                // We should set a utag even for the query when it was initially loaded
                queryChanged = true;

                instance.searchbarController.searchbar.searchfield.value = query;
                instance.search();
            });

            instance.contentView = ViewController.call(container, {
                activeType: 'teaser',
                containers: [
                    {
                        type: 'teaser',
                        wrapper: '.t12-searchresults__teaser-result-wrapper',
                        content: '.t12-searchresults__results',
                        onAfterAdd: function() {
                            this.content.dispatchEvent(Helper.customEvent('ni-equalizer.update', {}));
                            Pubsub.publish('tooltip.init');
                            Pubsub.publish('likes.bindTrigger');
                            Pubsub.publish('likes.loadLikes');
                            Pubsub.publish('likes.setActives');

                            Pubsub.publish('teasers.added');
                            Pubsub.publish('searchFactory.teasers.added');

                            // add delay, because dom can take a little time to append the teasers
                            setTimeout(function () {
                                Pubsub.publish('teasers.updateStates', [true]);
                            }, 200);
                        }
                    },
                    {
                        type: 'search-as-you-type',
                        wrapper: '.t12-searchresults__suggestion-result-wrapper',
                        content: '.t12-searchresults__suggestion-result-container',
                        onBeforeAdd: function () {
                            instance.commonSearchTerms.loadTags();
                        }
                    },
                    {
                        type: 'noresults',
                        wrapper: '.t12-searchresults__noresults-wrapper',
                        content: '.t12-searchresults__noresults-container',
                        onAfterAdd: function () {
                            Pubsub.publish('teasers.added');
                            Pubsub.publish('searchFactory.teasers.added');
                        },
                        onBeforeAdd: function () {
                            instance.commonSearchTerms.loadTags();
                            // categoryEntrance.style.display = 'block';
                        },
                        onAfterClean: function() {
                            // categoryEntrance.style.display = 'none';
                        }
                    }
                ]
            });

            // Eventhandlers
            container.addEventListener('token.addToken', function(event) {

                // checks the checkbox in the filter without dispatching the event at the end
                instance.filterController.addSearchParam(event.detail.token, true);

                instance.search();
            });

            container.addEventListener('token.removeToken', function(event) {

                // unchecks the checkbox in the filter without dispatching the event at the end
                instance.filterController.removeSearchParam(event.detail.token, true);

                instance.search();
            });

            container.addEventListener('token.removeAllToken', function(event) {

                // unchecks every checkbox in the filter without dispatching the event at the end
                event.detail.tokens.forEach(function(token) {
                    instance.filterController.removeSearchParam(token, true);
                });

                instance.search();
            });

            container.addEventListener('searchbar.submit', function() {

                if (instance.filterController.mobileFilter === true) {
                    smoothscroll.scrollTo(63, 0, 500);
                }

                query = instance.searchbarController.searchbar.searchfield.value;
                queryChanged = true;

                instance.searchbarController.blurInput();
                instance.filterController.closeLevelOne();
                instance.search();
            });

            container.addEventListener('searchbar.focus', function() {

                if (instance.filterController.mobileFilter === true) {
                    instance.searchbarController.suggestedSearchController.setLimit(4);
                    smoothscroll.scrollTo(63, 0, 350);
                } else {
                    instance.searchbarController.suggestedSearchController.setLimit(5);
                }
            });

            container.addEventListener('filter.add', function(event) {
                instance.tokenController.addToken({
                    id: event.detail.id,
                    title: event.detail.title,
                    value: true
                }, true);

                instance.search();
            });

            container.addEventListener('filter.remove', function(event) {
                instance.tokenController.removeToken({
                    id: event.detail.id
                }, true);

                instance.search();
            });

            container.addEventListener('filter.levelOneChange', function() {
                treffertypChanged = true;
                resetTokens();
                instance.search();
            });

            container.addEventListener('filter.radioChange', function() {
                instance.search();
            });

            container.addEventListener('click', function(e) {
                const wrapper = e.target.closest('.t12-searchresults__results');

                if (wrapper) {
                    const teasers = Array.from(wrapper.querySelectorAll('.teaser'));
                    const teaser = e.target.closest('.teaser');

                    if (teaser) {
                        const teaserLink = teaser.querySelector('.teaser__click-area');
                        const teaserLinkUrl = teaserLink.getAttribute('href').split('#')[0].split('?')[0];
                        Pubsub.publish('teaserGroup.clicked', [instance, teasers.indexOf(teaser), teaserLinkUrl]);
                    }
                }
            });

            sortElement = document.querySelector('#sort');

            dropdownLabels = container.querySelectorAll('[data-sort-dropdown-label]');
            dropdownValues = sortElement.querySelectorAll('[data-sort-dropdown-value]');

            dropdownValues.forEach(element => {
                const value = element.getAttribute('data-sort-dropdown-value');
                const text = element.innerText;

                element.addEventListener('click', () => {
                    sort = value;

                    dropdownLabels.forEach(label => {
                        label.innerText = text;
                    });

                    dropdownValues.forEach(valueElement => {
                        if (valueElement === element) {
                            valueElement.setAttribute('data-sort-dropdown-current', '');
                        } else {
                            valueElement.removeAttribute('data-sort-dropdown-current');
                        }
                    });

                    instance.search(start);
                });
            });

            Globals.history.subscribe('params.changed', function(params, previousParams, options) {
                paramsChangedByPopState = false;

                if (options.popstate) {
                    let start = 0;

                    if (params['start']) {
                        start = parseInt(params['start']);
                    }

                    paramsChangedByPopState = true;
                    initFiltersByParams(params, previousParams);
                    instance.search(start, true);
                }
            });

            let start = 0;
            let currentParams = Globals.history.getParams();

            if (currentParams['start']) {
                start = parseInt(currentParams['start']);
            }

            initFiltersByParams(currentParams);
            instance.search(start, true);

            return instance;
        };

        return instance.init();
    };

});
