

// dependencies
var fadeInTeasers = require('@/helpers/helper-fade').default.fadeInTeasers,
    fadeOutTeasers = require('@/helpers/helper-fade').default.fadeOutTeasers;

// vars
var defaults = {
    activeType: '',
    containers: []
};

/**
 * Controls the view of the filters (excluding states), mostly on mobile
 *
 * @param {object} options to overwrite default settings
 * @return {object} instance
 */
export default function(options) {
    var that = this || document,
        instance = {},
        containers = {},
        settings,

        /**
         * Cleans out the given scoped container
         *
         * @param {function} callback to call when finish
         */
        cleanView = function(callback) {

            callback = callback || function() {};

            var that = this,
                clean = function() {
                    that.content.innerHTML = '';

                    if (that.onAfterClean instanceof Function) {
                        that.onAfterClean.call(that);
                    }

                    if (callback instanceof Function) {
                        return callback();
                    }
                };

            if (this.onBeforeClean instanceof Function) {
                this.onBeforeClean.call(this);
            }

            fadeOutTeasers(this.content).then(clean);
        },

        /**
         * Implements a given markup into the scoped container
         *
         * @param {string} markup to implement
         * @param {function} callback to call when finish
         * @return {any} return of the calback
         */
        addToView = function(markup, callback) {

            callback = callback || {};

            var div = document.createElement('div');

            if (this.onBeforeAdd instanceof Function) {
                this.onBeforeAdd.call(this);
            }

            if (markup !== '') {

                div.innerHTML = markup;

                while(div.firstChild) {
                    this.content.appendChild(div.firstChild);
                }
            }

            fadeInTeasers(this.content);

            if (this.onAfterAdd instanceof Function) {
                this.onAfterAdd.call(this);
            }

            if (callback instanceof Function) {
                return callback.call(this);
            }
        },

        /**
         * Loops through all containers and sets the currently active one to display inherit
         */
        changeActiveView = function() {

            for (var key in containers) {
                if (containers.hasOwnProperty(key)) {
                    if (containers[key].wrapper !== this.wrapper) {
                        containers[key].wrapper.style.display = 'none';
                    }
                }
            }

            this.wrapper.style.display = 'inherit';
        };

    /**
     * Updates the view
     *
     * @param {string} type of the view to manipulate/show
     * @param {string} content toinsertinto view container
     * @param {boolean} cleanOld allows to clean the old content container befor filling
     * @param {boolean} cleanNew allows to clean the new content container befor filling
     * @param {function} onAfterClean is an optional callback after cleaning is finished
     * @param {function} onAfterAdd is an optional callback after adding is finished
     * @return {object} instance
     */
    instance.updateView = function(type, content, cleanOld, cleanNew, onAfterClean, onAfterAdd) {

        var changeView = false,
            callback;

        cleanOld = cleanOld || false;
        cleanNew = cleanNew || false;
        content = content || '';
        onAfterClean = onAfterClean || {};
        onAfterAdd = onAfterAdd || {};

        if (type !== settings.activeType) {
            changeView = true;
        }

        callback = function() {

            settings.activeType = type;

            if(onAfterClean instanceof Function) {
                onAfterClean.call(containers[settings.activeType]);
            }

            if (changeView) {
                changeActiveView.call(containers[settings.activeType]);
            }

            addToView.call(containers[settings.activeType], content, onAfterAdd);
        };

        // If type not allready active & clean new === true => clean content instantly
        if (cleanNew && settings.activeType !== type) {
            containers[type].content.innerHTML = '';
        } else if(cleanNew && settings.activeType === type) {
            cleanOld = true;
        }

        if (cleanOld) {
            cleanView.call(containers[settings.activeType], callback);
        } else {
            callback();
        }

        return instance;
    };

    /**
     * Returns the currently active type of the view
     *
     * @return {string} type
     */
    instance.getType = function() {
        return settings.activeType;
    };

    /**
     * Init all the good stuff
     *
     * @param {object} options to overwrite default settings
     * @return {object} instance
     */
    instance.init = function(options) {
        settings = Object.assign({}, defaults, options);

        if (settings.containers instanceof Array && settings.containers.length > 0) {

            settings.containers.forEach(function(container) {
                containers[container.type] = {
                    wrapper: that.querySelector(container.wrapper),
                    content: that.querySelector(container.content),
                    onBeforeAdd: container.onBeforeAdd || {},
                    onAfterAdd: container.onAfterAdd || {},
                    onBeforeClean: container.onBeforeClean || {},
                    onAfterClean: container.onAfterClean || {}
                };
            });

        } else {
            console.error('Something is fishy, please check your code!');
        }

        return instance;
    };

    return instance.init(options);
};
