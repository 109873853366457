const CLASS_TOOLTIP_DARK_BG = 'tooltip--theme-dark';

const TIMEOUT_CLOSE = 1500;
const TIMEOUT_TOGGLE = 500;

const VIEW_TO_DARK_THEME_MAP = {
    'not-logged-in': false,
    'loading': false,
    'success': true,
    'error': true
};

const ATTR_SAVED = 'data-saved';

const createSaveRecipeIngredients = ({ el, state, options }, Globals, Store, Tooltip, ViewController) => {
    let hookIdUserChanged;
    let hookIdUserFetched;

    const updateView = (view) => {
        if (VIEW_TO_DARK_THEME_MAP[view] || false) {
            state.globalRefs.tooltip.classList.add(CLASS_TOOLTIP_DARK_BG);
        } else {
            state.globalRefs.tooltip.classList.remove(CLASS_TOOLTIP_DARK_BG);
        }

        state.view.updateView(view);
    };

    const updateButton = () => {
        if (state.recipeSaved) {
            el.setAttribute(ATTR_SAVED, '');
            state.refs.buttonText.textContent = options.textDelete;
        } else {
            el.removeAttribute(ATTR_SAVED);
            state.refs.buttonText.textContent = state.textInitial;
        }
    };

    const checkForRecipeUserData = () => {
        const shoppingLists = Globals.user.get('shoppingLists');
        state.shoppingList = shoppingLists && shoppingLists[0] ? shoppingLists[0] : null;

        if (state.shoppingList) {
            state.recipeSaved = shoppingLists[0].recipe_ids.indexOf(options.recipeId) > -1;
        } else {
            state.recipeSaved = false;
        }
    };

    const userChangedHandler = () => {
        checkForRecipeUserData();
        updateButton();
    };

    const toggleRecipe = async () => {
        if (!state.shoppingList) {
            // create shoppinglist on the fly
            const { shoppingList } = await Globals.shoppingLists.createShoppingList(Store.shoppingListName);
            state.shoppingList = shoppingList;
        }

        if (state.recipeSaved) {
            // remove recipe
            await Globals.shoppingLists.clearShoppingList(state.shoppingList.id, options.recipeId);
            state.recipeSaved = false;
        } else {
            // add recipe
            const recipeYield = document.querySelector('[data-portion-calculator-initial-portions]');
            const amount = parseInt(recipeYield.textContent, 10);

            await Globals.shoppingLists.addRecipeToShoppingList(state.shoppingList.id, options.recipeId, amount);
            state.recipeSaved = true;
        }

        updateButton();
    };

    const showResult = (type) => {
        window.setTimeout(() => {
            updateView(type);

            window.setTimeout(() => {
                state.tooltipInstance.close();
            }, TIMEOUT_CLOSE);
        }, TIMEOUT_TOGGLE);
    };

    state.init = async () => {
        state.recipeSaved = false;

        state.refs = {
            buttonText: el.querySelector(options.refs.buttonText)
        };

        state.globalRefs = {
            tooltip: document.querySelector(options.globalRefs.tooltip)
        };

        state.textInitial = state.refs.buttonText.textContent;

        state.view = ViewController.call(state.globalRefs.tooltip, {
            containers: [
                {
                    type: 'not-logged-in',
                    wrapper: '[data-view-type="not-logged-in"]',
                    content: '[data-view-type="not-logged-in"]'
                },
                {
                    type: 'loading',
                    wrapper: '[data-view-type="loading"]',
                    content: '[data-view-type="loading"]'
                },
                {
                    type: 'success',
                    wrapper: '[data-view-type="success"]',
                    content: '[data-view-type="success"]'
                },
                {
                    type: 'error',
                    wrapper: '[data-view-type="error"]',
                    content: '[data-view-type="error"]'
                }
            ]
        });

        // make sure the user has finished loading
        await Globals.user.fetch();
        checkForRecipeUserData();
        updateButton();

        if (!Globals.user.isLoggedIn()) {
            hookIdUserChanged = Globals.user.subscribe('changed', userChangedHandler);
            hookIdUserFetched = Globals.user.subscribe('fetched', userChangedHandler);
        }

        state.tooltipInstance = Tooltip(el, {
            onBeforeClose: () => {
                state.tooltipInstance.unlock();
            },
            onBeforeOpen: async () => {
                const { user } = await Globals.user.checkSSOSessionState();

                if (!user) {
                    updateView('not-logged-in');
                    return;
                }

                state.tooltipInstance.lock();
                updateView('loading');

                toggleRecipe().then(() => {
                    showResult('success');
                }).catch((err) => {
                    console.error(err);
                    showResult('error');
                });
            }
        });
    };

    state.destroy = () => {
        if (hookIdUserChanged) Globals.user.unsubscribe(hookIdUserChanged);
        if (hookIdUserFetched) Globals.user.unsubscribe(hookIdUserFetched);

        state.tooltipInstance.destroy();
    };

    state.init();

    return state;
};

export const config = {
    name: 'save-recipe-ingredients',
    constructor: createSaveRecipeIngredients,
    dependencies: ['Globals', 'Store', 'Tooltip', 'ViewController'],
    options: {
        refs: {
            buttonText: '.btn__text'
        },
        globalRefs: {
            tooltip: '#recipe-ingredients-save'
        }
    }
};
