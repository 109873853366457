import { default as SvgatorPlayer, littleFoobyHeart } from '@/libs/svgator-player';
import Tooltip from '@/libs/tooltip';
import TitleTooltip from '@/libs/title-tooltip';
import { notify } from '@/libs/notifications';

const isMyFooby = document.querySelector('.t13-myfooby') !== null;

// dependencies
var pubsub = require('@/scaffold/pubsub').default,
    storage = require('@/libs/localstorage').default,
    greensock = require('@/libs/greensock-animations').default,
    HelperModal = require('@/helpers/helper-modal').default,
    globals = require('@/scaffold/globals').default;

// vars
var defaults = {
    containers: 'data-like-container', // has to be data-attribute
    attrUrl: 'data-like-container', // has to be data-attribute
    attrTreffertypSub: 'data-treffertyp-sub', // has to be data-attribute
    attrRecipeId: 'data-like-recipe-id', // has to be data-attribute
    triggers: 'data-like-save', // has to be data-attribute
    triggersLittleFooby: 'data-like-save-little-fooby', // has to be data-attribute
    boundTriggers: 'like-trigger--bound', // class
    likeCount: 'data-like-count', // has to be data-attribute
    likeCaption: 'data-like-caption',
    boundLikeCount: 'like-count--bound',
    active: 'like--active', // class
    likesAdded: 'data-likes-added',
    attrOwnRecipeId: 'data-own-recipe-id',
    attrMenuartId: 'data-menuart-id',
    bookmarkRemoveTrigger: 'data-bookmark-remove-trigger',
    bookmarkExternalRecipeDataAttr: 'data-external-recipe-data'
};

/**
 * Save/Delete bar module
 *
 * @param {object} options to overwrite default options
 * @return {object} instance
 */
export default function(options) {
    const recipeIdRegex = /\/([0-9]+)\/|^([0-9]+)$/;

    var that = this || document,
        settings = Object.assign({}, defaults, options),
        instance = {},
        containers,
        likes = [],
        // likesToRemove = [],
        userLoggedIn,

        getContainers = function() {
            containers = that.querySelectorAll(`[${settings.containers}], [${settings.attrRecipeId}], [${settings.triggers}]`);
        },

        setLikeText = function(el, count) {
            var counter = el.querySelector(`[${settings.likeCount}]`),
                caption = el.querySelector(`[${settings.likeCaption}]`) || false;

            // Checks if this like-container has a caption
            if (caption) {
                let value = (typeof count !== 'undefined') ? count : parseInt(counter.innerHTML);
                caption.innerHTML = value === 1 ? store.translations['trans:like'] : store.translations['trans:likes'];
            }
        },

        /**
         * Loads the likes from the user from the localstorage
         */
        loadLocalStorage = function() {
            getContainers();

            var storedLikes = storage.get('likes');
            // var storedLikesToRemove = storage.get('likesToRemove');

            if (storedLikes) {
                likes = storedLikes;
            }

            // if (storedLikesToRemove) {
            //     likesToRemove = storedLikesToRemove;
            // }

            // setActives();
        },

        loadFromUserData = function() {
            getContainers();

            const likeBookmarks = globals.bookmarks.getAllLikeBookmarks();

            console.info('likes.js: loadFromUserData', likeBookmarks);

            if (likeBookmarks.length === 0) {
                return;
            }

            likeBookmarks.forEach(bookmark => {
                let bookmarkRecipeId = bookmark.recipe_id;

                if (!bookmarkRecipeId) {
                    const recipeIdMatch = bookmark.target_url.match(recipeIdRegex);
                    bookmarkRecipeId = recipeIdMatch && Number(recipeIdMatch[1]);
                }

                // check if saved recipe is aready in local likes
                const like = likes.filter(like => {
                    const likeRecipeId = typeof like === 'string'
                        ? like.match(recipeIdRegex) && Number(like.match(recipeIdRegex)[1])
                        : like;

                    if (!likeRecipeId) {
                        return false;
                    }

                    return likeRecipeId === bookmarkRecipeId;
                });

                if (like.length === 0) {
                    likes.push(bookmark.target_url || bookmark.recipe_id);
                }
            });

            // sync saved likes (bookmarks) to local likes
            storage.set('likes', likes);

            // updateStates();
        },

        // // delete unsynced recipe likes
        // deleteUnsyncedLikes = function() {
        //     const likeBookmarks = globals.bookmarks.getAllLikeBookmarks();

        //     if (likesToRemove.length === 0 || likeBookmarks.length === 0) {
        //         return;
        //     }

        //     const unsyncedLikesToRemove = likesToRemove.filter(like => {
        //         const likeRecipeId = typeof like === 'string'
        //             ? like.match(recipeIdRegex) && Number(like.match(recipeIdRegex)[1])
        //             : like;

        //         // ignore if non recipe
        //         if (!likeRecipeId) {
        //             return false;
        //         }

        //         // check if local like is saved already
        //         const savedBookmark = likeBookmarks.filter(bookmark => {
        //             let bookmarkRecipeId = bookmark.recipe_id;

        //             if (!bookmarkRecipeId) {
        //                 const recipeIdMatch = bookmark.target_url.match(recipeIdRegex);
        //                 bookmarkRecipeId = recipeIdMatch && Number(recipeIdMatch[1]);
        //             }

        //             return likeRecipeId === bookmarkRecipeId;
        //         });

        //         return savedBookmark.length > 0;
        //     });

        //     if (unsyncedLikesToRemove.length > 0) {
        //         const unsyncedLikesToRemoveRecipeIds = unsyncedLikesToRemove.map(like => {
        //             return typeof like === 'string'
        //                 ? (like.match(recipeIdRegex) && Number(like.match(recipeIdRegex)[1])) || Number(like)
        //                 : like;
        //         });

        //         likes = likes.filter(like => likesToRemove.indexOf(like) === -1);
        //         storage.set('likes', likes);

        //         likesToRemove = [];
        //         storage.set('likesToRemove', []);

        //         globals.bookmarks.deleteBookmark(
        //             null,
        //             unsyncedLikesToRemoveRecipeIds.join(','),
        //             null,
        //             'rezept',
        //             null,
        //             true
        //         );
        //     }
        // },

        // // save unsynced recipe likes
        // saveUnsyncedLikes = function() {
        //     const likeBookmarks = globals.bookmarks.getAllLikeBookmarks();
        //     const likeCookbook = globals.cookbooks.getLikeCookbook();

        //     if (likes.length === 0 || likeBookmarks.length === 0) {
        //         return;
        //     }

        //     const unsyncedLikes = likes.filter(like => {
        //         const likeRecipeId = typeof like === 'string'
        //             ? like.match(recipeIdRegex) && Number(like.match(recipeIdRegex)[1])
        //             : like;

        //         // ignore if non recipe
        //         if (!likeRecipeId) {
        //             return false;
        //         }

        //         // check if local like is not saved already
        //         const savedBookmark = likeBookmarks.filter(bookmark => {
        //             let bookmarkRecipeId = bookmark.recipe_id;

        //             if (!bookmarkRecipeId) {
        //                 const recipeIdMatch = bookmark.target_url.match(recipeIdRegex);
        //                 bookmarkRecipeId = recipeIdMatch && Number(recipeIdMatch[1]);
        //             }

        //             return likeRecipeId === bookmarkRecipeId;
        //         });

        //         return savedBookmark.length === 0;
        //     });

        //     if (unsyncedLikes.length > 0) {
        //         const unsyncedLikesRecipeIds = unsyncedLikes.map(like => {
        //             return typeof like === 'string'
        //                 ? (like.match(recipeIdRegex) && Number(like.match(recipeIdRegex)[1])) || Number(like)
        //                 : like;
        //         });

        //         globals.bookmarks.createBookmark(
        //             null,
        //             unsyncedLikesRecipeIds.join(','),
        //             null,
        //             likeCookbook[0].cookbook_id,
        //             'rezept',
        //             null,
        //             false
        //         );
        //     }
        // },

        // setActives = function() {
        //     getContainers();

        //     // Adding classes to allready liked containers
        //     containers.forEach((container) => {
        //         if (
        //             likes.indexOf(container.getAttribute(settings.containers)) > -1 ||
        //             likes.indexOf(Number(container.getAttribute(settings.attrRecipeId))) > -1
        //         ) {
        //             container.classList.add(settings.active);

        //             const svg = container.querySelector('svg');
        //             const isLittleFooby = container.hasAttribute(settings.triggersLittleFooby);

        //             if (isLittleFooby) {
        //                 const heartLikeAnimation = littleFoobyHeart(svg);
        //                 svg.svgatorInstance = SvgatorPlayer.build(heartLikeAnimation);
        //                 svg.svgatorInstance.play();
        //             } else {
        //                 greensock.fill(container, true);
        //             }
        //         }
        //     });
        // },

        // setInactives = function(dislikes = []) {
        //     getContainers();

        //     containers.forEach((container) => {
        //         if (
        //             dislikes.indexOf(container.getAttribute(settings.containers)) > -1 ||
        //             dislikes.indexOf(Number(container.getAttribute(settings.attrRecipeId))) > -1
        //         ) {
        //             container.classList.remove(settings.active);

        //             const svg = container.querySelector('svg');
        //             const isLittleFooby = container.hasAttribute(settings.triggersLittleFooby);

        //             if (isLittleFooby) {
        //                 const heartLikeAnimation = littleFoobyHeart(svg, true);
        //                 svg.svgatorInstance = SvgatorPlayer.build(heartLikeAnimation);
        //                 svg.svgatorInstance.play();
        //             } else {
        //                 greensock.empty(svg, true);
        //             }
        //         }
        //     });
        // },

        /* Update the counter of every like count display of the liked url */
        addToCounter = function(url, recipeId, add = 1) {
            const elements = that.querySelectorAll(`[${settings.containers}="${url}"], [${settings.attrRecipeId}="${recipeId}"]`);

            elements.forEach((el) => {
                const counter = el.querySelector(`[${settings.likeCount}]`);
                counter.innerHTML = !Number.isNaN(Number(counter.innerHTML)) ? Number(counter.innerHTML) + add : 1;
                setLikeText(el);
            });
        },

        /**
         * Binds the events to the like triggers
         */
        bindTrigger = function() {
            getContainers();

            containers.forEach(trigger => {
                if (trigger.hasAttribute(settings.triggers)) {
                    if (!trigger.classList.contains(settings.boundTriggers)) {
                        trigger.classList.add(settings.boundTriggers);

                        const url = trigger.getAttribute(settings.attrUrl);
                        const recipeId = trigger.getAttribute(settings.attrRecipeId) || '';
                        const treffertypSub = trigger.getAttribute(settings.attrTreffertypSub) || '';
                        const ownRecipeId = trigger.hasAttribute(settings.attrOwnRecipeId) && trigger.getAttribute(settings.attrOwnRecipeId) !== '' && Number(trigger.getAttribute(settings.attrOwnRecipeId));
                        const menuartId = trigger.hasAttribute(settings.attrMenuartId) && trigger.getAttribute(settings.attrMenuartId);

                        let titleTooltip = null;
                        let tooltip = null;
                        let loading = false;

                        tooltip = Tooltip(trigger, {
                            highlight: true,
                            noClick: true,
                            target: treffertypSub === 'rezept' ? 'like-login' : 'like-article-login',
                            onBeforeOpen: () => {
                                if (titleTooltip) {
                                    titleTooltip.close();
                                }
                            }
                        });

                        titleTooltip = TitleTooltip(trigger, {
                            // doesn't work e.g. on recipe detail page header when text is below svg
                            // popperTrigger: () => {
                            //     return trigger.querySelector('svg');
                            // },
                            content: () => {
                                const isActive = trigger.classList.contains(settings.active);
                                return isActive
                                    ? store.translations['trans:likeChange']
                                    : store.translations[treffertypSub === 'rezept' ? 'trans:likeSave' : 'trans:likeArticleSave'];
                            }
                        });

                        // const toggleLike = (isActive, updateTooltips) => {
                        //     const url = trigger.getAttribute(settings.attrUrl);
                        //     const recipeId = trigger.getAttribute(settings.attrRecipeId) || '';
                        //     const likeCookbook = globals.cookbooks.getLikeCookbook()[0];

                        //     if (!isActive) {
                        //         likes.push(url || Number(recipeId));
                        //         storage.set('likes', likes);

                        //         addToCounter(url, recipeId, 1);
                        //         updateStates();

                        //         if (userLoggedIn && treffertypSub === 'rezept') {
                        //             // add bookmark
                        //             globals.bookmarks.createBookmark(
                        //                 url || null,
                        //                 recipeId || null,
                        //                 null,
                        //                 likeCookbook.cookbook_id,
                        //                 'rezept',
                        //                 null,
                        //                 true
                        //             );
                        //         } else {
                        //             let sendRequest = true;

                        //             if (treffertypSub === 'rezept') {
                        //                 // only add like when not added yet
                        //                 const removedLikes = likesToRemove.filter(like => like === url || like === Number(recipeId));

                        //                 likesToRemove = likesToRemove.filter(like => like !== url && like !== Number(recipeId));
                        //                 storage.set('likesToRemove', likesToRemove);

                        //                 if (removedLikes.length > 0) {
                        //                     sendRequest = false;
                        //                 }
                        //             }

                        //             if (sendRequest) {
                        //                 var http = new XMLHttpRequest();

                        //                 http.onload = function(event) {
                        //                     if (event.target.status !== 200) {
                        //                         throw new Error('Could not like url ' + url);
                        //                     }
                        //                 };

                        //                 http.open('POST', store.apis.likesSave);
                        //                 http.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
                        //                 http.send(JSON.stringify({
                        //                     'lang': store.lang,
                        //                     'target_url': url,
                        //                     'treffertyp_sub': treffertypSub,
                        //                     'recipe_id': recipeId
                        //                 }));
                        //             }
                        //         }
                        //     } else if (treffertypSub === 'rezept') {
                        //         addToCounter(url, recipeId, -1);
                        //         // setInactives([url, Number(recipeId)]);
                        //         updateStates();

                        //         likes = likes.filter(like => like !== url && like !== Number(recipeId));
                        //         storage.set('likes', likes);

                        //         if (userLoggedIn) {
                        //             globals.bookmarks.deleteBookmark(
                        //                 url || null,
                        //                 recipeId || null,
                        //                 null,
                        //                 'rezept',
                        //                 null,
                        //                 true
                        //             );
                        //         } else {
                        //             // add todo remove
                        //             likesToRemove.push(url || Number(recipeId));
                        //             storage.set('likesToRemove', likesToRemove);
                        //         }
                        //     }

                        //     if (treffertypSub === 'rezept') {
                        //         if (userLoggedIn) {
                        //             // find out recipe title
                        //             let recipeTitle = '';

                        //             if (trigger.closest('.teaser')) {
                        //                 recipeTitle = trigger
                        //                     .closest('.teaser')
                        //                     .querySelector('.teaser__body-title')
                        //                     .textContent;
                        //             } else if (globals.isRecipe) {
                        //                 recipeTitle = trigger
                        //                     .closest('#page-header-recipe__panel-detail')
                        //                     .querySelector('h1')
                        //                     .textContent;
                        //             }

                        //             const message = (isActive
                        //                 ? store.translations['trans:dislikeInfo']
                        //                 : store.translations['trans:likeInfo'])
                        //                 .replace(/{recipe}/ig, recipeTitle || '')
                        //                 .replace(/{likecookbook}/ig, store.translations['trans:likecookbook'] || '');

                        //             const buttons = [];

                        //             if (!isActive) {
                        //                 buttons.push(
                        //                     [`<button>${store.translations['trans:toCookbook']}</button>`, function () {
                        //                         window.location.href = store.myfoobyCookbookUrl
                        //                             .replace(/{lang}/ig, store.lang || 'de')
                        //                             .replace(/{cookbookId}/ig, likeCookbook.cookbook_id);
                        //                     }, true] // true to focus
                        //                 );
                        //             }

                        //             buttons.push(
                        //                 [`<button>${store.translations['trans:revert']}</button>`, function (instance, toast) {
                        //                     toggleLike(!isActive, false);
                        //                     instance.hide({}, toast, 'revertButton');
                        //                 }]
                        //             );

                        //             notify(message, {
                        //                 buttons
                        //             });
                        //         }

                        //         if (updateTooltips) {
                        //             if (!userLoggedIn && !isActive) {
                        //                 tooltip.open();
                        //             }

                        //             titleTooltip.updateContent();
                        //         }
                        //     }
                        // };

                        const openEditModal = function() {
                            HelperModal.openBookmarkModal({
                                url,
                                ownRecipeId,
                                menuartId,
                                treffertypSub,
                                recipeId: Number(recipeId)
                            });
                        };

                        const showToastMessage = function() {
                            // find out recipe title
                            let recipeTitle = '';

                            if (trigger.closest('.teaser')) {
                                recipeTitle = trigger
                                    .closest('.teaser')
                                    .querySelector('.teaser__body-title')
                                    .textContent;
                            } else if (globals.isRecipe) {
                                recipeTitle = trigger
                                    .closest('#page-header-recipe__panel-detail')
                                    .querySelector('h1')
                                    .textContent;
                            }

                            console.log(recipeTitle);

                            const message = store.translations[treffertypSub === 'rezept' ? 'trans:likeInfo' : 'trans:likeArticleInfo']
                                .replace(/{recipe}/ig, recipeTitle.trim() || '')
                                .replace(/{likecookbook}/ig, store.translations['trans:likecookbook'] || '');

                            const buttons = [];

                            // buttons.push(
                            //     [`<button>${store.translations['trans:toCookbook']}</button>`, function () {
                            //         window.location.href = store.myfoobyCookbookUrl
                            //             .replace(/{lang}/ig, store.lang || 'de')
                            //             .replace(/{cookbookId}/ig, likeCookbook.cookbook_id);
                            //     }, true] // true to focus
                            // );

                            buttons.push(
                                [`<button>${store.translations['trans:likeInfoChange']}</button>`, function (instance, toast) {
                                    openEditModal();
                                    instance.hide({}, toast, 'changeButton');
                                }]
                            );

                            notify(message, {
                                buttons
                            });
                        };

                        trigger.addEventListener('click', function(e) {
                            e.preventDefault();

                            if (loading) {
                                return;
                            }

                            const isActive = trigger.classList.contains(settings.active);
                            const likeCookbook = globals.cookbooks.getLikeCookbook()[0];

                            // if not logged in:
                            // show login tooltip
                            if (!userLoggedIn) {
                                tooltip.open();
                            } else {
                                // if not liked yet (not in any cookbook yet)
                                if (!isActive) {
                                    loading = false;

                                    const created = globals.bookmarks.createBookmark(
                                        url || null,
                                        Number(recipeId) || null,
                                        null,
                                        likeCookbook.cookbook_id,
                                        treffertypSub,
                                        null,
                                        true
                                    );

                                    created.then(() => {
                                        loading = false;

                                        // show toast message with change
                                        showToastMessage();

                                        const previouslyLiked = likes.filter(like => like === url || like === Number(recipeId));

                                        if (previouslyLiked.length === 0) {
                                            // increase counter
                                            likes.push(url || Number(recipeId));
                                            storage.set('likes', likes);
                                            addToCounter(url, recipeId, 1);
                                        }

                                        updateStates();
                                    }).catch(() => {
                                        loading = false;
                                    });
                                } else {
                                    // if liked (exists in any cookbook):
                                    // open edit modal
                                    openEditModal();
                                }
                            }
                        });
                    }
                }
            });
        },

        animateInLikes = function(container, el, likeCount) {
            const stepDelay = 50;
            const start = 1;
            const end = likeCount;
            const steps = end <= 10 ? end : 10;

            let pos = 1;

            const countUpdater = () => {
                const value = Math.round(pos * (end / steps));

                el.innerHTML = value;
                pos = pos + 1;

                if (pos <= steps) {
                    setTimeout(function () {
                        countUpdater();
                    }, stepDelay);
                }

                setLikeText(container, value);
            };

            el.innerHTML = start;

            setTimeout(function () {
                countUpdater();
            }, stepDelay);
        },

        writeLikes = function(response, requestDuration) {
            const minRequestDuration = 250;

            var container;

            for (const key in response) {

                // Key can be an URL or a recipeId
                if (response.hasOwnProperty(key)) {

                    let selector = `[${settings.containers}="${key}"], [${settings.attrRecipeId}="${key}"]`;
                    // let selector = `[${settings.containers}], [${settings.attrRecipeId}]`;

                    container = that.querySelectorAll(selector);

                    if (container instanceof NodeList && container.length > 0) {
                        for (let i = 0; i < container.length; i++) {
                            const containerElement = container[i].querySelector(`[${settings.likeCount}]`);

                            if (!containerElement.hasAttribute(settings.likesAdded) || Number(containerElement.getAttribute(settings.likesAdded)) !== Number(response[key])) {

                                if (requestDuration > minRequestDuration) {
                                    animateInLikes(container[i], containerElement, Number(response[key]));
                                } else {
                                    containerElement.innerHTML = response[key];
                                }

                                container[i].classList.add(settings.boundLikeCount);
                                setLikeText(container[i]);

                                containerElement.setAttribute(settings.likesAdded, response[key]);
                            }
                        }
                    }
                }
            }
        },

        loadLikes = function() {
            getContainers();

            let likesToFetch = {},
                likesToFetchArray = [];

            containers.forEach(function(el) {
                let url = el.getAttribute(settings.attrUrl) || '',
                    type = el.getAttribute(settings.attrTreffertypSub) || '',
                    id = el.getAttribute(settings.attrRecipeId) || '';

                if (id !== '' || type !== '' || url !== '') {
                    likesToFetch[`${type}-${id}-${url}`] = {
                        'target_url': url,
                        'treffertyp_sub': type,
                        'recipe_id': id
                    };
                }
            });

            // Convert to array:
            likesToFetchArray = Object.keys(likesToFetch).map(function (key) {
                return likesToFetch[key];
            });

            if (likesToFetchArray.length) {
                let http = new XMLHttpRequest(),
                    startTime = new Date().getTime();

                http.onload = function(event) {
                    let response = JSON.parse(event.target.response),
                        endTime = new Date().getTime();

                    writeLikes(response, endTime - startTime);
                };

                http.open('POST', store.apis.likesGet);
                http.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
                http.send(JSON.stringify({
                    'lang': store.lang,
                    'target_urls': likesToFetchArray
                }));
            }
        },

        updateStates = function() {
            getContainers();

            // console.log('likes.js update states 1', userLoggedIn);

            containers.forEach((bookmarkTrigger) => {
                let bookmarkUrl,
                    bookmarkRecipeId,
                    bookmarkRemoveTrigger,
                    bookmarkOwnRecipeId,
                    bookmarkTreffertypSub,
                    externalRecipeData,
                    tmpEl;

                const container = bookmarkTrigger.closest('.teaser') || bookmarkTrigger.parentElement;

                bookmarkRemoveTrigger = container.querySelector(`[${settings.bookmarkRemoveTrigger}]`);

                if (bookmarkTrigger) {
                    bookmarkOwnRecipeId = bookmarkTrigger.getAttribute(settings.attrOwnRecipeId);
                    bookmarkOwnRecipeId = (bookmarkOwnRecipeId || bookmarkOwnRecipeId === '0') ? Number(bookmarkOwnRecipeId) : false;
                    bookmarkTreffertypSub = bookmarkTrigger.getAttribute(settings.attrTreffertypSub);

                    if (tmpEl = container.querySelector(`[${settings.attrUrl}]`)) {
                        bookmarkUrl = tmpEl.getAttribute(settings.attrUrl);
                    }

                    if (tmpEl = container.querySelector(`[${settings.attrRecipeId}]`)) {
                        bookmarkRecipeId = Number(tmpEl.getAttribute(settings.attrRecipeId));
                    }
                } else {
                    bookmarkTrigger = container.querySelector(`[${settings.bookmarkExternalRecipeDataAttr}]`);

                    // external recipe
                    if (bookmarkTrigger) {
                        externalRecipeData = JSON.parse(bookmarkTrigger.getAttribute(settings.bookmarkExternalRecipeDataAttr));
                        bookmarkUrl = externalRecipeData['target_url'];
                    }
                }

                // console.log('likes.js update states 2', bookmarkRecipeId, bookmarkUrl, bookmarkOwnRecipeId, bookmarkTreffertypSub, bookmarkTrigger);

                if ((bookmarkUrl || bookmarkRecipeId || bookmarkTreffertypSub === 'own') && userLoggedIn) {
                    let bookmarks = [];

                    if (bookmarkRecipeId) {
                        bookmarks = globals.bookmarks.getByRecipeId(Number(bookmarkRecipeId));
                    } else if (bookmarkTreffertypSub === 'own') {
                        bookmarks = globals.bookmarks.getByOwnRecipeId(bookmarkOwnRecipeId);
                    } else {
                        bookmarks = globals.bookmarks.getByTargetUrl(bookmarkUrl);
                    }

                    // show remove trigger only if one of the bookmark exists in current active cookbook in myfooby area
                    if (bookmarkRemoveTrigger) {
                        const currentCookbook = document.querySelector('[data-myfooby-cookbook-container] [data-call-state="2"]');

                        if (isMyFooby && currentCookbook) {
                            const currentCookbookId = Number(currentCookbook.getAttribute('data-cookbook-id'));
                            const bookmarkInActiveCookbook = bookmarks.filter(bookmark => bookmark.cookbook_id === currentCookbookId).length > 0;

                            // console.log('likes.js update state 2.5', bookmarkUrl, bookmarkTrigger, bookmarks, bookmarkInActiveCookbook);

                            if (bookmarkInActiveCookbook) {
                                bookmarkRemoveTrigger.style.display = 'inline-flex';
                            } else {
                                bookmarkRemoveTrigger.style.display = 'none';
                            }
                        } else {
                            bookmarkRemoveTrigger.style.display = 'none';
                        }
                    }

                    const hasBookmarks = bookmarks.length > 0;
                    const wasActiveBefore = bookmarkTrigger.classList.contains(settings.active);
                    const svg = bookmarkTrigger.querySelector('svg');

                    // console.info('likes.js update state 3', bookmarkRecipeId || bookmarkUrl, bookmarkTrigger, bookmarks, wasActiveBefore);

                    bookmarkTrigger.classList.toggle(settings.active, hasBookmarks);

                    if (hasBookmarks && !wasActiveBefore) {
                        const isLittleFooby = bookmarkTrigger.hasAttribute(settings.triggersLittleFooby);

                        if (isLittleFooby) {
                            const heartLikeAnimation = littleFoobyHeart(svg);
                            svg.svgatorInstance = SvgatorPlayer.build(heartLikeAnimation);
                            svg.svgatorInstance.play();
                        } else {
                            greensock.fill(bookmarkTrigger, true);
                        }
                    } else if (!hasBookmarks && wasActiveBefore) {
                        const isLittleFooby = bookmarkTrigger.hasAttribute(settings.triggersLittleFooby);

                        if (isLittleFooby) {
                            const heartLikeAnimation = littleFoobyHeart(svg, true);
                            svg.svgatorInstance = SvgatorPlayer.build(heartLikeAnimation);
                            svg.svgatorInstance.play();
                        } else {
                            greensock.empty(bookmarkTrigger, true);
                        }
                    }
                }
            });
        };

    /**
     * Initializes the factory
     *
     * @return {object} instance
     */
    instance.init = async function() {
        // make sure the user has finished loading
        await globals.user.fetch();
        userLoggedIn = globals.user.isLoggedIn();

        loadLikes();
        pubsub.subscribe('likes.loadLikes', loadLikes);

        bindTrigger();
        pubsub.subscribe('likes.bindTrigger', bindTrigger);

        pubsub.subscribe('likes.setActives', updateStates);

        pubsub.subscribe('cookoverlay.next-step', function(progress) {
            if (Number(progress) === 100) {
                updateStates();
            }
        });

        pubsub.subscribe('bookmarks.created', updateStates);
        pubsub.subscribe('bookmarks.updated', updateStates);
        pubsub.subscribe('bookmarks.deleted', updateStates);
        pubsub.subscribe('bookmarks.fetched', updateStates);

        pubsub.subscribe('teasers.updateStates', updateStates);

        // Loads all likes from the user from the localStorage
        loadLocalStorage();

        if (userLoggedIn) {
            loadFromUserData();
            // deleteUnsyncedLikes();
            // saveUnsyncedLikes();
        }

        updateStates();

        return instance;
    };

    return instance.init();
};
