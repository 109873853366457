// TODO: delete file, since we took bookmarks and likes together, this functionality is not needed anymore
// because we don't have the bookmark button anymore inside the teasers

var globals = require('@/scaffold/globals').default,
    greensock = require('@/libs/greensock-animations').default;

const isMyFooby = document.querySelector('.t13-myfooby') !== null;

var defaults = {
    bookmarkTrigger: '[data-bookmark-trigger]',
    bookmarkRemoveTrigger: '[data-bookmark-remove-trigger]',
    bookmarkActiveClass: 'bookmark--active',
    bookmarkUrlAttr: 'data-bookmark-url',
    bookmarkTreffertypSubAttr: 'data-treffertyp-sub',
    bookmarkRecipeIdAttr: 'data-recipe-id',
    bookmarkOwnRecipeIdAttr: 'data-own-recipe-id',
    bookmarkExternalRecipeDataAttr: 'data-external-recipe-data'
};

export default function (container, options) {
    var instance = {},
        settings = Object.assign({}, defaults, options),
        bookmarkUrl,
        bookmarkRecipeId,
        bookmarkTrigger,
        bookmarkRemoveTrigger,
        bookmarkOwnRecipeId,
        bookmarkTreffertypSub,
        externalRecipeData,
        tmpEl;

    bookmarkTrigger = container.querySelector(settings.bookmarkTrigger);
    bookmarkRemoveTrigger = container.querySelector(settings.bookmarkRemoveTrigger);

    if (bookmarkTrigger) {
        bookmarkOwnRecipeId = bookmarkTrigger.getAttribute(settings.bookmarkOwnRecipeIdAttr);
        bookmarkOwnRecipeId = (bookmarkOwnRecipeId || bookmarkOwnRecipeId === '0') ? Number(bookmarkOwnRecipeId) : false;
        bookmarkTreffertypSub = bookmarkTrigger.getAttribute(settings.bookmarkTreffertypSubAttr);

        if (tmpEl = container.querySelector('[' + settings.bookmarkUrlAttr + ']')) {
            bookmarkUrl = tmpEl.getAttribute(settings.bookmarkUrlAttr);
        }

        if (tmpEl = container.querySelector('[' + settings.bookmarkRecipeIdAttr + ']')) {
            bookmarkRecipeId = Number(tmpEl.getAttribute(settings.bookmarkRecipeIdAttr));
        }
    } else {
        bookmarkTrigger = container.querySelector('[' + settings.bookmarkExternalRecipeDataAttr + ']');

        // external recipe
        if (bookmarkTrigger) {
            externalRecipeData = JSON.parse(bookmarkTrigger.getAttribute(settings.bookmarkExternalRecipeDataAttr));
            bookmarkUrl = externalRecipeData['target_url'];
        }
    }

    /**
     * Update state of bookmark trigger
     */
    instance.updateBookmarkState = function () {
        if ((bookmarkUrl || bookmarkRecipeId) && globals.user.isLoggedIn()) {
            let bookmarks = [];
            let hasBookmarks = false;

            if (bookmarkRecipeId) {
                bookmarks = globals.bookmarks.getByRecipeId(Number(bookmarkRecipeId));
            } else if (bookmarkTreffertypSub === 'own') {
                bookmarks = globals.bookmarks.getByOwnRecipeId(bookmarkOwnRecipeId);
            } else {
                bookmarks = globals.bookmarks.getByTargetUrl(bookmarkUrl);
            }

            hasBookmarks = bookmarks.length > 0;
            bookmarkTrigger.classList.toggle(settings.bookmarkActiveClass, hasBookmarks);

            // show remove trigger only if one of the bookmark exists in current active cookbook in myfooby area
            if (bookmarkRemoveTrigger) {
                const currentCookbook = document.querySelector('[data-myfooby-cookbook-container] [data-call-state="2"]');

                if (isMyFooby && currentCookbook) {
                    const currentCookbookId = Number(currentCookbook.getAttribute('data-cookbook-id'));
                    const bookmarkInActiveCookbook = bookmarks.filter(bookmark => bookmark.cookbook_id === currentCookbookId).length > 0;

                    if (bookmarkInActiveCookbook) {
                        bookmarkRemoveTrigger.style.display = 'inline-flex';
                    } else {
                        bookmarkRemoveTrigger.style.display = 'none';
                    }
                } else {
                    bookmarkRemoveTrigger.style.display = 'none';
                }
            }

            if (hasBookmarks) {
                greensock.fill(bookmarkTrigger, true);
            } else {
                greensock.empty(bookmarkTrigger);
            }
        }
    };

    // set initial state
    instance.updateBookmarkState();

    return instance;
};
