const createSwitch = ({ el, value }, Vue) => new Vue({
    el,
    data: {
        value
    },
    watch: {
        value() {
            this.$emit('change', this.value);
        }
    }
});

export default createSwitch;
