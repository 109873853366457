

/* public functions & vars */

var instance = {};

/**
 * Can throttle any event from being fired too often
 *
 * @param   {string}    type of the event you want to throttle
 * @param   {string}    name of the throttled event
 * @param   {object}    obj to bind the event to
 */
instance.throttle = function (type, name, obj) {
    obj = obj || window;

    var running = false,
        event = new CustomEvent(name),

        func = function () {
            if (!running) {
                requestAnimationFrame(function () {
                    obj.dispatchEvent(event);
                    running = false;
                });

                running = true;
            }
        };

    obj.addEventListener(type, func, { passive: true });
};

/**
 * inits throttling events
 */
instance.init = function () {
    instance.throttle('resize', 'optimizedResize', window);
    instance.throttle('scroll', 'optimizedScroll', window);
    instance.throttle('touchmove', 'optimizedTouchmove', window);
};

export default instance;
