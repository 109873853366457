const countdown = ({ el, state, options }, moment) => {
    let targetDate;

    const getSecondsLeft = () => {
        return targetDate.diff(moment(), 'seconds');
    };

    const ticker = () => {
        const timeLeft = getSecondsLeft();

        if (timeLeft <= 0) {
            clearTicker();
            displayFinishMessage();
            return;
        }

        const daysLeft = Math.floor(timeLeft / 60 / 60 / 24);
        const hoursLeft = Math.floor((timeLeft / 60 / 60) - (daysLeft * 24));
        const minutesLeft = Math.floor((timeLeft / 60) - (hoursLeft * 60) - (daysLeft * 24 * 60));
        const secondsLeft = timeLeft - (minutesLeft * 60) - (hoursLeft * 60 * 60) - (daysLeft * 24 * 60 * 60);

        state.refs.timeLeft.innerHTML = `
            ${daysLeft > 0 ? `<b>${daysLeft}</b>d : ` : ''}
            ${hoursLeft > 0 ? `<b>${hoursLeft}</b>h : ` : ''}
            ${minutesLeft > 0 ? `<b>${minutesLeft}</b>m : ` : ''}
            <b>${secondsLeft}</b>s`;
    };

    const clearTicker = () => {
        if (state.interval) {
            window.clearInterval(state.interval);
            state.interval = null;
        }
    };

    const displayFinishMessage = () => {
        state.refs.timeLeft.innerHTML = '<b>Der Wettbewerb ist abgelaufen!</b>';
    };

    /* Initiation/Destruction */

    state.init = () => {
        state.refs = {
            timeLeft: el.querySelector(options.refs.timeLeft)
        };

        targetDate = moment(el.getAttribute('data-countdown'));

        if (!targetDate.isValid() || getSecondsLeft() <= 0) {
            displayFinishMessage();
            return;
        }

        state.interval = window.setInterval(ticker, 1000);
    };

    state.destroy = () => {
        clearTicker();
    };

    state.init();

    return state;
};

export const config = {
    name: 'countdown',
    constructor: countdown,
    dependencies: ['Moment'],
    options: {
        refs: {
            timeLeft: '[data-time-left]'
        }
    }
};
