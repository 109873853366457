import observer from '@/libs/ni-observer';
import Helper from '@/helpers/helper';

const createHistory = () => {
    var instance = {},
        currentParams = {};

    const updateParamsDefaultOptions = {
        init: false,
        silent: false,
        popstate: false,
        forceUrlUpdate: false,
        push: true,
        data: null,
        overwrite: false
    };

    const popState = function(e) {
        instance.publish('popstate', [e]);

        const newParams = Helper.paramsFromUrl();
        updateParams(newParams, {
            popstate: true,
            overwrite: true
        });
    };

    const updateUrl = function(push, data) {
        const query = Helper.serialize(currentParams);
        const url = window.location.origin + window.location.pathname; // location.href.split('?')[0];
        const queryUrl = (query ? url + '?' + query : url) + window.location.hash;

        if (push) {
            history.pushState(data, null, queryUrl);
        } else {
            history.replaceState(data, null, queryUrl);
        }
    };

    const updateParams = function(params, options = {}) {
        var previousParams = Object.assign({}, currentParams);
        options = Object.assign({}, updateParamsDefaultOptions, options);

        if (options.overwrite) {
            currentParams = params;
        } else {
            Object.assign(currentParams, params);
        }

        if (options.forceUrlUpdate || (!options.init && !options.popstate)) {
            updateUrl(options.push, options.data);
        }

        if (!options.init && !options.silent) {
            const paramsCopy = Object.assign({}, currentParams);
            instance.publish('params.changed', [paramsCopy, previousParams, options]);
        }
    };

    instance.getParams = function() {
        return Object.assign({}, currentParams);
    };

    instance.pushParams = function(params, data = null, overwrite = false, silent = false) {
        updateParams(params, {
            push: true,
            data,
            silent,
            overwrite
        });
    };

    instance.replaceParams = function(params, data = null, overwrite = false, silent = false) {
        updateParams(params, {
            push: false,
            data,
            silent,
            overwrite
        });
    };

    instance.destroy = function() {
        window.removeEventListener('popstate', popState);
    };

    // make instance observable
    observer(instance);

    const initialParams = Helper.paramsFromUrl();
    updateParams(initialParams, {
        init: true
    });

    window.addEventListener('popstate', popState);

    return instance;
};

export default createHistory();
