import injector from '@/scaffold/injector';

export const startup = injector.resolve(
    ['Helper', 'Throttler', 'Tealium', 'Pictures', 'ScrollHandler', 'ScrollRestore', 'RecipeObserver', 'ViewportHeightFixer', 'Cookies'],
    (Helper, Throttler, Tealium, Pictures, ScrollHandler, ScrollRestore, RecipeObserver, ViewportHeightFixer, Cookies) => {
        // init helpers
        Helper.detectTouch();
        Helper.setTransitionEnd();
        Helper.setAnimationEnd();

        // init libraries
        Throttler.init();
        Pictures.init();
        Tealium.init();
        // TODO: refactor modules where window.scrollhandler is used to prevent window variable
        window.scrollhandler = ScrollHandler.init();
        ScrollRestore.init();
        RecipeObserver.enableObserving();
        ViewportHeightFixer.init();

        // make sure cookies are shared with all subdomains
        const rootDomain = location.hostname.split('.').reverse().splice(0, 2).reverse().join('.');

        Cookies.defaults = {
            path: '/',
            domain: rootDomain
        };
    });
