
import injector from '@/scaffold/injector';

export const startup = injector.resolve(['Store', 'Pubsub', 'HelperModal', 'Cookies'], (Store, Pubsub, HelperModal, Cookies) => {
    let popup = {
        lastShownDate: null,
        sessionsSinceLastShown: 0,
        conversions: {},
        shown: {}
    };

    const openModalByHash = function() {
        // open modal when hash given
        if (window.location.hash) {
            const hash = window.location.hash.substring(1);
            const triggerModal = 'trigger-modal-';

            if (Store.popup.settings[hash]) {
                HelperModal.openModal(Store.popup.settings[hash].id);
                return true;
            } else if (hash.indexOf(triggerModal) > -1) {
                const modalId = hash.substring(triggerModal.length);
                HelperModal.openModal(modalId);
                return true;
            }
        }

        return false;
    };

    const initConversionHandlers = function() {
        Object.keys(Store.popup.settings).forEach((modal) => {
            if (Store.popup.settings[modal].conversion) {
                Store.popup.settings[modal].conversion.forEach((conversion) => {
                    const { event, selector, pubsubEvent } = conversion;

                    if (pubsubEvent) {
                        Pubsub.subscribe(pubsubEvent, () => {
                            popup.conversions[modal] = true;
                        });
                    } else if (event && selector) {
                        document.addEventListener(event, (e) => {
                            if (e.target.matches(selector) || e.target.closest(selector)) {
                                popup.conversions[modal] = true;
                                savePopupCookie();
                            }
                        });
                    }
                });
            }
        });
    };

    const initSessionsSinceLastShown = function() {
        // if session cookie is not present
        if (!Cookies.get('session')) {
            Cookies.set('session', 'true');
            popup.sessionsSinceLastShown = popup.sessionsSinceLastShown + 1;
            savePopupCookie();
        }
    };

    const savePopupCookie = function() {
        Cookies.set('popup', popup, {
            // keep popup for 10 years or until your computer explodes
            expires: 365 * 10
        });
    };

    /**
     * Check if and which modal should be opened
     * according to days or session passed
     * since the last modal has been opened
     *
     * @returns {object} popup id and delay
     */
    const getNextJourneyPopup = function() {
        let lastPopupConverted = null;
        let popupKey = null;
        let delay = 0;

        for (let i = 0; i < store.popup.journey.length; i++) {
            // check if it has been already shown
            const id = store.popup.journey[i].id;
            const conversion = popup.conversions[id];
            const notShown = typeof conversion === 'undefined';

            if (notShown) {
                delay = store.popup.journey[i].delay || 0;
                popupKey = id;

                const lastPopup = store.popup.journey[i - 1];

                if (lastPopup) {
                    lastPopupConverted = typeof popup.conversions[lastPopup.id] !== 'undefined' ?
                        popup.conversions[lastPopup.id] : null;

                    const repeatWhenNotConverted = lastPopup.repeatWhenNotConverted || 0;

                    // show last popup again if no conversion happened and recurrence is configured
                    if (lastPopupConverted === false && repeatWhenNotConverted + 1 > popup.shown[lastPopup.id]) {
                        popupKey = lastPopup.id;
                    }
                }

                break;
            }
        }

        if (popupKey) {
            let show = false;

            const daysSinceLastPopup = popup.lastShownDate ? ((Date.now() - popup.lastShownDate) / 1000 / 60 / 60 / 24) : 0;
            const {
                convertedDay = 5,
                convertedSession = 5,
                notConvertedDay = 1,
                notConvertedSession = 2
            } = store.popup.nextPopupAfter || {};

            if (lastPopupConverted === true
                && (daysSinceLastPopup > convertedDay || popup.sessionsSinceLastShown > convertedSession)
            ) {
                show = true;
            } else if (lastPopupConverted === false
                && (daysSinceLastPopup > notConvertedDay || popup.sessionsSinceLastShown > notConvertedSession)
            ) {
                show = true;
            } else if (lastPopupConverted === null || daysSinceLastPopup === 0) { // no modal has been opened yet
                show = true;
            }

            if (show) {
                return { popupKey, delay };
            }
        }
    };

    const openJourneyPopup = function({ popupKey, delay = 0 } = {}) {
        if (!popupKey || !Store.popup.settings[popupKey] || !Store.popup.settings[popupKey].id) {
            return;
        }

        // open modal
        window.setTimeout(() => {
            HelperModal.openModal(Store.popup.settings[popupKey].id);

            // increase show counter
            popup.shown[popupKey] = typeof popup.shown[popupKey] !== 'undefined' ? popup.shown[popupKey] + 1 : 1;
            // treat as not converted when just opened
            popup.conversions[popupKey] = false;

            // update last shown date
            popup.lastShownDate = Date.now();
            // reset the sessions
            popup.sessionsSinceLastShown = 0;

            savePopupCookie();
        }, delay);
    };

    // follow popup journey
    if (store && store.popup && store.popup.journey && navigator.cookieEnabled) {
        // get current popup data from persistent cookie
        popup = Object.assign({}, popup, Cookies.getJSON('popup'));

        initSessionsSinceLastShown();
        initConversionHandlers();

        if (openModalByHash()) {
            return;
        }

        // determine next popup in popup journey and open it
        const nextPopup = getNextJourneyPopup();
        openJourneyPopup(nextPopup);
    } else {
        openModalByHash();
    }

    // used by google analytics to detect whether to show NPS modal
    window.getNextJourneyPopup = getNextJourneyPopup;
});
