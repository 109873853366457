
/**
 * createSaveLocation
 * @param {Object} module - Module
 * @param {Element} module.el - Element
 * @param {Object} module.state - State
 * @param {Object} module.options - Options
 * @return {Object} state
 */
const createSaveLocation = ({ el, state }) => {
    /* --- Private methods --- */

    /**
     * clickHandler
     * @return {undefined}
     */
    const clickHandler = () => {
        localStorage.setItem('tempLocation', window.location);
    };

    /**
     * addEventListeners
     * @return {undefined}
     */
    const addEventListeners = () => {
        el.addEventListener('click', clickHandler);
    };

    /**
     * removeEventListeners
     * @return {undefined}
     */
    const removeEventListeners = () => {
        el.removeEventListener('click', clickHandler);
    };

    /* --- Public methods --- */

    /**
     * init
     * @return {undefined}
     */
    state.init = () => {
        addEventListeners();
    };

    /**
     * destroy
     * @return {undefined}
     */
    state.destroy = () => {
        removeEventListeners();
    };

    state.init();

    return state;
};

export const config = {
    name: 'save-location',
    selector: '[data-save-location]',
    constructor: createSaveLocation
};
