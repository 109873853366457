
import injector from '@/scaffold/injector';

// scaffold
import Testdata from '@/testdata';
import Globals from '@/scaffold/globals';
import Pubsub from '@/scaffold/pubsub';

// helpers
import Helper from '@/helpers/helper';
import HelperModal from '@/helpers/helper-modal';

// own libs
import '@/libs/polyfills';
import '@/libs/dom-extension';
import '@/libs/CustomEase.min.js';
import Render from '@/libs/render';
import Observer from '@/libs/ni-observer';
import Tealium from '@/libs/tealium';
import UtagEvents from '@/libs/utag-events';
import Pictures from '@/libs/pictures';
import Breakpoint from '@/libs/breakpoint';
import Bridge from '@/libs/bridge';
import Easings from '@/libs/easings';
import MiniModal from '@/libs/mini-modal';
import Tooltip from '@/libs/tooltip';
import PdfHandler from '@/libs/pdf-handler';
import FormValidate from '@/libs/form-validate';
import Fractions from '@/libs/fractions';
import ApiFactory from '@/libs/api-factory';
import History from '@/libs/history';
import Throttler from '@/libs/throttler';
import RecipeObserver from '@/libs/recipe-observer';
import ScrollRestore from '@/libs/scroll-restore';
import ScrollHandler from '@/libs/ni-scrollhandler';
import ScrollShizzle from '@/libs/ni-scroll-shizzle';
import TitleLines from '@/libs/title-lines';
import Share from '@/libs/share';
import ViewportHeightFixer from '@/libs/vh-fixer';

// third party libs
import 'what-input';
import DebounceAnimationFrame from 'debounce-animation-frame';
import FormSerialize from 'form-serialize';
import Hogan from 'hogan.js';
import * as BodyScrollLock from 'body-scroll-lock';
import Scrollbooster from '@/libs/scrollbooster';
import Cookies from 'js-cookie';
import SvgatorPlayer from '@/libs/svgator-player';
import Fastdom from 'fastdom';
import StickyFill from 'stickyfilljs';

// plugins / mixins
import ViewController from '@/apps/main/plugins/view-controller';
import SearchController from '@/apps/main/plugins/search-controller';

// services
import createSeasonService from '@/apps/seasoncalendar/season.service.js';
import createNotesService from '@/apps/shared/services/notes';

// async dependencies
injector.registerAsync('Vue', () => import( /* webpackChunkName: "vue" */ 'vue').then(async module => {
    const smoothReflow = import(/* webpackChunkName: "vue" */ '@/apps/shared/components/smooth-reflow');
    await Promise.all([smoothReflow]);
    module.default.component('smooth-reflow', (await smoothReflow).default);
    return module.default;
}));

injector.registerAsync('TimelineMax', () => import( /* webpackChunkName: "TimelineMax" */ 'gsap/TimelineMax').then(async module => {
    await import(/* webpackChunkName: "TimelineMax" */ 'gsap/CSSPlugin');
    return module.default;
}));

injector.registerAsync('Swiper', () => import( /* webpackChunkName: "swiper" */ 'swiper').then(module => module.default));
injector.registerAsync('Moment', () => import( /* webpackChunkName: "moment" */ 'moment/src/moment').then(module => module.default));
injector.registerAsync('BaguetteBox', () => import( /* webpackChunkName: "baguettebox" */ '@/libs/baguetteBoxCustom').then(module => module.default));

// dependencies
injector.register('Testdata', Testdata);
injector.register('Store', window.store);
injector.register('Globals', Globals);
injector.register('Pubsub', Pubsub);

injector.register('Helper', Helper);
injector.register('HelperModal', HelperModal);

injector.register('Render', Render);
injector.register('Observer', Observer);
injector.register('Tealium', Tealium);
injector.register('UtagEvents', UtagEvents());
injector.register('Pictures', Pictures);
injector.register('Breakpoint', Breakpoint);
injector.register('Bridge', Bridge);
injector.register('Easings', Easings);
injector.register('MiniModal', MiniModal);
injector.register('Tooltip', Tooltip);
injector.register('PdfHandler', PdfHandler);
injector.register('FormValidate', FormValidate);
injector.register('Fractions', Fractions);
injector.register('ApiFactory', ApiFactory);
injector.register('History', History);
injector.register('Throttler', Throttler);
injector.register('RecipeObserver', RecipeObserver());
injector.register('ScrollRestore', ScrollRestore);
injector.register('ScrollHandler', ScrollHandler);
injector.register('ScrollShizzle', ScrollShizzle);
injector.register('TitleLines', TitleLines);
injector.register('Share', Share);
injector.register('ViewportHeightFixer', ViewportHeightFixer);

injector.register('DebounceAnimationFrame', DebounceAnimationFrame);
injector.register('FormSerialize', FormSerialize);
injector.register('Hogan', Hogan);
injector.register('BodyScrollLock', BodyScrollLock);
injector.register('Scrollbooster', Scrollbooster);
injector.register('Cookies', Cookies);
injector.register('SvgatorPlayer', SvgatorPlayer);
injector.register('Fastdom', Fastdom);
injector.register('StickyFill', StickyFill);

injector.register('ViewController', ViewController);
injector.register('SearchController', SearchController());

injector.register('SeasonService', createSeasonService());
injector.register('NotesService', createNotesService());
