let initialized = false;

const createLightbox = ({ state, options }, BaguetteBox) => {
    state.init = () => {
        if (initialized) {
            return true;
        }

        // lib can't be initialized with single DOM elements :(
        BaguetteBox.run(options.selector, {
            async: true
        });

        initialized = true;
    };

    state.destroy = () => {
        if (initialized) {
            BaguetteBox.destroy();
            initialized = false;
        }
    };

    return state.init();
};

export const config = {
    name: 'lightbox',
    selector: '.lightbox-gallery',
    constructor: createLightbox,
    dependencies: ['BaguetteBox'],
    options: {
        selector: '.lightbox-gallery'
    }
};
