const createAGBCheck = ({ el }, CreateApi, MiniModal, Pubsub, Vue, Store, Globals) => new Vue({
    el,
    data: {
        supercardAgb: false,
        supercardAgbDisabled: false,
        foobyAgb: false,
        foobyAgbDisabled: false,
        showError: false
    },
    created() {
        this.api = CreateApi(Store.apis.acceptAGBs, {
            method: 'POST'
        });

        // Check user agb status
        this.checkAgbStatus(Globals.user);
    },
    methods: {
        validate() {
            // Reset error
            this.showError = false;

            if (!this.supercardAgb || !this.foobyAgb) {
                this.showError = true;
            }

            return !this.showError;
        },
        submit() {
            if (this.validate()) {
                this.api.send({
                    success: (data) => {
                        data = JSON.parse(data);
                        if (data.success === true) {
                            Pubsub.publish('agb-check.accepted');
                            MiniModal.close();
                        }
                    }
                });
            }
        },
        abort() {
            // When the user declines, automatically log out
            let lastLocation = localStorage.getItem('tempLocation');

            if (!lastLocation) {
                lastLocation = window.location.origin;
            }

            if (lastLocation.indexOf('?') > -1) {
                lastLocation += '&auth-logout';
            } else {
                lastLocation += '?auth-logout';
            }

            localStorage.setItem('tempLocation', '');
            window.location = lastLocation;

            MiniModal.close();
        },
        checkAgbStatus(user) {
            if (user.get('foobyAgb') === true) {
                this.foobyAgb = true;
                this.foobyAgbDisabled = true;
            }

            if (user.get('supercardAgb') === true) {
                this.supercardAgb = true;
                this.supercardAgbDisabled = true;
            }

            MiniModal.open('modal-agbcheck', {
                backgroundClickClose: false,
                escClose: false,
                moveToBodyEnd: true
            });
        }
    }
});

export const config = {
    name: 'agb-check',
    dependencies: ['ApiFactory', 'MiniModal', 'Pubsub', 'Vue', 'Store', 'Globals'],
    constructor: createAGBCheck,
    autoInit: false
};
