

/**
 * Extends the DOM functionality in a jquery like way, but with optimal performance
 * Works only with pixelvalues at first
 *
 * Important Notes:
 *     * For this to work correcly crossbrowser, you'll need to have the requestAnimationFrame polyfill for older browsers
 *       http://paulirish.com/2011/requestanimationframe-for-smart-animating/
 *       http://my.opera.com/emoller/blog/2011/12/20/requestanimationframe-for-smart-er-animating
 *       requestAnimationFrame polyfill by Erik Möller. fixes from Paul Irish and Tino Zijdel
 *     * For the easing to work, you have to give the function itself as a parameter:
 *       Easing Functions - inspired from http://gizma.com/easing/
 *       only considering the t value for the range [0, 1] => [0, 1]
 *
 * @param {string} property to animate
 * @param {string} to animte the property to
 * @param {integer} duration of the animation (optional)
 * @param {Function} easing of the animation (optional)
 * @param {Function} callback to call after the animation has finished (optional)
 */
Element.prototype.notchAnimateStyle = function(property, to, duration, easing, callback) {

    duration = duration || 1000;
    easing = easing || '';
    callback = callback || null;
    to = to || 0;

    // // 'all' propertys ou should not be able to animate
    // var propertyBlacklist = [
    //     'display',
    //     'visibility'
    // ];
    // // check if property is blacklisted;

    var that = this,
        style = window.getComputedStyle(that),
        propStart = style.getPropertyValue(property),
        propFinish = to,
        propertyMeasure = '',
        difference,

        stripPropertyMeasure = function(value) {
            return parseFloat(value);
        },

        setPropertyMeasure = function(value) {
            if (value.indexOf('px') >= 0) {
                return 'px';
            } else if(value.indexOf('%') >= 0) {
                return '%';
            } else if(value.indexOf('em') >= 0) {
                return '%';
            } else if(value.indexOf('rem') >= 0) {
                return '%';
            } else if(value.indexOf('pt') >= 0) {
                return '%';
            } else {
                return '';
            }
        },

        startTime = null,
        progress,
        nextPropertyValue,

        animate = function(time) {
            if(startTime === null) {
                // first run through
                startTime = time;
            }

            // calculate progress (time passed since start)
            progress = time - startTime;

            // calculate next position with easing
            if (easing instanceof Function) {
                nextPropertyValue = difference * (easing(1 / duration * progress));
            } else {
                nextPropertyValue = difference * (1 / duration * progress);
            }

            that.style.setProperty(property, (nextPropertyValue + propStart) + propertyMeasure);

            if(progress > duration) {
                // exit the animation
                if(callback instanceof Function) {
                    return callback.call(that);
                }
                return;
            };

            requestAnimationFrame(animate);
        };

    propertyMeasure = setPropertyMeasure(propStart);

    propStart = stripPropertyMeasure(propStart);
    propFinish = stripPropertyMeasure(propFinish);

    difference = propFinish - propStart;

    // kicking off the animation
    requestAnimationFrame(animate);
};
