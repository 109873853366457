const createNewsletterSignout = ({ el, state }, Pubsub, Helper) => {

    const clickHandler = (event) => {
        event.preventDefault();

        const buid = Helper.get('buid'),
            newsletter = Helper.get('newsletter'),
            subscriber = Helper.get('subscriber'),
            http = new XMLHttpRequest(),
            params = 'newsletter=' + newsletter + '&buid=' + buid + '&subscriber=' + subscriber + '&lang=' + store.lang + '&url=' + window.location.href;

        http.onload = function(response) {
            if (response.target.status !== 200) {
                throw new Error('Newsletter signout failed!');
            }

            try {
                Pubsub.publish('newsletter.signout');
                const responseJSON = JSON.parse(response.target.responseText);
                window.location.href = responseJSON.redirectUrl;
            } catch (e) {
                throw new Error('Response could not be parsed as JSON');
            }
        };

        http.open('POST', store.apis.newsletterUnsubscribe);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.send(params);
    };

    /* Eventhandling */

    const addEventListeners = () => {
        el.addEventListener('click', clickHandler);
    };

    const removeEventListeners = () => {
        el.removeEventListener('click', clickHandler);
    };

    /* Initiation/Destruction */

    state.init = () => {
        addEventListeners();
    };

    state.destroy = () => {
        removeEventListeners();
    };

    state.init();

    return state;
};

export const config = {
    name: 'newsletter-signout',
    selector: '#newsletter-unsubscribe',
    constructor: createNewsletterSignout,
    dependencies: ['Pubsub', 'Helper'],
    options: {}
};
