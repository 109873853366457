let recipePageSiblingsLoaded = null;

const createPrevNext = ({ el, state, options }, Globals, Store, Cookies, Helper) => {
    const startUrl = Cookies.get('prevNextStartUrl');
    const currentIndex = Number(Cookies.get('prevNextCurrentIndex'));
    const currentUrl = Cookies.get('prevNextCurrentUrl');
    const params = JSON.parse(Cookies.get('prevNextParams') || '{}');

    const isCurrentPageEqualUrl = currentUrl === window.location.origin + window.location.pathname
        || Store.env === 'dev';

    const shouldInit = !isNaN(currentIndex) && isCurrentPageEqualUrl && startUrl && currentUrl;

    let total;

    const loadSiblingRecipes = () => new Promise((resolve, reject) => {
        const requestInitial = new XMLHttpRequest();

        requestInitial.onload = (e) => {
            const data = JSON.parse(e.target.response);
            const results = data.results;
            const count = data.resultcounts.all;

            const currentIsFirst = currentIndex === 0;
            const currentIsLast = currentIndex === count - 1;

            let firstLoaded = true;
            let lastLoaded = true;

            if (currentIsFirst && count > 2) {
                lastLoaded = new Promise((resolve, reject) => {
                    // fetch last recipe in list if we reached start
                    const requestLast = new XMLHttpRequest();

                    requestLast.onload = (e) => {
                        const data = JSON.parse(e.target.response);

                        if (data.results[0]) {
                            data.results[0].last = true;
                            // add at beginning of results to be available as "previous" page if start of list reached
                            results.unshift(data.results[0]);
                        }

                        resolve();
                    };

                    requestLast.onerror = reject;

                    requestLast.open('GET', Store.apis.search + '?' + Helper.solrSerialize({
                        ...params,
                        start: count - 1,
                        num: 1
                    }));

                    requestLast.send();
                });
            }

            if (currentIsLast && count > 2) {
                firstLoaded = new Promise((resolve, reject) => {
                    // fetch first recipe in list if we reached end
                    const requestFirst = new XMLHttpRequest();

                    requestFirst.onload = (e) => {
                        const data = JSON.parse(e.target.response);

                        if (data.results[0]) {
                            data.results[0].first = true;
                            // add at end of results to be available as "next" page if end of list reached
                            results.push(data.results[0]);
                        }

                        resolve();
                    };

                    requestFirst.onerror = reject;

                    requestFirst.open('GET', Store.apis.search + '?' + Helper.solrSerialize({
                        ...params,
                        start: 0,
                        num: 1
                    }));

                    requestFirst.send();
                });
            }

            Promise.all([firstLoaded, lastLoaded]).then(() => {
                resolve({
                    siblings: results,
                    count
                });
            });
        };

        requestInitial.onerror = reject;

        requestInitial.open('GET', Store.apis.search + '?' + Helper.solrSerialize({
            ...params,
            start: currentIndex === 0 ? 0 : currentIndex - 1,
            num: currentIndex === 0 ? 2 : 3
        }));

        requestInitial.send();
    });;

    const initNav = (results) => {
        const prevPage = total > 2
            ? results[0]
            : (total === 2 && currentIndex === 1
                ? results[0]
                : null
            );

        const nextPage = total > 2
            ? results[2]
            : (total === 2 && currentIndex === 0
                ? results[1]
                : null
            );

        // set overview link for user to be able to go back
        // to initial recipe overview where he came from
        state.refs.overviewLink.setAttribute('href', startUrl);

        // set previous site
        if (prevPage) {
            const currentIsFirst = currentIndex === 0;
            state.refs.prevLink.setAttribute('href', prevPage.url);
            state.refs.prevText.innerHTML = currentIsFirst
                ? Store.translations['trans:last'] : Store.translations['trans:previous'];

            state.refs.prevIcon.style.display = currentIsFirst ? 'none' : 'inline';
            state.refs.prevIconDouble.style.display = currentIsFirst ? 'inline' : 'none';
        } else {
            state.refs.prevLink.removeAttribute('href');
            state.refs.prevText.innerHTML = '';
            state.refs.prevIcon.style.display = 'none';
            state.refs.prevIconDouble.style.display = 'none';
        }

        // set next site
        if (nextPage) {
            const currentIsLast = currentIndex === total - 1;
            state.refs.nextLink.setAttribute('href', nextPage.url);
            state.refs.nextText.innerHTML = currentIsLast
                ? Store.translations['trans:first'] : Store.translations['trans:next'];

            state.refs.nextIcon.style.display = currentIsLast ? 'none' : 'inline';
            state.refs.nextIconDouble.style.display = currentIsLast ? 'inline' : 'none';
        } else {
            state.refs.nextLink.removeAttribute('href');
            state.refs.nextText.innerHTML = '';
            state.refs.nextIcon.style.display = 'none';
            state.refs.nextIconDouble.style.display = 'none';
        }

        // make prev/next nav visible
        el.style.display = 'flex';
    };

    const destroyNav = () => {
        el.removeAttribute('style');
    };

    const initHeaderClasses = () => {
        state.globalRefs.headerPanel.classList.add('big-padded');
        state.globalRefs.header.classList.add('header--grey-border');
    };

    const removeHeaderClasses = () => {
        state.globalRefs.headerPanel.classList.remove('big-padded');
        state.globalRefs.header.classList.remove('header--grey-border');
    };

    const clickPrevHandler = (e) => {
        const href = e.currentTarget.getAttribute('href');

        if (!href) {
            e.preventDefault();
            return;
        }

        Cookies.set('prevNextCurrentUrl', href.split('#')[0].split('?')[0]);
        Cookies.set('prevNextCurrentIndex',
            currentIndex === 0
                ? total - 1
                : currentIndex - 1
        );
    };

    const clickNextHandler = (e) => {
        const href = e.currentTarget.getAttribute('href');

        if (!href) {
            e.preventDefault();
            return;
        }

        Cookies.set('prevNextCurrentUrl', href.split('#')[0].split('?')[0]);
        Cookies.set('prevNextCurrentIndex',
            currentIndex === total - 1
                ? 0
                : currentIndex + 1
        );
    };

    const addEventListeners = () => {
        state.refs.prevLink.addEventListener('click', clickPrevHandler);
        state.refs.nextLink.addEventListener('click', clickNextHandler);
    };

    const removeEventListeners = () => {
        state.refs.prevLink.removeEventListener('click', clickPrevHandler);
        state.refs.nextLink.removeEventListener('click', clickNextHandler);
    };

    state.init = async () => {
        if (!Globals.isRecipe) {
            console.warn('Prev/Next navigation can only be initialized on a recipe site');
            return;
        }

        if (!shouldInit) {
            return;
        }

        state = {
            refs: {
                overviewLink: el.querySelector(options.refs.overviewLink),
                prevLink: el.querySelector(options.refs.prevLink),
                prevText: el.querySelector(options.refs.prevText),
                prevIcon: el.querySelector(options.refs.prevIcon),
                prevIconDouble: el.querySelector(options.refs.prevIconDouble),
                nextLink: el.querySelector(options.refs.nextLink),
                nextText: el.querySelector(options.refs.nextText),
                nextIcon: el.querySelector(options.refs.nextIcon),
                nextIconDouble: el.querySelector(options.refs.nextIconDouble)
            },
            globalRefs: {
                headerPanel: document.querySelector(options.globalRefs.headerPanel),
                header: document.querySelector(options.globalRefs.header)
            }
        };

        if (!recipePageSiblingsLoaded) {
            recipePageSiblingsLoaded = loadSiblingRecipes();
        }

        recipePageSiblingsLoaded.then(({ siblings, count }) => {
            total = count;
            initNav(siblings);
            initHeaderClasses();
            addEventListeners();
        });
    };

    state.destroy = () => {
        if (!Globals.isRecipe || !shouldInit) {
            return;
        }

        destroyNav();
        removeHeaderClasses();
        removeEventListeners();
    };

    state.init();

    return state;
};

export const config = {
    name: 'prev-next',
    constructor: createPrevNext,
    dependencies: ['Globals', 'Store', 'Cookies', 'Helper'],
    options: {
        refs: {
            overviewLink: '[data-overview-link]',
            prevLink: '[data-prev-link]',
            prevText: '[data-prev-link] [data-text]',
            prevIcon: '[data-prev-link] .icon-prev',
            prevIconDouble: '[data-prev-link] .icon-prev-double',
            nextLink: '[data-next-link]',
            nextText: '[data-next-link] [data-text]',
            nextIcon: '[data-next-link] .icon-next',
            nextIconDouble: '[data-next-link] .icon-next-double'
        },
        globalRefs: {
            headerPanel: '#page-header-recipe__panel-detail',
            header: '#header'
        }
    }
};
