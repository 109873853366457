import Popper from 'popper.js';

let titleTooltipCounter = 0;

const TitleTooltip = function (trigger, userOptions) {
    const options = {
        ...TitleTooltip.defaultOptions,
        ...userOptions
    };

    let instance = {};
    let opening = null;
    let closing = null;
    let popper = null;
    let initialOpenClosed = false;
    let transitionEndListener;
    let closeFrame = null;
    let openFrame = null;
    let tooltipElement = null;
    let tooltipInner = null;
    let tooltipArrow = null;
    let tooltipContent = null;

    const localStorageKeyClosedIntialOpen = `title-tooltip-${titleTooltipCounter++}-closed-initial-open`;

    const triggerMouseEnterListener = () => {
        instance.open();
    };

    const triggerMouseLeaveListener = () => {
        instance.close();
    };

    const triggerFocusListener = () => {
        instance.open();
    };

    const triggerBlurListener = () => {
        instance.close();
    };

    const createElement = () => {
        return new Promise((resolve) => {
            // create tooltip element
            tooltipElement = document.createElement('div');
            tooltipElement.className = 'tooltip tooltip--theme-dark tooltip--title';

            tooltipInner = document.createElement('div');
            tooltipInner.className = 'tooltip__inner';

            tooltipArrow = document.createElement('div');
            tooltipArrow.className = 'tooltip__arrow';
            tooltipArrow.setAttribute('x-arrow', '');

            tooltipContent = document.createElement('div');
            tooltipContent.className = 'tooltip__content';
            instance.updateContent();

            tooltipInner.appendChild(tooltipArrow);
            tooltipInner.appendChild(tooltipContent);
            tooltipElement.appendChild(tooltipInner);
            document.body.appendChild(tooltipElement);

            // init popper
            popper = new Popper((options.popperTrigger && options.popperTrigger()) || trigger, tooltipElement, {
                placement: options.placement,
                onCreate: () => {
                    resolve();
                }
            });
        });
    };

    const removeElement = () => {
        if (tooltipElement) {
            tooltipElement.remove();
        }
    };

    const destroyPopper = () => {
        if (popper) {
            popper.destroy();
            popper = null;
        }
    };

    const unbindEvents = () => {
        trigger.removeEventListener('focusin', triggerFocusListener);
        trigger.removeEventListener('focusout', triggerBlurListener);

        trigger.removeEventListener('mouseenter', triggerMouseEnterListener);
        trigger.removeEventListener('mouseleave', triggerMouseLeaveListener);
    };

    instance.handleClosed = () => {
        tooltipInner.removeEventListener('transitionend', transitionEndListener);
        transitionEndListener = null;

        tooltipElement.style.display = 'none';
        trigger.classList.remove(options.classTriggerOpen);

        if (options.onAfterClose) {
            options.onAfterClose.call(instance);
        }

        closing = null;
    };

    instance.init = () => {
        createElement();

        // bind events
        trigger.addEventListener('focusin', triggerFocusListener);
        trigger.addEventListener('focusout', triggerBlurListener);

        trigger.addEventListener('mouseenter', triggerMouseEnterListener);
        trigger.addEventListener('mouseleave', triggerMouseLeaveListener);

        // update intialOpenClosed from localStorage
        instance.getInitialOpenClosed();

        if (options.initialOpen
            && (!options.initialOpenUntilClosed
                || (options.initialOpenUntilClosed && !initialOpenClosed))
        ) {
            instance.open();
        }
    };

    instance.open = async () => {
        // console.log('open');

        if (closeFrame) {
            cancelAnimationFrame(closeFrame);
        }

        if (transitionEndListener) {
            instance.handleClosed();
        }

        if (opening) {
            return true;
        }

        if (options.onBeforeOpen) {
            await options.onBeforeOpen.call(instance);
        }

        // update content before opening
        instance.updateContent();

        opening = new Promise((resolve) => {
            popper.update();

            tooltipElement.style.display = 'block';

            openFrame = requestAnimationFrame(() => {
                openFrame = requestAnimationFrame(() => {
                    trigger.classList.add(options.classTriggerOpen);
                    tooltipElement.classList.add(options.classOpen);

                    resolve();

                    if (options.onAfterOpen) {
                        options.onAfterOpen.call(instance);
                    }

                    opening = null;
                });
            });
        });

        return opening;
    };

    instance.close = async ({ setInitialOpenClosed = true } = {}) => {
        // console.log('close', !popper, closing);

        if (openFrame) {
            cancelAnimationFrame(openFrame);
            opening = null;
        }

        if (closing) {
            return true;
        }

        if (options.onBeforeClose) {
            await options.onBeforeClose.call(instance);
        }

        if (setInitialOpenClosed) {
            localStorage.setItem(localStorageKeyClosedIntialOpen, true);
            initialOpenClosed = true;
        }

        closing = new Promise((resolve) => {
            tooltipInner.addEventListener('transitionend', transitionEndListener = (e) => {
                if (e.target === e.currentTarget) {
                    // console.log('transitionend', trigger);
                    instance.handleClosed();
                    // destroyPopper();
                    // removeElement();
                    resolve();
                }
            });

            closeFrame = requestAnimationFrame(() => {
                closeFrame = requestAnimationFrame(() => {
                    // console.log('remove class');
                    tooltipElement.classList.remove(options.classOpen);
                    closeFrame = null;
                });
            });
        });

        return closing;
    };

    instance.getInitialOpenClosed = () => {
        initialOpenClosed = localStorage.getItem(localStorageKeyClosedIntialOpen) !== null;
        return initialOpenClosed;
    };

    instance.updateContent = () => {
        tooltipContent.innerHTML = options.content && options.content();
        instance.update();
    };

    instance.getOptions = () => {
        return options;
    };

    instance.getTriggerElement = () => {
        return trigger;
    };

    instance.getTooltipElement = () => {
        return tooltipElement;
    };

    instance.getPopperInstance = () => {
        return popper;
    };

    instance.update = () => {
        if (!popper) {
            return;
        }

        popper.update();
    };

    instance.destroy = () => {
        unbindEvents();
        destroyPopper();
        removeElement();
    };

    instance.init();

    return instance;
};

TitleTooltip.defaultOptions = {
    content: () => { return ''; },
    popperTrigger: null,
    initialOpen: false,
    initialOpenUntilClosed: false,
    placement: 'bottom',
    classOpen: 'tooltip--open',
    classTriggerOpen: 'tooltip-trigger--active'
};

export default TitleTooltip;
