import Breakpoint from '@/libs/breakpoint';

var defaults = {
    triggerPosition: 0,
    triggerElement: null,
    duration: 100,
    preventUpdateOnBreakpoint: []
};

var scrollShizlle = function(options) {
    var instance = {
        settings: Object.assign({}, defaults, options),
        timeline: null
    };

    instance.setTimeline = function(timeLineObject) {
        instance.timeline = timeLineObject;
        instance.updateProgress();
        return instance;
    };

    instance.set = function(options) {
        var tmp = instance.settings;
        instance.settings = Object.assign({}, tmp, options);
        instance.setStartPosition();
        return instance;
    };

    instance.setStartPosition = function() {
        // TODO: triggerElement has no effect, should be implemented in setting the startPosition
        instance.settings.startPosition = instance.settings.triggerPosition;
        return instance;
    };

    instance.updateProgress = function() {
        if (instance.settings.preventUpdateOnBreakpoint.indexOf(Breakpoint.curBreakpoint) > -1) {
            return instance;
        }

        if(instance.timeline) {
            var scrollObject = {
                x: window.pageXOffset,
                y: window.pageYOffset
            };

            if(scrollObject.y < instance.settings.startPosition) {
                if(instance.timeline.progress() > 0) {
                    instance.timeline.progress(0);
                }
            } else if (scrollObject.y >= instance.settings.startPosition && scrollObject.y <= instance.settings.startPosition + instance.settings.duration) {
                var relativeProgress =  scrollObject.y - instance.settings.startPosition;
                var progress = (100 / instance.settings.duration * relativeProgress) / 100;
                instance.timeline.progress(progress);
            } else {
                if(instance.timeline.progress() < 1) {
                    instance.timeline.progress(1);
                }
            }
        }

        return instance;
    };

    instance.destroy = function() {
        // window.removeEventListener('scroll', instance.updateProgress, true);
        window.removeEventListener('optimizedScroll', instance.updateProgress, true);
        window.removeEventListener('optimizedTouchmove', instance.updateProgress, true);
        window.removeEventListener('optimizedResize', instance.updateProgress, true);
        return instance;
    };

    instance.remove = function() {
        // TODO: Should set everything to initial state, before scrollShizlle got created
    };

    instance.init = function() {
        instance.setStartPosition();
        // window.addEventListener('scroll', instance.updateProgress, true);
        window.addEventListener('optimizedScroll', instance.updateProgress, true);
        window.addEventListener('optimizedTouchmove', instance.updateProgress, true);
        window.addEventListener('optimizedResize', instance.updateProgress, true);
        return instance;
    };

    instance.init();

    return instance;
};

var instance = {};

instance.create = function(options) {
    return scrollShizlle(options);
};

export default instance;
