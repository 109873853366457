import injector from '@/scaffold/injector';

export default injector.resolve(['Store'], function createSeasonService(Store) {
    const instance = {};
    const items = [];
    const queue = [];
    let loading = false;
    let loaded = false;

    instance.getItems = (cb) => {
        if (loaded) {
            cb(items);
        } else if (!loaded) {
            queue.push(cb);

            if (!loading) {
                loading = true;

                const request = new XMLHttpRequest();

                request.onload = function() {
                    if (request.status === 200) {
                        const data = JSON.parse(request.response);
                        items.splice(0, items.length); // Clear all entries from array
                        items.push(...data.items);

                        // Notify all listeners
                        queue.forEach(cb => {
                            cb(items);
                        });
                        loading = false;
                    } else {
                        console.error(request.status);
                        loading = false;
                    }
                };

                request.onerror = function() {
                    console.error(request.status);
                    loading = false;
                };

                request.open('GET', Store.apis.calendar);
                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
                request.send('{}');
            }
        }
    };

    return instance;
});
