

// dependencies
var scopedPubsub = require('@/libs/ni-scoped-pub-sub').default;

// vars
var defaults = {
    selector: '[data-button]'
};

/**
 * Binds a button and fires when clicked
 *
 * @param {element} scope for the factory
 * @param {object} options to overwrite default options
 * @return {object} instance
 */
export default function(scope, options) {
    var settings = Object.assign({}, defaults, options),
        instance = {},
        button,

        /**
         * Binds the events to the buttons
         */
        bindEvents = function() {
            button.addEventListener('click', function() {
                instance.publish('click', instance, []);
            });
        };

    /**
     * Hides the button
     *
     * @return {object} instance
     */
    instance.hideButton = function() {
        button.style.display = 'none';

        return instance;
    };

    /**
     * Shows the button
     *
     * @return {object} instance
     */
    instance.showButton = function() {
        button.style.display = 'inline-flex';

        return instance;
    };

    /**
     * Adds the [disabled] to the button
     *
     * @return {object} instance
     */
    instance.disableButton = function() {
        button.setAttribute('disabled', '');

        return instance;
    };

    /**
     * Removes the [disabled] from the button
     *
     * @return {object} instance
     */
    instance.enableButton = function() {
        button.removeAttribute('disabled');

        return instance;
    };

    /**
     * Initializes the factory
     *
     * @return {object} instance
     */
    instance.init = function() {

        button = scope.querySelector(settings.selector);

        if (button instanceof Element) {
            bindEvents();

            // Extends the instance with the functions subscribe(), unsubscribe() & publish()
            scopedPubsub(instance);
        } else {
            console.error('No Element found with selector ' + settings.selector);
        }

        return instance;
    };

    return instance.init();
};
