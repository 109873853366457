import createGroceryList from './grocery-list';
import createIngredientFilter from './ingredient-filter';

const showBoughtIngredients = localStorage.getItem('shoppingList.showBoughtIngredients');
const areIngredientsGrouped = localStorage.getItem('shoppingList.areIngredientsGrouped');

const createMyFooby = ({ el, state, options }, Vue, Store, Helper, Globals, Pubsub, HelperModal, FormValidate) => {
    state.store = {};
    state.modules;

    // trigger refetching ingrendients from db on certain actions to keep app and web better in sync
    const refetchIgredientsOnUpdates = Store.env !== 'dev';

    const setFilteredRecipeIdByParam = (params) => {
        if (params['page'] === 'grocerylist') {
            state.store.filteredRecipeId = params['recipeId'] ? parseInt(params['recipeId'], 10) : null;
        }
    };

    const updateIngredients = (fetch = true) => {
        state.store.ingredients = [...Globals.shoppingListIngredients.getAll()];

        if (fetch && refetchIgredientsOnUpdates) {
            // fetch all ingrendients again to keep app and web in sync
            Globals.shoppingListIngredients.fetch({ shoppinglistId: state.store.shoppingList.id });
        }
    };

    state.fetchIngredients = async () => {
        await Globals.shoppingListIngredients.fetch({ shoppinglistId: state.store.shoppingList.id });
        updateIngredients(false);

        Globals.shoppingListIngredients.subscribe('created', updateIngredients);
        Globals.shoppingListIngredients.subscribe('updated', updateIngredients);
        Globals.shoppingListIngredients.subscribe('deleted', updateIngredients);

        Globals.shoppingListIngredients.subscribe('fetched', () => {
            updateIngredients(false);
        });
    };

    state.fetchOrCreateShoppingList = async () => {
        await Globals.shoppingLists.fetch();
        state.store.shoppingList = Globals.shoppingLists.get();

        if (!state.store.shoppingList) {
            // create shoppinglist on the fly
            const { shoppingList } = await Globals.shoppingLists.createShoppingList(Store.shoppingListName);
            state.store.shoppingList = shoppingList;
        }

        Globals.shoppingLists.subscribe('cleared', (shoppingListId, recipeId) => {
            if (shoppingListId !== state.store.shoppingList.id) {
                return;
            }

            // scroll to top
            window.scrollTo(0, 0);

            if (recipeId) {
                state.store.ingredients = state.store.ingredients.filter((ingredient) => {
                    return ingredient.recipeId !== recipeId;
                });

                if (state.store.filteredRecipeId === recipeId) {
                    document.querySelector('[data-ingredient-filter]').click();
                }
            } else {
                state.store.ingredients = [];
            }

            if (refetchIgredientsOnUpdates) {
                // fetch all ingrendients again to keep app and web in sync
                Globals.shoppingListIngredients.fetch({ shoppinglistId: state.store.shoppingList.id });
            }
        });
    };

    state.initSubmodules = () => {
        state.modules = {
            groceryList: createGroceryList({
                el: el.querySelector(options.refs.groceryList),
                store: state.store
            }, Vue, Store, Pubsub, Helper, Globals, HelperModal, FormValidate),
            ingredientFilter: createIngredientFilter({
                el: el.querySelector(options.refs.ingredientFilter),
                store: state.store
            }, Vue, Pubsub, Helper)
        };

        state.modules.groceryList.$on('showBoughtIngredients.changed', (showBoughtIngredients) => {
            localStorage.setItem('shoppingList.showBoughtIngredients', showBoughtIngredients);
        });

        state.modules.groceryList.$on('areIngredientsGrouped.changed', (areIngredientsGrouped) => {
            localStorage.setItem('shoppingList.areIngredientsGrouped', areIngredientsGrouped);
        });

        Globals.history.subscribe('params.changed', function (params, previousParams, options) {
            if (options.popstate) {
                setFilteredRecipeIdByParam(params);
            }
        });

        const currentParams = Globals.history.getParams();
        setFilteredRecipeIdByParam(currentParams);
    };

    state.init = async () => {
        Object.assign(state.store, options.store);
        state.initSubmodules();

        await state.fetchOrCreateShoppingList();
        await state.fetchIngredients();
    };

    state.init();

    return state;
};

export const config = {
    name: 'myfooby',
    dependencies: ['Vue', 'Store', 'Helper', 'Globals', 'Pubsub', 'HelperModal', 'FormValidate'],
    constructor: createMyFooby,
    options: {
        refs: {
            groceryList: '[data-module="grocery-list"]',
            ingredientFilter: '[data-module="ingredient-filter"]'
        },
        store: {
            editIngredientData: {
                id: null,
                title: '',
                amount: 1,
                unitId: 0,
                rayonId: '',
                state: 0,
                recipeId: null
            },
            filteredRecipeId: null,
            showBoughtIngredients: showBoughtIngredients === 'false' ? false : true,
            areIngredientsGrouped: areIngredientsGrouped === 'false' ? false : true,
            shoppingList: null,
            ingredients: []
        }
    }
};
