

// dependencies
var observer = require('@/libs/ni-observer').default;

// vars
var defaults = {
    container: '.simple-confirm',
    deny: '.simple-confirm__deny',
    confirm: '.simple-confirm__confirm'
};

/**
 * Factory for the
 *
 * @param {object} container - Element which contains deny and cofirm buttons
 * @param {object} options to overwrite default options
 * @return {object} instance
 */
export default function (container, options) {
    var settings = Object.assign({}, defaults, options),
        instance = {
            buttons: {}
        },

        deniedHandler = function () {
            instance.publish('denied');
        },

        confirmedHandler = function () {
            instance.publish('confirmed');
        },

        bindEvents = function() {
            if (instance.buttons.deny) {
                instance.buttons.deny.addEventListener('click', deniedHandler);
            }

            if (instance.buttons.confirm) {
                instance.buttons.confirm.addEventListener('click', confirmedHandler);
            }
        },

        unbindEvents = function () {
            if (instance.buttons.deny) {
                instance.buttons.deny.removeEventListener('click', deniedHandler);
            }
            if (instance.buttons.confirm) {
                instance.buttons.confirm.removeEventListener('click', confirmedHandler);
            }
        };

    /**
     * Initializes the factory
     *
     * @return {object} instance
     */
    instance.init = function() {
        instance.buttons.deny = container.querySelector(settings.deny);
        instance.buttons.confirm = container.querySelector(settings.confirm);

        bindEvents();

        // Extends the instance with the functions subscribe(), unsubscribe() & publish()
        observer(instance);

        return instance;
    };

    /**
     * Should revert to initial state
     *
     */
    instance.destroy = function() {
        unbindEvents();
        instance.buttons = {};
    };

    return instance.init();
};
