const MODAL_ID = 'modal-print';

/**
 * createPrintRecipe
 * @param {Object} module - Module
 * @param {Element} module.el - Element
 * @param {Object} module.state - State
 * @param {Object} module.options - Options
 * @param {Object} Pubsub - Global observer
 * @param {Object} Store - Global store
 * @param {Object} Testdata - Global testdata
 * @param {Object} MiniModal - Global mini modal
 * @param {Object} PdfHandler - PDF print lib
 * @return {Object} state
 */
const createPrintRecipe = ({ el, state, options }, Pubsub, Store, Testdata, MiniModal, PdfHandler) => {
    let pubsubHookIdQuantityUpdated;

    /* --- Private methods --- */

    const clickPrintButtonHandler = (event) => {
        event.preventDefault();
        const target = event.currentTarget;

        state.baseUrl = state.baseUrl || target.getAttribute('data-print-recipe');
        state.lang = state.lang || target.getAttribute('data-print-recipe-language');
        state.quantity = state.quantity || Number(target.getAttribute('data-print-recipe-quantity'));
        state.groceryList = typeof state.groceryList !== 'undefined'
            ? state.groceryList
            : !!Number(target.getAttribute('data-print-recipe-grocery-list'));

        updateDom();

        MiniModal.open(MODAL_ID);
    };

    const clickPrintButtonModalHandler = (event) => {
        event.preventDefault();

        let url = getUrl();
        let pages = getPages();

        if (Store.env === 'dev') {
            url = Testdata.pdfs.recipeWithGroceryList;
            pages = [
                { page: Testdata.pdfs.recipe },
                { page: Testdata.pdfs.groceryList, landscape: true }
            ];
        } else {
            url = getUrl();
            pages = getPages();
        }

        const printing = PdfHandler.printUrl(url, pages);

        if (printing) {
            state.refs.printButtonModal.classList.add(options.classStateLoading);

            printing.then(() => {
                Pubsub.publish('recipe.print', [{
                    filename: state.filename,
                    url: url
                }]);
            }).catch((e) => {
                // give up and print recipe website
                console.error(e);
                window.print();
            }).finally(() => {
                // delay class remove to always show a bit of the loading animation
                window.setTimeout(() => {
                    state.refs.printButtonModal.classList.remove(options.classStateLoading);
                }, options.timeoutRemoveLoadState);
            });
        }
    };

    const clickSaveButtonHandler = (event) => {
        event.preventDefault();

        let url;

        if (Store.env === 'dev') {
            url = Testdata.pdfs.recipeWithGroceryList;
        } else {
            url = getUrl();
        }

        const saving = PdfHandler.saveUrl(url, state.filename);

        if (saving) {
            state.refs.saveButton.classList.add(options.classStateLoading);

            saving.then(() => {
                Pubsub.publish('recipe.save', [{
                    filename: state.filename,
                    url: url
                }]);
            }).catch((e) => {
                // give up and just open pdf
                console.error(e);
                window.open(url, '_blank');
            }).finally(() => {
                // delay class remove to always show a bit of the loading animation
                window.setTimeout(() => {
                    state.refs.saveButton.classList.remove(options.classStateLoading);
                }, options.timeoutRemoveLoadState);
            });
        }
    };

    const updateDom = () => {
        const url = getUrl();

        state.refs.printButtonModal.setAttribute('href', url);
        state.refs.saveButton.setAttribute('href', url);
        state.refs.checkboxGroceryList.checked = state.groceryList;
    };

    const updateQuantityHandler = (newQuantity) => {
        state.quantity = newQuantity;
        updateDom();
    };

    const updateOptionsHandler = () => {
        let checkedCheckboxes = Array.from(state.refs.checkboxes).filter(checkbox => checkbox.checked);

        if (checkedCheckboxes.length > 0) {
            state.refs.actionBar.classList.remove(options.classStateHide);
        } else {
            state.refs.actionBar.classList.add(options.classStateHide);
        }

        state.groceryList = state.refs.checkboxGroceryList.checked;
        updateDom();
    };

    /**
     * Return recipe PDF url for current state.
     * @return {string} e.g. https: //test.fooby.ch/pdfs/culinaria/recipe.id-15968.lang-de.qty-8.sl.pdf
     */
    const getUrl = () => {
        return `${state.baseUrl}.lang-${state.lang}.qty-${state.quantity}${state.groceryList ? '.sl' : ''}.pdf`;
    };

    const getRecipeUrl = () => {
        return `${state.baseUrl}.lang-${state.lang}.qty-${state.quantity}.pdf`;
    };

    const getGroceryListUrl = () => {
        return `${state.baseUrl}.lang-${state.lang}.qty-${state.quantity}.sl-only.pdf`;
    };

    const getPages = () => {
        const pages = [{ page: getRecipeUrl() }];

        if (state.groceryList) {
            pages.push({ page: getGroceryListUrl(), landscape: true });
        }

        return pages;
    };

    const addEventListeners = () => {
        state.globalRefs.printButtons.forEach((button) => {
            button.addEventListener('click', clickPrintButtonHandler);
        });

        state.refs.printButtonModal.addEventListener('click', clickPrintButtonModalHandler);
        state.refs.saveButton.addEventListener('click', clickSaveButtonHandler);
        state.refs.checkboxes.forEach((checkbox) => {
            checkbox.addEventListener('change', updateOptionsHandler);
        });
    };

    const removeEventListeners = () => {
        state.globalRefs.printButtons.forEach((button) => {
            button.removeEventListener('click', clickPrintButtonHandler);
        });

        state.refs.printButtonModal.removeEventListener('click', clickPrintButtonModalHandler);
        state.refs.saveButton.removeEventListener('click', clickSaveButtonHandler);
        state.refs.checkboxes.forEach((checkbox) => {
            checkbox.removeEventListener('change', updateOptionsHandler);
        });
    };

    /* --- Public methods --- */

    state.init = () => {
        state = {
            refs: {
                actionBar: el.querySelector(options.refs.actionBar),
                checkboxes: el.querySelectorAll(options.refs.checkboxes),
                checkboxGroceryList: el.querySelector(options.refs.checkboxGroceryList),
                printButtonModal: el.querySelector(options.refs.printButtonModal),
                saveButton: el.querySelector(options.refs.saveButton)
            },
            globalRefs: {
                printButtons: document.querySelectorAll(options.globalRefs.printButtons)
            }
        };

        state.filename = state.refs.saveButton.getAttribute('download');

        addEventListeners();
        pubsubHookIdQuantityUpdated = Pubsub.subscribe('portionCalculator.update', updateQuantityHandler);
    };

    state.destroy = () => {
        removeEventListeners();
        Pubsub.unsubscribe(pubsubHookIdQuantityUpdated);
    };

    state.init();

    return state;
};

export const config = {
    name: 'print-recipe',
    selector: `#${MODAL_ID}`,
    constructor: createPrintRecipe,
    dependencies: ['Pubsub', 'Store', 'Testdata', 'MiniModal', 'PdfHandler'],
    options: {
        timeoutRemoveLoadState: 300,
        classStateLoading: 'loading',
        classStateHide: 'hide',
        refs: {
            actionBar: '[data-print-recipe-actionbar]',
            checkboxes: '[data-print-recipe-option]',
            checkboxGroceryList: '[data-print-recipe-option="grocery-list"]',
            printButtonModal: '[data-print-recipe-print]',
            saveButton: '[data-print-recipe-save]'
        },
        globalRefs: {
            printButtons: '[data-print-recipe]'
        }
    }
};
