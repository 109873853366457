

var instance = {},
    defaults = {
        button: '.back-to-top-button',
        buttonVisibleClass: 'back-to-top-button--visible',
        minScrollTopDistance: 400
    },
    settings,
    scrollHooks = {},
    button,
    buttonShown = false,
    pointCrossed = false,
    scrollDirection = 'down',

    hideButton = function() {
        buttonShown = false;
        button.classList.remove(settings.buttonVisibleClass);
    },

    showButton = function() {
        buttonShown = true;
        button.classList.add(settings.buttonVisibleClass);
    },

    updateButton = function() {
        if(buttonShown === false && pointCrossed === true && scrollDirection === 'up') {
            showButton();
        } else if(buttonShown === true && (pointCrossed === false || scrollDirection === 'down')) {
            hideButton();
        }
    },

    scrollHookPointcrossed = function(data) {
        if (data.scrollDirection === 'down') {
            pointCrossed = true;
        } else {
            pointCrossed = false;
        }
        updateButton();
    },

    scrollHookDirectionchange = function(data) {
        scrollDirection = data.scrollDirection;
        updateButton();
    },

    initEventHandlers = function() {
        scrollHooks.pointcrossed = window.scrollhandler.addHook({
            event: 'pointcrossed',
            point: settings.minScrollTopDistance,
            callback: scrollHookPointcrossed
        });

        scrollHooks.directionchange = window.scrollhandler.addHook({
            event: 'directionchange',
            callback: scrollHookDirectionchange
        });
    },

    removeEventHandlers = function() {
        window.scrollhandler.removeHook(scrollHooks.pointcrossed);
        window.scrollhandler.removeHook(scrollHooks.directionchange);
    };

// Public functions
instance.init = function (options) {
    settings = Object.assign({}, defaults, options);

    button = document.querySelector(settings.button);
    if (button) {

        // Move this to the end of the body for better performance of fixed position in IOS Safari
        document.body.appendChild(button);

        pointCrossed = window.scrollhandler.getPosition() >= settings.minScrollTopDistance;
        initEventHandlers();
    }

    return instance;
};

instance.destroy = function () {
    removeEventHandlers();
    hideButton();
};

export default instance;
