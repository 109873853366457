const CLASS_HOVER = 'hover';

const createMeatSelectionFactory = ({ el, state, options }, Pubsub) => {
    const triggerClickEvent = (hash) => {
        Pubsub.publish('meatSelection.click', [hash]);
    };

    const clickLinkHandler = (e) => {
        triggerClickEvent(e.currentTarget.getAttribute('href').substring(2));
    };

    const clickAreaHandler = (e) => {
        const meatAreaName = e.currentTarget.getAttribute('data-meat-svg-area-name');

        if (meatAreaName) {
            triggerClickEvent(meatAreaName);
            window.location.hash = `/${meatAreaName}`;
        }
    };

    const activeAreaHandler = (e) => {
        const meatAreaNr = parseInt(e.currentTarget.getAttribute('data-meat-svg-area'), 10);

        if (meatAreaNr) {
            const link = state.refs.links[meatAreaNr - 1];
            link.classList.add(CLASS_HOVER);
        }
    };

    const disableAreaHandler = (e) => {
        const meatAreaNr = parseInt(e.currentTarget.getAttribute('data-meat-svg-area'), 10);

        if (meatAreaNr) {
            const link = state.refs.links[meatAreaNr - 1];
            link.classList.remove(CLASS_HOVER);
        }
    };

    const activeLinkHandler = (e) => {
        const linkIndex = Array.from(state.refs.links).indexOf(e.currentTarget);

        if (linkIndex > -1) {
            const areas = state.refs.svgWrapper.querySelectorAll(`.meat-svg__area--${linkIndex+1}`);

            areas.forEach((area) => {
                area.classList.add(CLASS_HOVER);
            });
        }
    };

    const disableLinkHandler = (e) => {
        const linkIndex = Array.from(state.refs.links).indexOf(e.currentTarget);

        if (linkIndex > -1) {
            const areas = state.refs.svgWrapper.querySelectorAll(`.meat-svg__area--${linkIndex+1}`);

            areas.forEach((area) => {
                area.classList.remove(CLASS_HOVER);
            });
        }
    };

    const addEventListeners = () => {
        state.refs.meatAreas.forEach((meatArea) => {
            meatArea.addEventListener('click', clickAreaHandler);
            meatArea.addEventListener('mouseover', activeAreaHandler);
            meatArea.addEventListener('mouseout', disableAreaHandler);
        });

        state.refs.links.forEach((link) => {
            link.addEventListener('click', clickLinkHandler);
            link.addEventListener('mouseover', activeLinkHandler);
            link.addEventListener('mouseout', disableLinkHandler);
            link.addEventListener('focus', activeLinkHandler);
            link.addEventListener('blur', disableLinkHandler);
        });
    };

    const removeEventListeners = () => {
        state.refs.meatAreas.forEach((meatArea) => {
            meatArea.removeEventListener('click', clickAreaHandler);
            meatArea.removeEventListener('mouseover', activeAreaHandler);
            meatArea.removeEventListener('mouseout', disableAreaHandler);
        });

        state.refs.links.forEach((link) => {
            link.removeEventListener('click', clickLinkHandler);
            link.removeEventListener('mouseover', activeLinkHandler);
            link.removeEventListener('mouseout', disableLinkHandler);
            link.removeEventListener('focus', activeLinkHandler);
            link.removeEventListener('blur', disableLinkHandler);
        });
    };

    state.init = () => {
        state.refs = {
            svgWrapper: el.querySelector(options.refs.svgWrapper),
            meatAreas: el.querySelectorAll(options.refs.meatAreas),
            links: el.querySelectorAll(options.refs.links)
        };

        addEventListeners();
    };

    state.destroy = () => {
        removeEventListeners();
    };

    state.init();

    return state;
};

export const config = {
    name: 'meat-selection',
    constructor: createMeatSelectionFactory,
    dependencies: ['Pubsub'],
    options: {
        refs: {
            svgWrapper: '[data-meat-selection-svg-wrapper]',
            meatAreas: '[data-meat-selection-svg-wrapper] .meat-svg__area',
            links: '[data-meat-selection-link]'
        }
    }
};
