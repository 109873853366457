

// dependencies
var g = require('@/scaffold/globals').default,
    toArray = require('@/helpers/helper').default.toArray,
    fadeInTeasers = require('@/helpers/helper-fade').default.fadeInTeasers,
    pubsub = require('@/scaffold/pubsub').default,

    modules = {
        button: require('@/apps/search/modules/button').default
    };

var counter = 0;
const defaultNum = 9;

/**
 * Teaser Group recipelist & magazinelist
 *
 * @param {element} container where to initiate the teaser group recipelist & magazinelist
 */
var recipelist = function(container) {
    var instance = {},
        id = ++counter,
        currentStart = 0,
        tempContainer = document.createElement('div');

    // creating instances of the needed factories
    instance.button = modules.button(container);
    instance.button.subscribe('click', function() {
        currentStart += defaultNum;

        const params = {};
        params[`startRecipelist${id}`] = currentStart;

        if (currentStart !== 0 && g.isRecipe === false) {
            g.history.replaceParams(params);
        }

        var nextTeasers = [];
        tempContainer.childNodes.forEach(function(el, index) {
            if(index < defaultNum) {
                nextTeasers.push(el);
            }
        });

        nextTeasers.forEach(function(el) {
            container.querySelector('.row').appendChild(el);
        });

        // After all the teasers are appended, fetch the likes again
        pubsub.publish('likes.loadLikes');
        pubsub.publish('teasers.added');
        pubsub.publish('teaserGroupRecipelist.teasers.added');

        fadeInTeasers(container).then(function() {
            pubsub.publish('teaserGroupRecipelist.teasers.fadedIn');
        });

        if (tempContainer.childNodes.length === 0) {
            instance.button.hideButton();
        }
    });

    var teasers = toArray(container.querySelector('.row').querySelectorAll('.teaser'));
    var teasersWrappers = teasers.map(function(el) {
        return el.parentNode;
    });

    const currentParams = g.history.getParams();

    if (currentParams[`startRecipelist${id}`]) {
        currentStart = parseInt(currentParams[`startRecipelist${id}`]);
    }

    const startNum = defaultNum + currentStart;

    if (teasersWrappers.length <= startNum) {
        instance.button.hideButton();
    } else {
        var toHide = teasersWrappers.slice(startNum);
        toHide.forEach(function(el) {

            // Removes Node from DOM and buts in tempContainer
            tempContainer.appendChild(el);
        });
    }

    pubsub.publish('teasers.added');
    pubsub.publish('teaserGroupRecipelist.teasers.added');
};

export default function() {
    var containers = document.querySelectorAll('[data-teaser-group-recipelist], [data-teaser-group-magazinelist]');

    if (containers.length) {
        containers.forEach(recipelist);

        pubsub.publish('teasers.initialized', [containers]);
        pubsub.publish('teaserGroupRecipelist.teasers.initialized', [containers]);
    }
};
