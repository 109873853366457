

// dependencies
var forEach = require('@/helpers/helper').default.forEach,
    customEvent = require('@/helpers/helper').default.customEvent;

// vars
var defaults = {
    tokenContainer: '.tokens',
    removeAllToken: '.tokens__remove-all-token',
    tokenTemplate: '<li class="tokens__item" data-token-id="" data-token-value=""><span class="tokens__item-text" data-token-title=""></span> <span class="tokens__item-remove-trigger">&times;</span></li>',
    classNotEmpty: 'tokens--not-empty'
};

/**
 * Controls the tokens
 *
 * @param {Element} container which to search inside for the token-container
 * @param {object} options to configure
 * @return {object} instance
 */
export default function(container, options) {
    var settings = Object.assign({}, defaults, options),
        tokenContainer,
        removeAllTokenTrigger,
        instance = {},
        tokens = [],

        /**
         * Creates the Token via HTML template
         *
         * @param {object} token which should be created
         * @return {Element} token which was created
         */
        createToken = function(token) {
            var tempDiv = document.createElement('div');
            tempDiv.innerHTML = settings.tokenTemplate;
            var tokenNode = tempDiv.children[0];

            tokenNode.setAttribute('data-token-id', token.id);
            tokenNode.setAttribute('data-token-value', token.value);

            var title = tokenNode.querySelector('[data-token-title]');
            title.setAttribute('data-token-title', token.title);
            title.innerHTML = token.title;

            return tokenNode;
        },

        /**
         * Return the tokenNode as object
         *
         * @param {Element} tokenNode which should be taken the information from
         * @return {object} token
         */
        getTokenObject = function(tokenNode) {
            var token = {
                id: tokenNode.getAttribute('data-token-id'),
                value: tokenNode.getAttribute('data-token-value'),
                title: tokenNode.querySelector('[data-token-title]').getAttribute('data-token-title')
            };
            return token;
        },

        /**
         * Return the tokenNode as object
         *
         * @param {object} token which should be taken the information from
         * @return {Element} tokenNode
         */
        getTokenNode = function(token) {
            var tokenNode = container.querySelector('[data-token-id="' + token.id + '"]');

            return tokenNode;
        },

        /**
         * Checks if the remove all trigger should be shown or hidden
         */
        checkRemoveAllTokenTriggerVisibility = function() {
            if (tokens.length > 1) {
                removeAllTokenTrigger.classList.remove('hidden');
            } else {
                removeAllTokenTrigger.classList.add('hidden');
            }

            if (tokens.length > 0) {
                tokenContainer.classList.add(settings.classNotEmpty);
            } else {
                tokenContainer.classList.remove(settings.classNotEmpty);
            }
        };

    /**
     * Adds a token to the view
     *
     * @param {object} token which should be added
     * @param {boolean} cancelEvent determines if the event dispatch should be canceled or not
     * @return {object} instance
     */
    instance.addToken = function(token, cancelEvent) {

        cancelEvent = cancelEvent || false;

        var tokenNode = createToken(token);

        var closer = tokenNode.querySelector('.tokens__item-remove-trigger');
        closer.addEventListener('click', function(event) {
            var cancleTokenRemoveEvent = event.detail.cancelEvent || false;
            instance.removeToken(tokenNode, cancleTokenRemoveEvent);
        });

        // add token to internal array
        tokens.push(tokenNode);

        // add token to DOM
        tokenContainer.insertBefore(tokenNode, removeAllTokenTrigger);

        checkRemoveAllTokenTriggerVisibility();

        // dispatch the event on the container
        if (cancelEvent === false) {
            container.dispatchEvent(customEvent('token.addToken', { token: token, tokenNode: tokenNode }));
        }

        return instance;
    };

    /**
     * Removes a token from the view
     *
     * @param {object} tokenToRemove to be removed
     * @param {boolean} cancelEvent determines if the event dispatch should be canceled or not
     * @return {object} instance
     */
    instance.removeToken = function(tokenToRemove, cancelEvent) {

        cancelEvent = cancelEvent || false;

        var tokenNode, token;

        if(tokenToRemove instanceof Element) {
            token = getTokenObject(tokenToRemove);
            tokenNode = tokenToRemove;
        } else {
            token = tokenToRemove;
            tokenNode = getTokenNode(tokenToRemove);
        }

        // remove token from internal array
        var index = tokens.indexOf(tokenNode);
        tokens.splice(index, 1);

        // remove node from DOM
        tokenNode.parentNode.removeChild(tokenNode);

        checkRemoveAllTokenTriggerVisibility();

        // dispatch the event on the container
        if (cancelEvent === false) {
            container.dispatchEvent(customEvent('token.removeToken', { token: token, tokenNode: tokenNode }));
        }

        return instance;
    };

    /**
     * Removes all tokens from the view
     *
     * @param {boolean} cancelEvent determines if the event dispatch should be canceled or not
     * @return {object} tokens
     */
    instance.removeAllTokens = function(cancelEvent) {

        cancelEvent = cancelEvent || false;

        // if this gets called when it has no tokens, go straight to return
        if(tokens.length < 1) {
            return;
        }

        var removeTriggers = container.querySelectorAll('.tokens__item-remove-trigger');

        // generated the tokenObject that will be deleted
        var tokenObjects = [];
        for (var i = 0; i < tokens.length; i++) {
            tokenObjects.push(getTokenObject(tokens[i]));
        }

        forEach(removeTriggers, function(trigger) {
            trigger.dispatchEvent(customEvent('click', { cancelEvent: true }));
        });

        checkRemoveAllTokenTriggerVisibility();

        // dispatch the event on the container
        if (cancelEvent === false) {
            container.dispatchEvent(customEvent('token.removeAllToken', { tokens: tokenObjects }));
        }

        return tokenObjects;
    };

    /**
     * Initiates necessary stuff
     *
     * @return {object} instance
     */
    instance.init = function() {

        tokenContainer = container.querySelector(settings.tokenContainer);
        removeAllTokenTrigger = container.querySelector(settings.removeAllToken);

        removeAllTokenTrigger.addEventListener('click', function() {
            instance.removeAllTokens();
        });

        return instance;
    };

    return instance.init();
};
