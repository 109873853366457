

// dependencies
var pubsub = require('@/scaffold/pubsub').default,
    teaserView = require('@/apps/main/plugins/teaser-view').default,
    fadeInTeasers = require('@/helpers/helper-fade').default.fadeInTeasers,
    addEllipsis = require('@/helpers/helper-ellipsis').default.addEllipsis,
    toArray = require('@/helpers/helper').default.toArray;

// vars
var defaults = {
        teaser: '.teaser'
    },
    instance = {},
    settings,
    teaserCollection = [],

    fetchTeasers = function () {
        var teasers = toArray(document.querySelectorAll(settings.teaser)),
            ellipsisElements = [];

        teaserCollection = [];

        teasers.forEach(function (teaser) {
            teaserCollection.push(teaserView(teaser));

            if (teaser.querySelectorAll('[data-ellipsis]').length > 0) {
                ellipsisElements.push(teaser.querySelectorAll('[data-ellipsis]')[0]);
            }
        });

        addEllipsis(ellipsisElements);
    };

/**
 * Updates the state of all teasers.
 *
 * @param {boolean} refetchTeasers - Look for new teasers.
 */
instance.updateStates = function (refetchTeasers) {
    if (refetchTeasers) {
        fetchTeasers();
    }

    teaserCollection.forEach(function (teaser) {
        teaser.updateBookmarkState();
    });
};


// public methods & properties
instance.init = function (options) {
    settings = Object.assign({}, defaults, options);

    fetchTeasers();

    // fade in initially loaded teasers
    fadeInTeasers();

    // subscriptions
    pubsub.subscribe('bookmarks.created', instance.updateStates);
    pubsub.subscribe('bookmarks.updated', instance.updateStates);
    pubsub.subscribe('bookmarks.deleted', instance.updateStates);
    pubsub.subscribe('teasers.updateStates', instance.updateStates);

    return instance;
};

export default instance;
