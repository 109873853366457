const createGroceryList = ({ el, store }, Vue, Store, Pubsub, Helper, Globals, HelperModal, FormValidate) => new Vue({
    el,
    delimiters: ['((', '))'],
    data: store,
    watch: {
        showBoughtIngredients() {
            this.$emit('showBoughtIngredients.changed', this.showBoughtIngredients);
        },
        areIngredientsGrouped() {
            this.$emit('areIngredientsGrouped.changed', this.areIngredientsGrouped);
        }
    },
    mounted() {
        const form = this.$refs.ingredientForm;

        this.formValidate = FormValidate(form, {
            messages: {
                presence: form.getAttribute('data-msg-presence')
            }
        });

        this.formValidate.subscribe('valid', this.saveIngredient);
        this.formValidate.subscribe('invalid', this.invalidFormHandler);
    },
    methods: {
        invalidFormHandler(errors) {
            Pubsub.publish('errors.show', ['Ingredient Overlay', errors]);
        },
        stateChange(item) {
            Globals.shoppingListIngredients.updateShoppingListIngredient({
                shoppinglistId: this.shoppingList.id,
                ...item
            });
        },
        unitLabel(unitId) {
            return Store.translations.units[unitId] && Store.translations.units[unitId].name;
        },
        rayonLabel(rayonId) {
            return Store.translations.rayons[rayonId];
        },
        addIngredient() {
            this.editIngredientData = {
                id: null,
                title: '',
                amount: 1,
                unitId: 0,
                rayonId: Store.defaultRayonId,
                state: 0,
                recipeId: null
            };

            this.formValidate.reset();
            HelperModal.openModal('modal-ingredient');
        },
        editIngredient(data) {
            this.editIngredientData = { ...data };
            this.formValidate.reset();
            HelperModal.openModal('modal-ingredient');
        },
        cancelIngredient() {
            HelperModal.closeModal();
        },
        async deleteIngredient() {
            await Globals.shoppingListIngredients.deleteShoppingListIngredient({
                id: this.editIngredientData.id
            });

            HelperModal.closeModal();
        },
        async saveIngredient() {
            if (this.editIngredientData.id) {
                await Globals.shoppingListIngredients.updateShoppingListIngredient({
                    shoppinglistId: this.shoppingList.id,
                    ...this.editIngredientData
                });
            } else {
                await Globals.shoppingListIngredients.createShoppingListIngredient({
                    shoppinglistId: this.shoppingList.id,
                    ...this.editIngredientData
                });
            }

            HelperModal.closeModal();
        },
        deleteFilteredItems() {
            Globals.shoppingLists.clearShoppingList(this.shoppingList.id, this.filteredRecipeId);
        },
        toggleBoughtIngredients() {
            this.showBoughtIngredients = !this.showBoughtIngredients;
        }
    },
    computed: {
        units() {
            const selectableUnits = {};

            Object.keys(Store.translations.units).forEach((key) => {
                const unit = Store.translations.units[key];

                if (unit.selectable) {
                    selectableUnits[key] = unit.name;
                }
            });

            return selectableUnits;
        },
        rayons() {
            return Store.translations.rayons;
        },
        filteredRecipe() {
            if (!this.filteredRecipeId || !this.filteredIngredients[0]) {
                return null;
            }

            return {
                id: this.filteredRecipeId,
                name: this.filteredIngredients[0].recipe,
                isLittleFooby: this.filteredIngredients[0].recipeIsLittleFooby || false
            };
        },
        filteredIngredients() {
            return this.ingredients.filter((ingredient) => {
                if (this.filteredRecipeId) {
                    return ingredient.recipeId === this.filteredRecipeId;
                } else {
                    return true;
                }
            });
        },
        stateGroupedIngredients() {
            return Helper.groupBy(this.filteredIngredients, 'state');
        },
        stateRayonGroupedIngredients() {
            const stateRayonGroups = {};
            const stateGroups = this.stateGroupedIngredients;

            Object.keys(stateGroups).forEach((key) => {
                stateRayonGroups[key] = Helper.groupByArray(stateGroups[key], 'rayonId');
            });

            return stateRayonGroups;
        }
    }
});

export default createGroceryList;
