var Clipboard = require('clipboard');
import helpers from '@/helpers/helper';

// 280 - URL (always 24) = 256
const MAX_TWEET_LENGTH = 256;

var defaults = {
    facebookLink: '[data-share="Facebook"] a',
    whatsappLink: '[data-share="WhatsApp"] a',
    // messengerLink: '[data-share="Messenger"] a',
    // pinterestLink: '[data-share="Pinterest"] a',
    twitterLink: '[data-share="Twitter"] a',
    mailToLink: '[data-share="Mail"] a',
    copyTrigger: '[data-copy-trigger]',
    copyTarget: '[data-copy-target]',
    copyInput: '.copy-link__input',
    copySuccess: '.copy-link__success',
    copySuccessTimout: 1000
};

export default function (container, options) {
    var instance = {},
        settings = Object.assign({}, defaults, options),
        // shareApi,
        shareInfo = {},
        copyInput,
        copyTrigger,
        copyTarget,
        copySuccess,
        facebookLink,
        whatsappLink,
        // messengerLink,
        // pinterestLink,
        twitterLink,
        mailToLink,
        clipboard,

        // getPageID = function () {
        //
        //     // tbd.
        //     // how do we get page identification? is it the url, an id somewhere in the markup?
        //
        //     return window.location.href;
        // },

        focusHandler = function () {
            copyTarget.select();
        },

        handleResponse = function (response) {
            try {
                shareInfo = JSON.parse(response);
            } catch (e) {
                console.error('Error while parsing response. Is response in JSON format?', e);
                return;
            }

            copyInput.value = shareInfo.shortUrl;
            clipboard = new Clipboard(copyTrigger);
            clipboard.on('success', function () {
                var transitionHandler = function () {
                    setTimeout(function () {
                        copySuccess.removeEventListener(transitionEnd(), transitionHandler);
                        copySuccess.classList.remove('show-copied');
                    }, settings.copySuccessTimout);
                };

                copySuccess.addEventListener(transitionEnd(), transitionHandler);
                copySuccess.classList.add('show-copied');
            });

            copyTarget.addEventListener('focus', focusHandler);

            // encode values for share urls
            const title = encodeURIComponent(shareInfo.title);
            const twitterTitle = encodeURIComponent(helpers.abbreviateText(shareInfo.title, MAX_TWEET_LENGTH));
            const pinterestImage = encodeURIComponent(helpers.getMeta('og:image'));
            console.log(pinterestImage);

            const shortUrl = encodeURIComponent('https://fooby.ch/de/rezepte/25544/blumenkohl-caesar-salad?startAuto1=0');
            const shareMailSubject = encodeURIComponent(shareInfo.shareMailSubject);
            const shareMailBody = encodeURIComponent(shareInfo.shareMailBody);

            if (facebookLink) {
                facebookLink.href = `https://www.facebook.com/sharer/sharer.php?u=${shortUrl}`;
            }

            if (whatsappLink) {
                whatsappLink.href = `https://api.whatsapp.com/send?text=${title}${encodeURIComponent(':\r\n')}${shortUrl}`;
            }

            // if (messengerLink) {
            //     // https://developers.facebook.com/docs/sharing/reference/send-dialog#examples
            //     messengerLink.href = `fb-messenger://share/?link=${shortUrl}&app_id=123456789`;
            //     messengerLink.href = `http://www.facebook.com/dialog/send?app_id=123456789&link=${shortUrl}`;
            // }

            // if (pinterestLink) {
            //     pinterestLink.href = `https://pinterest.com/pin/create/link/?url=${shortUrl}&description=${title}&media=${pinterestImage}`;
            //     // https://www.pinterest.ch/pin-builder/?description=FOOBY&media=https%3A%2F%2Frecipecontent.fooby.ch%2F25544_3-2_1200-800.jpg&method=link&url=https%3A%2F%2Ffooby.ch%2Fde%2Frezepte%2F25544%2Fblumenkohl-caesar-salad%3FstartAuto1%3D0
            // }

            if (twitterLink) {
                twitterLink.href = `https://twitter.com/intent/tweet?text=${twitterTitle}&url=${shortUrl}`;
            }

            if (mailToLink) {
                mailToLink.href = `mailto:?subject=${shareMailSubject}&body=${shareMailBody}`;
            }
        },

        // handleError = function (status) {
        //     console.error('api call was not successful. returned status code ' + status + '.');
        // },

        getShareInfos = function () {
            // shareApi = api(store.apis.shareInfo, {
            //     method: 'GET',
            //     params: {
            //         url: getPageID()
            //     },
            //     success: handleResponse,
            //     error: handleError
            // });
            //
            // shareApi.send();

            // we don't need api call for the moment.
            handleResponse(JSON.stringify({
                title: document.title,
                shareMailSubject: document.title,
                shareMailBody: window.location.href,
                shortUrl: window.location.href
            }));
        };

    if (!container) {
        return console.error('container not available.');
    }

    // assign DOM elements
    copyInput = container.querySelector(settings.copyInput);
    copyTrigger = container.querySelector(settings.copyTrigger);
    copyTarget = container.querySelector(settings.copyTarget);
    copySuccess = container.querySelector(settings.copySuccess);
    mailToLink = container.querySelector(settings.mailToLink);
    facebookLink = container.querySelector(settings.facebookLink);
    whatsappLink = container.querySelector(settings.whatsappLink);
    // messengerLink = container.querySelector(settings.messengerLink);
    // pinterestLink = container.querySelector(settings.pinterestLink);
    twitterLink = container.querySelector(settings.twitterLink);

    // get share infos
    getShareInfos();

    // public methods
    instance.destroy = function () {
        copyTarget.removeEventListener('focus', focusHandler);
        clipboard.destroy();
    };

    return instance;
};
