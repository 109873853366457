

// vars
var instance = {},
    defaults = {
        'playerIdAttr': 'data-player-id',
        'videoIdAttr': 'data-video-id',
        'videoOverlay': '.video__overlay',
        'videoStopBtn': '.video__stop-btn',
        'videoOverlayOpenClass': 'video__overlay--open',
        'playerContainerIdPrefix': 'player-',
        'api': false
    },
    ytApiLoaded = false,
    videoTriggers;

// private functions
var initVideoHandler = function (video) {
        var settings = Object.assign({}, defaults);

        var playerId = video.getAttribute(settings.playerIdAttr),
            overlay = document.querySelector(settings.videoOverlay + '[' + settings.playerIdAttr + '="' + playerId + '"]');

        if (overlay === null)
            return;

        var videoId = overlay.getAttribute(settings.videoIdAttr),
            stopBtn = overlay.querySelector(settings.videoStopBtn),
            player = null,

            closeOverlay = function (e) {
                unbindEsc();

                var selfremovingListener;

                overlay.addEventListener(transitionEnd(), selfremovingListener = function () {
                    player.stopVideo();
                    overlay.style.display = 'none';
                    overlay.style.opacity = '';
                    overlay.classList.remove(settings.videoOverlayOpenClass);
                    overlay.removeEventListener(transitionEnd(), selfremovingListener);
                });

                // this triggers the transitionend listener at the end of css transition
                overlay.style.opacity = '0';

                if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            },

            keyUpHandler = function (event) {
                if (event.key === 'Escape') {
                    closeOverlay();
                }
            },

            bindEsc = function () {
                window.addEventListener('keyup', keyUpHandler);
            },

            unbindEsc = function () {
                window.removeEventListener('keyup', keyUpHandler);
            },

            openOverlay = function () {
                var open = function () {
                    overlay.style.display = 'block';

                    setTimeout(function () {
                        overlay.classList.add(settings.videoOverlayOpenClass);
                    }, 20);

                    player.playVideo();

                    // binds esc to close overlay
                    bindEsc();
                };

                if (!player) {
                    player = new YT.Player(settings.playerContainerIdPrefix + playerId, {
                        videoId: videoId,
                        playerVars: {
                            'controls': '1',
                            'showinfo': '0',
                            'hl': store.lang
                        },
                        events: {
                            'onReady': open,
                            'onError': function (event) {
                                console.log(event);
                            }
                        }
                    });
                } else {
                    open();
                }
            };

        // Bind the click event on the video to open the overlay
        video.addEventListener('click', function () {
            openOverlay();
        });

        stopBtn.addEventListener('click', closeOverlay);

        video.classList.add('is-init');

        // move overlay to end of body
        document.body.appendChild(overlay);
    },

    initTriggers = function () {
        for (var i = 0; i < videoTriggers.length; i++) {
            if (videoTriggers[i].classList.contains('is-init')) continue;
            initVideoHandler(videoTriggers[i]);
        }
    };


// public functions

/**
 * Loading the Youtube API
 */
instance.loadApi = function() {
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};

instance.init = function () {
    videoTriggers = document.querySelectorAll('[data-player-trigger]');

    if (videoTriggers.length > 0) {

        // kicking off the api laoder
        if(!ytApiLoaded) {
            instance.loadApi();
        } else {
            initTriggers();
        }
    }

    return instance;
};

window.onYouTubeIframeAPIReady = function () {
    ytApiLoaded = true;

    initTriggers();
};

export default instance;
