

// dependencies
import Helper from '@/helpers/helper';

var defaults = {
    method: 'GET',
    params: {},
    dataType: 'form',
    context: {},
    success: function () {},
    error: function () {},
    done: function () {}
};

/**
 * API factory which acts similar to jQuery.ajax().
 *
 * options Object:
 * - method: 'GET' (default), 'POST'
 * - params: key/value pairs
 * - dataType: 'form' (default), 'json'. With method 'GET' dataType will be overwritten to 'form'.
 * - success: function (). If response status is 200, this function will be called.
 * - error: function (statusCode). If response status is other than 200, this function will be called.
 * - done: function (). This function will be called when request has finished, regardless of success or error.
 *
 * Instance methods:
 * - Instance.send() : Make the ajax call.
 * - Instance.setOptions: Change Options after instance has been created.
 * - Instance.setUrl: Change after instance has been created.
 *
 * @param {string} url - URL to send request to
 * @param {object} options - Object with all options described above
 * @returns {object} ApiInstance
 */
export default function (url, options) {
    var instance = {},
        settings = Object.assign({}, defaults, options),
        apiUrl = url,
        data = null,
        headerContentType,

        getSendData = function () {
            var sendData;

            switch (settings.dataType) {
            case 'json':
                sendData = JSON.stringify(settings.params);
                headerContentType = 'application/json;charset=UTF-8';
                break;

            case 'form':
            default:
                sendData = Helper.serialize(settings.params);
                headerContentType = 'application/x-www-form-urlencoded;charset=UTF-8';
            }

            return sendData;
        };

    /**
     * Upadet the currently set options
     *
     * @param {object} options - Options object
     */
    instance.setOptions = function (options) {
        settings = Object.assign({}, settings, options);
    };

    /**
     * Update the API url.
     *
     * @param {string} url - Url for this API
     */
    instance.setUrl = function (url) {
        apiUrl = url;
    };

    /**
     * Make the Ajax call.
     *
     * @param {object} options - You can pass an Object here, which overrides the current options.
     *
     * @returns {Promise} - Promise resolving when request has finished.
     */
    instance.send = function (options) {
        return new Promise((resolve, reject) => {
            let sendData;
            let query = null;

            if (options) {
                instance.setOptions(options);
            }

            const request = new XMLHttpRequest();

            if (settings.cookies) {
                request.withCredentials = true;
            }

            request.onload = function() {
                if (request.status === 200) {
                    settings.success(request.response, this);
                } else {
                    settings.error(request.status, request.response);
                }

                settings.done();
                resolve();
            }.bind(settings.context);

            request.onerror = function() {
                settings.error(request.status, request.response);
                reject();
            }.bind(settings.context);

            if (settings.method === 'GET' || settings.method === 'DELETE') {

                // overwrite dataType
                settings.dataType = 'form';

                if ((sendData = getSendData()) !== '') {
                    query = '?' + sendData;
                }
            } else {
                data = getSendData();
            }

            request.open(settings.method, apiUrl + (query || ''));
            request.setRequestHeader('Content-Type', headerContentType);
            request.send(data);
        });
    };

    return instance;
};
