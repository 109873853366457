import Pubsub from '@/scaffold/pubsub';

// vars
var defaults = {
    loadedClass: 'global--visible'
};

/**
 * Fancyload delays displaying images, after they're leoaded by adding a class (actual transition must be handles via css)
 *
 * @param {any} elements - either a NodeList of an Element
 * @param {object} options - overwrites default settings (optional)
 * @return {object} returns instance of factory
 */
export default function (elements, options) {
    var settings = Object.assign({}, defaults, options),
        instance = {},

        /**
         * Add an Eventlistener to the load event and add a class after it's loaded
         *
         * @param {object} element to add the eventlistener
         */
        addLoadListener = function(element) {
            if (!element.complete) {
                element.addEventListener('load', function() {
                    setTimeout(function() {
                        element.classList.add(settings.loadedClass);
                        Pubsub.publish('image.loaded', [element]);
                    }, 100);
                });
            } else {
                // picture allready loaded
                element.classList.add(settings.loadedClass);
                Pubsub.publish('image.loaded', [element]);
            }
        };

    /**
     * Initializes the factory
     *
     * @param {object} elements to be fancyloaded
     * @return {object} instance
     */
    instance.init = function(elements) {
        if(elements instanceof Element) {
            addLoadListener(elements);
        } else if (elements instanceof NodeList) {
            elements.forEach(function(element) {
                addLoadListener(element);
            });
        }

        return instance;
    };

    /**
     * Should revert to initial state
     */
    instance.destroy = function() {

    };

    return instance.init(elements);
};
