const weneed = ({ el, state, options }, Pubsub) => {
    let pubsubHookIdQuantityUpdated;

    const getUrl = () => {
        const recipeUrl = options.recipeUrl || location.origin + location.pathname;
        return `${options.url}?recipe=${encodeURIComponent(recipeUrl)}&baseQuantity=${options.baseQuantity}&requestedQuantity=${state.quantity}`;
    };

    const updateDom = () => {
        const url = getUrl();
        el.setAttribute('href', url);
    };

    const updateQuantityHandler = (newQuantity) => {
        state.quantity = newQuantity;
        updateDom();
    };

    /* Initiation/Destruction */

    state.init = () => {
        state.quantity = options.baseQuantity;
        updateDom();
        pubsubHookIdQuantityUpdated = Pubsub.subscribe('portionCalculator.update', updateQuantityHandler);
    };

    state.destroy = () => {
        Pubsub.unsubscribe(pubsubHookIdQuantityUpdated);
    };

    state.init();

    return state;
};

export const config = {
    name: 'weneed',
    constructor: weneed,
    dependencies: ['Pubsub'],
    options: {
        url: 'https://app.weneed.ch',
        recipeUrl: '',
        baseQuantity: 4
    }
};
