var instance = {};

instance.addEllipsis = function(elements) {
    if (elements.length === 0) {
        return;
    }

    return new Promise(function () {
        elements.forEach(function(element) {
            const reduceContent = function (words, position) {
                    return words.slice(0, position).join(' ');
                },
                addElementEllipsis = function () {
                    let height = element.offsetHeight,
                        position = 0;

                    element.innerHTML = '';

                    while (height >= element.scrollHeight && position <= wordCount) {
                        element.innerHTML = reduceContent(words, ++position) + ellipsisString;
                    }

                    if (position > wordCount) {
                        element.innerHTML = reduceContent(words, position);
                    } else {
                        element.innerHTML = reduceContent(words, --position) + ellipsisString;
                    }
                },
                ellipsisString = '...',
                originalContent = element.innerHTML,
                words = originalContent.split(' '),
                wordCount = words.length;

            addElementEllipsis();

            window.addEventListener('optimizedResize', function() {
                element.innerHTML = originalContent;
                addElementEllipsis();
            });
        });
    }).then(function () {
        pubsub.publish('ellipsis.add');
    });
};

export default instance;
