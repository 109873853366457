
const createMiniModalFactory = ({ el, state, options }, HelperModal) => {
    let modalId;

    const clickHandler = (event) => {
        HelperModal.openModal(modalId, null, event);
    };

    /* Eventhandling */

    const addEventListeners = () => {
        el.addEventListener('click', clickHandler);
    };

    const removeEventListeners = () => {
        el.removeEventListener('click', clickHandler);
    };

    /* Initiation/Destruction */

    state.init = () => {
        modalId = el.getAttribute(options.attrTrigger);
        addEventListeners();
    };

    state.destroy = () => {
        removeEventListeners();
    };

    state.init();

    return state;
};

export const config = {
    name: 'mini-modal-factory',
    selector: '[data-mini-modal-target]',
    constructor: createMiniModalFactory,
    dependencies: ['HelperModal'],
    options: {
        attrTrigger: 'data-mini-modal-target'
    }
};
