

// dependencies
var observer = require('@/libs/ni-observer').default;

// vars
var defaults = {
    delete: '[data-sdb-delete]',
    save: '[data-sdb-save]',
    cancel: '[data-sdb-cancel]'
};

/**
 * Save/Delete bar module
 *
 * @param {object} container - Element which contains save and delete buttons
 * @param {object} options to overwrite default options
 * @return {object|null} instance - Will return nothing if container is not a DOM element.
 */
export default function(container, options) {
    var settings = Object.assign({}, defaults, options),
        instance = {
            buttons: {}
        },

        deleteHandler = function () {
            instance.publish('delete');
        },

        saveHandler = function () {
            instance.publish('save');
        },

        cancelHandler = function () {
            instance.publish('cancel');
        },

        bindEvents = function() {
            instance.buttons.delete && instance.buttons.delete.addEventListener('click', deleteHandler);
            instance.buttons.save && instance.buttons.save.addEventListener('click', saveHandler);
            instance.buttons.cancel && instance.buttons.cancel.addEventListener('click', cancelHandler);
        },

        unbindEvents = function () {
            instance.buttons.delete && instance.buttons.delete.removeEventListener('click', deleteHandler);
            instance.buttons.save && instance.buttons.save.removeEventListener('click', saveHandler);
            instance.buttons.cancel && instance.buttons.cancel.removeEventListener('click', cancelHandler);
        };

    // Abort script if container is not an element.
    if (container instanceof HTMLElement === false) {
        console.error('Passed container element is not a DOM element.');
        return;
    }

    /**
     * Adds the [disabled] to the Delete button. Assuming element is <button>.
     *
     * @return {object} instance
     */
    instance.disableDelete = function() {
        if (instance.buttons.delete) {
            instance.buttons.delete.disabled = true;
        }

        return instance;
    };

    /**
     * Removes the [disabled] from the Delete button. Assuming element is <button>.
     *
     * @return {object} instance
     */
    instance.enableDelete = function() {
        if (instance.buttons.delete) {
            instance.buttons.delete.disabled = false;
        }

        return instance;
    };

    /**
     * Adds the [disabled] to the Save button. Assuming element is <button>.
     *
     * @return {object} instance
     */
    instance.disableSave = function() {
        if (instance.buttons.save) {
            instance.buttons.save.disabled = true;
        }

        return instance;
    };

    /**
     * Removes the [disabled] from the Save button. Assuming element is <button>.
     *
     * @return {object} instance
     */
    instance.enableSave = function() {
        if (instance.buttons.save) {
            instance.buttons.save.disabled = false;
        }

        return instance;
    };

    /**
     * Adds the [disabled] to the Cancel button. Assuming element is <button>.
     *
     * @return {object} instance
     */
    instance.disableCancel = function() {
        if (instance.buttons.cancel) {
            instance.buttons.cancel.disabled = true;
        }

        return instance;
    };

    /**
     * Removes the [disabled] from the Cancel button. Assuming element is <button>.
     *
     * @return {object} instance
     */
    instance.enableCancel = function() {
        if (instance.buttons.cancel) {
            instance.buttons.cancel.disabled = false;
        }

        return instance;
    };

    /**
     * Initializes the factory
     *
     * @return {object} instance
     */
    instance.init = function() {
        instance.buttons.delete = container.querySelector(settings.delete);
        instance.buttons.save = container.querySelector(settings.save);
        instance.buttons.cancel = container.querySelector(settings.cancel);

        bindEvents();

        // Extends the instance with the functions subscribe(), unsubscribe() & publish()
        observer(instance);

        return instance;
    };

    instance.destroy = function () {
        unbindEvents();
        instance.unsubscribeAll();
        instance.buttons = {};
    };

    return instance.init();
};
