import LazyLoad from 'vanilla-lazyload/dist/lazyload';
import picturefill from 'picturefill';
import objectFitImages from 'object-fit-images';
import pubsub from '@/scaffold/pubsub';

let myLazyLoad;

/**
 * Eventhandler for subscribed events
 * Re-executes picturefill
 */
const handler = () => {
    myLazyLoad.update();
};

const instance = {
    init() {
        myLazyLoad = new LazyLoad({
            // eslint-disable-next-line camelcase
            elements_selector: '[data-lazy]',
            // eslint-disable-next-line camelcase
            callback_set: (element) => {
                picturefill({
                    reevaluate: true,
                    elements: [element]
                });

                objectFitImages(element);
            },
            // eslint-disable-next-line camelcase
            callback_load: (element) => {
                pubsub.publish('image.loaded', [element]);
            }
        });

        pubsub.subscribe('teasers.added', handler);
        pubsub.subscribe('teasers.fadedIn', handler);
        pubsub.subscribe('cookoverlay.opened', handler);

        // initial call for already visible pictures
        picturefill();
        objectFitImages();
    }
};

export default instance;
