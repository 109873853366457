

var breakpoint = require('@/libs/breakpoint').default,
    accordionFactory = require('@/apps/main/modules/accordion').default,
    getCoords = require('@/helpers/helper').default.getCoords;


// vars
var instance = {},
    defaults = {
        footerNavId: 'footer-nav',
        footerMetaNavId: 'footer-meta-navigation',
        footerSocialMediaListId: 'social-media-list'
    },
    settings,
    footer,
    footerNavAccordion,
    footerMetaNavigation,
    footerSocialMediaList;


// private functions
var updateAccordionState = function() {
        if (breakpoint.curBreakpoint === 'small' && !footerNavAccordion.initialized()) {
            footerNavAccordion.init();
        } else if (breakpoint.curBreakpoint !== 'small' && footerNavAccordion.initialized()) {
            footerNavAccordion.destroy();
        }
    },

    /**
     * Aligns either the social icons to the metanavigation or the other way around
     */
    alignFooterMetanavigation = function() {

        var metaOffsetTop = getCoords(footerMetaNavigation).top - 45; // subtracting distance between social icon title and icons to align with icons
        var socialOffsetTop = getCoords(footerSocialMediaList).top;

        if(socialOffsetTop > metaOffsetTop) {
            footerMetaNavigation.style.paddingTop = (socialOffsetTop - metaOffsetTop) + 'px';
        } else if (socialOffsetTop < metaOffsetTop) {
            footerSocialMediaList.style.paddingTop = (metaOffsetTop - socialOffsetTop) + 'px';
        }
    },

    /**
     * Resets the Alignment on social media icons or metanavigation
     */
    resetFooterMetanavigation = function() {
        footerMetaNavigation.style.paddingTop = '';
        footerSocialMediaList.style.paddingTop = '';
    },

    /**
     * Handles Breakpoint changes and adds or removes the listener for aligning
     *
     * @param {object} event with the breakpoint data in it
     */
    breakpointHandler = function() {
        resetFooterMetanavigation();

        if (breakpoint.curBreakpoint === 'xlarge') {
            alignFooterMetanavigation();
        }
    };


// public functions
instance.destroy = function() {
    footerNavAccordion.destroy();
    window.removeEventListener('optimizedResize', updateAccordionState);
    window.removeEventListener('optimizedResize', breakpointHandler);
};

instance.init = function(options) {
    settings = Object.assign({}, defaults, options);
    footer = document.querySelectorAll('.footer')[0];

    if (footer instanceof Element !== true) {

        // Exit this if the footer was not found in the DOM
        return;
    }

    footerNavAccordion = accordionFactory.create(document.getElementById(settings.footerNavId));
    footerMetaNavigation = document.getElementById(settings.footerMetaNavId);
    footerSocialMediaList = document.getElementById(settings.footerSocialMediaListId);

    breakpoint.initialized.then(() => {
        // Updating Accordion according to viewport sizes
        updateAccordionState();

        breakpointHandler();

        // Start the resize Listener
        window.addEventListener('optimizedResize', updateAccordionState, true);

        // Start the breakpoint change Listener
        window.addEventListener('optimizedResize', breakpointHandler, true);
    });
};

export default instance;
