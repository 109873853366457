const foobyapp = ({ el, state, options }, Bridge) => {
    const clickHandler = (e) => {
        const link = e.target.tagName.toLowerCase() === 'a'
            ? e.target
            : e.target.closest('a');

        if (link) {
            const hasHashAtStart = link.href.indexOf('#') >= 0;
            const isInternalHost = options.hosts.indexOf(link.host) >= 0;

            if (!hasHashAtStart) {
                if (isInternalHost) {
                    let bridgeAction = null;

                    // detect whether link should be opened via bridge
                    for (let i = 0; i < options.pathBridgeActions.length && !bridgeAction; i++) {
                        const action = options.pathBridgeActions[i];
                        if (link.pathname.match(action.match)) {
                            bridgeAction = action.name;
                        }
                    }

                    if (bridgeAction) {
                        // prevent redirect to link
                        e.preventDefault();

                        // call bridge instead to open app page
                        Bridge.invokeNative({
                            function: bridgeAction,
                            payload: {
                                url: link.href
                            }
                        });

                        return;
                    }
                }

                // make sure the link opens in a new chrome window and not inside webview
                link.setAttribute('target', '_blank');
            }
        }
    };

    const addEventListeners = () => {
        el.addEventListener('click', clickHandler, true);
    };

    const removeEventListeners = () => {
        el.removeEventListener('click', clickHandler, true);
    };

    /* Initiation/Destruction */

    state.init = () => {
        addEventListeners();
    };

    state.destroy = () => {
        removeEventListeners();
    };

    state.init();

    return state;
};

export const config = {
    name: 'foobyapp',
    constructor: foobyapp,
    dependencies: ['Bridge'],
    options: {
        pathBridgeActions: [
            {
                match: /(?:en|de|fr|it)\/(?:recipes|rezepte|recettes|ricette)/i,
                name: 'OpenRecipeOrRecipeList'
            },
            {
                match: /(?:en|de|fr|it)\/(?:foobyverse|foobyvers|foobyversum|foobyverso)/i,
                name: 'OpenStory'
            },
            {
                match: /(?:en|de|fr|it)\/(?:cookery-school|kochschule|en-cuisine|cuciniamo)/i,
                name: 'OpenCookeryschool'
            }
        ],
        hosts: [
            document.location.host,
            'fooby.ch',
            'little.fooby.ch'
        ]
    }
};
