
const contest = ({ el, state, options }, CreateApi, Pubsub, FormSerialize, FormValidate) => {
    let formValidate, apiContest, messages, constraints;

    const handleSuccess = (response) => {
        response = JSON.parse(response);

        if (!response.success && !response.hasOwnProperty('redirect')) {
            for (const key of response.fields) {
                const value = response.fields[key];
                const input = el.querySelector('input[name="' + key + '"]');

                let msg = el.getAttribute(value);

                if (msg === null) {
                    msg = value;
                }

                formValidate.showErrorsForInput(input, [msg]);
            }
        } else if (response.hasOwnProperty('redirect')) {
            window.location.href = response.redirect;
        } else {
            el.reset();
        }
    };

    const handleError = (status) => {
        console.error('api call was not successful. returned status code ' + status + '.');
    };

    const validHandler = () => {
        const params = FormSerialize(el, {
            hash: true
        });

        // add language
        params.lang = store.lang;

        apiContest.send({
            params: params,
            success: handleSuccess,
            error: handleError
        });
    };

    const invalidHandler = (errors) => {
        Pubsub.publish('errors.show', ['Contest Form', errors]);
    };

    const initApi = () => {
        apiContest = CreateApi(store.apis.contest, {
            method: 'POST',
            params: {} // will be set dynamically
        });
    };

    const initMessages = () => {
        messages = {
            presence: el.getAttribute(options.messageAttrs.presence),
            email: el.getAttribute(options.messageAttrs.email),
            format: el.getAttribute(options.messageAttrs.format),
            maxlength: el.getAttribute(options.messageAttrs.maxlength)
        };
    };

    const initConstraints = () => {
        constraints = {};

        if (state.refs.answerInputs.length > 0) {
            if (state.refs.answerInputs[0].type === 'checkbox') {
                constraints['answer[]'] = {
                    presence: {
                        message: '^' + messages.presence
                    }
                };
            } else {
                constraints.answer = {
                    presence: {
                        message: '^' + messages.presence
                    }
                };
            }
        }
    };

    state.init = () => {
        state.refs = {
            answerInputs: document.querySelectorAll(options.refs.answerInputs)
        };

        initApi();
        initMessages();
        initConstraints();

        formValidate = FormValidate(el, { constraints, messages });
        formValidate.subscribe('valid', validHandler);
        formValidate.subscribe('invalid', invalidHandler);
    };

    state.destroy = () => {
        formValidate.destroy();
    };

    state.init();

    return state;
};

export const config = {
    name: 'contest',
    constructor: contest,
    dependencies: ['ApiFactory', 'Pubsub', 'FormSerialize', 'FormValidate'],
    options: {
        refs: {
            answerInputs: '[data-contest-answer-input]'
        },
        messageAttrs: {
            presence: 'data-msg-presence',
            email: 'data-msg-email',
            format: 'data-msg-format',
            maxlength: 'data-msg-maxlength'
        }
    }
};
