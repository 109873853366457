

/**
 * SNO - Small Navigation Overlay
 *
 *
 *
 *
 */

// dependencies
var forEach = require('@/helpers/helper').default.forEach,
    delegate = require('@/helpers/helper').default.delegate,
    getCombinedheight = require('@/helpers/helper').default.getCombinedheight;

// vars
var defaults = {
    snoId: 'data-sno-container', // must be data-attribute
    openClass: 't13-myfooby__sidebar--open',
    allLevels: '.sno__flex-flex-inner',
    rootLevel: '.sno__root-inner',
    title: 'data-sno-title', // must be data-attribute
    titleDefault: 'data-sno-title-default',
    levelOpenClass: 'sno__nextlevel-inner--open',
    changelevelTarget: 'data-sno-changelevel-target', // must be data-attribute
    scrollBlocker: 'data-sno-scrollblocker',
    triggers: {
        close: 'data-sno-close', // must be data-attribute
        open: 'data-sno-open', // must be data-attribute
        changelevel: 'data-sno-changelevel', // must be data-attribute
        changetitle: 'data-sno-changetitle' // must be data-attribute
    },
    callbacks: {
        onClose: function() {},
        onOpen: function() {}
    }
};

export default function(options) {
    var that = this || document,
        instance = {},
        settings = Object.assign({}, defaults, options),
        snoId, // short for small navigation overlay
        titleContainer,
        rootLevelContainer,
        overflowScrollPreventer,
        transitionend = transitionEnd(),

        changeLevel = function() {
            instance.changeLevel(this.getAttribute(settings.triggers.changelevel));
        },

        changeTitle = function() {
            instance.changeTitle(this.getAttribute(settings.triggers.changetitle));
        },

        disableEvent = function(e) {
            e.preventDefault();
        },

        getAllLevels = function() {
            return that.querySelectorAll(settings.allLevels);
        },

        prepareLevels = function () {
            var levels = getAllLevels();

            forEach(levels, function(level) {
                var height = level.offsetHeight,
                    scrollHeight = getCombinedheight(level.children, true);

                level.scrollTop = 1;

                level.removeEventListener('touchmove', disableEvent);
                level.removeEventListener('touchstart', overflowScrollPreventer);

                overflowScrollPreventer = function() {
                    if (this.scrollTop === 0) {
                        this.scrollTop = 1;
                    } else if (scrollHeight <= this.scrollTop + height + 1) {
                        this.scrollTop -= 1;
                    }
                };

                if (height > scrollHeight) {
                    level.addEventListener('touchmove', disableEvent);
                } else {
                    level.addEventListener('touchstart', overflowScrollPreventer);
                }
            });
        },

        bindListeners = function() {
            forEach(document.querySelectorAll('[' + settings.triggers.open + '="' + snoId + '"]'), function(trigger) {
                trigger.addEventListener('click', instance.openOverlay);
            });

            delegate(that, 'click', '[' + settings.triggers.close + ']', instance.closeOverlay);

            forEach(that.querySelectorAll('[' + settings.triggers.changelevel + ']'), function(trigger) {
                trigger.addEventListener('click', changeLevel);
            });

            forEach(that.querySelectorAll('[' + settings.triggers.changetitle + ']'), function(trigger) {
                trigger.addEventListener('click', changeTitle);
            });

            forEach(that.querySelectorAll('[' + settings.scrollBlocker + ']'), function(trigger) {
                trigger.addEventListener('touchmove', disableEvent);
            });
        },

        unbindListeners = function() {
            forEach(document.querySelectorAll('[' + settings.triggers.open + '="' + snoId + '"]'), function(trigger) {
                trigger.removeEventListener('click', instance.openOverlay);
            });

            forEach(that.querySelectorAll('[' + settings.triggers.close + ']'), function(trigger) {
                trigger.removeEventListener('click', instance.closeOverlay);
            });

            forEach(that.querySelectorAll('[' + settings.triggers.changelevel + ']'), function(trigger) {
                trigger.removeEventListener('click', changeLevel);
            });

            forEach(that.querySelectorAll('[' + settings.triggers.changetitle + ']'), function(trigger) {
                trigger.removeEventListener('click', changeTitle);
            });

            forEach(that.querySelectorAll('[' + settings.scrollBlocker + ']'), function(trigger) {
                trigger.removeEventListener('touchmove', disableEvent);
            });
        };

    instance.updateLevels = function() {
        prepareLevels();

        return instance;
    };

    /**
     * Change the level which is shown
     *
     * @param {string} level of the new level, if enpty goes to rootlevel
     * @return {object} instance
     */
    instance.changeLevel = function(level) {
        if (level === '') {
            forEach(that.querySelectorAll('.' + settings.levelOpenClass), function(element) {
                element.classList.remove(settings.levelOpenClass);
            });
            rootLevelContainer.style.overflow = '';
        } else {
            var newLevel = that.querySelector('[' + settings.changelevelTarget + '="' + level + '"]');

            newLevel.classList.add(settings.levelOpenClass);
            rootLevelContainer.style.overflow = 'hidden';
        }

        return instance;
    };

    /**
     * Change the overlay title which is shown
     *
     * @param {string} title to change to, if enpty goes to default title
     * @return {object} instance
     */
    instance.changeTitle = function(title) {
        if (title !== '') {
            titleContainer.innerHTML = title;
        } else {
            titleContainer.innerHTML = titleContainer.getAttribute(settings.titleDefault);
        }

        return instance;
    };

    /**
     * Opens the small navigation overlay
     */
    instance.openOverlay = function() {
        that.classList.add(settings.openClass);
        prepareLevels();
    };

    /**
     * Closes the small navigation overlay
     */
    instance.closeOverlay = function() {

        var callback;

        // self removing event listener
        that.addEventListener(transitionend, callback = function() {
            this.removeEventListener(transitionend, callback);

            // lets wait a bit
            setTimeout(function() {
                // Resets to root level and default title
                instance.changeLevel('');
                instance.changeTitle('');
            }, 100);
        });

        that.classList.remove(settings.openClass);
    };

    /**
     * Initializes MyFooby
     *
     * @param {object} options to overwrite default settings
     * @return {object} instance
     */
    instance.init = function() {

        bindListeners();

        return instance;
    };

    /**
     * Sets everything back to state befor initiated
     *
     * @return {object} instance
     */
    instance.destroy = function() {

        instance.closeOverlay();

        unbindListeners();

        return instance;
    };

    if (that instanceof Element === false) {
        throw new Error('Given scope is not an Element');
    }

    snoId = that.getAttribute(settings.snoId);
    titleContainer = that.querySelector('[' + settings.title + ']');
    rootLevelContainer = that.querySelector(settings.rootLevel);

    return instance;
};
