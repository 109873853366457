import fastdom from 'fastdom';
import Browser from '@/libs/browser';
import currentIos from 'ios-version/current';

const createAXO = (type) => {
    var ax;
    try {
        ax = new ActiveXObject(type);
    } catch (e) {
        ax = null;
    }
    return ax;
};

const loadPdfJsLibrary = async () => {
    return new Promise((resolve) => {
        const scriptEl = document.createElement('script');
        scriptEl.onload = resolve;
        scriptEl.setAttribute('src', pdfJsLibraryUrl);
        document.head.appendChild(scriptEl);
    });
};

const pdfJsViewerUrl = '/viewer/web/viewer.html';
const pdfJsLibraryUrl = '/viewer/build/pdf.js';

const hasAxo = !!(window.ActiveXObject || 'ActiveXObject' in window);
const timeoutAxoPrint = 3000;
const useAxo = hasAxo && !!(createAXO('AcroPDF.PDF') || createAXO('PDF.PdfCtrl'));

// use own pdf.js viewer for IE which doesn't have acrobat installed
// and firefox due bug where the print method is not available
// const usePdfJs = Browser.isFirefox || (hasAxo && !useAxo);
const usePdfJs = (hasAxo && !useAxo);

// chrome on mobile just shows a download button when a pdf is embeded
// so we use pdf.js lib to create an image for every pdf page to print them
const useMobilePrint = Browser.isChrome && Browser.isMobile;

// ios 8 and larger is displaying just the first page of a pdf and renders it as an image
// to bypass this problem we create an iframe for each page of the pdf and resize it and its image to a4 size
const useIosPrint = currentIos && currentIos.major >= 8;

let instance = {};

let frame, embed;

if (!useAxo) {
    // create print iframe (pdf.js needs a reasonable width and height, A4 in px, 300dpi)
    fastdom.mutate(() => {
        frame = document.createElement('iframe');
        frame.setAttribute('style', 'visibility: hidden; width: 2480px; height: 3508px; position: fixed;');
        document.body.appendChild(frame);

        if (useMobilePrint || useIosPrint) {
            // setup print iframe to be able to print sheets with images with right size
            const styleEl = document.createElement('style');
            frame.contentWindow.document.head.appendChild(styleEl);

            styleEl.sheet.insertRule(`@media print {
                @page {
                    size: A4;
                    margin: 0;
                }
            }`);

            styleEl.sheet.insertRule(`@media all {
                body {
                    margin: 0
                }

                .sheet {
                    margin: 0;
                    overflow: hidden;
                    position: relative;
                    box-sizing: border-box;
                    page-break-after: always;
                    /* A4 page size */
                    width: 210mm;
                    height: 296mm
                }

                .sheet iframe {
                    border: none;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    page-break-inside: avoid;
                }
            }`);
        }
    });
}

const appendMobilePrintSheets = async (url, singlePages) => {
    // clean frame
    frame.contentWindow.document.body.innerHTML = '';

    const fragment = document.createDocumentFragment();
    const pdf = await pdfjsLib.getDocument(url).promise;
    const pagesRendered = [];

    for (let currentPage = 1; currentPage <= pdf.numPages; currentPage++) {
        const renderPage = async () => {
            const page = await pdf.getPage(currentPage);
            const pageOptions = singlePages[currentPage - 1];
            const viewportWidth = page.view[2];
            const landscape = pageOptions && pageOptions.landscape;

            let scale = 2480 / viewportWidth;

            if (landscape) {
                scale = 3508 / viewportWidth;
            }

            const viewport = page.getViewport({ scale, rotation: landscape ? -90 : 0 });
            const canvas = document.createElement('canvas');
            const canvasContext = canvas.getContext('2d');

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            await page.render({ canvasContext, viewport }).promise;

            const dataUrl = canvas.toDataURL('image/png');
            page.cleanup();

            return { number: page.pageNumber, image: dataUrl };
        };

        pagesRendered.push(renderPage());
    }

    const data = await Promise.all(pagesRendered);

    data.sort(function(a, b) {
        return a.number - b.number;
    });

    data.forEach((d) => {
        const sheet = document.createElement('div');
        sheet.classList.add('sheet');

        const image = document.createElement('img');
        image.setAttribute('src', d.image);
        image.style.width = '210mm';

        sheet.appendChild(image);

        fragment.appendChild(sheet);
    });

    frame.contentWindow.document.body.appendChild(fragment);
};

const appendIosPrintSheets = async (url, singlePages) => {
    // clean frame
    frame.contentWindow.document.body.innerHTML = '';

    let allPagesLoaded = null;

    if (singlePages) {
        const fragment = document.createDocumentFragment();

        allPagesLoaded = Promise.all(singlePages.map(({ page, landscape = false }) => {
            const { sheet, loaded } = createIosPrintSheet(page, landscape);
            fragment.appendChild(sheet);
            return loaded;
        }));

        frame.contentWindow.document.body.appendChild(fragment);
    } else {
        const { sheet, loaded } = createIosPrintSheet(url);
        allPagesLoaded = loaded;

        frame.contentWindow.document.body.appendChild(sheet);
    }

    await allPagesLoaded;
};

const createIosPrintSheet = (pagePdf, landscape = false) => {
    const sheet = document.createElement('div');
    sheet.classList.add('sheet');

    const picFrame = document.createElement('iframe');

    const loaded = new Promise((resolve) => {
        picFrame.onload = () => {
            const picDocument = picFrame.contentWindow.document;
            const image = picDocument.getElementsByTagName('img')[0];

            picDocument.body.style.margin = '0';
            picDocument.body.style.padding = '0';
            image.style.width = '210mm';

            if (landscape) {
                // transform landscape page into portrait to be able to print
                image.style.marginTop = `${297 / 4}mm`;
                image.style.webkitTransform = 'scale(1.4142) rotate(-90deg)';
                image.style.transform = 'scale(1.4142) rotate(-90deg)';
            }

            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    resolve();
                });
            });
        };
    });

    picFrame.setAttribute('src', pagePdf);
    sheet.appendChild(picFrame);

    return { loaded, sheet };
};

const printFrame = () => {
    if (useAxo) {
        embed.print();
    } else {
        if (!frame.contentWindow.document.execCommand('print', false, null)) {
            frame.contentWindow.focus();
            frame.contentWindow.print();
        }
    }
};

/**
 * Print given PDF
 *
 * @param {string} url to be printed
 * @param {array} singlePages to be printed (for print on mobile/ios)
 * @return {Promise} whether the print was successful
 */
instance.printUrl = async (url, singlePages = null) => {
    return new Promise(async (resolve, reject) => {
        const print = () => {
            try {
                printFrame();
                resolve();
            } catch (e) {
                reject(e);
            }
        };

        if (useAxo) {
            if (embed) {
                embed.removeNode(true);
            }

            const request = new XMLHttpRequest();

            request.onload = () => {
                embed = document.createElement('embed');
                embed.setAttribute('type', 'application/pdf');
                embed.setAttribute('src', url);
                document.body.appendChild(embed);
                window.setTimeout(print, timeoutAxoPrint);
            };

            request.onerror = reject;
            request.open('GET', url);
            request.responseType = 'blob';

            request.send();
        } else if (useIosPrint) {
            await appendIosPrintSheets(url, singlePages);
            print();
        } else if (useMobilePrint) {
            await loadPdfJsLibrary();
            await appendMobilePrintSheets(url, singlePages);
            print();
        } else {
            if (usePdfJs) {
                url = `${pdfJsViewerUrl}?file=${encodeURIComponent(url)}`;
                url = `${pdfJsViewerUrl}?file=${encodeURIComponent(url)}`;
            }

            frame.onerror = reject;

            frame.onload = () => {
                if (usePdfJs) {
                    frame.contentWindow.addEventListener('pagesloaded', print);
                } else {
                    print();
                }
            };

            // use replace to prevent polluting the browser history
            frame.contentWindow.location.replace(url);
        }
    });
};

/**
 * Save given PDF
 *
 * @param {string} url to be saved
 * @param {string} filename to be used
 * @return {Promise} whether the save was successful
 */
instance.saveUrl = async (url, filename = '') => {
    filename = filename || url.substring(url.lastIndexOf('/') + 1);

    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();

        request.onload = () => {
            const blob = request.response;

            var newBlob = new Blob([blob], {
                type: 'application/pdf'
            });

            // IE doesn't allow using a blob object directly as link href
            // but we can use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob, filename);
                resolve();
                return;
            }

            // for other browsers:
            // create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download = filename;
            document.body.appendChild(link);
            link.click();

            window.setTimeout(() => {
                window.URL.revokeObjectURL(data);
                document.body.removeChild(link);
                resolve();
            }, 100);
        };

        request.onerror = reject;
        request.open('GET', url);
        request.responseType = 'blob';

        request.send();
    });
};

export default instance;
