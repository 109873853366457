import injector from '@/scaffold/injector';

export default injector.resolve(['Store', 'Globals', 'ApiFactory', 'Observer'], (Store, Globals, ApiFactory, Observer) => {

    let fetching = null;
    let isLoading = false;

    let shoppingList = null;

    const instance = {};
    Observer(instance);

    // public methods

    /**
     * Get the shopping list
     *
     * @return {Array}  Returns array
     */
    instance.get = () => {
        return shoppingList;
    };

    /**
     * Fetches shopping list.
     *
     * Event 'fetched' will be fired.
     *
     * @param {object} [fromUser=true] Whether the shopping list should be loaded from user or from API
     * @return {Promise} Loading
     */
    instance.fetch = async (fromUser = true) => {
        if (fromUser) {
            const { user } = await Globals.user.fetch();

            if (user.shoppingLists && user.shoppingLists[0]) {
                shoppingList = user.shoppingLists[0];
                instance.publish('fetched', [shoppingList]);
            }
        } else {
            if (isLoading) {
                return fetching;
            }

            isLoading = true;

            fetching = new Promise((resolve, reject) => {
                const api = ApiFactory(Store.apis.userShoppinglist, {
                    success: (response) => {
                        let shoppingLists = JSON.parse(response);
                        shoppingList = shoppingLists[0];
                        instance.publish('fetched', [shoppingList]);
                        resolve({ response, shoppingList });
                    },
                    error: reject
                });

                api.send();
            });

            return fetching;
        }
    };

    /**
     * Creates a new shopping list
     *
     * Response is ignored in success handler.
     * Event 'created' will be fired.
     *
     * @param {string} name - Name of shopping list
     * @return {Promise} Promise
     */
    instance.createShoppingList = async (name) => {
        return new Promise((resolve, reject) => {
            const api = ApiFactory(Store.apis.userShoppinglist, {
                method: 'POST',
                success: (response) => {
                    shoppingList = { name, ...JSON.parse(response) };
                    instance.publish('created', [shoppingList]);
                    resolve({ response, shoppingList });
                },
                error: (error) => {
                    console.error('Failed creating shoppinh list', error);
                    reject(error);
                },
                params: {
                    'name': name,
                    '_charset_': 'UTF-8'
                }
            });

            api.send();
        });
    };

    /**
     * Clear shopping list from (recipe) ingredients.
     *
     * @param {string} id - Id of shopping list which should be cleaned.
     * @param {integer|null} [recipeId=null] - Recipe ID to remove only ingredients from that recipe
     *
     * @return {Promise} Promise
     */
    instance.clearShoppingList = (id, recipeId = null) => {
        return new Promise((resolve, reject) => {
            const api = ApiFactory(Store.apis.userShoppinglistClear, {
                method: 'DELETE',
                success: (response) => {
                    instance.publish('cleared', [id, recipeId, response]);
                    resolve({ response, id, recipeId });
                },
                error: (error) => {
                    console.error('Failed clearing shopping list', error);
                    reject(error);
                },
                params: {
                    'shoppinglistId': id,
                    'recipeId': recipeId
                }
            });

            api.send();
        });
    };

    /**
     * Add recipe ingredients to shopping list.
     *
     * @param {string} id - Id of shopping list the ingredients should be added.
     * @param {integer} recipeId - Recipe ID to add
     * @param {integer|null} [amount] - Amount to add
     *
     * @return {Promise} Promise
     */
    instance.addRecipeToShoppingList = (id, recipeId, amount) => {
        return new Promise((resolve, reject) => {
            const api = ApiFactory(Store.apis.userShoppinglistRecipe, {
                method: 'POST',
                success: (response) => {
                    instance.publish('recipeAdded', [id, recipeId, amount, response]);
                    resolve({ response, id, recipeId, amount });
                },
                error: (error) => {
                    console.error('Failed adding recipe to shopping list', error);
                    reject(error);
                },
                params: {
                    'shoppinglistId': id,
                    'recipeId': recipeId,
                    'amount': amount
                }
            });

            api.send();
        });
    };

    return instance;
});
