// TODO: refactor imports
import BookmarkTooltip from '@/apps/myfooby/bookmark-tooltip';
import CookbookTooltip from '@/apps/myfooby/cookbook-tooltip';

const createTooltip = ({ el, state }, Tooltip, Globals, Pubsub, Helper, Share, Breakpoint) => {
    let hookIdNotesFetched;
    let hookBeforeOpen;
    let hookAfterClose;
    let activeBreakpoints = [];
    let options = {};

    const highlightElementPosition = (highlightElement, triggerBounding, trigger) => {
        let paddingVertical = 8;
        let paddingHorizontal = 14;

        // when only an icon is inside the trigger, we use a smaller padding
        if (trigger.children.length === 1
            && (trigger.firstElementChild.tagName.toLowerCase() === 'svg'
                || trigger.firstElementChild.firstElementChild.tagName.toLowerCase() === 'svg')
        ) {
            paddingVertical = 5;
            paddingHorizontal = 5;
        }

        // make sure highlight bubble is at least 40px wide
        if (triggerBounding.width + paddingHorizontal * 2 < 40) {
            paddingHorizontal = (40 - triggerBounding.width) / 2;
        }

        // make sure highlight bubble is at least 40px long
        if (triggerBounding.height + paddingVertical * 2 < 40) {
            paddingVertical = (40 - triggerBounding.height) / 2;
        }

        highlightElement.style.position = 'absolute';
        highlightElement.style.transform = `translate3d(${window.scrollX + triggerBounding.left - paddingHorizontal}px, ${window.scrollY + triggerBounding.top - paddingVertical}px, 0)`;
        highlightElement.style.width = `${triggerBounding.width + (paddingHorizontal * 2)}px`;
        highlightElement.style.height = `${triggerBounding.height + (paddingVertical * 2)}px`;
    };

    const getBookmarkTooltipOptions = () => {
        let bookmarkTooltipInstance;

        return {
            preventTriggerAction: true,
            highlight: true,
            highlightElementPosition,
            onBeforeOpen: async function() {
                await Globals.user.checkSSOSessionState();

                bookmarkTooltipInstance = BookmarkTooltip(this.getTooltipElement(), {
                    trigger: el,
                    tooltipInstance: this
                });
            },
            onAfterClose: function() {
                bookmarkTooltipInstance.destroy();
            }
        };
    };

    const getShareTooltipOptions = () => {
        let shareInstance;

        return {
            preventTriggerAction: true,
            highlight: true,
            highlightElementPosition,
            onBeforeOpen: function() {
                const tooltipElement = this.getTooltipElement();
                shareInstance = Share(tooltipElement);
            },
            onAfterClose: function() {
                shareInstance.destroy();
            }
        };
    };

    const getCookbookTooltipOptions = () => {
        let cookbookChangeInstance;

        return {
            preventTriggerAction: true,
            highlight: true,
            highlightElementPosition,
            onBeforeOpen: function() {
                cookbookChangeInstance = CookbookTooltip(this.getTooltipElement(), {
                    trigger: el,
                    tooltipInstance: this
                });
            },
            onAfterClose: function() {
                cookbookChangeInstance.destroy();
            }
        };
    };

    const getHintTooltipOptions = () => {
        return {
            initialOpen: true,
            initialOpenUntilClosed: true,
            manually: true
        };
    };

    const getHintSearchOverlayTooltipOptions = () => {
        return {
            manually: true
        };
    };

    const getHintRecipeOverlayTooltipOptions = () => {
        return {
            initialOpen: true,
            initialOpenUntilClosed: true
        };
    };

    const hintClickListener = () => {
        el.removeEventListener('click', hintClickListener);
        state.instance.close();
    };

    const shareClickListener = (e) => {
        if (['small', 'medium'].indexOf(Breakpoint.curBreakpoint) === -1) {
            // force showing tooltip when not on small or medium
            return;
        }

        // prevent showing tooltip
        e.stopImmediatePropagation();

        navigator.share({
            title: document.title,
            url: window.location.href
        });
    };

    const reponseItemsHandler = ({ response }) => {
        if (response.text && state.instance) {
            state.instance.close();
        }
    };

    state.init = async () => {
        if (el.hasAttribute('data-bookmark-trigger')
            || el.hasAttribute('data-bookmark-remove-trigger')
        ) {
            options = getBookmarkTooltipOptions();
        } else if (el.hasAttribute('data-share-trigger')) {
            await Breakpoint.initialized;

            // only available in android chrome >= 75 (with https) and iOS >= 12.2
            if (navigator.share) {
                el.addEventListener('click', shareClickListener);
            }

            options = getShareTooltipOptions();
        }  else if (el.hasAttribute('data-cookbook-tooltip-trigger')) {
            options = getCookbookTooltipOptions();
        } else if (el.hasAttribute('data-hint-recipe-overlay-trigger')) {
            options = getHintRecipeOverlayTooltipOptions();
        } else if (el.hasAttribute('data-hint-search-overlay-trigger')) {
            options = getHintSearchOverlayTooltipOptions();
            el.addEventListener('click', hintClickListener);

            hookBeforeOpen = Pubsub.subscribe('searchOverlay.beforeOpen', () => {
                const wasClosedBefore = state.instance.getInitialOpenClosed();

                if (!wasClosedBefore) {
                    window.setTimeout(() => {
                        state.instance.open();
                    }, 450);
                }
            });

            hookAfterClose = Pubsub.subscribe('searchOverlay.afterClose', () => {
                state.instance.close({ setInitialOpenClosed: false });
            });

        } else if (el.hasAttribute('data-hint-trigger')) {
            options = getHintTooltipOptions();
            el.addEventListener('click', hintClickListener);

            if (el.hasAttribute('data-notes-open')) {
                hookIdNotesFetched = Pubsub.subscribe('notes.fetched', reponseItemsHandler);
            }
        }

        if (el.hasAttribute('data-tooltip-placement')) {
            options.placement = el.getAttribute('data-tooltip-placement');
        }

        if (el.hasAttribute('data-tooltip-stay')) {
            options.stay = !!el.getAttribute('data-tooltip-stay');
        }

        if (el.hasAttribute('data-tooltip-breakpoints')) {
            await Breakpoint.initialized;
            activeBreakpoints = el.getAttribute('data-tooltip-breakpoints').split(',');
            state.breakpointInitOrDestroy();

            window.addEventListener('breakpointChange', state.breakpointInitOrDestroy);
        } else {
            state.instance = Tooltip(el, options);
        }
    };

    state.breakpointInitOrDestroy = () => {
        if (activeBreakpoints.indexOf(Breakpoint.curBreakpoint) > -1) {
            if (!state.instance) {
                state.instance = Tooltip(el, options);
            }
        } else if (state.instance) {
            state.instance.destroy();
            state.instance = null;
        }
    },

    state.destroy = () => {
        if (hookIdNotesFetched) {
            Pubsub.unsubscribe(hookIdNotesFetched);
        }

        if (hookBeforeOpen) {
            Pubsub.unsubscribe(hookBeforeOpen);
        }

        if (hookAfterClose) {
            Pubsub.unsubscribe(hookAfterClose);
        }

        state.instance.destroy();
        state.instance = null;
    };

    state.init();

    return state;
};

export const config = {
    name: 'tooltip',
    selector: '.tooltip-trigger',
    constructor: createTooltip,
    dependencies: ['Tooltip', 'Globals', 'Pubsub', 'Helper', 'Share', 'Breakpoint'],
    options: {}
};
