

/**
 * Shoppinglist Modal
 *
 * @author Christian Sany
 */

// Dependencies
var hogan = require('hogan.js'),
    api = require('@/libs/api-factory').default,
    breakpoint = require('@/libs/breakpoint').default,
    pubsub = require('@/scaffold/pubsub').default,
    delegate = require('@/helpers/helper').default.delegate,
    isValidEmail = require('@/helpers/helper').default.isValidEmail,
    fraction = require('@/libs/fractions').default;

// Vars & Functions
var instance = {},
    defaults = {
        container: 'shoppinglist',
        addmoreInput: '[data-addmore-input]',
        addmoreTrigger: '[data-addmore-trigger]',
        ingredientItem: '[data-ingredient-item]',
        ingredientItemQuantity: '[data-ingredient-item-quantity]',
        ingredientItemDesc: '[data-ingredient-item-desc]',
        addToIngredients: '[data-add-to-ingredients]',
        addToStock: '[data-add-to-stock]',
        ingredientsList: '[data-ingredient-list]',
        stockList: '[data-stock-list]',
        stockListTitle: '[data-stock-list-title]',
        shareIconList: '[data-share-icon-list]',
        shareLink: '[data-share-link]',
        emailInput: '[data-shoppinglist-email-field]',
        errorMsgElement: '[data-shoppinglist-error-msg]',
        submitTrigger: '[type="submit"]'
    },
    settings,
    container,
    template = '',
    tempalteIngredient = '',
    ingredientsListRef,
    stockListRef,
    addmoreInput,
    apiShoppinglist,
    emailInput,
    errorMsgElement,
    stockListTitle,
    shareIconList,
    shareLink,
    miniModal,
    msg1, // Errormessage
    msg2, // Errormessage

    getShoppinglistShareText = function(withUrl = false) {
        const ingredients = readIngredientsFromList(ingredientsListRef);
        const stockIngredients = readIngredientsFromList(stockListRef);

        return (`Zutaten für ${recipeJSON.name} (${shoppinglistJSON.actualPortions} ${shoppinglistJSON.portionLabel}):\r\n\r\n`
            + ingredients.map((item) => `${item.quantity ? item.quantity.trim() + ' ' : ''}${item.desc.trim()}`.trim()).join('\r\n')
            + (stockIngredients.length ? '\r\n\r\nIm Vorrat:\r\n' : '')
            + stockIngredients.map((item) => `${item.quantity ? item.quantity.trim() + ' ' : ''}${item.desc.trim()}`.trim()).join('\r\n')
            + (withUrl ? '\r\n\r\n' + window.location.href : '')
            + '\r\n');
    },

    /**
     * Loads a Template per AJAX
     *
     * @param {string} templatePath of the Template to load
     * @param {function} callback to call when finished loading
     */
    loadTemplate = function(templatePath, callback) {
        var http = new XMLHttpRequest();
        http.addEventListener('load', function(response) {

            // Everything is ok, File could be loaded
            if (response.target.status === 200) {
                callback.call(null, response);
            } else { // Error while loading the Tempalte
                throw new Error('Template ' + templatePath + ' could not be loaded\nStatus Code: ' + response.target.status);
            }
        });
        http.open('GET', templatePath, true);
        http.send();
    },

    /**
     * Checks if the Stocklist has items
     *
     * If the Stocklist doesn't have any children, the title will be hidden and vice versa
     */
    checkStockTitle = function() {
        if (stockListRef.children.length) {
            stockListTitle.style.display = '';
        } else {
            stockListTitle.style.display = 'none';
        }
    },

    /**
     * Validates the Form
     *
     * @return {boolean} true/false when Forminfo is valid or not
     */
    validateForm = function() {
        // Email inputfield is empty
        if (emailInput.value.trim() === '') {
            errorMsgElement.innerHTML = msg1;
            pubsub.publish('errors.show', ['Shopping List', msg1]);
            return false;
        } else if (!isValidEmail(emailInput.value)) { // Email format is not valid
            errorMsgElement.innerHTML = msg2;
            pubsub.publish('errors.show', ['Shopping List', msg2]);
            return false;
        } else {
            errorMsgElement.innerHTML = '';
            return true;
        }
    },

    /**
     * Add a new Ingredient to the list via addMoreInput
     *
     * @param {element} container to put the new Ingredient into
     * @param {string} description of the ingredient (Amoutn and Unit will be in the description aswell)
     */
    addIngedient = function(container, description) {
        var el = document.createElement('ul');
        el.innerHTML = tempalteIngredient.render({
            desc: description
        });
        container.appendChild(el.children[0]);
    },

    /**
     * Read Ingredients from the DOM
     *
     * @param {element} list of the ingredients to read
     * @return {array} returns the igredients as objects inside an array
     */
    readIngredientsFromList = function(list) {
        var ingredients = [];
        list.querySelectorAll(settings.ingredientItem).forEach(function(el) {
            var obj = {
                desc: el.querySelector(settings.ingredientItemDesc).innerHTML
            };

            // Check if this ingredient has a quantity given
            if (el.querySelector(settings.ingredientItemQuantity)) {
                obj.quantity = el.querySelector(settings.ingredientItemQuantity).value;
            }

            ingredients.push(obj);
        });

        return ingredients;
    },

    /**
     * Successhandler after sucessfully calling the API for sending the shoppinglist
     */
    sendSuccessHandler = function() {

        pubsub.publish('shoppinglist.sent');

        container.parentNode.parentNode.classList.add('theme--dark');

        container.querySelectorAll('[data-shoppinglist-view-default]').forEach(function(el) {
            el.style.display = 'none';
        });

        container.querySelectorAll('[data-shoppinglist-view-success]').forEach(function(el) {
            el.style.display = 'block';
        });

        setTimeout(function() {
            miniModal.close();

            // Remove dark theme after the modal has closed
            setTimeout(function() {
                container.parentNode.parentNode.classList.remove('theme--dark');
            }, 1000);
        }, 1500);
    };

/*
 * Init the functions on dom ready.
 * Load and precompile Hogan Tempalte to populate the modal just befor it opens
 */
instance.init = function (options) {
    settings = Object.assign({}, defaults, options);

    container = document.getElementById(settings.container);

    // Modal is not found, exit initiation
    if (container === undefined || container === null) {
        return;
    }

    // Shoppinglist JSON not found in DOM, exit instead
    if (typeof shoppinglistJSON !== 'object') {
        throw new Error('The shoppinglistJSON should be defined in this Template');
    }

    // Recipe JSON not found in DOM, exit instead
    if (typeof recipeJSON !== 'object') {
        throw new Error('The recipeJSON should be defined in this Template');
    }

    // Set errormessages
    msg1 = container.getAttribute('data-msg-presence');
    msg2 = container.getAttribute('data-msg-email');

    // Load and precompile template
    loadTemplate(store.assetsPath + 'tmpl/shoppinglist.tmpl', function(response) {

        // Cache precompiled Hogan Template
        template = hogan.compile(response.target.responseText);
    });

    loadTemplate(store.assetsPath + 'tmpl/shoppinglist-ingredient.tmpl', function(response) {

        // Cache precompiled Hogan Template
        tempalteIngredient = hogan.compile(response.target.responseText);
    });

    // Setup the API Connection
    apiShoppinglist = api(store.apis.shoppinglist, {
        method: 'POST',
        params: {
            'email' : '',
            'recipeName' : '',
            'recipeUrl' : '',
            'lang': '',
            'portions': 0,
            'ingredients': [],
            'stock': []
        },
        dataType: 'json',
        success: sendSuccessHandler
    });
};

/**
 * onBeforeOpen
 *
 * Renders the Template inside the Modal befor the Modal opens.
 * Sets up all eventlisteners after the markup has been rendered
 *
 * @param {object} miniModalModule Module
 */
instance.onBeforeOpen = function(miniModalModule) {
    miniModal = miniModalModule;

    var portionElement = document.querySelector('[data-portion-calculator-initial-portions]'),
        initialPortions = portionElement.getAttribute('data-portion-calculator-initial-portions'),
        portionLabelElement = document.querySelector('[data-portion-calculator-portion-label]'),
        portionLabel = portionLabelElement.innerHTML,
        actualPortions = portionElement.innerHTML,
        ingredientsList = '',
        stockList = '',

        /**
         * Returns a function which will be called inside hogan to calculate the quantity of the ingredient
         *
         * @return {function} which will be called inside hogan
         */
        calcQuantity = function() {

            // Calculating real quantity depending on what the initial portionamount and the set portionamount are (can be set by the User)
            return fraction(this.quantity * (actualPortions / initialPortions));
        };

    // Iterate through Ingredients and render Markup
    if (Array.isArray(shoppinglistJSON.ingredients) && shoppinglistJSON.ingredients.length) {
        for (var i = 0; i < shoppinglistJSON.ingredients.length; i++) {
            ingredientsList = ingredientsList + tempalteIngredient.render(Object.assign({}, shoppinglistJSON.ingredients[i], {
                calcQuantity: calcQuantity
            }));
        }
    }
    shoppinglistJSON.ingredientsList = ingredientsList;

    // Iterate through Stockitems and render Markup
    if (Array.isArray(shoppinglistJSON.stock) && shoppinglistJSON.stock.length) {
        for (var q = 0; q < shoppinglistJSON.stock.length; q++) {
            stockList = stockList + tempalteIngredient.render(Object.assign({}, shoppinglistJSON.stock[q], {
                calcQuantity: calcQuantity
            }));
        }
    }
    shoppinglistJSON.stockList = stockList;

    shoppinglistJSON.shareApi = !!navigator.share && ['small', 'medium'].indexOf(breakpoint.curBreakpoint) > -1;
    shoppinglistJSON.actualPortions = actualPortions;
    shoppinglistJSON.portionLabel = portionLabel;

    // Add the rendered Markup into the DOM
    container.innerHTML = template.render(shoppinglistJSON);

    // Set up Reference to DOM Elements
    ingredientsListRef = container.querySelector(settings.ingredientsList);
    stockListRef = container.querySelector(settings.stockList);
    addmoreInput = container.querySelector(settings.addmoreInput);
    emailInput = container.querySelector(settings.emailInput);
    errorMsgElement = container.querySelector(settings.errorMsgElement);
    stockListTitle = container.querySelector(settings.stockListTitle);
    shareIconList = container.querySelector(settings.shareIconList);
    shareLink = container.querySelector(settings.shareLink);

    if (shoppinglistJSON.shareApi && shareLink) {
        shareLink.addEventListener('click', () => {
            navigator.share({
                title: recipeJSON.name,
                url: window.location.href,
                text: getShoppinglistShareText()
            });
        });
    } else if (shareIconList) {
        // fallback if device doesn't support Web Share API
        const whatsAppShare = shareIconList.querySelector('[data-share="WhatsApp"] a');
        whatsAppShare.setAttribute('target', '_blank');
        whatsAppShare.setAttribute('href', 'https://api.whatsapp.com/send?text=' + encodeURIComponent(getShoppinglistShareText(true)));

        whatsAppShare.addEventListener('click', (e) => {
            e.preventDefault();
            window.open('https://api.whatsapp.com/send?text=' + encodeURIComponent(getShoppinglistShareText(true)), '_blank');
        });
    }

    // Check if Stocktitle is needed
    checkStockTitle();

    function submitForm() {
        // Checks if the form is valid to be sent
        if (validateForm()) {
            apiShoppinglist.send({
                params: {
                    'email': emailInput.value,
                    'recipeName': recipeJSON.name,
                    'recipeUrl': window.location.href,
                    'lang': store.lang,
                    'portions': Number(actualPortions),
                    'ingredients': readIngredientsFromList(ingredientsListRef),
                    'stock': readIngredientsFromList(stockListRef)
                }
            });
        }
    }

    // Add eventhandling for submitting the shoppinglist
    container.querySelectorAll(settings.submitTrigger).forEach(function(el) {
        el.addEventListener('click', submitForm, false);
    });

    emailInput.addEventListener('keydown', function (e) {
        if (e.keyCode === 13) {
            submitForm();
        }
    });

    function addOwnIngredient() {
        if (addmoreInput.value.trim() !== '') {

            // Add Ingredient to ingredients list
            addIngedient(ingredientsListRef, addmoreInput.value.trim());

            // Clear input Field after adding the Ingredient
            addmoreInput.value = '';
        } else {

            // Focus the input Field if empty
            addmoreInput.focus();
        }
    }

    container.querySelectorAll(settings.addmoreTrigger).forEach(function(el) {
        el.addEventListener('click', addOwnIngredient);
    });

    addmoreInput.addEventListener('keydown', function(e) {
        if (e.keyCode === 13) {
            addOwnIngredient();
        }
    });

    // Add eventhandling for adding Ingredients to the Stocklist
    // Has to be delegated, because there can be added More Ingedients by the user later on
    delegate(ingredientsListRef, 'click', settings.addToStock, function(event) {
        var ingredientItem = event.delegateTarget.closest(settings.ingredientItem);
        stockListRef.appendChild(ingredientItem);

        // Check if Stocktitle is needed
        checkStockTitle();
    });

    // Add eventhandling for adding Stockitems to the Ingredientlist
    // Has to be delegated, because there can be added More Ingedients by the user later on
    delegate(stockListRef, 'click', settings.addToIngredients, function(event) {
        var ingredientItem = event.delegateTarget.closest(settings.ingredientItem);
        ingredientsListRef.appendChild(ingredientItem);

        // Check if Stocktitle is needed
        checkStockTitle();
    });
};

export default instance;
