

var isMyFooby = document.querySelector('.t13-myfooby') !== null,
    hogan = require('hogan.js'),

    assignTeaserImages = function(teaser, teaserdata) {
        var prefix = '';

        if (teaser.hasOwnProperty('if_rezept') && teaser.if_rezept === true) {
            prefix = 'recipe-';
        } else {
            prefix = '';
        }

        teaser['image:md'] = store.imagePaths[prefix + 'md-' + teaserdata.md].replace('{{keyvisual}}', teaser.keyvisual);
        teaser['image:md:retina'] = store.imagePaths[prefix + 'md-' + teaserdata.md + '-@1.3x'].replace('{{keyvisual}}', teaser.keyvisual);
        teaser['image:sm'] = store.imagePaths[prefix + 'sm-' + teaserdata.sm].replace('{{keyvisual}}', teaser.keyvisual);
        teaser['image:sm:retina'] = store.imagePaths[prefix + 'sm-' + teaserdata.sm + '-@1.5x'].replace('{{keyvisual}}', teaser.keyvisual);
        teaser['image:xs'] = store.imagePaths[prefix + 'xs-' + teaserdata.xs].replace('{{keyvisual}}', teaser.keyvisual);
        teaser['image:xs:retina'] = store.imagePaths[prefix + 'xs-' + teaserdata.xs + '-@2x'].replace('{{keyvisual}}', teaser.keyvisual);
        teaser['image:author'] = store.imagePaths.author.replace('{{keyvisual}}', teaser.bild_autor);
        teaser['image:partner'] = store.imagePaths.partner.replace('{{keyvisual}}', teaser.keyvisual);

        return teaser;
    },

    assignSearchAsYouTypeImages = function(result) {
        var prefix = '';

        if (result.hasOwnProperty('treffertyp_sub') && result.treffertyp_sub === 'rezept') {
            prefix = 'recipe-';
        } else {
            prefix = '';
        }

        result['image'] = store.imagePaths[prefix + 'sayt'].replace('{{keyvisual}}', result.keyvisual);
        result['image:retina'] = store.imagePaths[prefix + 'sayt-@2x'].replace('{{keyvisual}}', result.keyvisual);

        return result;
    },

    getTranslation = function(name) {
        return store.translations['trans:' + name];
    };

export function formatTime(minutes) {
    const min = getTranslation('min') || '';
    const h = getTranslation('h') || '';

    minutes = Number(minutes);

    const restMinutes = minutes % 60;
    const hours = Math.floor(minutes / 60);

    if (hours === 0) {
        return `${restMinutes} ${min}`;
    } else if (restMinutes === 0) {
        return `${hours} ${h}`;
    } else {
        const zerofilledRestMinutes = ('0' + restMinutes).slice(-2);
        return `${hours}:${zerofilledRestMinutes} ${h}`;
    }
}

export default {

    teaser: function(teasers, teaserdata, data, foobys, isMenuTeaser = false) {
        var content = '',
            teaser;

        // removes avatar and author info from the teaser if true
        foobys = foobys || false;

        var svgs = {};

        for (var key in store.templates.svgs) {
            if (store.templates.svgs.hasOwnProperty(key)) {
                svgs['svgs:' + key] = hogan.compile(store.templates.svgs[key]).render();
            }
        }

        if (teasers instanceof Array) {
            for (var i = 0; i < teasers.length; i++) {

                // Adding default values
                teaser = Object.assign({}, teaserdata, teasers[i]);

                // Adding translations
                teaser = Object.assign({}, store.translations, teaser);

                // Adding svgs
                teaser = Object.assign({}, svgs, teaser);

                // Adding the if statement so the template 'knows' which type the teaser is
                teaser['if_' + teaser.treffertyp_sub] = true;

                // Convert String 'true' to actual boolean true and String 'false' to actual boolean false
                teaser['hassteps'] = teaser['hassteps'] === 'true';

                teaser.index = i;

                // console.log(isMyFooby, isMenuTeaser);

                if (!isMenuTeaser && isMyFooby) {
                    teaser['if_has-delete'] = true;
                } else {
                    teaser['if_has-delete'] = false;
                }

                if (teaser.level || (teaser.treffertyp_sub && teaser.treffertyp_sub.indexOf('lf-') === 0)) {
                    teaser['if_little-fooby'] = true;

                    // always show lf logo on lf recipe or when it's a lf story and outside of lf site
                    if (teaser.level || store.isLittleFooby === false) {
                        teaser['if_little-fooby-logo'] = true;
                    }

                    if (teaser.level === '2') {
                        teaser['if_little-fooby-l2'] = true;
                    } else if (teaser.level === '3') {
                        teaser['if_little-fooby-l3'] = true;
                    } else {
                        teaser['if_little-fooby-l1'] = true;
                    }
                } else {
                    teaser['if_little-fooby'] = false;
                }

                // Checks if share functions should be available or not
                if (teaser.treffertyp_sub === 'community' || teaser.treffertyp_sub === 'partner' || teaser.treffertyp_sub === 'rezeptkategorie') {
                    teaser['if_has-no-share'] = true;
                } else {
                    teaser['if_has-share'] = true;
                }

                if (teaser.treffertyp_sub !== 'partner') {
                    teaser['if_not-partner'] = true;
                } else {
                    teaser['if_not-partner'] = false;
                }

                if (foobys) {
                    teaser['if_not-foobys'] = false;
                } else {
                    teaser['if_not-foobys'] = true;
                }

                // // if (teaser.treffertyp_sub === 'own' || teaser.treffertyp_sub === 'extern') {
                // if (teaser.treffertyp_sub === 'extern') {
                //     teaser['if_has-like'] = false;
                // } else {
                teaser['if_has-like'] = true;
                // }

                if (teaser.hasOwnProperty('author') && teaser.author !== '') {
                    teaser['has_author'] = true;
                } else {
                    teaser['has_author'] = false;
                }

                if (teaser.hasOwnProperty('bild_autor') && teaser.bild_autor !== '') {
                    teaser['has_author_image'] = true;
                } else {
                    teaser['has_author_image'] = false;
                }

                if (teaser.treffertyp_sub === 'howto' || (teaser.hasOwnProperty('hasvideo') && (teaser.hasvideo === 'true' || teaser.hasvideo === true) && !teaser.hasOwnProperty('bild_autor'))) {
                    teaser['if_hasvideo'] = true;
                } else {
                    teaser['if_hasvideo'] = false;
                }

                // Wenn es ein rezept ist
                if (teaser.treffertyp_sub === 'rezept' && teaser.dauer_aktiv !== '' && teaser.dauer_gesamt !== '') {
                    teaser.hasTime = true;
                    if (teaser.dauer_aktiv !== teaser.dauer_gesamt) {

                        // Wenn aktiv & gesamt die gleiche Zeit ist, wird nur die gesamtzeit angezeigt
                        teaser.notSameTime = true;
                    }

                    teaser['dauer_aktiv_trans'] = formatTime(teaser.dauer_aktiv);
                    teaser['dauer_gesamt_trans'] = formatTime(teaser.dauer_gesamt);
                } else {
                    teaser.hasTime = false;
                }

                /* eslint-disable camelcase */

                // Check if teaser has a description
                if (teaser.teaser && teaser.teaser !== '') {
                    teaser.if_teaser = true;
                }

                /* eslint-enable camelcase */

                // Add ImagePaths to use
                teaser = assignTeaserImages(teaser, teaserdata);
                // Add assetsPath
                teaser.assetsPath = store.assetsPath;
                // Add environment variable
                teaser.isLittleFooby = store.isLittleFooby;

                // rendering the teaser
                data.content = hogan.compile(store.templates.teaser).render(teaser);

                // adding rendered teaser including col-wrapper to content
                content += hogan.compile(store.templates.col).render(data);
            }
        }

        return content;
    },

    tags: function(tags) {
        var content = '',
            data = {};

        if (tags instanceof Array) {
            for (var i = 0; i < tags.length; i++) {

                data.tag = tags[i];
                data.isLittleFooby = store.isLittleFooby;
                data.isEven = i % 2 === 0;

                // adding rendered tag to content
                content += hogan.compile(store.templates.tag).render(data);
            }
        }

        return content;
    },

    searchAsYouType: function(response) {
        var content = '';

        var svgs = {};

        for (let key in store.templates.svgs) {
            if (store.templates.svgs.hasOwnProperty(key)) {
                svgs['svgs:' + key] = hogan.compile(store.templates.svgs[key]).render();
            }
        }

        for (let key in response) {
            if (response.hasOwnProperty(key)) {

                var data = response[key];

                /* eslint-disable camelcase */

                if (data.resultcounts.all === 0) {
                    data.count = 0;
                    data.if_count_zero = true;
                    data.if_show_more = false;
                } else {
                    data.count = data.resultcounts.all;
                    data.if_count_zero = false;
                    if (data.resultcounts.all > 3) {
                        data.if_show_more = true;
                    } else {
                        data.if_show_more = false;
                    }
                }

                data.treffertyp = getTranslation(key);
                data.treffertyp_raw = key;

                var treffertypContent = '',
                    temp;

                if (data.results) {
                    for (var i = 0; i < data.results.length; i++) {
                        temp = data.results[i];
                        temp.treffertyp_sub_title = getTranslation(data.results[i].treffertyp_sub);
                        temp.isEven = i % 2 === 0;
                        temp.isLittleFooby = store.isLittleFooby;

                        // Adding translations
                        temp = Object.assign({}, svgs, store.translations, temp);

                        temp = assignSearchAsYouTypeImages(temp);

                        treffertypContent += hogan.compile(store.templates.searchAsYouType.result).render(temp);
                    }
                }

                data.content = treffertypContent;
                data.isLittleFooby = store.isLittleFooby;

                // Adding translations
                data = Object.assign({}, svgs, store.translations, data);

                content += hogan.compile(store.templates.searchAsYouType.group).render(data);

                /* eslint-enable camelcase */

            }
        }

        content = hogan.compile(store.templates.searchAsYouType.wrapper).render({
            content: content,
            svgs: svgs,
            isLittleFooby: store.isLittleFooby
        });

        return content;
    }
};
