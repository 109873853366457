import createChart from './chart';
import createDropdown from './dropdown';
import createFilterBar from './filter-bar';
import createFilterSelect from './filter-select';
import createSwitch from '../shared/modules/switch';

const createSeasonCalendar = ({ el, state, options }, SeasonService, Vue, Moment) => {
    state.store = {};
    state.modules;

    state.fetchItems = () => {
        SeasonService.getItems(data => {
            state.store.items.push(...data);
        });
    };

    state.initSubmodules = () => {
        state.modules = {
            chart: createChart({ el: el.querySelector(options.refs.chart), store: state.store }, Vue, Moment),
            filterSelect: createFilterSelect({ el: el.querySelector(options.refs.filterSelect), store: state.store }, Vue),
            filterBar: createFilterBar({ el: el.querySelector(options.refs.filterBar), store: state.store }, Vue),
            dropdown: createDropdown({ el: el.querySelector(options.refs.dropdown), store: state.store }, Vue),
            switches: []
        };

        el.querySelectorAll(options.refs.switch).forEach(el => {
            const sw = createSwitch({ el, value: state.store.isSeason }, Vue);
            sw.$on('change', (value) => {
                state.store.isSeason = value;

                state.modules.switches.forEach((sw) => {
                    sw.value = value;
                });
            });

            state.modules.switches.push(sw);
        });
    };

    state.init = () => {
        Object.assign(state.store, options.store);
        state.fetchItems();
        state.initSubmodules();
    };

    state.init();

    return state;
};

export const config = {
    name: 'season-calendar',
    dependencies: ['SeasonService', 'Vue', 'Moment'],
    constructor: createSeasonCalendar,
    options: {
        refs: {
            chart: '[data-module="chart"]',
            dropdown: '[data-module="dropdown"]',
            filterSelect: '[data-module="filter-select"]',
            filterBar: '[data-module="filter-bar"]',
            switch: '[data-module="switch"]'
        },
        store: {
            items: [],
            filter: '',
            isSeason: true
        }
    }
};
