
const createPageHeaderRecipe = ({ el, state, options }, Store, Breakpoint, TitleLines, DebounceAnimationFrame) => {
    const CLASS_META_BREAKS_CONTROL = 'meta-info--visible-breaks';

    const checkMetaBreaks = function () {
        state.refs.metaBreakContainer.forEach(info => {
            info.classList.remove(CLASS_META_BREAKS_CONTROL);

            // checks if more then 1 line in height (line-height: 15px)
            if (info.offsetHeight > 16) {
                info.classList.add(CLASS_META_BREAKS_CONTROL);
            }
        });
    };

    const headerSizeHandler = function () {
        const isLargeUp = Breakpoint.curBreakpoint === 'large' || Breakpoint.curBreakpoint === 'xlarge';
        const stylesPanel = getComputedStyle(state.refs.panel, null);
        const stylesPanelInner = getComputedStyle(state.refs.panelInner, null);

        const maxWidth = parseInt(stylesPanel.maxWidth, 10);
        const minWidth = parseInt(stylesPanel.minWidth, 10);
        const paddingLeft = parseInt(stylesPanelInner.paddingLeft, 10);
        const paddingRight = parseInt(stylesPanelInner.paddingRight, 10);

        const goldenRatioViewport = window.innerWidth * 0.382;

        if (Store.isLittleFooby) {
            const stylesTitleLittle = getComputedStyle(state.refs.title, null);
            const paddingLeftTitle = parseInt(stylesTitleLittle.paddingLeft, 10);
            const paddingRightTitle = parseInt(stylesTitleLittle.paddingRight, 10);

            const sidePaddings = paddingLeft + paddingRight + paddingLeftTitle + paddingRightTitle;
            const titleViewportMaxWidth = state.refs.panel.offsetWidth - sidePaddings;
            const titleMaxWidth = maxWidth - sidePaddings;
            const titleMinWidth = minWidth - sidePaddings;
            const titleGoldenRatioViewportMax = goldenRatioViewport - sidePaddings;

            state.maxWidthTitleLittle = isLargeUp
                // take viewport/min-width into consideration if on desktop
                ? Math.min(titleMaxWidth, Math.max(titleGoldenRatioViewportMax, titleMinWidth))
                : Math.min(titleMaxWidth, titleViewportMaxWidth);
        } else {
            if (isLargeUp) {
                state.refs.panelInner.style.maxWidth = `${Math.min(maxWidth, goldenRatioViewport)}px`;
            } else {
                state.refs.panelInner.style.maxWidth = 'initial';
            }
        }

        const spacingMin = Store.isLittleFooby ? 30 : 80;
        const panelRect = state.refs.panel.getBoundingClientRect();
        const wrapperRect = state.refs.wrapper.getBoundingClientRect();

        let spacingTop = panelRect.y - wrapperRect.y;
        const spacingBottom = (wrapperRect.y + wrapperRect.height) - (panelRect.y + panelRect.height);

        if (Store.isLittleFooby) {
            // if we have a little fooby recipe we take the title element as most top element, because it leaps outside of the panel
            const titleRect = state.refs.title.getBoundingClientRect();
            spacingTop = titleRect.y - wrapperRect.y;
        }

        if (isLargeUp) {
            // set min height
            const spacingLarge = Math.min(spacingTop, spacingBottom);
            state.refs.wrapper.style.minHeight = `${wrapperRect.height + spacingMin - (spacingLarge * 2)}px`;
        } else {
            state.refs.wrapper.style.minHeight = 'initial';
        }
    };

    const resizeTitle = function() {
        state.titleLines.renderLines((linesCount) => {
            state.linesRenderedTitle = linesCount;
            el.setAttribute('data-lines', linesCount);

            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    resize();

                    requestAnimationFrame(() => {
                        requestAnimationFrame(() => {
                            el.classList.add('is-loaded');
                        });
                    });
                });
            });
        });
    };

    const resize = function() {
        checkMetaBreaks();
        headerSizeHandler();
    };

    const debouncedResizeTitle = DebounceAnimationFrame(resizeTitle);
    const debouncedResize = DebounceAnimationFrame(resize);

    const createTitleLines = function () {
        return TitleLines(state.refs.title, {
            // max width that each line should have (recalculated during resize)
            maxWidth: () => {
                return state.maxWidthTitleLittle;
            },

            // line configuration
            lines: [
                { minFontSize: 45, wordsCount: null },
                { minFontSize: 75, wordsCount: null, primary: true },
                { minFontSize: 75, optional: true }
            ],

            // chars that should be used to split the recipe title into more single words
            wordMatch: /[^-\s]+-?\s?/giu
        });
    };

    state.init = async () => {
        state.refs = {
            title: el.querySelector(options.refs.title),
            metaBreakContainer: el.querySelectorAll(options.refs.metaBreakContainer),
            wrapper: el.querySelector(options.refs.wrapper),
            panel: el.querySelector(options.refs.panel),
            panelInner: el.querySelector(options.refs.panelInner)
        };

        state.linesRenderedTitle = 2;
        state.maxWidthTitleLittle = null;

        await Breakpoint.initialized;

        // initial resize
        resize();

        window.addEventListener('resize', debouncedResize);

        if (Store.isLittleFooby) {
            state.titleLines = createTitleLines();
            window.addEventListener('resize', debouncedResizeTitle);
        }

        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                if (Store.isLittleFooby) {
                    // initial title resize after general resize
                    resizeTitle();
                } else {
                    // or just add loaded class
                    el.classList.add('is-loaded');
                }
            });
        });
    };

    state.destroy = () => {
        window.removeEventListener('resize', debouncedResize);

        if (Store.isLittleFooby) {
            window.removeEventListener('resize', debouncedResizeTitle);
        }
    };

    state.init();

    return state;
};

export const config = {
    name: 'page-header-recipe',
    selector: '#page-header-recipe',
    constructor: createPageHeaderRecipe,
    dependencies: ['Store', 'Breakpoint', 'TitleLines', 'DebounceAnimationFrame'],
    options: {
        refs: {
            title: 'h1',
            metaBreakContainer: '[data-metabreak]',
            wrapper: '.page-header-recipe__wrapper',
            panel: '.page-header-recipe__panel',
            panelInner: '#page-header-recipe__panel-detail'
        }
    }
};
