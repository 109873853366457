
// vars
var defaults = {
    container: '[data-sort-bar]',
    items: '[data-sort-order]',
    activeClass: 'is-active',
    onChange: {},
    active: false
};

/**
 * Handles a searchbar and trigger
 *
 * @param {object} sortbar - overwrites default settings (optional)
 * @param {object} options - overwrites default settings (optional)
 * @return {object} instance of factory
 * */
export default function (sortbar, options) {
    var settings = Object.assign({}, defaults, options),
        instance = {
            container: sortbar.querySelector(settings.container)
        },

        /**
         * Initializes the factory
         *
         * @return {object} instance
         */
        init = function () {

            bindEvents();

            if (settings.active) {
                instance.setActiveElement(settings.active);
            }

            return instance;
        },

        /**
         * Handle sort item clicks
         *
         * @param {object} event browser click event
         * */
        barItemClickHandler = function (event) {
            instance.container.querySelectorAll(settings.items)
                .forEach((el) => el.classList.remove(settings.activeClass));

            event.target.classList.add(settings.activeClass);

            if (settings.onChange instanceof Function) {
                settings.onChange(instance.getActiveValue());
            }
        },

        /**
         * Bind all events to the elements
         */
        bindEvents = function() {
            instance.container.querySelectorAll(settings.items)
                .forEach((el) => el.addEventListener('click', barItemClickHandler));
        };

    /**
     * Get active sort order
     *
     * @return {string} returns active sort order string
     * */
    instance.getActiveValue = function() {
        return instance.container.querySelector(`${settings.items}.${settings.activeClass}`).dataset.sortOrder;
    };

    /**
     * Set active sort order
     *
     * @param {string} active - for example "latest" or "az"
     * */
    instance.setActiveElement = function(active) {
        instance.container.querySelectorAll(settings.items)
            .forEach((el) => el.classList.remove(settings.activeClass));

        instance.container.querySelector(`[data-sort-order="${active}"]`).classList.add(settings.activeClass);
    };

    /**
     * Set or overwrite properties in the settings object
     *
     * @param {object} options - overwrites current settings (optional)
     * @return {object} returns instance of factory
     * */
    instance.setOptions = function(options) {
        settings = Object.assign({}, settings, options);

        // Optionally update internal logic

        return instance;
    };

    /**
     * Should revert to initial state
     *
     * @return {object} instance
     */
    instance.destroy = function() {
        instance.container.querySelectorAll(settings.items)
            .forEach((el) => el.removeEventListener('click'));

        return instance;
    };

    return init();
};
