const pubsub = require('@/scaffold/pubsub').default,
    breakpoint = require('@/libs/breakpoint').default,
    fancyload = require('@/libs/ni-fancyload').default,
    chunk = require('@/helpers/helper').default.chunk;

/* public functions*/

var instance = {};

const fadeSettings = {
    fadeInImageGlob: '[data-animation-image-fadein]',
    fadeInGlob: '[data-animation-fade-in-block]',
    fadeInClass: 'global--fade-in-block',
    fadeOutClass: 'global--fade-out-block',
    fadeInDelay: 70
};

instance.fadeInElements = function(elements) {
    return new Promise(function(resolve) {
        elements.forEach(function(element, index) {
            setTimeout(function() {
                element.classList.add(fadeSettings.fadeInClass);
            }, index * fadeSettings.fadeInDelay);
        });

        setTimeout(resolve, elements.length * fadeSettings.fadeInDelay);
    });
};

instance.fadeInTeasers = function(container = document) {
    var images = container.querySelectorAll(fadeSettings.fadeInImageGlob);
    if (images instanceof NodeList && images.length > 0) {
        fancyload(images);
    }

    const elements = container.querySelectorAll(`${fadeSettings.fadeInGlob}:not(.${fadeSettings.fadeInClass})`);

    return new Promise(function(resolve) {
        if (elements.length === 0) {
            resolve();
            return;
        }

        let maxItemsWithFadeInDelay = 12;
        let chunkItemsDelay = 4;

        breakpoint.initialized.then(() => {
            if (breakpoint.curBreakpoint === 'small') {
                maxItemsWithFadeInDelay = 4;
            }

            if (elements.length <= maxItemsWithFadeInDelay) {
                // fade in all elements with normal delay
                instance.fadeInElements(elements).then(resolve);
            } else {
                var elementsArray = Array.from(elements);
                var startElements = elementsArray.slice(0, maxItemsWithFadeInDelay);
                var furtherElements = elementsArray.slice(maxItemsWithFadeInDelay);

                // fade in start elements with normal delay
                instance.fadeInElements(startElements).then(resolve);

                var chunks = chunk(furtherElements, chunkItemsDelay);

                // fade in rest of elements as chunks with same start time
                chunks.forEach(function(chunkElements) {
                    instance.fadeInElements(chunkElements);
                });
            }
        });
    }).then(function() {
        pubsub.publish('teasers.fadedIn');
    });
};

instance.fadeOutTeasers = function(container = document) {
    const transitionend = transitionEnd();
    const elements = container.querySelectorAll(fadeSettings.fadeInGlob);

    return new Promise(function(resolve) {
        let temp;

        if (elements.length === 0) {
            resolve();
            return;
        }

        elements[0].addEventListener(transitionend, temp = function() {
            // results in one time listening to the event
            elements[0].removeEventListener(transitionend, temp);
            resolve();
        });

        elements.forEach(function(element) {
            element.classList.add(fadeSettings.fadeOutClass);
        });
    });
};

export default instance;
