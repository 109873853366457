

var globals = require('@/scaffold/globals').default,
    pubsub = require('@/scaffold/pubsub').default,
    greensock = require('@/libs/greensock-animations').default;

/**
 * This module handles the states of the page-action groups.
 */

var instance = {},
    defaults = {
        pageActions: '[data-page-actions]',
        bookmarkTrigger: '[data-bookmark-trigger]',
        bookmarkActiveClass: 'bookmark--active',
        bookmarkUrlAttr: 'data-bookmark-url',
        recipeIdAttr: 'data-recipe-id',
        treffertypSubAttr: 'data-treffertyp-sub',
        ownRecipeIdAttr: 'data-own-recipe-id',
        shareTrigger: '[data-share-trigger]',
        shareTriggerBound: 'share-trigger-bound'
    },
    settings,
    pageActions,

    fetchPageActions = function() {

        // Fetch Nodes
        // Has to be executed every time, because the DOM could have changed
        pageActions = document.querySelectorAll(settings.pageActions);
    };

instance.updateBookmarkStates = function() {
    // TODO: delete this function, since we took bookmarks and likes together, this functionality is not needed anymore
    // because we don't have the bookmark button anymore
    pageActions.forEach(function(pageAction) {
        var bookmarkTrigger = pageAction.querySelector(settings.bookmarkTrigger),
            bookmarkUrl,
            bookmarkTreffertypSub,
            bookmarkOwnRecipeId,
            bookmarkRecipeId,
            hasBookmarks;

        if (!bookmarkTrigger) {
            return;
        }

        var copy = bookmarkTrigger.querySelector('.share-group__single-share-copy');

        bookmarkUrl = bookmarkTrigger.hasAttribute(settings.bookmarkUrlAttr) && bookmarkTrigger.getAttribute(settings.bookmarkUrlAttr);
        bookmarkRecipeId = bookmarkTrigger.hasAttribute(settings.recipeIdAttr) && bookmarkTrigger.getAttribute(settings.recipeIdAttr);
        bookmarkTreffertypSub = bookmarkTrigger.hasAttribute(settings.treffertypSubAttr) && bookmarkTrigger.getAttribute(settings.treffertypSubAttr);
        bookmarkOwnRecipeId = bookmarkTrigger.hasAttribute(settings.ownRecipeIdAttr) && bookmarkTrigger.getAttribute(settings.ownRecipeIdAttr) !== '' && Number(bookmarkTrigger.getAttribute(settings.ownRecipeIdAttr));

        if (bookmarkRecipeId) {
            hasBookmarks = globals.bookmarks.getByRecipeId(Number(bookmarkRecipeId)).length > 0;
        } else if (bookmarkTreffertypSub === 'own') {
            hasBookmarks = globals.bookmarks.getByOwnRecipeId(bookmarkOwnRecipeId).length > 0;
        } else {
            hasBookmarks = globals.bookmarks.getByTargetUrl(bookmarkUrl).length > 0;
        }

        bookmarkTrigger.classList.toggle(settings.bookmarkActiveClass, hasBookmarks);
        copy.innerHTML = hasBookmarks ? store.translations['trans:gespeichert'] : store.translations['trans:speichern'];

        if (hasBookmarks) {
            greensock.fill(bookmarkTrigger, true);
        } else {
            greensock.empty(bookmarkTrigger);
        }
    });
};

instance.bindShareTriggers = function() {
    pageActions.forEach(function(pageAction) {
        var shareTrigger = pageAction.querySelector(settings.shareTrigger + ':not(.' + settings.shareTriggerBound + ')');

        if (shareTrigger) {
            shareTrigger.classList.add(settings.shareTriggerBound);

            shareTrigger.addEventListener('mouseenter', function() {
                greensock.fill(this);
            });

            shareTrigger.addEventListener('mouseleave', function() {
                greensock.empty(this);
            });
        }
    });
};

/**
 * Initializes the states of page-actions
 *
 * @param {object} options - Options for this module
 * @return {object} Returns instance of module
 */
instance.init = function (options) {
    settings = Object.assign({}, defaults, options);

    fetchPageActions();

    // initially bind the shareTriggers
    instance.bindShareTriggers();

    globals.bookmarks.subscribe('fetched', function() {
        instance.updateBookmarkStates();
    });

    pubsub.subscribe('bookmarks.created', function() {
        instance.updateBookmarkStates();
    });

    pubsub.subscribe('bookmarks.deleted', function() {
        instance.updateBookmarkStates();
    });

    pubsub.subscribe('cookoverlay.opened', function() {

        // Has to fetch containers again, since this action rendered new Elements in the DOM
        fetchPageActions();
        instance.updateBookmarkStates();
        instance.bindShareTriggers();
    });

    pubsub.subscribe('cookoverlay.next-step', function(progress) {
        if (Number(progress) === 100) {
            instance.updateBookmarkStates();
        }
    });

    return instance;
};

export default instance;
