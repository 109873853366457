// TODO: friction https://github.com/ilyashubin/scrollbooster/blob/master/src/index.js

import Smoothscroll from '@/apps/main/modules/ni-smoothscroll';

const HEIGHT_HEADER_SLIM = 51;

const ATTR_SCROLL_SECTION = 'data-scroll-navigation-section';

const CLASS_ACTIVE = 'active';
const CLASS_SCROLL_NAVIGATION_DRAGGING = 'scroll-navigation-dragging';
const CLASS_REACHED_LEFT = 'reached-left';
const CLASS_REACHED_RIGHT = 'reached-right';
const CLASS_HAS_OVERFLOW = 'overflow';

const DURATION_SCROLL_TO = 500;

const createScrollNavigation = ({ el, state, options }, Pubsub, Helper, StickyFill) => {

    let meatSelectionHookId;

    let activeSection;
    let disableClick = false;
    let hasWalked = false;
    let scrollUpdate = true;
    let downElement = false;
    let startX;
    let lastTransform = 0;
    let lastTransformMove = 0;

    let maxTransform;
    let containerWidth;

    const resizeListener = () => {
        updateSizes();
        scrollListenerDebounced();
    };

    const scrollListener = () => {
        if (!scrollUpdate) {
            return;
        }

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

        state.refs.sections.forEach((element, index) => {
            // reset classes
            element.classList.remove(CLASS_ACTIVE);
            state.refs.navItems[index].classList.remove(CLASS_ACTIVE);

            const offsetTop = HEIGHT_HEADER_SLIM + state.refs.nav.offsetHeight;
            const elementTop = element.offsetTop;
            const elementBottom = elementTop + element.offsetHeight;
            const scrollTopSticky = scrollTop + offsetTop;

            if (elementTop <= scrollTopSticky && elementBottom > scrollTopSticky) {
                activeSection = { element, index };
            }
        });

        if (activeSection) {
            const navItem = state.refs.navItems[activeSection.index];
            activeSection.element.classList.add(CLASS_ACTIVE);
            navItem.classList.add(CLASS_ACTIVE);

            // bring nav item to middle
            centerNavItem(navItem);
        }
    };

    const scrollListenerDebounced = Helper.debounce(scrollListener, 100);

    const pointerdownListener = (e) => {
        // e.preventDefault();

        const isTouch = !!e.touches;
        const pageX =  isTouch ? e.touches[0].pageX : e.pageX;

        downElement = e.target;
        document.body.classList.add(CLASS_SCROLL_NAVIGATION_DRAGGING);
        startX = pageX - state.refs.navContainer.offsetLeft;
    };

    const pointerupListener = (e) => {
        // e.preventDefault();

        const isTouch = !!e.touches;

        document.body.classList.remove(CLASS_SCROLL_NAVIGATION_DRAGGING);
        lastTransform = lastTransformMove;

        if (hasWalked && !isTouch && e.target === downElement) {
            // prevent next click
            disableClick = true;
        }

        downElement = false;
        hasWalked = false;
    };

    const pointermoveListener = (e) => {
        if (!downElement) {
            return;
        }

        // e.preventDefault();

        const isTouch = !!e.touches;
        const pageX =  isTouch ? e.touches[0].pageX : e.pageX;

        const x = pageX - state.refs.navContainer.offsetLeft;
        const walk = (x - startX);

        if (walk < -1 || walk > 1) {
            hasWalked = true;
        }

        let transformX = lastTransform + walk;
        updateNavTransform(transformX);
    };

    const linkClickListener = (e) => {
        if (disableClick) {
            e.preventDefault();
            disableClick = false;
            return;
        }

        const hash = e.currentTarget.getAttribute('href').split('/')[1];
        scrollToSection(hash);
    };

    const loadListener = () => {
        const hash = location.hash.split('/')[1];
        scrollToSection(hash);
    };

    const prevClickListener = () => {
        const transformX = lastTransform + containerWidth;
        lastTransform = updateNavTransform(transformX);
    };

    const nextClickListener = () => {
        const transformX = lastTransform - containerWidth;
        lastTransform = updateNavTransform(transformX);
    };

    const meatSelectionHookHandler = (sectionName) => {
        scrollToSection(sectionName);
    };

    const scrollToSection = (hash) => {
        const section = el.querySelector(`[${ATTR_SCROLL_SECTION}="${hash}"]`);

        if (section) {
            // temporarily disable updating active section on scroll
            scrollUpdate = false;
            const offsetTop = HEIGHT_HEADER_SLIM + state.refs.nav.offsetHeight - 1;
            const sectionOffsetTop = section.offsetTop;

            Smoothscroll.scrollTo(sectionOffsetTop, offsetTop, DURATION_SCROLL_TO, null, () => {
                // make sure that section has been reached (if pictures have been lazyloaded)
                if (sectionOffsetTop !== section.offsetTop) {
                    scrollToSection(hash);
                } else {
                    // reenable updating active section on scroll
                    // and call it manually to make sure the correct one is active
                    scrollUpdate = true;
                    scrollListener();
                }
            });
        }
    };

    const updateNavTransform = (transformX = 0) => {
        if (transformX >= 0) {
            transformX = 0;
            state.refs.nav.classList.add(CLASS_REACHED_LEFT);
            state.refs.nav.classList.remove(CLASS_REACHED_RIGHT);
        } else if (transformX <= maxTransform) {
            transformX = maxTransform;
            state.refs.nav.classList.add(CLASS_REACHED_RIGHT);
            state.refs.nav.classList.remove(CLASS_REACHED_LEFT);
        } else {
            state.refs.nav.classList.remove(CLASS_REACHED_LEFT);
            state.refs.nav.classList.remove(CLASS_REACHED_RIGHT);
        }

        state.refs.listWrapper.style.transform = `translateX(${transformX}px)`;
        lastTransformMove = transformX;

        return transformX;
    };

    const centerNavItem = (navItem) => {
        const transformX = ((containerWidth / 2) - navItem.offsetWidth / 2) - navItem.offsetLeft;
        lastTransform = updateNavTransform(transformX);
    };

    const updateSizes = () => {
        const listWidth = state.refs.listWrapper.offsetWidth;

        const computedContainer = getComputedStyle(state.refs.navContainer);
        containerWidth = state.refs.navContainer.clientWidth;
        containerWidth -= parseFloat(computedContainer.paddingLeft) + parseFloat(computedContainer.paddingRight);

        const positionLeft = state.refs.listWrapper.getBoundingClientRect().left;
        const transformMatrix = new WebKitCSSMatrix(getComputedStyle(state.refs.listWrapper).transform);

        if (listWidth + (positionLeft - transformMatrix.m41) + state.refs.prev.clientWidth <= window.innerWidth) {
            state.refs.nav.classList.remove(CLASS_HAS_OVERFLOW);
        } else {
            state.refs.nav.classList.add(CLASS_HAS_OVERFLOW);
        }

        maxTransform = (listWidth - containerWidth) / -1;
    };

    const addEventListeners = () => {
        window.addEventListener('optimizedResize', resizeListener);
        window.addEventListener('optimizedScroll', scrollListenerDebounced);

        window.addEventListener('mouseup', pointerupListener);
        window.addEventListener('mousemove', pointermoveListener);

        window.addEventListener('touchend', pointerupListener);
        window.addEventListener('touchmove', pointermoveListener, { passive: true });

        // initially scroll to section if present in hash
        if (location.hash) {
            const scrollRestorationExists = 'scrollRestoration' in history;

            if (scrollRestorationExists) {
                history.scrollRestoration = 'manual';
            }

            window.addEventListener('load', loadListener);
        }

        state.refs.listWrapper.addEventListener('mousedown', pointerdownListener);
        state.refs.listWrapper.addEventListener('touchstart', pointerdownListener, { passive: true });

        state.refs.navLinks.forEach((link) => {
            link.addEventListener('click', linkClickListener);
        });

        state.refs.prev.addEventListener('click', prevClickListener);
        state.refs.next.addEventListener('click', nextClickListener);
    };

    const removeEventListeners = () => {
        window.removeEventListener('optimizedResize', resizeListener);
        window.removeEventListener('optimizedScroll', scrollListenerDebounced);

        window.removeEventListener('mouseup', pointerupListener);
        window.removeEventListener('mousemove', pointermoveListener);

        window.removeEventListener('touchend', pointerupListener);
        window.removeEventListener('touchmove', pointermoveListener);

        window.removeEventListener('load', loadListener);

        state.refs.listWrapper.removeEventListener('mousedown', pointerdownListener);
        state.refs.listWrapper.removeEventListener('touchstart', pointerdownListener);

        state.refs.navLinks.forEach((link) => {
            link.removeEventListener('click', linkClickListener);
        });

        state.refs.prev.removeEventListener('click', prevClickListener);
        state.refs.next.removeEventListener('click', nextClickListener);
    };

    state.init = () => {
        state.refs = {
            shadowLeft: el.querySelector(options.refs.shadowLeft),
            shadowRight: el.querySelector(options.refs.shadowRight),
            prev: el.querySelector(options.refs.prev),
            next: el.querySelector(options.refs.next),
            nav: el.querySelector(options.refs.nav),
            listWrapper: el.querySelector(options.refs.listWrapper),
            navContainer: el.querySelector(options.refs.navContainer),
            navItems: el.querySelectorAll(options.refs.navItems),
            navLinks: el.querySelectorAll(options.refs.navLinks),
            sections: el.querySelectorAll(options.refs.sections)
        };

        // init position sticky polyfill
        state.stickyfill = StickyFill.addOne(state.refs.nav);

        // weird refresh for IE 11
        window.setTimeout(() => {
            state.stickyfill.refresh();

            requestAnimationFrame(() => {
                updateSizes();
                updateNavTransform();
                scrollListener();
            });
        }, 1000);

        meatSelectionHookId = Pubsub.subscribe('meatSelection.click', meatSelectionHookHandler);

        updateSizes();
        updateNavTransform();
        addEventListeners();
    };

    state.destroy = () => {
        Pubsub.unsubscribe(meatSelectionHookId);
        removeEventListeners();
        Stickyfill.removeOne(this.refs.nav);
    };

    state.init();

    return state;
};

export const config = {
    name: 'scroll-navigation',
    constructor: createScrollNavigation,
    dependencies: ['Pubsub', 'Helper', 'StickyFill'],
    options: {
        refs: {
            shadowLeft: '[data-scroll-navigation-shadow-left]',
            shadowRight: '[data-scroll-navigation-shadow-right]',
            prev: '[data-scroll-navigation-prev]',
            next: '[data-scroll-navigation-next]',
            nav: '[data-scroll-navigation-nav]',
            navLinks: '[data-scroll-navigation-nav] a',
            navItems: '[data-scroll-navigation-nav] li',
            navContainer: '[data-scroll-navigation-nav] .container',
            listWrapper: '[data-scroll-navigation-list-wrapper]',
            sections: '[data-scroll-navigation-section]'
        }
    }
};
