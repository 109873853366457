import { startup as startupGlobals } from '@/startup/globals';
import { startup as startupLibraries } from '@/startup/libs';
import { startup as startupModules } from '@/startup/modules';
import { startup as startupScrollRestore } from '@/startup/scroll-restore';
import { startup as startupUtagData } from '@/startup/utag-data';
import { startup as startupPopup } from '@/startup/popup';
import { startup as startupPrevNext } from '@/startup/prev-next';

export const startup = function() {
    startupGlobals();
    startupLibraries();
    startupPrevNext();
    startupModules();
    startupScrollRestore();
    startupUtagData();
    startupPopup();
};
