import injector from '@/scaffold/injector';
import { NAMESPACE_MAIN } from '@/scaffold/namespaces';

export const startup = injector.resolve(['Globals', 'Helper', 'Tealium', 'UtagEvents'], (Globals, Helper, Tealium, UtagEvents) => {
    const userLoaded = Globals.user.fetch();

    const waitToTrackPageView = [
        Tealium.loaded,
        window[NAMESPACE_MAIN].allTeasersAdded,
        userLoaded
    ];

    Promise.all(waitToTrackPageView).then(() => {
        userLoaded.then(({ user }) => {
            const supercardNr = user['supercard_nr'];
            const onlineId = user['online_id'];

            // set user login data
            utag.data['user_profile_profileInfo_loginStatus'] = supercardNr ? 'registered' : 'anonymous';
            utag.data['user_profile_profileInfo_profileID'] = onlineId || '';
            utag.data['user_profile_profileInfo_coid'] = onlineId || '';
        });

        window[NAMESPACE_MAIN].allTeasersAdded.then(() => {
            const recipes = Array.from(document.querySelectorAll('.teaser[data-recipe-id]'));
            const isListEvent = utag.data['event_attributes_eventName'] === 'list';
            const isCategoryEvent = utag.data['event_attributes_eventName'] === 'category';

            if (Globals.isSearch) {
                const totalCount = document.querySelector('[data-resultcount-total]').textContent;

                // set search data
                utag.data['onsiteSearch_category'] = Helper.get('filters').treffertyp;
                utag.data['onsiteSearch_searchTerm'] = Helper.get('query');
                utag.data['onsiteSearch_searchResults'] = totalCount;
            }

            if (recipes.length > 0 && (isListEvent || isCategoryEvent)) {
                // set products data
                utag.data['product_attributes_position'] = recipes.map((recipe) => recipe.getAttribute('data-position'));
                utag.data['product_productInfo_sku'] = recipes.map((recipe) => recipe.getAttribute('data-recipe-id'));
                utag.data['product_category_01'] = recipes.map((recipe) => recipe.getAttribute('data-product-category-01'));
                utag.data['product_category_02'] = recipes.map((recipe) => recipe.getAttribute('data-product-category-02'));
                utag.data['product_category_03'] = recipes.map((recipe) => recipe.getAttribute('data-product-category-03'));
                utag.data['product_productInfo_productName'] = recipes.map((recipe) => recipe.querySelector('.teaser__body-title').textContent);
                utag.data['product_list_name'] = recipes[0].closest('.t12-searchresults') ?
                    `Suche${Helper.get('query') ? ` : ${Helper.get('query')}` : ''}` :
                    `Kategorie : ${document.location.pathname.split('/').pop().replace('.html', '')}`;
            }
        });
    }).then(() => {
        UtagEvents.trackPageView();
        UtagEvents.enableListening();
        console.info('Tracking enabled');
    }).catch((err) => {
        console.warn('Failed to enable tracking:', err);
    });
});
