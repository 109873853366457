/**
 * File with local test data.
 */

export default {
    pdfs: {
        recipe: '/assets/images/test-recipe.pdf',
        recipeWithGroceryList: '/assets/images/test-recipe.sl.pdf',
        groceryList: '/assets/images/test-recipe.sl-only.pdf'
    }
};
