import fastdom from 'fastdom';

var instance = {
        initialized: null,
        breakpoints: {
            small: 0,
            medium: 768,
            large: 992,
            xlarge: 1260
        },
        curBreakpoint: null,
        viewportDimensions: {
            width: null,
            height: null
        }
    },
    customEvent = require('../helpers/helper').default.customEvent,

    /**
     * Sets the current ViewportDimensions
     * @return {Promise} - Whether viewport dimensions have been set
     */
    setViewportDimensions = function() {
        return new Promise((resolve) => {
            fastdom.measure(() => {
                instance.viewportDimensions = {
                    width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
                    height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
                };

                resolve();
            });
        });
    },

    /**
     * Sets the current Breakpoint
     */
    setCurBreakpoint = async function() {
        await setViewportDimensions();

        var tempBreakpoint = instance.curBreakpoint;

        if(instance.viewportDimensions.width < instance.breakpoints.medium) {
            instance.curBreakpoint = 'small';
        } else if(instance.viewportDimensions.width < instance.breakpoints.large) {
            instance.curBreakpoint = 'medium';
        } else if(instance.viewportDimensions.width < instance.breakpoints.xlarge) {
            instance.curBreakpoint = 'large';
        } else {
            instance.curBreakpoint = 'xlarge';
        }

        // triggers every listener
        if(tempBreakpoint !== instance.curBreakpoint) {
            window.dispatchEvent(customEvent('breakpointChange', {
                new: instance.curBreakpoint,
                old: tempBreakpoint
            }));
        }
    },

    /**
     * Inits the instance
     *
     * @return {object} instance
     */
    init = function() {
        instance.initialized = setCurBreakpoint();
        window.addEventListener('resize', setCurBreakpoint, false);

        return instance;
    };

// returns initioated object, so that
export default init();
