const ingredientFilter = ({ el, store }, Vue, Pubsub, Helper) => new Vue({
    el,
    delimiters: ['((', '))'],
    data: store,
    computed: {
        recipes() {
            return Helper.groupBy(this.ingredients, 'recipeId');
        }
    },
    methods: {
        setRecipeFilter(id) {
            this.filteredRecipeId = id ? parseInt(id, 10) : null;
        }
    },
    updated() {
        Pubsub.publish('ingredientFilters.updated');
    }
});

export default ingredientFilter;
