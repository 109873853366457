

/* private functions & vars */

var g = require('@/scaffold/globals').default,
    breakpoint = require('@/libs/breakpoint').default,
    forEach = require('@/helpers/helper').default.forEach,
    header,
    pubsub = require('@/scaffold/pubsub').default,
    burger,
    loginLink,
    body,
    html,
    navigation,
    scrollBlockerElements,
    animatedElements,
    isOpen = false,
    isSlim = false,
    setHeaderElement = function () {
        if (header instanceof HTMLElement === false) {
            header = document.getElementById('header');
        }
    },
    setBurgerElement = function() {
        if(burger instanceof HTMLElement === false) {
            burger = document.getElementById('burger');
        }
    },
    setBodyElement = function() {
        if(body instanceof HTMLElement === false) {
            body = document.getElementsByTagName('body')[0];
        }
    },
    setHtmlElement = function() {
        if(html instanceof HTMLElement === false) {
            html = document.getElementsByTagName('html')[0];
        }
    },
    setNavigation = function() {
        if(navigation instanceof HTMLElement === false) {
            navigation = document.getElementById('navigation');
        }
    },
    setBlockerElements = function() {
        scrollBlockerElements = document.querySelectorAll('.navigation-hamburger, .header-logo, .navigation-meta, .navigation-meta__touch-coop-link, .language-nav, .header__touch-nav-bg, .header__touch-bottom-wrapper');
    },
    setAnimatedElements = function() {
        animatedElements = document.querySelectorAll('.header__touch-nav-bg, .navigation-desktop, .header__touch-bottom-wrapper');
    },
    burgerClickHandler = function () {
        if(isOpen === false) {
            instance.openTouchNavigation();
        } else if(isOpen === true) {
            instance.closeTouchNavigation();
        }
    },
    addSlimClass = function () {
        setHeaderElement();
        isSlim = true;
        header.classList.add('header--slim');
    },
    removeSlimClass = function () {
        setHeaderElement();
        isSlim = false;
        header.classList.remove('header--slim');
    },
    headerScrollHandler = function () {
        var scrollObject = {
            x: window.pageXOffset,
            y: window.pageYOffset
        };

        var slimBreakpoint = 60;

        if (scrollObject.y >= slimBreakpoint && isSlim === false) {
            addSlimClass();
        } else if (scrollObject.y < slimBreakpoint && isSlim === true) {
            removeSlimClass();
        }
    },
    disableScrolling = function(e) {
        e.preventDefault();
    },

    checkLoginState = function () {
        if (g.user.isLoggedIn()) {

            // disable login link
            loginLink.classList.add('logged-in');
        }
    };

/* public functions */

var instance = {};

instance.openTouchNavigation = function() {
    setHeaderElement();
    setBodyElement();
    setHtmlElement();
    setNavigation();
    setBlockerElements();
    setAnimatedElements();

    isOpen = true;
    header.classList.add('header--open');

    setTimeout(function() {

        [].forEach.call(animatedElements, function(element) {
            element.classList.add('trump--block');
            setTimeout(function() {
                requestAnimationFrame(function() {
                    element.classList.add('animate--fade-in');
                });
            }, 20);
        });

        setTimeout(function() {

            // after all elements are physically visible in the DOM, height can be read and eventListeners can be added

            [].forEach.call(animatedElements, function(element) {
                element.classList.add('animate--fade-in');
            });

            var height = navigation.offsetHeight,
                scrollHeight = navigation.querySelectorAll('ul')[0].offsetHeight;

            // // Disable parent scrolling
            // navigation.addEventListener('touchstart', function() {
            //     if (this.scrollTop === 0) {
            //         this.scrollTop = 1;
            //     } else if (scrollHeight === this.scrollTop + height) {
            //         this.scrollTop -= 1;
            //     }
            // });

            navigation.scrollTop = 1;

            navigation.addEventListener('scroll', function() {
                if (this.scrollTop === 0) {
                    this.scrollTop = 1;
                } else if (scrollHeight === this.scrollTop + height) {
                    this.scrollTop -= 1;
                }
            });

            // Add scrollblocker to surrounding elements of navigation
            [].forEach.call(scrollBlockerElements, function(element) {
                element.addEventListener('touchmove', disableScrolling);
            });
        }, 20);
    }, 450);
};

instance.closeTouchNavigation = function() {
    setHeaderElement();
    setBodyElement();
    setHtmlElement();
    setNavigation();
    setBlockerElements();
    setAnimatedElements();

    isOpen = false;
    header.classList.remove('header--open');

    var selfRemovingCallbackHeaderClosing;
    header.addEventListener(transitionEnd(), selfRemovingCallbackHeaderClosing = function(e) {
        if (header === e.target) {
            this.removeEventListener(transitionEnd(), selfRemovingCallbackHeaderClosing);
            this.classList.remove('header--closing');
        }
    });

    header.classList.add('header--closing');

    [].forEach.call(animatedElements, function(element) {
        requestAnimationFrame(function() {
            var selfRemovingCallback;

            // this gets triggered as soon as the css transition finishes of the current element
            element.addEventListener(transitionEnd(), selfRemovingCallback = function() {
                this.removeEventListener(transitionEnd(), selfRemovingCallback);
                this.classList.remove('animate--fade-out');
                this.classList.remove('trump--block');
            });

            element.classList.remove('animate--fade-in');
            element.classList.add('animate--fade-out');
        });
    });

    // Remove scrollblocker event on header section
    [].forEach.call(scrollBlockerElements, function(element) {
        element.removeEventListener('touchmove', disableScrolling);
    });
};

instance.addTouchNavigationHandler = function() {
    setBurgerElement();
    burger.addEventListener('click', burgerClickHandler);
};

instance.removeTouchNavigationHandler = function() {
    setBurgerElement();
    burger.removeEventListener('click', burgerClickHandler);
};

instance.addHeaderScrollHandler = function() {
    window.addEventListener('scroll', headerScrollHandler);
};

instance.removeHeaderScrollHandler = function() {
    window.removeEventListener('scroll', headerScrollHandler);
};

var desktopNavDropdowns = (function() {
    var links = document.querySelectorAll('.navigation-desktop__l1-list-item');
    var returns = [];
    forEach(links, function(link) {
        // check if has child ul
        if (link.children.length > 1) {
            link.setAttribute('data-nav-items', link.children[1].children.length); // amount of child lis
            returns.push(link);
        }
    });
    return returns;
})();

var onMouseEnter = function() {
        var openHeight = this.getAttribute('data-nav-items') * 40;
        this.children[1].style.maxHeight = openHeight + 'px';
    },

    onMouseLeave = function() {
        this.children[1].style.maxHeight = '0';
    };

instance.dropdownController = function(newBreakpoint, oldBreakpoint) {

    oldBreakpoint = oldBreakpoint || '';

    if(breakpoint.curBreakpoint === 'xlarge') {
        desktopNavDropdowns.forEach(function(trigger) {
            trigger.addEventListener('mouseenter', onMouseEnter);
            trigger.addEventListener('mouseleave', onMouseLeave);
        });
    } else if(oldBreakpoint !== '') {
        desktopNavDropdowns.forEach(function(trigger) {
            trigger.removeEventListener('mouseenter', onMouseEnter);
            trigger.removeEventListener('mouseleave', onMouseLeave);
            trigger.children[1].style.maxHeight = '';
        });
    }
};

instance.init = function() {
    setHeaderElement();

    // check if header exists in this template
    if(header instanceof Element === false) {
        return false;
    }

    setBurgerElement();
    setBodyElement();
    setHtmlElement();
    setNavigation();
    setBlockerElements();
    setAnimatedElements();

    pubsub.subscribe('smartbanner.close', function () {
        var selfRemovingCallbackHeaderClosing;
        header.addEventListener(transitionEnd(), selfRemovingCallbackHeaderClosing = function (e) {
            if (header === e.target) {
                this.removeEventListener(transitionEnd(), selfRemovingCallbackHeaderClosing);
                header.style.transition = '';
                header.classList.remove('header--smartbanner-closing');
            }
        });
    });

    pubsub.subscribe('smartbanner.initialized', function () {
        var selfRemovingCallbackHeaderClosing;
        header.addEventListener(transitionEnd(), selfRemovingCallbackHeaderClosing = function (e) {
            if (header === e.target) {
                this.removeEventListener(transitionEnd(), selfRemovingCallbackHeaderClosing);
                header.style.transition = '';
                this.classList.remove('header--smartbanner-opening');
            }
        });
    });

    // cache login link
    loginLink = header.querySelector('[data-login-link]');

    instance.addHeaderScrollHandler();
    instance.addTouchNavigationHandler();

    breakpoint.initialized.then(() => {
        window.addEventListener('breakpointChange', function (event) {
            instance.dropdownController(event.detail.new, event.detail.old);
        });

        instance.dropdownController(breakpoint.curBreakpoint);
    });

    g.user.subscribe('fetched', checkLoginState);
    g.user.subscribe('changed', checkLoginState);
};

window.headerModule = instance;

export default instance;
