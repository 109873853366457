

// dependencies
var observer = require('@/libs/ni-observer').default;

// vars
var defaults = {
    wrapper: '.cookbook-add',
    form: '.cookbook-add__form',
    input: '.cookbook-add__input',
    submit: '.cookbook-add__submit',
    error: '.cookbook-add__error',
    spinner: '.loading-add',
    classError: 'error'
};

/**
 * Factory for the
 *
 * @param {object} cookbooks - Cookbooks model
 * @param {object} container - DOM element
 * @param {object} options - Overrides default values
 * @return {object} Instance of viewmodel
 */
export default function (cookbooks, container, options) {
    var settings = Object.assign({}, defaults, options),
        instance = {
            elements: {}
        },
        busy,
        errorMessageEmpty,

        submit = function(e) {
            var status;

            if (instance.elements.input.value === '') {
                status = false;
                // set focus back to input (for ie)
                instance.elements.input.focus();
                instance.elements.input.classList.add(settings.classError);
                instance.elements.error.classList.add('show');
                instance.elements.error.innerHTML = errorMessageEmpty;
            } else {
                add(instance.elements.input.value);
                status = true;
                instance.elements.input.classList.remove(settings.classError);
                instance.elements.error.classList.remove('show');
                instance.elements.error.innerHTML = '';
            }

            instance.publish('submit', [status]);

            e.preventDefault();
        },

        cleanInput = function() {
            instance.elements.input.value = '';
        },

        add = function(title) {
            var uid, errorUid,

                cleanup = function () {
                    cookbooks.unsubscribe(uid);
                    cookbooks.unsubscribe(errorUid);
                    busy = false;
                },

                createdHandler = function (cookbook) {
                    cleanInput();
                    instance.publish('added', [cookbook]);

                    if (instance.elements.spinner) {
                        instance.elements.spinner.classList.remove('loading-add--state-plus');
                        instance.elements.spinner.classList.add('loading-add--state-check');
                        instance.elements.spinner.classList.remove('loading-add--state-spinning');
                    }

                    // time that the check is shown
                    setTimeout(function () {
                        if (instance.elements.spinner) {
                            instance.elements.spinner.classList.add('loading-add--state-plus');
                            instance.elements.spinner.classList.remove('loading-add--state-check');
                            instance.elements.spinner.classList.remove('loading-add--state-spinning');
                        }
                    }, 2000);

                    cleanup();
                },

                createdErrorHandler = function (code, response) {
                    instance.elements.input.classList.add(settings.classError);
                    instance.elements.error.classList.add('show');
                    instance.elements.error.innerHTML = response.message || 'An error has occured while saving';

                    if (instance.elements.spinner) {
                        instance.elements.spinner.classList.add('loading-add--state-plus');
                        instance.elements.spinner.classList.remove('loading-add--state-check');
                        instance.elements.spinner.classList.remove('loading-add--state-spinning');
                    }

                    cleanup();
                };

            // if not already a request is processing
            if (busy === false) {
                busy = true;

                uid = cookbooks.subscribe('created', createdHandler);
                errorUid = cookbooks.subscribe('created.error', createdErrorHandler);

                // reset error element and input
                instance.elements.input.classList.remove(settings.classError);
                instance.elements.error.classList.remove('show');
                instance.elements.error.innerHTML = '';

                // make ajax call
                cookbooks.createCookbook(title);

                if (instance.elements.spinner) {
                    instance.elements.spinner.classList.remove('loading-add--state-plus');
                    instance.elements.spinner.classList.remove('loading-add--state-check');
                    instance.elements.spinner.classList.add('loading-add--state-spinning');
                }
            }
        },

        bindEvents = function() {
            instance.elements.submit.addEventListener('click', submit);
            instance.elements.form.addEventListener('submit', submit);
        },

        unbindEvents = function () {
            if (instance.elements.submit) {
                instance.elements.submit.removeEventListener('click', submit);
            }
            if (instance.elements.submit) {
                instance.elements.form.removeEventListener('submit', submit);
            }
        };

    /**
     * Initializes the factory
     *
     * @return {object} instance
     */
    instance.init = function() {

        busy = false;

        instance.elements.wrapper = container.querySelector(settings.wrapper);
        instance.elements.form = container.querySelector(settings.form);
        instance.elements.input = container.querySelector(settings.input);
        instance.elements.submit = container.querySelector(settings.submit);
        instance.elements.spinner = container.querySelector(settings.spinner);
        instance.elements.error = container.querySelector(settings.error);

        errorMessageEmpty = instance.elements.input.getAttribute('data-cookbook-add-empty-error');

        bindEvents();

        // Extends the instance with the functions subscribe(), unsubscribe() & publish()
        observer(instance);

        return instance;
    };

    /**
     * Should revert to initial state
     *
     */
    instance.destroy = function() {
        unbindEvents();
        instance.unsubscribeAll();
        instance.elements = {};
    };

    return instance.init();
};
