

// vars
var instance = {};


// private functions
var deserialize = function (value) {
        return typeof value === 'string' ? JSON.parse(value) : void 0;
    },

    serialize = function (value) {
        return JSON.stringify(value);
    };


// public functions
instance.get = function (key, defaultValue) {
    var value = deserialize(localStorage.getItem(key));
    return (typeof value === 'undefined' ? defaultValue : value);
};

instance.set = function (key, value) {
    localStorage.setItem(key, serialize(value));
};

instance.remove = function (key) {
    localStorage.removeItem(key);
};

export default instance;
