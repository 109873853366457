// https://github.com/diegomorales/mini-modal

import * as BodyScrollLock from 'body-scroll-lock';

// dependencies
var getCombinedheight = require('@/helpers/helper').default.getCombinedheight;

var MiniModal = {},
    defaults = {
        modalStaticHeightClass: 'mini-modal--static-height',
        modalOverlayClass: 'mini-modal__background',
        modalContentClass: 'mini-modal__content',
        modalCloseBtnClass: 'mini-modal__close',
        bodyOpenClass: 'mini-modal--active',
        modalOpenClass: 'mini-modal--open',
        backgroundClickClose: true,
        escClose: true,
        openImmediately: false,
        moveToBodyEnd: true,
        onInit: function () {},
        onBeforeOpen: function () {},
        onBeforeClose: function () {},
        onAfterClose: function () {}
    },

    selectors = {
        modalOverlay: '[data-mini-modal-background]',
        modalContent: '[data-mini-modal-content]',
        modalCloseBtn: '[data-mini-modal-close]',
        scrollBlocker: '[data-mini-modal-scrollblocker]',
        modalFlexWrapper: '[data-mini-modal-flex-wrapper]',
        modalFlexContainer: '[data-mini-modal-flex]'
    },

    activeModal;

// static functions
MiniModal.create = function (modalId, options) {
    var settings = Object.assign({}, defaults, options),
        m = {},
        overflowScrollPreventer;

    // abort if modal doesn't exist.
    if (!(m.modal = document.getElementById(modalId))) {
        return console.warn('Element with id "' + modalId + '" not found.');
    }

    // store element references
    m.modalOverlay = m.modal.querySelector(selectors.modalOverlay);
    m.modalContent = m.modal.querySelector(selectors.modalContent);
    m.modalCloseBtn = m.modal.querySelector(selectors.modalCloseBtn);
    m.modalFlexWrapper = m.modal.querySelector(selectors.modalFlexWrapper);
    m.modalFlexContainer = m.modal.querySelector(selectors.modalFlexContainer);
    m.modalFlexContainerInner = m.modalFlexContainer.children[0];

    // add styling classes
    m.modalOverlay.classList.add(settings.modalOverlayClass);
    m.modalContent.classList.add(settings.modalContentClass);

    if (m.modalCloseBtn) {
        m.modalCloseBtn.classList.add(settings.modalCloseBtnClass);
    }

    if (m.modal.getAttribute('data-mini-modal-static-height') !== null) {
        m.hasStaticHeight = true;
        m.modal.classList.add(settings.modalStaticHeightClass);
    } else {
        m.hasStaticHeight = false;
    }

    var disableEvent = function(e) {
            e.preventDefault();
        },

        bindClose = function () {
            if (m.modalCloseBtn) {
                m.modalCloseBtn.addEventListener('click', close);
            }

            if (settings.escClose) {
                document.body.addEventListener('keyup', escCloseHandler);
            }

            if (settings.backgroundClickClose) {
                m.modalOverlay.addEventListener('click', close);
            }
        },

        unbindClose = function () {
            if (m.modalCloseBtn) {
                m.modalCloseBtn.removeEventListener('click', close);
            }
            m.modalOverlay.removeEventListener('click', close);
            document.body.removeEventListener('keyup', escCloseHandler);
        },

        bindEventHandlers = function() {
            if (!m.hasStaticHeight) {
                m.modal.querySelectorAll(selectors.scrollBlocker).forEach(function(trigger) {
                    trigger.addEventListener('touchmove', disableEvent);
                });

                resizeHandler();
                window.addEventListener('optimizedResize', resizeHandler);
            }
        },

        unbindEventHandlers = function() {
            if (!m.hasStaticHeight) {
                m.modal.querySelectorAll(selectors.scrollBlocker).forEach(function(trigger) {
                    trigger.removeEventListener('touchmove', disableEvent);
                });

                releaseFlex();
                window.removeEventListener('optimizedResize', resizeHandler);
            }
        },

        setFlex = function() {
            var containerHeight = m.modalFlexContainer.offsetHeight,
                innerHeight = getCombinedheight(m.modalFlexContainer.children, true);

            m.modalFlexContainerInner.scrollTop = 1;

            m.modalFlexContainerInner.removeEventListener('touchmove', disableEvent);
            m.modalFlexContainerInner.removeEventListener('touchstart', overflowScrollPreventer);

            overflowScrollPreventer = function() {
                if (this.scrollTop === 0) {
                    this.scrollTop = 1;
                } else if (innerHeight <= this.scrollTop + containerHeight + 1) {
                    this.scrollTop -= 1;
                }
            };

            if (containerHeight < innerHeight) {
                m.modalFlexContainer.style.height = containerHeight + 'px';
                m.modalFlexContainer.classList.add('mini-modal__flex-flex--scrollable');
                m.modalFlexContainerInner.addEventListener('touchstart', overflowScrollPreventer);
            } else {
                m.modalFlexContainerInner.addEventListener('touchmove', disableEvent);
            }
        },

        releaseFlex = function() {
            m.modalFlexContainer.style.height = '';
            m.modalFlexContainer.classList.remove('mini-modal__flex-flex--scrollable');
            m.modalFlexContainerInner.removeEventListener('touchmove', disableEvent);
            m.modalFlexContainerInner.removeEventListener('touchstart', overflowScrollPreventer);
        },

        setFlexParent = function() {

            // the sole purpose of this is to fix a flexbox issue in IE 11 which won't shring the flex-flex if there is no height set on the parent
            if (parseInt(document.defaultView.getComputedStyle(m.modalFlexWrapper, '').getPropertyValue('max-height')) === m.modalFlexWrapper.offsetHeight) {
                m.modalFlexWrapper.style.height = m.modalFlexWrapper.offsetHeight + 'px';
            }
        },

        releaseFlexParent = function() {
            m.modalFlexWrapper.style.height = '';
        },

        resizeHandler = function() {
            releaseFlex();
            releaseFlexParent();
            setFlexParent();
            setFlex();
        },

        escCloseHandler = function (e) {
            if (e.keyCode === 27) {
                close();
            }

            e.preventDefault();
        },

        open = function () {

            // close any open modal first.
            MiniModal.close();

            bindClose();
            bindEventHandlers();

            // show modal. setTimeout is needed if transitions are used.
            setTimeout(function () {

                // callback
                settings.onBeforeOpen.call(null, m);

                // show modal
                document.body.classList.add(settings.bodyOpenClass);
                m.modal.classList.add(settings.modalOpenClass);

                // store active modal, so it can be closed with static close method.
                activeModal = m;
            }, 10);
        },

        close = function () {
            if (arguments[0] && arguments[0].preventDefault) {
                arguments[0].preventDefault();
            }

            BodyScrollLock.clearAllBodyScrollLocks();

            // fire callback
            if (settings.onBeforeClose.call(null, m) !== false) {

                // hide modal
                m.modal.classList.remove(settings.modalOpenClass);
                document.body.classList.remove(settings.bodyOpenClass);

                unbindClose();
                unbindEventHandlers();
                activeModal = null;

                settings.onAfterClose.call(null, m);
            }
        },

        // You will need this only in very rare occasions
        trigger = function (callbackName) {
            switch (callbackName) {
            case 'onInit':
                settings.onInit.call(null, m);
                break;

            case 'onBeforeOpen':
                settings.onBeforeOpen.call(null, m);
                break;

            case 'onBeforeClose':
                settings.onBeforeClose.call(null, m);
            }
        };

        // This is very ugnly, but needs to be fixed now
        // TODO: Refactor later, combine with line 120
        // createOverflowScrollHandler = (container, children) => {
        //     let callback;

        //     return () => {
        //         const containerHeight = container.offsetHeight;
        //         const innerHeight = getCombinedheight(children, true);

        //         container.scrollTop = 1;

        //         // Doesn't do anything if handler is not attached
        //         container.removeEventListener('touchmove', disableEvent);
        //         container.removeEventListener('touchstart', callback);

        //         callback = function() {
        //             if (this.scrollTop === 0) {
        //                 this.scrollTop = 1;
        //             } else if (innerHeight <= this.scrollTop + containerHeight + 1) {
        //                 this.scrollTop -= 1;
        //             }
        //         };

        //         if (containerHeight < innerHeight) {
        //             container.addEventListener('touchstart', callback);
        //         } else {
        //             container.addEventListener('touchmove', disableEvent);
        //         }
        //     };
        // },

        // staticHeightHandler = createOverflowScrollHandler(m.modal, [m.modalContent]);

    settings.onInit.call(null, m);

    // move modal to end of body
    if (settings.moveToBodyEnd) {
        document.body.appendChild(m.modal);
    }

    // show modal
    if (settings.openImmediately) {
        open();
    }

    if (m.hasStaticHeight) {
        BodyScrollLock.disableBodyScroll(m.modal, {
            allowTouchMove: el => {
                if (el.tagName === 'TEXTAREA') {
                    return true;
                }

                const modalFlex = el.closest('[data-mini-modal-flex]');
                const modalFlexScrollable = modalFlex.scrollHeight > modalFlex.offsetHeight;

                if (modalFlex && modalFlexScrollable) {
                    return true;
                }
            }
        });
    }

    // export functions to instance
    m.open = open;
    m.close = close;
    m.trigger = trigger;
    m.bindClose = bindClose;
    m.unbindClose = unbindClose;

    m.setOptions = function (options) {
        settings = Object.assign({}, settings, options);
    };

    return m;
};

// static functions
MiniModal.close = function () {
    activeModal && activeModal.close();
    activeModal = null;
};

MiniModal.open = function (id, options) {
    MiniModal.close();
    return MiniModal.create(id, Object.assign({}, options, { openImmediately: true }));
};

MiniModal.getActiveModal = function () {
    return activeModal;
};

export default MiniModal;
