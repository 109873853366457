const createFilterBar = ({ el, store }, Vue) => new Vue({
    el,
    data: {
        store,
        collapsed: true
    },
    mounted() {
        window.addEventListener('scroll', this.collapse, { passive: true });
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.collapse);
    },
    methods: {
        collapse() {
            this.collapsed = true;
        },
        onClick(newFilter) {
            if (!this.collapsed) {
                this.store.filter = newFilter;
            }
            this.collapsed = !this.collapsed;
        }
    }
});

export default createFilterBar;
