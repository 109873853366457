import injector from '@/scaffold/injector';
import { NAMESPACE_MAIN } from '@/scaffold/namespaces';
import { default as createApp, getModule } from '@/scaffold/app';

import { config as myFoobyConfig } from '@/apps/myfooby-vue/main';
import { config as seasonCalendarConfig } from '@/apps/seasoncalendar/main';

import { config as agbCheckConfig } from '@/apps/main/modules/agb-check';
import { config as portionCalculatorConfig } from '@/apps/main/modules/portion-calculator';
import { config as printRecipeConfig } from '@/apps/main/modules/print-recipe';
import { config as saveLocationConfig } from '@/apps/main/modules/save-location';
import { config as countdownConfig } from '@/apps/main/modules/countdown';
import { config as contestConfig } from '@/apps/main/modules/contest';
import { config as tooltipConfig } from '@/apps/main/modules/tooltip';
import { config as recipeOverlayConfig } from '@/apps/main/modules/recipe-overlay';
import { config as imagegalleryConfig } from '@/apps/main/modules/imagegallery';
import { config as lightboxConfig } from '@/apps/main/modules/lightbox';
import { config as miniModalFactoryConfig } from '@/apps/main/modules/mini-modal-factory';
import { config as newsletterSignupConfig } from '@/apps/main/modules/newsletter-signup';
import { config as newsletterSignoutConfig } from '@/apps/main/modules/newsletter-signout';
import { config as searchHomeConfig } from '@/apps/main/modules/search-home';
import { config as notesConfig } from '@/apps/main/modules/notes';
import { config as saveRecipeIngredientsConfig } from '@/apps/main/modules/save-recipe-ingredients';
import { config as scrollboostConfig } from '@/apps/main/modules/scrollboost';
import { config as homeTitleConfig } from '@/apps/main/modules/home-title';
import { config as scrollNavigationConfig } from '@/apps/main/modules/scroll-navigation';
import { config as pageHeaderConfig } from '@/apps/main/modules/page-header';
import { config as pageHeaderRecipeConfig } from '@/apps/main/modules/page-header-recipe';
import { config as readmoreConfig } from '@/apps/main/modules/readmore';
import { config as prevNextConfig } from '@/apps/main/modules/prev-next';
import { config as meatSelectionConfig } from '@/apps/main/modules/meat-selection';
import { config as foobyappConfig } from '@/apps/main/modules/foobyapp';
import { config as popularRecipesConfig } from '@/apps/main/modules/popular-recipes';
import { config as weneedConfig } from '@/apps/main/modules/weneed';
import { config as categoryEntranceConfig } from '@/apps/main/modules/category-entrance';
import { config as searchConfig } from '@/apps/search/modules/search-factory';

// TODO: cleanup from time to time
// legacy modules
import header from '@/apps/main/modules/header';
import footer from '@/apps/main/modules/footer';
import equalizer from '@/apps/main/modules/ni-equalizer-new';
import teasers from '@/apps/main/modules/teasers';
import teaserGroupFeed from '@/apps/search/modules/teaser-group-feed';
import teaserGroupAutomated from '@/apps/main/modules/teaser-group-automated';
import fromCoopApp from '@/apps/main/modules/from-coopapp';
import videos from '@/apps/main/modules/videos';
import smoothscroll from '@/apps/main/modules/ni-smoothscroll';
import recipelist from '@/apps/main/modules/teaser-group-recipelist';
import shoppingList from '@/apps/main/modules/shoppinglist';
import alertMessage from '@/apps/main/modules/alert-message-factory';
import myfooby from '@/apps/myfooby/myfooby-factory';
import pageActions from '@/apps/main/modules/page-actions';
import serviceTeaser from '@/apps/main/modules/service-teaser';
import likes from '@/apps/main/modules/likes';

export const startup = injector.resolve(['Pubsub'], (Pubsub) => {
    let teaserGroupsInitialized = 0;
    let teaserGroupsAdded = 0;

    const teaserInitializedHook = Pubsub.subscribe('teasers.initialized', (containers) => {
        teaserGroupsInitialized += containers.length || 1;
    });

    const teaserAddedHook = Pubsub.subscribe('teasers.added', () => {
        teaserGroupsAdded++;
    });

    // Estatico App initiation
    window[NAMESPACE_MAIN] = createApp({
        configs: [
            myFoobyConfig,
            foobyappConfig,
            pageHeaderRecipeConfig,
            pageHeaderConfig,
            prevNextConfig,
            searchHomeConfig,
            searchConfig,
            popularRecipesConfig,
            agbCheckConfig,
            seasonCalendarConfig,
            saveLocationConfig,
            portionCalculatorConfig,
            printRecipeConfig,
            weneedConfig,
            categoryEntranceConfig,
            lightboxConfig,
            imagegalleryConfig,
            countdownConfig,
            contestConfig,
            tooltipConfig,
            recipeOverlayConfig,
            miniModalFactoryConfig,
            newsletterSignupConfig,
            newsletterSignoutConfig,
            notesConfig,
            scrollNavigationConfig,
            meatSelectionConfig,
            scrollboostConfig,
            saveRecipeIngredientsConfig,
            readmoreConfig,
            homeTitleConfig
        ]
    });

    // TODO: refactor and listen to correct events e.g. teasers.added (same for likes)
    Pubsub.subscribe('tooltip.init', () => {
        getModule(NAMESPACE_MAIN, 'tooltip').init();
    });

    // TODO: cleanup from time to time
    // legacy modules
    recipelist();
    alertMessage.call(document);
    likes();
    fromCoopApp.init();
    smoothscroll.init();
    header.init();
    equalizer.init();
    myfooby.init();
    shoppingList.init();
    serviceTeaser.init();
    videos.init();
    footer.init();
    pageActions.init();
    teasers.init();
    teaserGroupFeed();
    teaserGroupAutomated();

    // promise to see when all initially loaded teasers are added to the DOM
    window[NAMESPACE_MAIN].allTeasersAdded = new Promise(async (resolve) => {
        await window[NAMESPACE_MAIN].initialized;

        const updateRecipePositions = () => {
            const recipes = document.querySelectorAll('.teaser[data-recipe-id]');

            recipes.forEach((recipe, i) => {
                recipe.setAttribute('data-position', i + 1);
            });
        };

        const finish = () => {
            // add data-position attribute to all recipe teasers (static or loaded with ajax)
            updateRecipePositions();
            // update data-position when new ajax teasers are added
            Pubsub.subscribe('teasers.added', updateRecipePositions);

            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    resolve({
                        teaserGroupsAdded,
                        teaserGroupsInitialized
                    });
                });
            });
        };

        if (teaserGroupsInitialized === teaserGroupsAdded) {
            Pubsub.unsubscribe(teaserInitializedHook);
            Pubsub.unsubscribe(teaserAddedHook);
            finish();
            return;
        }

        const teasersAddedHookId2 = Pubsub.subscribe('teasers.added', () => {
            if (teaserGroupsInitialized <= teaserGroupsAdded) {
                Pubsub.unsubscribe(teaserInitializedHook);
                Pubsub.unsubscribe(teaserAddedHook);
                Pubsub.unsubscribe(teasersAddedHookId2);
                finish();
            }
        });
    });
});
