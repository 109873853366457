

// dependencies
var hogan = require('hogan.js'),
    forEach = require('@/helpers/helper').default.forEach,
    toArray = require('@/helpers/helper').default.toArray,
    observer = require('@/libs/ni-observer').default;

/* eslint-disable indent */

// vars
var defaults = {
        url: '', // is the identifyer for the teaser
        container: '.cookbook-select'
    },

    templates = {
        item: [`
            <label class="cookbook-select__item" data-cookbook-id="{{ cookbook_id }}">
                <input id="cb-{{ uid }}" type="checkbox" class="cookbook-select__item-checkbox">
                <div class="cookbook-select__item-label label">
                    {{^isLikeCookbook}}
                        {{ title }}
                    {{/isLikeCookbook}}

                    {{#isLikeCookbook}}
                        {{ likeTitle }}
                    {{/isLikeCookbook}}
                </div>
                <div class="cookbook-select__item-icon">
                    {{^isLikeCookbook}}
                        <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                            <path d="M17.5 3H5v18h12.5c.4 0 .75-.15 1.05-.45.3-.3.45-.65.45-1.05v-15c0-.4-.15-.75-.45-1.05-.3-.3-.65-.45-1.05-.45Zm-5.55 1h3.1v4.15L13.5 7.1l-1.55 1.05V4ZM6 4h1v16H6V4Zm12 15.5c0 .1333-.05.25-.15.35-.1.1-.2167.15-.35.15H8V4h3v6l2.5-1.75L16 10V4h1.5c.1333 0 .25.05.35.15.1.1.15.2167.15.35v15Z" fill="currentColor"></path>
                        </svg>
                    {{/isLikeCookbook}}
                    {{#isLikeCookbook}}
                        <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                            <path d="M7.85 5c.6667 0 1.2833.1667 1.85.5.6.3333 1.0833.8 1.45 1.4L12 8.3l.85-1.4c.4333-.7667 1.05-1.3 1.85-1.6.8333-.3333 1.6667-.3833 2.5-.15.8333.2333 1.5167.7 2.05 1.4.5333.7.7833 1.5.75 2.4-.0333 1.7667-.6167 3.3167-1.75 4.65-1.7667 2.1333-3.85 3.8833-6.25 5.25-2.3667-1.4333-4.4333-3.2-6.2-5.3C4.6667 12.2167 4.0667 10.6667 4 8.9c0-1.0667.3667-1.9833 1.1-2.75C5.8667 5.3833 6.7833 5 7.85 5Zm8.3-1c-.8333 0-1.6333.2167-2.4.65-.7333.4333-1.3167 1.0167-1.75 1.75-.4333-.7333-1.0333-1.3167-1.8-1.75C9.4667 4.2167 8.6833 4 7.85 4c-1.3333 0-2.4833.4833-3.45 1.45C3.4667 6.4167 3 7.5667 3 8.9c.0667 2 .7333 3.75 2 5.25 2 2.3667 4.3333 4.3167 7 5.85l1.3-.75c1.0333-.6333 1.9833-1.3167 2.85-2.05 1.2333-1 2.2-2 2.9-3 .6333-.7333 1.1167-1.55 1.45-2.45.3333-.9333.5-1.8833.5-2.85 0-1.3333-.4833-2.4667-1.45-3.4-.9333-.9667-2.0667-1.4667-3.4-1.5Z" fill="currentColor"></path>
                        </svg>
                    {{/isLikeCookbook}}
                </div>
            </label>
        `].join('\n')
    },

    uid = -1;

/* eslint-enable indent */

/**
 * Viewmodel for selecting cookbooks
 *
 * @param {object} cookbooks - Cookbooks model
 * @param {object} container - DOM element
 * @param {object} options - Overrides default values
 * @return {object} Instance of viewmodel
 */
export default function (cookbooks, container, options) {
    container = container || document;

    var settings = Object.assign({}, defaults, options),
        instance = {},
        containers = {},
        cookbooksList = [],

        cleanCookbooks = function() {
            containers.container.innerHTML = '';
        },

        insertCookbooks = function() {
            cleanCookbooks();

            const likeCookbook = cookbooksList.filter(cookbook => cookbook.title === store.cookbookLikes);
            const restCookbooks = cookbooksList.filter(cookbook => cookbook.title !== store.cookbookLikes);

            cookbooksList = [...likeCookbook, ...restCookbooks].map((cookbook) => {
                const isLikeCookbook = cookbook.title === store.cookbookLikes;
                const isSwipeCookbook = cookbook.title === store.cookbookSwipe;

                return {
                    ...cookbook,
                    isNormalCookbook: !isLikeCookbook && !isSwipeCookbook && !cookbook.menu,
                    isLikeCookbook,
                    isSwipeCookbook,
                    likeTitle: store.translations['trans:likecookbook'],
                    swipeTitle: store.translations['trans:swipecookbook']
                };
            });

            for (var i = 0; i < cookbooksList.length; i++) {
                let cookbook = cookbooksList[i];

                // make sure to exclude menu cookbooks and like cookbook
                if (cookbook && !cookbook.menu && cookbook.title !== store.cookbookSwipe) {
                    uid++;
                    cookbook['uid'] = uid;
                    containers.container.innerHTML += hogan.compile(templates.item).render(cookbook);
                }
            }
        },

        changeHandler = function (e) {
            instance.publish('changed', [e.target.parentNode.getAttribute('data-cookbook-id')]);
        },

        bindChange = function () {
            containers.container.addEventListener('change', changeHandler);
        },

        unbindChange = function () {
            containers.container.removeEventListener('change', changeHandler);
        };

    instance.getSelected = function () {
        var elements = toArray(containers.container.querySelectorAll('input:checked'));

        return elements.map(function (element) {
            return Number(element.parentNode.getAttribute('data-cookbook-id'));
        });
    };

    /**
     * Set checkboxes with given ids
     *
     * @param {Array} ids - List of cookbook ids
     */
    instance.setActives = function(ids) {
        var elements = containers.container.querySelectorAll('[data-cookbook-id]');

        forEach(elements, function (el) {
            const checkbox = el.querySelector('input[type="checkbox"]');
            const cookbookId = Number(el.getAttribute('data-cookbook-id'));
            checkbox.checked = ids.indexOf(cookbookId) > -1;
        });
    };

    instance.updateCookbooks = function (selectedIds, newlyAddedCookbook) {
        cookbooksList = cookbooks.getAll();
        insertCookbooks();

        if (selectedIds) {
            instance.setActives(selectedIds);
        }

        if (newlyAddedCookbook) {
            var addedNode = containers.container.querySelector('[data-cookbook-id="' + newlyAddedCookbook['cookbook_id'] + '"]'),
                callback;

            // self removing event listener
            addedNode.addEventListener(transitionEnd(), callback = function() {

                this.removeEventListener(transitionEnd(), callback);
                this.classList.remove('cookbook-select__item--new');
            });

            addedNode.classList.add('cookbook-select__item--new');
        }
    };


    /**
     * Initializes the factory
     *
     * @return {object} instance
     */
    instance.init = function() {
        containers.container = container.querySelector(settings.container);

        instance.updateCookbooks();

        bindChange();

        // Extends the instance with the functions subscribe(), unsubscribe() & publish()
        observer(instance);

        return instance;
    };

    /**
     * Should revert to initial state
     *
     * @return {object} instance
     */
    instance.destroy = function() {
        unbindChange();
        instance.unsubscribeAll();
        return instance;
    };

    return instance.init();
};
