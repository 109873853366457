

// vars
var instance = {},
    defaults = {
        actClass: 'is-active',
        swiperOptions: {
            direction: 'horizontal',
            loop: false,
            slidesPerView: 0,
            spaceBetween: 0,
            nextButton: '.teaser-service__swiperbtn.next[data-id="<%id%>"]',
            prevButton: '.teaser-service__swiperbtn.prev[data-id="<%id%>"]',
            breakpoints: {

                // when window width is <= 320px
                768: {
                    slidesPerView: 1,
                    spaceBetween: 10
                }
            }
        }
    },
    settings,
    swipers;

instance.init = async (options) => {
    settings = Object.assign({}, defaults, options);
    swipers = document.querySelectorAll('.teaser-service--swiper');

    if (swipers.length) {
        var additionalBp = {
            slidesPerView: 3,
            spaceBetween: 32
        };

        const { default: Swiper } = await import(/* webpackChunkName: "swiper" */'swiper');

        for (var i = 0; i < swipers.length; i++) {

            // add id to control btns
            var id = (i + 1).toString(),
                controls = swipers[i].querySelectorAll('.teaser-service__swiperbtn'),
                startAmount = parseInt(swipers[i].getAttribute('data-amount')),
                startspace = parseInt(swipers[i].getAttribute('data-space')),
                swiperOptions = Object.assign({}, settings.swiperOptions);

            for (var j = 0; j < controls.length; j++) {
                controls[j].setAttribute('data-id', id);
            }

            // update swiper options
            swiperOptions.slidesPerView = startAmount;
            swiperOptions.spaceBetween = startspace;
            swiperOptions.nextButton = swiperOptions.nextButton.replace('<%id%>', id);
            swiperOptions.prevButton = swiperOptions.prevButton.replace('<%id%>', id);

            // add additional breakpoint on startAmount 4
            if (startAmount === 4) {
                swiperOptions.breakpoints[1023] = additionalBp;
            }

            // create swiper. no need to store created swiper instance.
            new Swiper(swipers[i].querySelector('.swiper-container'), swiperOptions);
        }
    }

};

export default instance;
