
const createSearchFactory = ({ el, state }, Pubsub, SearchController) => {

    state.init = () => {
        state.searchController = SearchController(el);
        Pubsub.publish('teasers.initialized', [[el]]);
        Pubsub.publish('searchFactory.teasers.initialized', [[el]]);
    };

    state.destroy = () => {
        // TODO
    };

    state.init();

    return state;
};

export const config = {
    name: 'search-factory',
    selector: '[data-search-type="full-featured"]',
    constructor: createSearchFactory,
    dependencies: ['Pubsub', 'SearchController'],
    options: {}
};
