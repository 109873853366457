

var forEach = require('@/helpers/helper').default.forEach,
    closest = require('@/helpers/helper').default.closest;

// vars
var instance = {},
    defaults = {
        sectionClass: 'accordion__section',
        triggerClass: 'accordion__trigger',
        contentClass: 'accordion__content',
        triggerActiveClass: 'accordion__trigger--active',
        contentOpenClass: 'accordion__content--open'
    },

    selectors = {
        section: 'data-ni-accordion-section',
        trigger: 'data-ni-accordion-trigger',
        content: 'data-ni-accordion-content'
    };


/**
 * Creates an Accordion instance
 *
 * @param   {object}        container - accordion wrapper
 * @param   {object}        options - overwrites default settings (optional)
 * @return  {object}        returns instance
 * */
instance.create = function (container, options) {
    var settings = Object.assign({}, defaults, options),
        isInitialized = false,
        delegateHandler,
        accordion = {};

    // private functions
    var toggleSection = function (trigger) {
            var triggerId = trigger.getAttribute(selectors.trigger),

                // get corresponding content
                content = accordion.container.querySelector('[' + selectors.content + '="' + triggerId + '"]');

            if (trigger.classList.contains(settings.triggerActiveClass) || content.classList.contains(settings.contentOpenClass)) {

                // close section
                trigger.classList.remove(settings.triggerActiveClass);
                content.classList.remove(settings.contentOpenClass);
            } else {

                // open section
                trigger.classList.add(settings.triggerActiveClass);
                content.classList.add(settings.contentOpenClass);
            }
        },

        bindEvents = function () {
            delegateHandler = function (e) {

                // necessary because trigger can contain icons or other elements.
                var el = closest(e.target, '[' + selectors.trigger + ']');

                if (el) {
                    toggleSection(el);
                    e.preventDefault();
                }

            };

            container.addEventListener('click', delegateHandler);
        },

        applyStyleClasses = function () {
            var elements = accordion.container.querySelectorAll('[' + selectors.section + '],[' + selectors.content + '],[' + selectors.trigger + ']');

            forEach(elements, function (el) {
                if (el.hasAttribute(selectors.section) && settings.sectionClass !== '') {
                    el.classList.add(settings.sectionClass);
                    return;
                }

                if (el.hasAttribute(selectors.content) && settings.contentClass !== '') {
                    el.classList.add(settings.contentClass);
                }

                if (el.hasAttribute(selectors.trigger) && settings.triggerClass !== '') {
                    el.classList.add(settings.triggerClass);
                }
            });
        },

        removeStyleClasses = function () {
            var elements = accordion.container.querySelectorAll('[' + selectors.section + '],[' + selectors.content + '],[' + selectors.trigger + ']');

            forEach(elements, function (el) {
                if(settings.sectionClass !== '')
                    el.classList.remove(settings.sectionClass);

                if(settings.triggerClass !== '')
                    el.classList.remove(settings.triggerClass);

                if(settings.contentClass !== '')
                    el.classList.remove(settings.contentClass);
                el.classList.remove(settings.triggerActiveClass);
                el.classList.remove(settings.contentOpenClass);
            });
        };

    // make container element public
    accordion.container = container;

    /**
     * @returns {boolean}   Returns true if instance has already been initialized
     */
    accordion.initialized = function () {
        return isInitialized;
    };


    /**
     * Initializes the factory
     *
     * @return  {object}        returns instance of factory
     * */
    accordion.init = function() {
        if(!isInitialized) {
            bindEvents();
            applyStyleClasses();
            isInitialized = true;
        }

        return accordion;
    };


    /**
     * Should revert to initial state
     * */
    accordion.destroy = function() {
        removeStyleClasses();
        container.removeEventListener('click', delegateHandler);

        isInitialized = false;
    };

    return accordion;
};

export default instance;
