import fastdom from 'fastdom';

// vars
const instance = {};
const hooks = [];

let lastScrollTop = 0;
let scrollDirection = 'down'; // initially we assume that the User is at the top of the page

/**
 * Gets called when scrolled
 * Fires callbacks of the current hooks if requirements met
 */
const scroll = function() {
    fastdom.measure(() => {
        const st = window.pageYOffset || document.documentElement.scrollTop;
        let directionChange = false;

        if (st > lastScrollTop && scrollDirection !== 'down') {
            directionChange = true;
            scrollDirection = 'down';
        } else if(st < lastScrollTop && scrollDirection !== 'up') {
            directionChange = true;
            scrollDirection = 'up';
        }

        const scrollObject = {
            scrollDirection: scrollDirection,
            scrollTop: st
        };

        hooks.forEach(function(hook) {
            if (hook.event === 'directionchange' && directionChange === true) {
                hook.callback.call(null, scrollObject);
            } else if (hook.event === 'pointcrossed' && ((hook.point > st && hook.point <= lastScrollTop) || (hook.point <= st && hook.point > lastScrollTop))) {
                hook.callback.call(null, scrollObject);
            } else if (hook.event === 'scroll') {
                hook.callback.call(null, scrollObject);
            }
        });

        lastScrollTop = st;
    });
};

/**
 * Simply return the current scrollposition
 *
 * @return {integer} lastScrollTop
 */
instance.getPosition = function() {
    return lastScrollTop;
};

/**
 * Adds a hook to the listener
 *
 * @param {integer} hookIndex to be removed (was given as return value of addHook)
 * @return {boolean} returns true if successfully removed
 */
instance.removeHook = function(hookIndex) {
    return delete hooks[hookIndex];
};

/**
 * Adds a hook to the listener
 *
 * @param {object} hook to be looked through
 * @return {integer} hookIndex is the id used to remove a hook
 */
instance.addHook = function(hook) {
    // TODO: insert some type of validation
    const hookIndex = hooks.push(hook) - 1;
    return hookIndex;
};

/**
 * Initiates the component
 *
 * @return {object} instance
 */
instance.init = function () {
    fastdom.measure(() => {
        lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    });

    window.addEventListener('scroll', scroll);
    return instance;
};

export default instance;
