import validate from 'validate.js';
import observer from '@/libs/ni-observer';

const selectorRequired = '[data-required]';
const selectorEmail = '[data-email]';
const selectorDate = '[data-date]';

const validateForm = function(form, { constraints = {}, messages = {} }) {
    const instance = {};

    /* Private methods */

    /**
     * Check all inner form-fields of container for errors
     * @param {HTMLElement} container - Wrapping container of the input fields to be checked
     * @return {Object} errors
     */
    const validateInner = (container) => {
        const errors = validate(container, constraints);
        showErrors(container, errors || {});
        return errors;
    };

    const showErrors = (form, errors) => {
        form.querySelectorAll('[name]').forEach(element => {
            instance.showErrorsForInput(element, errors && errors[element.name]);
        });
    };

    const resetFormGroup = (formGroup) => {
        formGroup.classList.remove('has-error');
        formGroup.classList.remove('has-success');

        formGroup.querySelectorAll('.form-element__msgbar > *').forEach(element => {
            element.parentNode.removeChild(element);
        });
    };

    const addError = (messages, error) => {
        const block = document.createElement('p');
        block.classList.add('help-block');
        block.classList.add('error');
        block.innerText = error;
        messages.appendChild(block);
    };

    const submitHandler = (event) => {
        event.preventDefault();
        const errors = validateInner(form);

        if (errors) {
            instance.publish('invalid', [errors]);
        } else {
            instance.publish('valid');
        }
    };

    const blurHandler = (event) => {
        validateInner(event.currentTarget.parentNode.parentNode);
    };

    const changeHandler = (event) => {
        validateInner(event.currentTarget.parentNode.parentNode);
    };

    const initDefaultConstraints = () => {
        // add constraints for required fields
        form.querySelectorAll(selectorRequired).forEach(field => {
            const name = field.getAttribute('name');

            if (!constraints[name]) {
                constraints[name] = {};
            }

            constraints[name].presence = {
                message: '^' + messages.presence
            };
        });

        // add constraints for emails fields
        form.querySelectorAll(selectorEmail).forEach(field => {
            const name = field.getAttribute('name');

            if (!constraints[name]) {
                constraints[name] = {};
            }

            constraints[name].email = {
                message: '^' + messages.email
            };

            constraints[name].length = {
                maximum: 254,
                message: '^' + messages.maxlength
            };
        });

        // add constraints for date fields
        form.querySelectorAll(selectorDate).forEach(field => {
            const name = field.getAttribute('name');

            if (!constraints[name]) {
                constraints[name] = {};
            }

            constraints[name].datetime = {
                dateOnly: true,
                message: '^' + messages.date
            };
        });
    };

    const init = () => {
        // make instance observable
        observer(instance);

        initDefaultConstraints();

        form.addEventListener('submit', submitHandler);

        form.querySelectorAll('input[name]').forEach(field => {
            field.addEventListener('blur', blurHandler);
        });

        form.querySelectorAll('input[type="radio"]').forEach(field => {
            field.addEventListener('change', changeHandler);
        });

        form.querySelectorAll('input[type="checkbox"]').forEach(field => {
            field.addEventListener('change', changeHandler);
        });
    };

    /* Public methods */

    instance.reset = () => {
        const formGroups = form.querySelectorAll('.form-element');
        formGroups.forEach(formGroup => resetFormGroup(formGroup));
    };

    instance.showErrorsForInput = (input, errors) => {
        const formGroup = input.closest('.form-element');

        if (!formGroup) {
            return;
        }

        const messages = formGroup.querySelector('.form-element__msgbar');
        resetFormGroup(formGroup);

        if (errors) {
            formGroup.classList.add('has-error');
            addError(messages, errors[0]);
        } else {
            formGroup.classList.add('has-success');
        }
    };

    instance.destroy = () => {
        instance.unsubscribeAll();

        form.removeEventListener('submit', submitHandler);

        form.querySelectorAll('input[name]').forEach(field => {
            field.removeEventListener('blur', blurHandler);
        });

        form.querySelectorAll('input[type="radio"]').forEach(field => {
            field.removeEventListener('change', changeHandler);
        });

        form.querySelectorAll('input[type="checkbox"]').forEach(field => {
            field.removeEventListener('change', changeHandler);
        });
    };

    init();

    return instance;
};

export default validateForm;
