const MAX_LINE_WIDTH_DESKTOP = 400;

const homeTitle = ({ el, state }, Helper, TitleLines) => {

    const resizeHandler = () => {
        state.titleLinesInstance.renderLines();
    };

    const resizeHandlerDebounced = Helper.debounce(resizeHandler, 100);

    state.init = () => {
        state.titleLinesInstance = TitleLines(el, {
            // max width that each line should have (recalculated during resize)
            maxWidth: () => {
                // TODO: use 100% of window on mobile and tablet
                return MAX_LINE_WIDTH_DESKTOP;
            },

            // line configuration
            lines: [
                { minFontSize: 75, wordsCount: 1 },
                { minFontSize: 45, wordsCount: null }
            ],

            // chars that should be used to split the recipe title into more single words
            wordMatch: /[^-\s]+-?\s?/giu
        });

        window.addEventListener('optimizedResize', resizeHandlerDebounced);
        resizeHandler();
    };

    state.destroy = () => {
        window.removeEventListener('optimizedResize', resizeHandlerDebounced);
    };

    state.init();

    return state;
};

export const config = {
    name: 'home-title',
    constructor: homeTitle,
    dependencies: ['Helper', 'TitleLines'],
    options: {
    }
};
