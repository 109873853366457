
import commonSearchTerms from '@/apps/main/plugins/common-search-terms';
import searchbarController from '@/apps/search/modules/searchbar-controller';

const createSearchHome = ({ el, state }, Breakpoint) => {

    const controllers = {};

    const checkCommonSearch = (currentBreakpoint) => {
        if (currentBreakpoint !== 'small') {
            controllers.commonSearchTerms.loadTags();
        }
    };

    const initControllers = () => {
        controllers.searchbarController = searchbarController(el, {
            autocomplete: {
                init: true,
                openthreshhold: 2,
                limit: 5,
                api: store.apis.autocomplete,
                method: 'GET'
            }
        });

        controllers.commonSearchTerms = commonSearchTerms.call(el);
    };

    state.init = () => {
        state.refs = {
            form: el.getElementsByTagName('form')[0]
        };

        initControllers();

        controllers.commonSearchTerms.subscribe('click', function () {
            // Reads Term out of button clicked
            var term = this.getElementsByTagName('span')[0].innerHTML;

            controllers.searchbarController.searchbar.searchfield.value = term;
            state.refs.form.submit();
        });

        Breakpoint.initialized.then(() => {
            checkCommonSearch(Breakpoint.curBreakpoint);

            el.addEventListener('searchbar.focus', function () {
                controllers.commonSearchTerms.loadTags();
            });

            window.addEventListener('breakpointChange', function (event) {
                checkCommonSearch(event.detail.new);
            });
        });

        el.addEventListener('searchbar.submit', function () {
            state.refs.form.submit();
        });
    };

    state.destroy = () => {
        // TODO
    };

    state.init();

    return state;
};

export const config = {
    name: 'search-home',
    selector: '.main-search--overlay-equivalent:not(.main-search--in-overlay)',
    constructor: createSearchHome,
    dependencies: ['Breakpoint'],
    options: {}
};
