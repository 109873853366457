

/**
 * Simple script that adds the pub/sub system directly onto a given instance
 *
 * @author Christian Sany
 *
 * @param {Object} [instance = {}] to add the pub/sub to
 * @return {Object} instance
 */
export default function(instance = {}) {
    var hooks = {},
        uid = -1;

    /**
     * Adds a hook
     *
     * @param {String} hook identifyer
     * @param {Function} callback to add
     * @param {Boolean} [once = false] - If true, subscription will be removed after
     * @return {Integer} hookindex. unique id.
     */
    instance.subscribe = function(hook, callback, once = false) {
        if (!hooks[hook]) {
            hooks[hook] = {queue: []};
        }

        // Add the callback to queue
        uid++;
        hooks[hook].queue.push({
            uid,
            callback,
            once
        });

        return uid;
    };

    /**
     * Removes a hook
     *
     * @param {integer} hookindex of the hook to be removed
     * @return {object} instance
     */
    instance.unsubscribe = function(hookindex) {

        for (var h in hooks) {
            if (hooks[h]) {
                for (var i = 0; i < hooks[h].queue.length; i++) {
                    if (typeof hooks[h].queue[i] === 'object' && hooks[h].queue[i] && hooks[h].queue[i].hasOwnProperty('uid') && hooks[h].queue[i].uid === hookindex) {
                        hooks[h].queue[i] = null;
                        return hookindex;
                    }
                }
            }
        }

        return instance;
    };

    /**
     * Removes all hooks
     *
     * @return {Array} List of hook names which have been removed.
     */
    instance.unsubscribeAll = function () {
        var hookNames = Object.keys(hooks);

        // clear hooks
        hooks = {};

        return hookNames;
    };

    /**
     * Fires all callbacks from given hook
     *
     * @param {string} hook identifier
     * @param {array} args to be given the callback function
     * @param {any} scope for the callback. Default scope ist the given instance.
     */
    instance.publish = function(hook, args, scope) {
        scope = scope || instance;

        // If the topic doesn't exist, or there's no listeners in queue, just leave
        if (!hooks[hook] || !hooks[hook].queue.length) {
            return;
        }

        // Cycle through topics queue, fire!
        var items = hooks[hook].queue;
        items.forEach(function(item) {
            if (item) {
                item.callback.apply(scope, args || []);
                if (item.once) {
                    instance.unsubscribe(item.uid);
                }
            }
        });
    };

    return instance;
};
