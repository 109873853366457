import Tooltip from '@/libs/tooltip';

const createDropdown = ({ el, store }, Vue) => new Vue({
    el,
    data: {
        store,
        tooltip: null
    },
    watch: {
        'store.filter': function(newFilter) {
            this.$refs.label.textContent = this.$refs[`value-${newFilter}`].textContent;
        }
    },
    mounted() {
        this.tooltip = new Tooltip(this.$refs.button, {
            stay: true,
            placement: this.$refs.button.getAttribute('data-tooltip-placement')
        });
    },
    beforeDestroy() {
        this.tooltip.destroy();
    },
    methods: {
        onClick(newFilter) {
            this.store.filter = newFilter;
        }
    }
});

export default createDropdown;
