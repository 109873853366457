

var globals = require('@/scaffold/globals').default,
    api = require('@/libs/api-factory').default,
    pubsub = require('@/scaffold/pubsub').default,
    observer = require('@/libs/ni-observer').default;

/**
 * Creates a ExternalRecipe service.
 *
 * This model depends on Cookbooks.
 * This means data will be set in User model, which is of course unusal.
 *
 * @return {object} ExternalRecipe service
 */
export default function () {
    var service = {},
        apiExternalRecipeCreate,
        apiExternalRecipeUpdate,
        apiExternalRecipeDelete,

        createSuccessHandler = function (response, context) {
            var createdExternalRecipe = {
                'target_url': context.tmpTargetUrl,
                'image_url': context.tmpImageUrl,
                'title': context.tmpTitle,
                'cookbook_ids': context.tmpCookbookIds.split(',')
            };

            // pass created external recipe to subscribers.
            service.publish('created', [createdExternalRecipe]);
            pubsub.publish('externalRecipe.created', [{ recipe: createdExternalRecipe }]);

            globals.bookmarks.addBookmark(context.tmpTargetUrl, null, null, context.tmpCookbookIds, 'extern', null);
        },

        updateSuccessHandler = function (response, context) {
            var updatedExternalRecipe = {
                'target_url': context.tmpTargetUrl,
                'title': context.tmpTitle,
                'cookbook_ids': context.tmpCookbookIds.split(',')
            };

            // pass updated external recipe to subscribers.
            service.publish('updated', [updatedExternalRecipe]);
            pubsub.publish('externalRecipe.updated', [{ recipe: updatedExternalRecipe }]);

            globals.bookmarks.changeBookmark(context.tmpTargetUrl, null, null, context.tmpCookbookIds, 'extern', null);
        },

        deleteSuccessHandler = function (response, context) {
            var deletedExternalRecipe = {
                'target_url': context.tmpTargetUrl
            };

            // pass created bookmarks to subscribers.
            service.publish('deleted', [deletedExternalRecipe]);
            pubsub.publish('externalRecipe.edited', [{ recipe: deletedExternalRecipe }]);

            globals.bookmarks.removeBookmark(context.tmpTargetUrl, null, null);
        };

    // define APIs
    apiExternalRecipeCreate = api(store.apis.externalRecipe, {
        method: 'POST',
        params: {
            'target_url': '',       // will be set dynamically
            'image_url': '',        // will be set dynamically
            'title': '',            // will be set dynamically
            'cookbook_ids': '',     // will be set dynamically
            '_charset_': 'UTF-8'
        },
        success: createSuccessHandler
    });

    apiExternalRecipeUpdate = api(store.apis.externalRecipe, {
        method: 'PUT',
        params: {
            'target_url': '',       // will be set dynamically
            'title': '',            // will be set dynamically
            'cookbook_ids': '',     // will be set dynamically
            '_charset_': 'UTF-8'
        },
        success: updateSuccessHandler
    });

    apiExternalRecipeDelete = api(store.apis.externalRecipe, {
        method: 'DELETE',
        params: {
            'target_url': ''         // will be set dynamically
        },
        success: deleteSuccessHandler
    });

    // extend model
    observer(service);

    // public methods

    /**
     * Creates a new an external recipe
     *
     * Response is ignored in success handler.
     * Event 'created' will be fired.
     *
     * @param {string} targetUrl - Url of external recipe
     * @param {string} imageUrl - Url of selected image
     * @param {string} title - Title for external recipe
     * @param {string} cookbookIds - Comma-separated string with ids of cookbooks !Important: define with backend
     */
    service.createExternalRecipe = function (targetUrl, imageUrl, title, cookbookIds) {
        apiExternalRecipeCreate.send({
            params: {
                'target_url': targetUrl,
                'image_url': imageUrl,
                'title': title,
                'cookbook_ids': cookbookIds,
                '_charset_': 'UTF-8'
            },
            context: {
                tmpTargetUrl: targetUrl,
                tmpImageUrl: imageUrl,
                tmpTitle: title,
                tmpCookbookIds: cookbookIds
            }
        });
    };

    /**
     * Updates an external recipe
     *
     * Response is ignored in success handler.
     * Event 'updated' will be fired.
     *
     * @param {string} targetUrl - Url of external recipe
     * @param {string} title - Title for external recipe
     * @param {string} cookbookIds - Comma-separated string with ids of cookbooks !Important: define with backend
     */
    service.updateExternalRecipe = function (targetUrl, title, cookbookIds) {
        apiExternalRecipeUpdate.send({
            params: {
                'target_url': targetUrl,
                'title': title,
                'cookbook_ids': cookbookIds,
                '_charset_': 'UTF-8'
            },
            context: {
                tmpTargetUrl: targetUrl,
                tmpTitle: title,
                tmpCookbookIds: cookbookIds
            }
        });
    };

    /**
     * Deletes an external recipe
     *
     * Response is ignored in success handler.
     * Event 'deleted' will be fired.
     *
     * @param {string} targetUrl - Url of recipe which should be deleted.
     */
    service.deleteExternalRecipe = function (targetUrl) {
        apiExternalRecipeDelete.send({
            params: {
                'target_url': targetUrl
            },
            context: {
                tmpTargetUrl: targetUrl
            }
        });
    };

    return service;
};
