

var g = require('@/scaffold/globals').default,
    customEvent = require('@/helpers/helper').default.customEvent,
    parseQuery = require('@/helpers/helper').default.parseQuery,
    breakpoint = require('@/libs/breakpoint').default,
    render = require('@/libs/render').default,
    pubsub = require('@/scaffold/pubsub').default,
    api = require('@/libs/api-factory').default,

    modules = {
        button: require('@/apps/search/modules/button').default,
        view: require('@/apps/main/plugins/view-controller').default
    };

var counter = 0;

/**
 * Teaser Group automated
 *
 * @param {element} container where to initiate the teaser group automated
 */
var automated = function(container) {
    var instance = {},
        id = ++counter,
        results,
        url,
        searchParams,
        teasers = [],
        foobys = false,
        swiperInitiated = false,
        endReached = false,
        swiper,
        defaultNum = 4,
        currentStart = 0,
        swiperContainers = {},
        apiAutomated,
        blacklist,

        /**
         * Shows the next 4 (or less) teasers
         *
         * @param {start} start item start index
         * @param {boolean} clean to tell if the container should be cleaned first or not
         * @param {boolean} init whether it's the initial call
         */
        showNext = function(start = 0, clean = false, init = false) {
            if (endReached) {
                return;
            }

            let num = defaultNum;

            if (init) {
                currentStart = start;
                num = start + num;
            } else {
                currentStart += num;
            }

            var params = {};
            params[`startAuto${id}`] = currentStart;

            if (!swiperInitiated && currentStart !== 0 && g.isRecipe === false) {
                g.history.replaceParams(params);
            }

            instance.view.updateView('teaser', getX(num), clean, clean, function() {
                // End is reached
                if (teasers.length < 1) {
                    endReached = true;
                    instance.button.hideButton();
                } else {
                    instance.button.enableButton();
                }
            }, function() {
                if (swiperInitiated) {
                    swiperSlideClasses();
                    swiper.update();
                }
            });
        },

        /**
         * Reads the next X (or less) teasers from the temporary teasers element
         *
         * @param {integer} num number of teasers to read
         * @return {string} html
         */
        getX = function(num) {
            var temp = document.createElement('div'),
                items = (teasers.length < num) ? teasers.length : num;

            for (var i = 0; i < items; i++) {
                temp.innerHTML += teasers[0];
                teasers.shift();
            }

            return temp.innerHTML;
        },

        /**
         * Checks, if teasers should be loaded if the swiper is near to the right end
         *
         * @param {object} swiper to check
         */
        swiperCheckIfTeasersNeeded = function(swiper) {
            if((swiper.slides.length - swiper.activeIndex) < 5) {
                showNext();
            }
        },

        /**
         * Adding swiper classes to the single slides of the swiper
         */
        swiperSlideClasses = function() {
            var slides = swiperContainers.inner.children;

            for(var slideIndex in slides) {
                var slide = slides[slideIndex];
                if(slide instanceof HTMLElement) {
                    slide.classList.add('swiper-slide');
                }
            }
        },

        /**
         * Handles if the swiper should be initiated or destroyed, depending on current state & window breakpoint
         */
        swiperHandler = function() {
            if((breakpoint.curBreakpoint === 'small' || breakpoint.curBreakpoint === 'medium') && swiperInitiated === false) {
                swiperInit();
            } else if ((breakpoint.curBreakpoint !== 'small' && breakpoint.curBreakpoint !== 'medium') && swiperInitiated === true) {
                swiperDestroy();
            }
        },

        /**
         * Initiates the swiper
         */
        swiperInit = async function() {
            const { default: Swiper } = await import(/* webpackChunkName: "swiper" */'swiper');

            swiperInitiated = true;

            swiperContainers.container.style.overflow = 'hidden';
            swiperContainers.wrapper.classList.add('swiper-container');
            swiperContainers.inner.classList.add('swiper-wrapper');

            swiperSlideClasses();

            let initialSlide = 0;
            let currentParams = g.history.getParams();

            if (currentParams[`startAutoSlide${id}`]) {
                initialSlide = parseInt(currentParams[`startAutoSlide${id}`]);
            }

            swiper = new Swiper('[data-feed-url="' + url + '"] [data-feed-wrapper]', {
                spaceBetween: 30,
                slidesOffsetAfter: 30,
                slidesOffsetBefore: 0,
                slidesPerView: 'auto',
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev',
                // Responsive breakpoints
                breakpoints: {
                    // when window width is <= 767px
                    767: {
                        spaceBetween: 12
                    }
                }
            }).on('slideChangeStart', function(swiper) {
                var params = {};
                params[`startAutoSlide${id}`] = swiper.activeIndex;
                g.history.replaceParams(params);

                if(swiper.activeIndex > 0) {
                    swiperContainers.wrapper.classList.add('swiper-container--centered');
                } else {
                    swiperContainers.wrapper.classList.remove('swiper-container--centered');
                }
                swiperCheckIfTeasersNeeded(swiper);
            });

            // initial check if more teasers should be displayed
            setTimeout(function() {
                swiper.slideTo(initialSlide);

                // if this doesn't have delay, it gets called too fast???
                swiperCheckIfTeasersNeeded(swiper);
            }, 700);

            swiperContainers.inner.dispatchEvent(customEvent('ni-equalizer.update', {}));
        },

        /**
         * Destroys the swiper
         */
        swiperDestroy = function() {
            swiperInitiated = false;

            // forEach(automatedFeeds, function(feed, index) {
            swiper.destroy(true, true);

            swiperContainers.container.style.overflow = '';
            swiperContainers.wrapper.classList.remove('swiper-container');
            swiperContainers.inner.classList.remove('swiper-wrapper');

            var slides = swiperContainers.inner.children;

            for(var slideIndex in slides) {
                var slide = slides[slideIndex];
                if(slide instanceof HTMLElement) {
                    slide.classList.remove('swiper-slide');
                }
            }

            swiperContainers.inner.dispatchEvent(customEvent('ni-equalizer.update', {}));
        },

        hideEntireContainer = function () {
            container.parentNode.removeChild(container);
        },

        /**
         * Initiates the whole automated feed after teasers are fetched from the Servlet
         */
        init = function() {
            if(blacklist.length > 0) {

                // Filtering out blacklisted recipes
                results = results.filter(function(el) {
                    if (el.hasOwnProperty('recipe_id')) {
                        return blacklist.indexOf(el.recipe_id.toString()) === -1;
                    } else {
                        return true;
                    }
                });
            }

            teasers = results.reduce(function (acc, result) {
                acc.push(render.teaser([result], {
                    initialwith: 3,
                    xs: 12,
                    sm: 4,
                    md: 3
                }, {
                    xs: 12,
                    sm: 4,
                    md: 3,
                    hgutter: true
                }, foobys));
                return acc;
            }, []);

            instance.getCurrentSearchParams = function() {
                return searchParams;
            };

            // creating instances of the needed factories
            instance.button = modules.button(container);
            instance.button.subscribe('click', function() {
                instance.button.disableButton();
                showNext();
            });

            instance.view = modules.view.call(container, {
                activeType: 'teaser',
                containers: [
                    {
                        type: 'teaser',
                        wrapper: '[data-feed-inner]',
                        content: '[data-feed-inner]',
                        onAfterAdd: function() {
                            var view = this;
                            setTimeout(function() {
                                view.content.dispatchEvent(customEvent('ni-equalizer.update', {}));
                                pubsub.publish('tooltip.init');
                                pubsub.publish('likes.bindTrigger');
                                pubsub.publish('likes.loadLikes');
                                pubsub.publish('likes.setActives');

                                pubsub.publish('teasers.added');
                                pubsub.publish('teaserGroupAutomated.teasers.added');

                                // add delay, because dom can take a little time to append the teasers
                                setTimeout(function () {
                                    pubsub.publish('teasers.updateStates', [true]);
                                }, 200);
                            }, 50);
                        }
                    }
                ]
            });

            swiperContainers.container = container.querySelector('.container');
            swiperContainers.wrapper = container.querySelector('[data-feed-wrapper]');
            swiperContainers.inner = container.querySelector('[data-feed-inner]');

            container.addEventListener('click', function(e) {
                const wrapper = e.target.closest('[data-feed-wrapper]');

                if (wrapper) {
                    const teasers = Array.from(wrapper.querySelectorAll('.teaser'));
                    const teaser = e.target.closest('.teaser');
                    const teaserLink = teaser.querySelector('.teaser__click-area');
                    const teaserLinkUrl = teaserLink.getAttribute('href').split('#')[0].split('?')[0];
                    pubsub.publish('teaserGroup.clicked', [instance, teasers.indexOf(teaser), teaserLinkUrl]);
                }
            });

            let start = 0;
            let currentParams = g.history.getParams();

            if (currentParams[`startAuto${id}`]) {
                start = parseInt(currentParams[`startAuto${id}`]);
            }

            showNext(start, true, true);

            breakpoint.initialized.then(() => {
                window.addEventListener('optimizedResize', swiperHandler);
                swiperHandler();
            });
        };

    // Teasers on fooby detailpages get rendered differently
    if (container.hasAttribute('data-feed-foobys')) {
        foobys = true;
    }

    url = container.getAttribute('data-feed-url') || '';
    blacklist = (container.hasAttribute('data-feed-blacklist')) ? container.getAttribute('data-feed-blacklist').split(',') : [];

    // create api
    apiAutomated = api(url, {
        success: function (response) {

            response = JSON.parse(response);

            // don't init teasers if no result is available
            if (response.hasOwnProperty('results') && response.results.length) {
                searchParams = parseQuery(url.split('?')[1]);
                results = response.results;
                init();
            } else {
                hideEntireContainer();
            }
        },

        error: function () {
            hideEntireContainer();
        }
    });

    // call api
    apiAutomated.send();
};

export default function() {
    var containers = document.querySelectorAll('[data-feed-automated]');

    if (containers.length) {
        containers.forEach(automated);

        pubsub.publish('teasers.initialized', [containers]);
        pubsub.publish('teaserGroupAutomated.teasers.initialized', [containers]);
    }
};
