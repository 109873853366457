const qs = require('qs');
const easings = require('@/libs/easings').default;

/* public functions*/

var instance = {};

instance.abbreviateText = function(text, length) {
    if (text.length <= length) {
        return text;
    }

    var lastWhitespaceIndex = text.substring(0, length - 1).lastIndexOf(' ');

    if (lastWhitespaceIndex) {
        return text.substring(0, lastWhitespaceIndex) + '\u2026';
    } else {
        return text.substring(0, length - 1);
    }
};

// returns content of <meta name="" content=""> tags or '' if empty/non existant
instance.getMeta = function(name) {
    const meta = document.querySelector(`meta[name="${name}"],[property="${name}"]`);
    return meta ? meta.getAttribute('content') : '';
};

/**
 * Animates number values using requestAnimationFrame
 * Code from: https://github.com/adrianklimek/smoothscroll
 * @return {boolean} False if no onUpdate function is defined, otherwise true
 */
instance.animate = function({ start, end, duration, easing, onUpdate, onComplete }) {
    if (typeof onUpdate !== 'function') return false;

    const easingFunc = typeof easing !== 'function'
        ? easings[easing] || easings.easeInOutQuad
        : easing;

    let initialTime = null;

    const animationFrame = timestamp => {
        initialTime = initialTime || timestamp;
        const elapsedTime = timestamp - initialTime;
        const animationProgress = Math.min(elapsedTime / duration, 1);
        const currentValue = start + (end - start) * easingFunc(animationProgress);

        onUpdate(currentValue, animationProgress);

        if (elapsedTime <= duration) window.requestAnimationFrame(animationFrame);
        else if (typeof onComplete === 'function') onComplete(currentValue, animationProgress);
    };

    window.requestAnimationFrame(animationFrame);

    return true;
};

/**
 * Check if given item is object (excludes array and null)
 *
 * @param {*} item to check
 * @return {boolean} Whether given item is an object
 */
instance.isObject = function(item) {
    return (typeof item === 'object' && !Array.isArray(item) && item !== null);
};

/**
 * calculates the combined height ob elements given
 *
 * @param {NodeList} elements to read the combined height from
 * @param {boolean} withMargins also counts margins on top and bottom to height
 * @return {integer} combinedHeight
 */
instance.getCombinedheight = function(elements, withMargins) {

    withMargins = withMargins || false;

    var combinedHeight = 0,
        elementHeight = 0,
        elementMargin = 0;

    instance.forEach(elements, function(element) {
        elementHeight = element.offsetHeight;
        if (withMargins) {
            elementMargin = parseInt(document.defaultView.getComputedStyle(element, '').getPropertyValue('margin-top')) + parseInt(document.defaultView.getComputedStyle(element, '').getPropertyValue('margin-bottom'));
        }
        combinedHeight += elementHeight + elementMargin;
    });

    return combinedHeight;
};

/**
 * Get the Coordinates of an element, relative to the document
 *
 * @param {element} elem to get the coords from
 * @return {object} coords
 */
instance.getCoords = function(elem) {
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top  = box.top +  scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
};

/**
 * Appends a String to a given element
 *
 * @param {element} element to apend to
 * @param {string} string to append
 */
instance.appendHtml = function(element, string) {
    var div = document.createElement('div');
    div.innerHTML = string;
    while (div.children.length > 0) {
        element.appendChild(div.children[0]);
    }
};

/**
 * Checks for the existence of touchstart event and add the touch class to the body if found
 *
 * @param {string} name of the custom event
 * @param {object} eventData that you want to pass to the eventListener
 * @return {event} event
 */
instance.customEvent = function(name, eventData) {
    var event;
    if(document.createEvent){
        event = document.createEvent('HTMLEvents');
        event.initEvent(name, true, true);
        event.detail = eventData;
    } else if(document.createEventObject){
        event = new CustomEvent(name, { detail: eventData });
    }
    return event;
};

/**
 * Checks for the existence of touchstart event and add the touch class to the body if found
 */
instance.detectTouch = function() {
    if ('ontouchstart' in window) {
        document.getElementsByTagName('body')[0].classList.add('touch');
    }
};

/**
 * Adds a polyfilled version of transitionend to the window object (transitionEnd)
 */
instance.setTransitionEnd = function() {
    // Function from David Walsh: http://davidwalsh.name/css-animation-callback
    window.transitionEnd = window.transitionEnd || (function() {
        var t,
            el = document.createElement('fakeelement');

        var transitions = {
            'transition'      : 'transitionend',
            'OTransition'     : 'oTransitionEnd',
            'MozTransition'   : 'transitionend',
            'WebkitTransition': 'webkitTransitionEnd'
        };

        for (t in transitions){
            if (el.style[t] !== undefined){
                return transitions[t];
            }
        }
    });
};

/**
 * Adds a polyfilled version of animationend to the window object (animationEnd)
 */
instance.setAnimationEnd = function () {
    window.animationEnd = window.animationEnd || (function () {
        var t,
            el = document.createElement('fakeelement');

        var animations = {
            'animation': 'animationend',
            'OAnimation': 'oAnimationEnd',
            'MozAnimation': 'animationend',
            'WebkitAnimation': 'webkitAnimationEnd'
        };

        for (t in animations) {
            if (el.style[t] !== undefined) {
                return animations[t];
            }
        }
    });
};


/**
 * Checks if a given element has a surtain class
 *
 * @param   {object}    el - Element to be checked
 * @param   {string}    cls - Class to be checked
 * @return  {boolean}   Has class = true, doesn't have class = false
 *
 */
instance.hasClass = function(el, cls) {
    return el.className && new RegExp('(\\s|^)' + cls + '(\\s|$)').test(el.className);
};


/**
 * Helper to loop through an Array-like object.
 * Use only if you need to loop through a NodeList.
 *
 * https://toddmotto.com/ditch-the-array-foreach-call-nodelist-hack/
 *
 * @param   {Array}                     array - Array or NodeList to loop through
 * @param   {function}                  callback - function which will executed for each element in array
 * @param   {Object} [scope=window]     scope - 'this' variable in callback. By default it's the window object.
 *
 */
instance.forEach = function (array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
        callback.call(scope, array[i], i); // passes back stuff we need
    }
};

/**
 * Converts a NodeList to an Array
 *
 * @param {NodeList} list - A NodeList
 * @returns {Array} Converted NodeList
 */
instance.toArray = function (list) {
    var arr = [],
        length = list.length;

    for (var i = 0; i < length; i++) {
        arr.push(list[i]);
    }

    return arr;
};

/**
 * Chunk array.
 *
 * @param {Array} arr - Array to chunk
 * @param {integer} n - Size of chunk
 *
 * @returns {Array} Array with chunks
 */
instance.chunk = function(arr, n) {
    var i, j, temparray = [], chunk = n;

    for (i = 0, j = arr.length; i < j; i += chunk) {
        temparray.push(arr.slice(i, i + chunk));
    }

    return temparray;
};

/**
 * Imitates the closest() function from jQuery.
 *
 * @param   {HTMLElement}   startEl - Element from where climb up
 * @param   {string}        selector - Selector which should match the closest parent
 * @return  {HTMLElement}   DOM element which mathed with destClass
 */
instance.closest = function (startEl, selector) {
    do {
        if (!(startEl.matches && startEl.matches(selector))) {
            continue;
        }

        return startEl;
    } while ((startEl = startEl.parentNode));
};


instance.delegate = function (element, event, selector, listener) {
    var delegateInstance = {},
        delegateListener = function (e) {

            // jQuery also uses delegateTarget
            e.delegateTarget = instance.closest(e.target, selector);

            if (e.delegateTarget) {
                listener.call(element, e);
            }
        };

    element.addEventListener(event, delegateListener);

    delegateInstance.destroy = function () {
        element.removeEventListener(event, delegateListener);
    };

    return delegateInstance;
};

/**
 * Serialize object to query string
 *
 * @param {object} obj to serialize
 * @return {string} str
 */
instance.serialize = function (obj) {
    return qs.stringify(obj, { encode: false });
};

/**
 * Serialitze object to query string (SPECIAL FOR SOLR REQUESTS)
 *
 * @param {object} obj to serialize
 * @return {string} str
 */
instance.solrSerialize = function (obj) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            if (obj[p] instanceof Array) {
                for (var i = 0; i < obj[p].length; i++) {
                    // obj[p][i]
                    str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p][i]));
                }
                // console.log(obj[p]);
            } else {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
            }
        }

    return str.join('&');
};

/**
 * Compares two arrays if they have the same values
 *
 * @param {array} a first array
 * @param {array} b second array
 * @return {boolean} true|false
 */
instance.compareArrays = function(a, b) {
    if (a.length !== b.length)
        return false;
    for (var i = 0; i < b.length; i++) {
        if (a[i].compare) { // To test values in nested arrays
            if (!a[i].compare(b[i]))
                return false;
        } else if (a[i] !== b[i])
            return false;
    }
    return true;
};

// https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-a-array-of-objects
instance.groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

// https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-a-array-of-objects
instance.groupByArray = (xs, key) => {
    return xs.reduce(function (rv, x) {
        const v = key instanceof Function ? key(x) : x[key];
        const el = rv.find((r) => r && r.key === v);
        if (el) {
            el.values.push(x);
        } else {
            rv.push({
                key: v,
                values: [x]
            });
        }
        return rv;
    }, []);
};

/**
 * Checks and returns the value of a parameter searched in the url query
 *
 * @param {string} name of the parameter
 * @return {string} value of the parameter
 */
instance.get = function(name) {
    var query = location.search.substr(1);
    return qs.parse(query)[name];
};

instance.parseQuery = function(query) {
    return qs.parse(query);
};

instance.paramsFromUrl = function() {
    const query = window.location.search.substr(1);
    return instance.parseQuery(query);
};


/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing. The function also has a property 'clear'
 * that is a function which will clear the timer to prevent previously scheduled executions.
 *
 * @source underscore.js
 * @see http://unscriptable.com/2009/03/20/debouncing-javascript-methods/
 * @param {Function} func
 *   Function to wrap.
 * @param {Number}   wait
 *   Timeout in ms (`60`).
 * @param {Boolean}  immediate
 *   Whether to execute at the beginning (`false`).
 *
 * @returns {Function}
 *   A new function that wraps the `func` function passed in.
 */
instance.debounce = function(func, wait = 60, immediate = false) {
    var timeout, args, context, timestamp, result;

    function later() {
        var last = Date.now() - timestamp;

        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            if (!immediate) {
                result = func.apply(context, args);
                context = args = null;
            }
        }
    }

    var debounced = function() {
        context = this;
        args = arguments;
        timestamp = Date.now();
        var callNow = immediate && !timeout;
        if (!timeout) timeout = setTimeout(later, wait);
        if (callNow) {
            result = func.apply(context, args);
            context = args = null;
        }

        return result;
    };

    debounced.clear = function() {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    };

    return debounced;
};

/**
 * Checks if given email is valid or not
 *
 * @param {string} email to be checked
 * @return {boolean} if the email is valid or not
 */
instance.isValidEmail = function(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export default instance;
