

// dependencies
var customEvent = require('@/helpers/helper').default.customEvent,
    breakpoint = require('@/libs/breakpoint').default,
    easings = require('@/libs/easings').default,
    pubsub = require('@/scaffold/pubsub').default;

export default function(container) {
    var instance = {},
        levelOneTriggers,
        // instance.mobileFilter, // boolean true/false
        mobile = {},

        /**
         * Fetch a filterNode from a given filterObject
         *
         * @param {object} filterObject to fetch the node from
         * @return {Element} filterNode
         */
        getFilterNode = function(filterObject) {
            return container.querySelector('[data-filter-id="' + filterObject.id + '"]');
        },

        /**
         * Add/Remove searchParam depending on clicked filter state
         *
         * @param {object} filterObject to change
         * @param {boolean} cancelEvent allows to cancel follow up event at the end of called functions
         */
        changeFilter = function(filterObject = {}, cancelEvent = false) {
            if(filterObject.state !== '0') {
                if(filterObject.state === '1') {
                    instance.addSearchParam(filterObject, cancelEvent);
                } else {
                    instance.removeSearchParam(filterObject, cancelEvent);
                }
            }
        },

        /**
         * Bind filter checkboxes to functionality (Level two)
         */
        bindLevelTwoCheckboxes = function() {
            var triggers = container.querySelectorAll('[data-filter-type="checkbox"]');
            if (triggers.length > 0) {
                triggers.forEach(function(trigger) {
                    trigger.addEventListener('click', function() {
                        instance.filterLevelTwoCheckbox(this);
                    });
                });
            }
        },

        /**
         * Bind filter radios to functionality (Level two)
         */
        bindLevelTwoRadio = function() {
            var radioMasters = container.querySelectorAll('[data-filter-type="radio-master"]');
            if (radioMasters.length > 0) {
                radioMasters.forEach(function(radioMaster) {
                    var options = radioMaster.querySelectorAll('[data-filter-type="radio"]');
                    options.forEach(function(option) {
                        option.addEventListener('click', function() {
                            instance.filterLevelTwoRadio(this);
                        });
                    });
                });
            }
        },

        /**
         * Changes the filters displayed in the (Level two view)
         *
         * @param {string} levelOneFilterId is the id of the child filters to be displayed
         */
        changeLevelTwoView = function(levelOneFilterId) {
            var toClose = container.querySelectorAll('[data-filter-leveltwo-view][data-parent-filter-id]:not([data-parent-filter-id="' + levelOneFilterId + '"])');
            if (toClose.length > 0) {
                toClose.forEach(function(secondLevelFilterGroup) {
                    secondLevelFilterGroup.setAttribute('data-filter-state', 1); // default state
                });
            }

            var toOpen = container.querySelector('[data-filter-leveltwo-view][data-parent-filter-id="' + levelOneFilterId + '"]');
            if (toOpen instanceof Element) {
                toOpen.setAttribute('data-filter-state', 2); // active state
            }
        },

        /**
         * State change handler (Level one)
         *
         * @param {string} filterId of the the rootlevel to change to (has radio like functionality) (Level one)
         * @param {object} cancelEvent flag whether event should be cancelled
         */
        changeLevelOne = function(filterId, cancelEvent = false) {

            levelOneTriggers.forEach(function(trigger) {

                if(trigger.getAttribute('data-filter-id') === filterId) {
                    trigger.setAttribute('data-filter-state', '2');
                } else if(trigger.getAttribute('data-filter-state') === '2') {
                    trigger.setAttribute('data-filter-state', '1');
                }
            });

            container.dispatchEvent(customEvent('filter.changed', {}));
            pubsub.publish('filterController.filter.changed');

            if (cancelEvent === false) {
                container.dispatchEvent(customEvent('filter.levelOneChange', {
                    filterId
                }));

                pubsub.publish('filterController.filter.levelOneChange', [{ filterId }]);
            }
        },

        /**
         * Click handler (Level one)
         */
        clickLevelOne = function() {
            var triggerState = this.getAttribute('data-filter-state');
            if(triggerState !== '2' && triggerState !== '0') {
                var filterId = this.getAttribute('data-filter-id');
                instance.changeLevelOne(filterId);
            }
        },

        /**
         * Bind root level filters (Level one)
         */
        bindlevelOne = function() {
            levelOneTriggers = container.querySelectorAll('[data-filter-rootlevel]');
            levelOneTriggers.forEach(function(trigger) {
                trigger.addEventListener('click', clickLevelOne);
            });
        },

        slideInLevelThree = function() {
            if (this.getAttribute('data-filter-state') === '0') {
                return;
            }

            this.setAttribute('data-filter-state-open', '');

            if (typeof mobile.levelTwoTitle !== 'undefined') {
                mobile.levelTwoTitle.querySelectorAll('[data-filter-title-element]')[0].innerHTML = this.querySelectorAll('[data-filter-title-element]')[0].innerHTML;
                mobile.levelOneTitle.style.display = 'none';
                mobile.levelTwoTitle.style.display = 'block';
            }
        },

        slideOutLevelThree = function(element) {
            element.removeAttribute('data-filter-state-open');
            mobile.levelOneTitle.style.display = 'block';
            mobile.levelTwoTitle.style.display = 'none';
        },

        openMobileLevelTwoOverlay = function() {
            if (mobile.levelOneUl) {
                mobile.levelOneTitle.querySelectorAll('[data-filter-title-element]')[0].innerHTML = container.querySelector('[data-filter-rootlevel][data-filter-state="2"]').querySelectorAll('[data-filter-title-element]')[0].innerHTML;
            }

            mobile.overlay.style.display = 'block';

            setTimeout(function() {
                mobile.overlay.notchAnimateStyle('opacity', 1, 300, easings.easeInOutCubic, function() {
                    mobile.body.classList.add('no-scroll');
                });
            }, 10);
        },

        closeMobileLevelTwoOverlay = function(time, callback) {

            if (time !== parseInt(time, 10)) {
                time = 300;
            }
            callback = callback || null;

            mobile.overlay.notchAnimateStyle('opacity', 0, time, easings.easeInOutCubic, function() {
                // console.log('this gets called at the end');
                mobile.overlay.querySelectorAll('[data-filter-state-open]').forEach(function(element) {
                    slideOutLevelThree(element);
                });
                mobile.body.classList.remove('no-scroll');
                mobile.overlay.style.display = 'none';

                if (callback instanceof Function) {
                    callback.call();
                }
            });
        },

        loseFocusLevelTwo = function() {
            slideOutLevelThree(this.parentNode.parentNode);
        },

        levelOneMobileHandler = function() {
            if (mobile.levelOneUl) {
                if (this.getAttribute('data-filter-id') === mobile.levelOneActive && mobile.levelOneUl.classList.contains('closed')) {
                    openLevelOne();
                } else if(this.getAttribute('data-filter-id') === mobile.levelOneActive && mobile.levelOneUl.classList.contains('closed') === false) {
                    closeLevelOne();
                } else if(this.getAttribute('data-filter-id') !== mobile.levelOneActive) {
                    mobile.levelOneActive = this.getAttribute('data-filter-id');
                    closeLevelOne();
                }
            }
        },

        showSubfilterButton = function() {
            if (mobile.levelTwoWarpper !== null) {
                mobile.levelTwoWarpper.classList.remove('is--slim');
            }
            mobile.levelTwoSubfilterButton.style.display = 'block';
            setTimeout(function() {
                mobile.levelTwoSubfilterButton.notchAnimateStyle('opacity', 1, 200, easings.easeInOutCubic);
            }, 10);
        },

        hideSubfilterButton = function() {
            mobile.levelTwoSubfilterButton.notchAnimateStyle('opacity', 0, 200, easings.easeInOutCubic, function() {
                mobile.levelTwoSubfilterButton.style.display = 'none';
                mobile.levelTwoWarpper.classList.add('is--slim');
            });

        },

        levelTwoMobileButtonHandler = function(event) {
            if(container.querySelectorAll('[data-parent-filter-id="' + event.detail.filterId + '"]').length > 0) {
                showSubfilterButton();
            } else {
                hideSubfilterButton();
            }
        },

        openLevelOne = function() {
            if (mobile.levelOneStatus === false && mobile.levelOneUl) {
                mobile.levelOneUl.classList.remove('closed');
                mobile.levelOneStatus = true;
            }
        },

        closeLevelOne = function() {
            if (mobile.levelOneStatus === true && mobile.levelOneUl) {
                mobile.levelOneUl.classList.add('closed');
                mobile.levelOneStatus = false;
            }
        },

        /**
         * Should indicate chen a filter is active on mobile
         */
        mobileFilterChangeHandler = function() {

            // var filters = instance.getFilterQuery();
            //
            // if(filters[0].children.length > 0) {
            //     mobile.overlayGreenButton.classList.remove('btn--inverted');
            // } else {
            //     mobile.overlayGreenButton.classList.add('btn--inverted');
            // }
        },

        addMobileHandlers = function() {

            // fill mobile object
            mobile.overlay = container.querySelector('[data-filter-l2-inner]');
            mobile.overlayGreenButton = container.querySelector('[data-filter-level-two-overlay-close-trigger]');
            mobile.levelOneTitle = container.querySelector('[data-filter-level-one-active-title]');
            mobile.levelTwoTitle = container.querySelector('[data-filter-level-two-active-title]');
            mobile.levelTwoSubfilterButton = container.querySelector('[data-filter-level-two-overlay-open-trigger]');
            mobile.levelTwoWarpper = container.querySelector('[data-filter-level-two]');
            mobile.levelOneUl = container.querySelector('[data-filter-level-one-list]');
            mobile.levelOneStatus = true; // true = open, false = closed
            mobile.levelOneActive;
            mobile.body = document.getElementsByTagName('body')[0];

            // add listeners
            window.addEventListener('touchmove', closeLevelOne);
            container.addEventListener('filter.levelOneChange', levelTwoMobileButtonHandler);
            container.addEventListener('filter.changed', mobileFilterChangeHandler);

            container.querySelectorAll('[data-filter-rootlevel]').forEach(function(trigger) {
                trigger.addEventListener('click', levelOneMobileHandler, true);
                if (trigger.getAttribute('data-filter-state') === '2') {
                    mobile.levelOneActive = trigger.getAttribute('data-filter-id');
                    levelTwoMobileButtonHandler({detail: { filterId: mobile.levelOneActive }});
                }
            });

            container.querySelectorAll('[data-filter-level-three-slide-in-trigger]').forEach(function(trigger) {
                if(trigger.getAttribute('data-filter-type') === 'dropdown') {
                    trigger.addEventListener('click', slideInLevelThree, true);
                }
            });

            container.querySelectorAll('[data-filter-lose-focus-level-two-trigger]').forEach(function(trigger) {
                trigger.addEventListener('click', loseFocusLevelTwo);
            });

            container.querySelectorAll('[data-filter-level-two-overlay-open-trigger]').forEach(function(trigger) {
                trigger.addEventListener('click', openMobileLevelTwoOverlay);
            });

            container.querySelectorAll('[data-filter-level-two-overlay-close-trigger]').forEach(function(trigger) {
                trigger.addEventListener('click', closeMobileLevelTwoOverlay);
            });
        },

        removeMobileHandlers = function() {

            closeMobileLevelTwoOverlay(1, function() {

                // revert to initial state
                mobile.overlay.style.display = '';
                mobile.overlay.style.opacity = '';

                if (mobile.levelOneUl) {
                    mobile.levelOneUl.classList.remove('closed');
                }

                // reset mobile object
                mobile = {};
            });

            // in case this is hidden, open it
            showSubfilterButton();

            // reset the level two button to default
            mobile.levelTwoSubfilterButton.style.display = '';
            mobile.levelTwoSubfilterButton.style.opacity = '';

            // remove listeners
            window.removeEventListener('touchmove', closeLevelOne);
            container.removeEventListener('filter.levelOneChange', levelTwoMobileButtonHandler);
            container.removeEventListener('filter.changed', mobileFilterChangeHandler);

            container.querySelectorAll('[data-filter-rootlevel]').forEach(function(trigger) {
                trigger.removeEventListener('click', levelOneMobileHandler);
            });

            container.querySelectorAll('[data-filter-level-three-slide-in-trigger]').forEach(function(trigger) {
                trigger.removeEventListener('click', slideInLevelThree);
            });

            container.querySelectorAll('[data-filter-lose-focus-level-two-trigger]').forEach(function(trigger) {
                trigger.removeEventListener('click', loseFocusLevelTwo);
            });

            container.querySelectorAll('[data-filter-level-two-overlay-open-trigger]').forEach(function(trigger) {
                trigger.removeEventListener('click', openMobileLevelTwoOverlay);
            });

            container.querySelectorAll('[data-filter-level-two-overlay-close-trigger]').forEach(function(trigger) {
                trigger.removeEventListener('click', closeMobileLevelTwoOverlay);
            });
        },

        deviceHandler = function() {
            if(breakpoint.curBreakpoint === 'small' && instance.mobileFilter !== true) {
                instance.mobileFilter = true;
                addMobileHandlers();
            } else if(breakpoint.curBreakpoint !== 'small' && instance.mobileFilter === true) {
                instance.mobileFilter = false;
                removeMobileHandlers();
            }
        },

        /**
         * Bind all the major listeners to the dOM for the Filter to work properly
         */
        bindListeners = function() {
            bindlevelOne();
            bindLevelTwoCheckboxes();
            bindLevelTwoRadio();

            breakpoint.initialized.then(() => {
                window.addEventListener('optimizedResize', deviceHandler, false);
            });
        },

        /**
         * Changed the displayed count of a filter
         *
         * @param {element} filter to be updated
         * @param {integer} count to be assigned
         */
        setFilterCount = function (filter, count) {
            if (!filter) {
                return;
            }

            if (filter.hasAttribute('data-filter-count')) {
                filter.setAttribute('data-filter-count', count);
            }

            // If a counter-element exists, set the counter
            if (filter.querySelector('[data-filter-count-element]')) {
                filter.querySelector('[data-filter-count-element]').innerHTML = count;
            }

            if (count === 0 && filter.getAttribute('data-filter-state') === '1') {
                filter.setAttribute('data-filter-state', '0');
            } else if (filter.getAttribute('data-filter-state') === '0' && count !== 0) {
                filter.setAttribute('data-filter-state', '1');
            }
        };



    instance.changeLevelOne = function(filterId, cancelEvent = false) {
        changeLevelOne(filterId, cancelEvent);
        changeLevelTwoView(filterId);
    };

    instance.filterLevelTwoCheckbox = function(checkbox, cancelEvent = false) {
        var filterObject = {
            id: checkbox.getAttribute('data-filter-id'),
            state: checkbox.getAttribute('data-filter-state'),
            title: checkbox.querySelector('[data-filter-title-element]').innerHTML
        };

        changeFilter(filterObject, cancelEvent);
    };

    instance.filterLevelTwoRadio = function(radio, cancelEvent = false) {
        var radioMaster = radio.closest('[data-filter-type="radio-master"]'),
            options = radioMaster.querySelectorAll('[data-filter-type="radio"]');

        var optionId = radio.getAttribute('data-filter-id'),
            optionValue = radio.getAttribute('data-filter-value'),
            state = radio.getAttribute('data-filter-state');

        if (state === '1') {
            radioMaster.setAttribute('data-filter-value', optionValue);

            options.forEach(function(temp) {
                if(temp.getAttribute('data-filter-state') === '2') {
                    temp.setAttribute('data-filter-state', '1');
                } else if(temp.getAttribute('data-filter-id') === optionId) {
                    temp.setAttribute('data-filter-state', '2');
                }
            });

            container.dispatchEvent(customEvent('filter.changed', {}));
            pubsub.publish('filterController.filter.changed');

            if (cancelEvent === false) {
                container.dispatchEvent(customEvent('filter.radioChange', {}));
                pubsub.publish('filterController.filter.radioChange', [{ radio }]);
            }
        }
    };

    instance.triggerFilter = function(filterId) {
        var filter = container.querySelector('[data-filter-id="' + filterId + '"]');
        filter.dispatchEvent(customEvent('click', {}));
    };

    /**
     * Makes function available from outside
     */
    instance.closeLevelOne = function() {
        if (instance.mobileFilter) {
            closeLevelOne();
        }
    };

    /**
     * Updates all the total count displays in the view
     *
     * @param {integer} totalcounter to set the new totalcounter displays to
     */
    instance.updateTotalCount = function(totalcounter) {

        totalcounter = totalcounter || 0;

        var elements = container.querySelectorAll('[data-resultcount-total]');
        if (elements instanceof NodeList && elements.length > 0) {
            elements.forEach(function(element) {
                element.innerHTML = totalcounter;
            });
        }
    };

    /**
     * Change alle the cound numbers of all the filters recieved in the result
     *
     * @param {object} filters as an array of each filter to change the count of
     * @param {boolean} searchAsYouType to tell if it a normal search or a searchAsYouType result
     */
    instance.updateFilterCounts = function(filters, searchAsYouType) {

        var activeTreffertyp = '';

        searchAsYouType = searchAsYouType || false;

        if (searchAsYouType) {
            var temp = container.querySelectorAll('[data-filter-id]');
            for (var i = 0; i < temp.length; i++) {
                setFilterCount(temp[i], 0);
            }

            for (var key in filters) {
                if (filters.hasOwnProperty(key)) {
                    setFilterCount(container.querySelector('[data-filter-id="' + key + '"]'), filters[key].count);
                }
            }

        } else {

            if (filters && filters.hasOwnProperty('treffertyp')) {
                filters.treffertyp.forEach(function(filter) {
                    if (filter.selected === true) {
                        activeTreffertyp = filter.name;
                    }
                });
            }

            container.querySelectorAll('[data-filter-rootlevel]').forEach(function(el) {
                if (filters && filters.hasOwnProperty('treffertyp')) {
                    var tmpFilterObj;

                    filters.treffertyp.forEach(function(filterObj) {

                        if (el.getAttribute('data-filter-id') === filterObj.name) {
                            tmpFilterObj = filterObj;
                            return false;
                        }
                    });

                    if (tmpFilterObj) {
                        // allcount += tmpFilterObj.count;
                        setFilterCount(el, tmpFilterObj.count);
                    } else {

                        // Filter is not given, therefor no results are available => therefore disable
                        setFilterCount(el, 0);
                    }
                } else {
                    setFilterCount(el, 0);
                }
            });

            if (filters && filters.hasOwnProperty('inhaltsart')) {
                var allcount = 0;

                var inhaltsarten = container.querySelectorAll('[data-filter-id*="' + activeTreffertyp + '-inhaltsart-"]:not([data-filter-id*="-inhaltsart-alle"])');

                inhaltsarten.forEach(function(inhaltsart) {
                    var tmpFilterObj;

                    filters.inhaltsart.forEach(function(filterObj) {

                        if (inhaltsart.getAttribute('data-filter-value') === filterObj.name) {
                            tmpFilterObj = filterObj;
                            return false;
                        }
                    });

                    if (tmpFilterObj) {
                        allcount += tmpFilterObj.count;
                        setFilterCount(inhaltsart, tmpFilterObj.count);
                    } else {

                        // Filter is not given, therefor no results are available => therefore disable
                        setFilterCount(inhaltsart, 0);
                    }
                });

                // Fetches reserved element with the id 'alle'
                var querySelector = '[data-filter-id="' + activeTreffertyp + '-inhaltsart-alle"]';
                var allElement = container.querySelector(querySelector);
                if (allElement instanceof Element) {
                    setFilterCount(allElement, allcount);
                }
            } else {
                var inhaltsartenAll = container.querySelectorAll('[data-filter-id*="-inhaltsart-"]');

                inhaltsartenAll.forEach(function(inhaltsart) {
                    setFilterCount(inhaltsart, 0);
                });
            }

            // Looping through the normal aditional filters (treffertyp == 'rezepte')
            container.querySelectorAll('[data-filter-type="dropdown"][data-parent-filter-id]').forEach(function(element) {

                var levelTwoId = element.getAttribute('data-filter-id');

                // In case when this is disabled by the search as you tube fuctionality, enable it again
                element.setAttribute('data-filter-state', 1);

                container.querySelectorAll('[data-filter-id][data-parent-filter-id="' + levelTwoId + '"]:not([data-filter-never-disable])').forEach(function(el) {
                    if (filters && filters.hasOwnProperty(levelTwoId)) {
                        var tmpFilterObj;

                        filters[levelTwoId].forEach(function(filterObj) {

                            if (el.getAttribute('data-filter-id') === filterObj.name) {
                                tmpFilterObj = filterObj;
                                return false;
                            }
                        });

                        if (tmpFilterObj) {
                            // allcount += tmpFilterObj.count;
                            setFilterCount(el, tmpFilterObj.count);
                        } else {

                            // Filter is not given, therefor no results are available => therefore disable
                            setFilterCount(el, 0);
                        }
                    } else {
                        setFilterCount(el, 0);
                    }
                });
            });
        }
    };

    /**
     * Generate the query that will be sent in the following ajaxrequest
     *
     * @param {boolean} [plain=false] Whether it should output filter query for solr search (false) or for url query string usage (true)
     * @return {object} filters
     */
    instance.getFilterQuery = function(plain = false) {

        var filter = {};

        if (container.hasAttribute('data-teaser-group-feed-basefilter')) {
            let basefilter = JSON.parse(container.getAttribute('data-teaser-group-feed-basefilter'));
            filter.treffertyp = basefilter.treffertyp;
        } else {
            const activeRootFilter = container.querySelector('[data-filter-rootlevel][data-filter-state="2"]');

            filter.treffertyp = activeRootFilter
                ? activeRootFilter.getAttribute('data-filter-id')
                : 'rezepte';
        }

        var childfilters = container.querySelectorAll('[data-parent-filter-id="' + filter.treffertyp + '"]');

        childfilters.forEach(function(child) {

            var childId = child.getAttribute('data-filter-id');

            if (child.getAttribute('data-filter-type') === 'dropdown') {

                var childsFilters = container.querySelectorAll('[data-filter-state="2"][data-parent-filter-id="' + childId + '"]'),
                    temp = [],
                    maxValues = [];

                if(childsFilters instanceof NodeList && childsFilters.length > 0) {
                    childsFilters.forEach(function(node) {
                        if (!plain && node.hasAttribute('data-filter-maxvalue')) {
                            maxValues.push(node.getAttribute('data-filter-id'));
                        } else if (!plain && node.hasAttribute('data-filter-sendquoted')) {
                            temp.push('"' + node.getAttribute('data-filter-id') + '"');
                        } else {
                            temp.push(node.getAttribute('data-filter-id'));
                        }
                    });
                }

                if(temp.length > 0) {
                    filter[childId] = temp;
                }

                if (maxValues.length > 0) {
                    filter[childId] = [Math.max.apply(null, maxValues)];
                }

            } else if(child.getAttribute('data-filter-type') === 'radio-master') {
                var activeRadio = container.querySelector('[data-filter-state="2"][data-parent-filter-id="' + childId + '"]');

                if (activeRadio.getAttribute('data-filter-value') !== '' && activeRadio.getAttribute('data-filter-value') !== 'alle') {
                    filter.inhaltsart = activeRadio.getAttribute('data-filter-value');
                }
            }
        });

        return filter;
    };

    /**
     * Change the state of a Filter option to state 2 => active
     *
     * @param {object} filterObject to change
     * @param {boolean} cancelEvent allows to cancel the event at the end
     */
    instance.addSearchParam = function(filterObject = {}, cancelEvent = false) {
        var filter = getFilterNode(filterObject),
            parentId = filter.getAttribute('data-parent-filter-id'),
            parent = container.querySelector('[data-filter-id="' + parentId + '"]');

        filter.setAttribute('data-filter-state', 2);

        filter.parentNode.parentNode.setAttribute('data-filter-state', 2);

        container.dispatchEvent(customEvent('filter.changed', filterObject));
        pubsub.publish('filterController.filter.changed');

        // dispatch the event on the container
        if (cancelEvent === false) {
            container.dispatchEvent(customEvent('filter.add', filterObject));
            pubsub.publish('filterController.filter.add', [{ filter, filterObject, parent }]);
        }
    };


    /**
     * Change the state of a Filter option to state 1 => neutral
     *
     * @param {object} filterObject to change
     * @param {boolean} cancelEvent allows to cancel the event at the end
     */
    instance.removeSearchParam = function(filterObject = {}, cancelEvent = false) {
        var filter = getFilterNode(filterObject);

        filter.setAttribute('data-filter-state', 1);

        if(filter.parentNode.querySelectorAll('[data-filter-state="2"]').length === 0) {
            filter.parentNode.parentNode.setAttribute('data-filter-state', 1);
        }

        container.dispatchEvent(customEvent('filter.changed', filterObject));
        pubsub.publish('filterController.filter.changed');

        // dispatch the event on the container
        if (cancelEvent === false) {
            container.dispatchEvent(customEvent('filter.remove', filterObject));
            pubsub.publish('filterController.filter.remove', [{ filter, filterObject }]);
        }
    };

    /**
     * Init all the good stuff
     *
     * @return {object} instance
     */
    instance.init = function() {

        container = container || window;

        instance.mobileFilter = false;

        bindListeners();

        breakpoint.initialized.then(() => {
            // Also executed via window resize
            deviceHandler();
        });

        return instance;
    };

    return instance.init();
};
