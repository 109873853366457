export default {
    fill: async function(svg, ease) {
        ease = ease || false;
        var el = svg.querySelector('.fill-up');
        const { default: TweenLite } = await import(/* webpackChunkName: "TweenLite" */ 'gsap/TweenLite');
        await import(/* webpackChunkName: "TweenLite" */ 'gsap/CSSPlugin'); // Adds to global scope
        if (el) {

            // TODO: changing style attribute is not very nice
            el.style.transform = '';
            if (ease) {
                TweenLite.to(el, 0.7, { y: '-100%', ease: CustomEase.create('custom', 'M0,0 C0.2,1 0.802,0.196 1,1') });
            } else {
                TweenLite.to(el, 0.7, { y: '-100%' });
            }
        }
    },
    empty: async function(svg, ease) {
        ease = ease || false;
        var el = svg.querySelector('.fill-up');

        // TODO: Add check if it's already empty, in that case just return
        const { default: TweenLite } = await import(/* webpackChunkName: "TweenLite" */ 'gsap/TweenLite');
        await import(/* webpackChunkName: "TweenLite" */ 'gsap/CSSPlugin'); // Adds to global scope
        if (el) {
            if (ease) {
                TweenLite.to(el, 0.7, { y: '0%', ease: CustomEase.create('custom', 'M0,0 C0.2,1 0.802,0.196 1,1') });
            } else {
                TweenLite.to(el, 0.7, { y: '0%' });
            }
        }
    }
};
