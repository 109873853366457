

// dependencies
var modules = {
    api: require('@/libs/api-factory').default
};

// vars
var defaults = {
    suggestedSearchContainer: '.suggested-search',
    template: '<li class="suggested-search__item"></li>',
    openthreshhold: 2,
    limit: 5,
    onValueChange: function() {},
    onSelect: function() {}
};

/**
 * Controls the suggested search
 *
 * @param {Element} container which to search inside for the suggested-search-container
 * @param {object} options to configure
 * @return {object} instance
 */
export default function(container, options) {
    var settings = Object.assign({}, defaults, options),
        instance = {},
        api,
        suggestedSearchContainer,
        suggested = [],
        suggestedNodes = [],
        flyoutStatus = 'closed',
        searchbarFocused = false,
        arrowsBound = false,
        searchInputValue = '',
        cachedSearchInputValue = '',
        activeSuggestion = null,
        lastInput = 'mouse',
        openthreshholdReached = false,

        /**
         * Handles the results from the api call
         *
         * @param {object} response is a json object containing the autocompletes results given by the api call
         */
        setSuggested = function(response) {
            suggested = response.search;
            updateView();
        },

        /**
         * Opens the autocomplete overlay
         */
        openSuggestedSearchFlyout = function() {
            flyoutStatus = 'open';
            activeSuggestion = null;
            suggestedSearchContainer.classList.remove('hidden');
            checkArrowBindings();
        },

        /**
         * Closes the autocomplete overlay
         */
        closeSuggestedSearchFlyout = function() {
            flyoutStatus = 'closed';
            suggestedSearchContainer.classList.add('hidden');
            checkArrowBindings();
        },

        /*
         * Fills in the new autocomplete suggestions and adds the nodes to the suggestedNodes array
         */
        fillFlyout = function() {

            var tempDiv, suggestedNode;

            suggestedSearchContainer.innerHTML = '';
            suggestedNodes = [];

            var rexex = new RegExp('(' + searchInputValue + ')', 'i'),
                item;

            for (var i = 0; i < suggested.length; i++) {

                if (i >= settings.limit) {
                    break;
                }

                item = suggested[i];

                tempDiv = document.createElement('div');
                tempDiv.innerHTML = settings.template;
                suggestedNode = tempDiv.children[0];
                item = item.replace(rexex, '<b>$1</b>');

                suggestedNode.innerHTML = item;

                suggestedNodes.push(suggestedNode);

                suggestedSearchContainer.appendChild(suggestedNode);
            }

            // suggested.forEach(function(item) {
            //
            //     tempDiv = document.createElement('div');
            //     tempDiv.innerHTML = settings.template;
            //     suggestedNode = tempDiv.children[0];
            //     item = item.replace(rexex, '<b>$1</b>');
            //
            //     suggestedNode.innerHTML = item;
            //
            //     suggestedNodes.push(suggestedNode);
            //
            //     suggestedSearchContainer.appendChild(suggestedNode);
            // });
        },

        /**
         * Gets callen whenever an update happens
         * Checks if the overlay should be shown or hidden, also binds events to autocomplete suggestions
         */
        updateView = function() {
            if (searchbarFocused === true && suggested.length > 0 && openthreshholdReached === true) {

                // filling the suggestions into the flyout
                fillFlyout();
                bindSuggestionNodes();

                if(flyoutStatus === 'closed') {
                    openSuggestedSearchFlyout();
                }
            } else if(searchbarFocused === true) {
                closeSuggestedSearchFlyout();
            }
        },

        /**
         * Adds/removes arrow bindings according to status of flyout & focus on searchbar
         */
        checkArrowBindings = function() {
            if(flyoutStatus === 'open' && searchbarFocused === true && arrowsBound === false) {
                bindArrows();
            } else if(flyoutStatus === 'closed' && arrowsBound === true) {
                unbindArrows();
            }
        },

        /**
         * Updates the view if mouseenter gets called or the arrows are beeing used to navigate through the autocomplete suggestions
         */
        updateActiveSuggestion = function() {

            suggestedNodes.forEach(function(element) {
                element.classList.remove('suggested-search__item--active');
            });

            if (activeSuggestion !== null) {
                suggestedNodes[activeSuggestion].classList.add('suggested-search__item--active');

                if (lastInput === 'keyboard') {
                    if (settings.onValueChange instanceof Function) {
                        settings.onValueChange(suggestedNodes[activeSuggestion].innerText);
                    }
                }
            } else if(lastInput === 'keyboard') {
                if (settings.onValueChange instanceof Function) {
                    settings.onValueChange(cachedSearchInputValue);
                }
            }
        },

        /**
         * Binds the autocomplete Node Elements
         */
        bindSuggestionNodes = function() {
            suggestedNodes.forEach(function(element, index) {
                element.addEventListener('mousedown', function() {
                    setLastInput('mouse');
                    activeSuggestion = index;

                    if (settings.onSelect instanceof Function) {
                        settings.onSelect.call(null, suggestedNodes[activeSuggestion].innerText);
                    }
                });

                element.addEventListener('mouseenter', function() {
                    setLastInput('mouse');
                    activeSuggestion = index;
                    updateActiveSuggestion();
                });

                element.addEventListener('mouseleave', function() {
                    setLastInput('mouse');
                    if (activeSuggestion === index) {
                        activeSuggestion = null;
                        updateActiveSuggestion();
                    }
                });
            });
        },

        /**
         * Sets last used input device
         *
         * @param {string} input device used (mouse/keyboard)
         */
        setLastInput = function(input) {
            lastInput = input;
        },

        /**
         * Navigates through the autocomplete overlay and sets the new active suggestion
         */
        arrowUp = function() {
            setLastInput('keyboard');

            if (activeSuggestion === null) {
                cachedSearchInputValue = searchInputValue;
                activeSuggestion = suggestedNodes.length - 1;
            } else if(activeSuggestion - 1 > -1) {
                activeSuggestion = activeSuggestion - 1;
            } else {
                activeSuggestion = null;
            }

            updateActiveSuggestion();
        },

        /**
         * Navigates through the autocomplete overlay and sets the new active suggestion
         */
        arrowDown = function() {
            setLastInput('keyboard');

            if (activeSuggestion === null) {
                cachedSearchInputValue = searchInputValue;
                activeSuggestion = 0;
            } else if(activeSuggestion + 1 < suggestedNodes.length) {
                activeSuggestion = activeSuggestion + 1;
            } else {
                activeSuggestion = null;
            }

            updateActiveSuggestion();
        },

        /**
         * Figgures out if keyup was the up or down arrow and acts accordingly
         *
         * @param {event} event of the keyup
         */
        arrowHandler = function(event) {
            if (event.which === 40) {
                arrowDown();
            } else if(event.which === 38) {
                arrowUp();
            }
        },

        /**
         * Binds the keyup event which will be filtered for the arrow keys later
         */
        bindArrows = function() {
            arrowsBound = true;
            container.addEventListener('keyup', arrowHandler);
        },

        /**
         * Unbinds the keyup event which will be filtered for the arrow keys later
         */
        unbindArrows = function() {
            arrowsBound = false;
            container.removeEventListener('keyup', arrowHandler);
        },

        /**
         * Calls the API with the given query, language & limit
         */
        apicall = function() {
            api.send({
                params: {
                    query: searchInputValue,
                    lang: store.lang,
                    limit: '10'
                }
            });
        };

    /**
     * Fires an api call when called (condition: string length is larger than threshhold)
     * Also resets active suggestion to defaul value
     *
     * @param {string} value to set the internal searchInputValue for
     */
    instance.queryChange = function(value) {
        searchInputValue = value;
        setLastInput('keyboard');
        activeSuggestion = null;

        if(value.length >= settings.openthreshhold) {
            openthreshholdReached = true;
            apicall();
        } else {
            openthreshholdReached = false;
            closeSuggestedSearchFlyout();
        }
    };

    /**
     * Sets the searchbarFocused to false, so the overlay will be hidden
     *
     * @return {object} instance
     */
    instance.searchbarFocus = function() {
        searchbarFocused = true;
        return instance;
    };

    /**
     * Sets the searchbarFocused to true, so the overlay could possibly be open
     *
     * @return {object} instance
     */
    instance.searchbarBlur = function() {
        searchbarFocused = false;
        closeSuggestedSearchFlyout();

        return instance;
    };

    /**
     * Forces the overlay to close
     *
     * @return {object} instance
     */
    instance.forceClose = function() {
        closeSuggestedSearchFlyout();

        return instance;
    };

    /**
     * Allows to adjust limit
     *
     * @param {integer} limit to be set
     * @return {object} instance
     */
    instance.setLimit = function(limit) {
        settings.limit = limit;

        return instance;
    };

    /**
     * Destroys instance to initial state before initiating
     *
     * @return {object} instance
     */
    instance.destroy = function() {

        // unbindListeners();
        unbindArrows();

        return instance;
    };

    /**
     * Initiates the searchbar functionality
     *
     * @return {object} instance
     */
    instance.init = function() {

        container = container || document;

        suggestedSearchContainer = container.querySelector(settings.suggestedSearchContainer);
        api = modules.api(store.apis.autocomplete, {
            params: {}, // params will be set dynamically
            success: function (response) {
                setSuggested(JSON.parse(response));
            }
        });

        return instance;
    };

    return instance.init();
};
