import breakpoint from '@/libs/breakpoint';

// const MOBILE_BREAKPOINT = 'small';

const createChart = ({ el, store }, Vue, moment) => new Vue({
    el,
    delimiters: ['((', '))'],
    data: {
        store,
        breakpoint: null,
        months: window.store.translations.months,
        date: moment(),
        scrollLeft: 0,
        hasScrollLeft: false,
        hasScrollRight: false
    },
    filters: {
        shortMonth(value) {
            return value.substring(0, 3);
        }
    },
    watch: {
        'store.items': function() {
            this.$nextTick(() => {
                this.setCurrentDate();
                this.updateScrollState();
            });
        },
        'scrollLeft': function(newValue) {
            this.$refs.months.scrollLeft = newValue;
            this.$refs.verticalGrid.scrollLeft = newValue;
            this.$refs.list.scrollLeft = newValue;
            this.updateScrollState();
        }
    },
    computed: {
        loaded() {
            return !!(this.store.items.length);
        },
        computedMonths() {
            // if (this.breakpoint === MOBILE_BREAKPOINT) {
            //     let result = [];

            //     result.push(...this.months.slice(this.date.month(), this.date.month() + 4));

            //     // Add additional months, if we go over the year-switch
            //     result.push(...this.months.slice(0, 4 - result.length));

            //     return result;
            // }

            return this.months;
        },
        preparedList() {
            return this.store.items.map(item => {
                return {
                    name: item.name,
                    link: item.link,
                    group: item.group,
                    bars: item.bars.map(bar => {
                        const blocks = [];

                        // Create moment objects ans set the year in case the API sends older seasons
                        const startMoment = moment(bar.start, 'YYYY-MM-DD').year(this.date.year());
                        const endMoment = moment(bar.end, 'YYYY-MM-DD').year(this.date.year());

                        // if (this.breakpoint === MOBILE_BREAKPOINT) {
                        //     if (endMoment < startMoment) {
                        //         endMoment.year(endMoment.year() + 1);
                        //     }

                        //     const pairs = [{
                        //         start: startMoment,
                        //         end: endMoment
                        //     }];

                        //     pairs.push({
                        //         start: moment(pairs[0].start).year(pairs[0].start.year() - 1),
                        //         end: moment(pairs[0].end).year(pairs[0].end.year() - 1)
                        //     });

                        //     pairs.push({
                        //         start: moment(pairs[0].start).year(pairs[0].start.year() + 1),
                        //         end: moment(pairs[0].end).year(pairs[0].end.year() + 1)
                        //     });

                        //     const currentStartDate = moment(this.date).date(1);
                        //     const currentEndDate = moment(currentStartDate).month(currentStartDate.month() + 4);

                        //     const tempBlocks = [];

                        //     pairs.forEach(({ start, end }) => {
                        //         let a;
                        //         let b;

                        //         if (start < currentStartDate) {
                        //             a = 0;
                        //         } else if (start >= currentStartDate && start <= currentEndDate) {
                        //             const hundred = currentEndDate - currentStartDate;
                        //             const precent = start - currentStartDate;
                        //             const fraction = 100 / hundred * precent;
                        //             a = Math.round(24 / 100 * fraction);
                        //         } else {
                        //             a = 24;
                        //         }

                        //         if (end > currentEndDate) {
                        //             b = 24;
                        //         } else if (end >= currentStartDate && end <= currentEndDate) {
                        //             const hundred = currentEndDate - currentStartDate; // Get our baseline
                        //             const precent = end - currentStartDate;
                        //             const fraction = 100 / hundred * precent;
                        //             b = Math.round(24 / 100 * fraction);
                        //         } else {
                        //             b = 0;
                        //         }

                        //         // Skip if a & b === 0 or a & b === 24
                        //         if (a !== b) {
                        //             tempBlocks.push({
                        //                 start: a,
                        //                 length: b - a
                        //             });
                        //         }
                        //     });

                        //     tempBlocks.sort((a, b) => {
                        //         return a.start - b.start;
                        //     });

                        //     let left = 0;

                        //     tempBlocks.forEach(block => {
                        //         let start = block.start - left;

                        //         left += block.start + block.length;

                        //         blocks.push({
                        //             classes:[
                        //                 'bar-grid-col-' + block.length,
                        //                 'bar-grid-left-' + start,
                        //                 'season-chart__color-bar'
                        //             ]
                        //         });
                        //     });
                        // } else {

                        if (startMoment > endMoment) {
                            blocks.push({
                                classes:[
                                    'bar-grid-col-' + (this.getColumnByDate(endMoment) - 0),
                                    'season-chart__color-bar'
                                ]
                            });

                            blocks.push({
                                classes:[
                                    'bar-grid-col-' + (24 - this.getColumnByDate(startMoment)),
                                    'bar-grid-left-' + (this.getColumnByDate(startMoment) - this.getColumnByDate(endMoment)),
                                    'season-chart__color-bar'
                                ]
                            });

                            endMoment.year(endMoment.year() + 1);
                        } else {
                            blocks.push({
                                classes:[
                                    'bar-grid-col-' + (this.getColumnByDate(endMoment) - this.getColumnByDate(startMoment)),
                                    'bar-grid-left-' + this.getColumnByDate(startMoment),
                                    'season-chart__color-bar'
                                ]
                            });
                        }

                        // }

                        return {
                            color: bar.color,
                            isSeason: bar.isSeason,
                            start: startMoment,
                            end: endMoment,
                            blocks
                        };
                    })
                };
            });
        },
        filteredList() {
            let results = this.preparedList;

            if (this.store.filter !== '') {
                results = results.filter((item) => item.group === this.store.filter);
            }

            if (this.store.isSeason) {
                results = results.filter((item) => {
                    const bar = item.bars.find(bar => bar.isSeason);

                    if (!bar) {
                        return false;
                    }

                    if (bar.start.year() !== bar.end.year()) {
                        return bar.start <= this.date || this.date <= bar.end;
                    }

                    return ((bar.start <= this.date) && (this.date <= bar.end));
                });
            }

            return results;
        }
    },
    mounted() {
        this.setCurrentDate();
        this.updateScrollState();

        breakpoint.initialized.then(() => {
            this.breakpoint = breakpoint.curBreakpoint;
        });

        this.$refs.months.addEventListener('scroll', this.scrollMonth);
        this.$refs.verticalGrid.addEventListener('scroll', this.scrollVerticalGrid);
        this.$refs.list.addEventListener('scroll', this.scrollList);

        window.addEventListener('optimizedResize', this.setCurrentDate);
        window.addEventListener('breakpointChange', this.setBreakpoint);
    },
    beforeDestroy() {
        this.$refs.months.removeEventListener('scroll', this.scrollMonth);
        this.$refs.verticalGrid.removeEventListener('scroll', this.scrollVerticalGrid);
        this.$refs.list.removeEventListener('scroll', this.scrollList);

        window.removeEventListener('optimizedResize', this.setCurrentDate);
        window.removeEventListener('breakpointChange', this.setBreakpoint);
    },
    methods: {
        scrollMonth() {
            this.scrollLeft = this.$refs.months.scrollLeft;
        },
        scrollVerticalGrid() {
            this.scrollLeft = this.$refs.verticalGrid.scrollLeft;
        },
        updateScrollState() {
            this.hasScrollLeft = this.scrollLeft > 0;
            this.hasScrollRight = Math.ceil(this.scrollLeft + this.$refs.list.offsetWidth) < this.$refs.list.scrollWidth;
        },
        scrollList() {
            this.scrollLeft = this.$refs.list.scrollLeft;
        },
        getDate(day, month, year = new Date().getFullYear()) {
            return new Date(year + '-' + month + '-' + day);
        },
        setBreakpoint(event) {
            this.breakpoint = event.detail.new;
        },
        setCurrentDate() {
            // const currentElement = this.$el.querySelectorAll('.vertical-grid')[(this.breakpoint === MOBILE_BREAKPOINT) ? 0 : this.date.month()];
            const currentElement = this.$el.querySelectorAll('.vertical-grid')[this.date.month()];
            const dayInPixels = currentElement.offsetWidth / this.date.daysInMonth(); // Calculate the pixelvalue that represents one day in the current month
            this.$refs.currentDate.style.left = currentElement.offsetLeft + (dayInPixels * this.date.date()) + 'px'; // Set the left-position so the element represents the current day
        },
        getColumnByDate(date, mid = 10, full = 23) {
            let columns = date.month() * 2;
            const days = date.date();

            if (days >= full) {
                columns += 2; // 30th of January should result in starting in february
            } else if (days >= mid) {
                columns += 1;
            }

            return columns;
        }
    }
});

export default createChart;
